import React, { useState } from "react";

import StashedCreatedLogo from "assets/stash-created.svg";

import SuccessLogo from "assets/success.svg";

import "../../pages/auth/TransactionFlow/TransactionSuccess/styles.css";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import Button from "components/NewButton";
import AuthHeader from "components/AuthHeader";
import { useAppDispatch, useAppSelector } from "store/store";
import { setIsAccountCreated, setUserSpendingDetails } from "@slices/appSlice";
import TextInput from "components/TextInput";

// import USDSVG from "assets/USD.svg";
import USDSVG from "assets/USDIcon2.svg";

import { decryptMessage, encryptMessage, showAlert } from "utils/utils";
import { SUPPORTED_NETWORKS } from "constants/chains";
import Web3 from "web3";
import axios from "axios";
import { BASE_URL } from "constants/index";

const PerformTransaction = () => {
  const [loading, setLoading] = useState(false);
  const [transactionAmount, setTransactionAmount] = useState("");

  const { userSpendingDetails, rootAccountInfo, activeNetwork } =
    useAppSelector((state) => state.app);
  const { hashedPassword } = useAppSelector((state) => state.wallet);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleNavigateForward = async () => {
    // const pkey = decryptMessage(rootAccountInfo.secret, hashedPassword);
    try {
      // const web3 = new Web3(
      //   SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS].rpc
      // );

      // const signature = web3.eth.accounts.sign(transactionAmount, pkey);
      // console.log(
      //   "🚀 ~ file: ConfirmDrawPattern.tsx:44 ~ handleNavigateForward ~ signature:",
      //   signature
      // );
      // const { data } = await axios.post(
      //   BASE_URL + "/security-question/setSpendingLimit",
      //   {
      //     address: rootAccountInfo.address,
      //     signature: signature.signature,
      //     spendingLimit: transactionAmount,
      //   }
      // );
      // if (data) {
      dispatch(
        setUserSpendingDetails({
          spendinglimit: transactionAmount,
        })
      );
      // }
      navigate("/draw-pattern");
    } catch (error) {
      // @ts-ignore
      showAlert(error?.message);
    }
  };

  const handleNavigateBack = () => {
    navigate("/security-pattern");
  };

  const handleChange = (value: string) => {
    const regex = /^[0-9]*(\.[0-9]{0,8})?$/;

    if (value === "" || regex.test(value)) {
      setTransactionAmount(value);
    }
  };

  return (
    <div>
      <div
        className="transaction-box"
        style={{
          marginTop: "10px",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            margin: "20px 0px 20px 0px",
            fontWeight: "700",
            textAlign: "center",
            fontSize: "28px",
          }}
        >
          Enter Daily Approved Expenditure
        </Typography>

        <Typography
          variant="body2"
          sx={{
            margin: "0px 0px 20px 0px",
            color: "rgba(26, 28, 32, 0.5)",
            fontWeight: "500",
            textAlign: "center",
            width: "85%",
            fontSize: "17px",
            fontFamily: "Helvetica Neue",
          }}
        >
          When you transact over this amount during a 24h period, you will be
          asked to enter your security pattern
        </Typography>

        <div>
          <Typography
            variant="body2"
            textAlign={"left"}
            sx={{
              fontWeight: "500",
              fontSize: "18px",
              fontFamily: "Helvetica Neue",
              textAlign: "left",
              color: "#1A1C2080",
              // position: "absolute",
              // top: "100px",
              // left: "233px",
            }}
          >
            Enter Amount
          </Typography>

          <TextInput
            title=""
            value={transactionAmount}
            showEndIcon={false}
            onChange={handleChange}
            type={"text"}
            style={{
              width: "520px",
              fontSize: "25px",
              fontWeight: "700",
              padding: "0px 15px 15px 15px",
              fontfamily: "Space Grotesk",
              // position: "relative",
              // margin: "30px",
            }}
            customIcon={USDSVG}
            iconText={{
              label: "USD",
              styles: {
                fontSize: "15px",
                fontWeight: "700",
                color: "##1A1C20BF",
                fontFamily: "Space Grotesk",
                margin: "0 0 0 10px",
              },
            }}
          />
        </div>

        <div className="transaction-wrapper" style={{ top: 20 }}>
          <Typography
            variant="body2"
            sx={{
              color: "#1A1C20",
              fontWeight: "700",
              textAlign: "center",
              fontSize: "17px",
              fontFamily: "Space Grotesk",
              cursor: "pointer",
              margin: "auto auto auto 50px",
            }}
            onClick={handleNavigateBack}
          >
            Back
          </Typography>
          <Button
            title="Continue"
            onClick={handleNavigateForward}
            loading={loading}
            disabled={+transactionAmount <= 0}
            style={{
              margin: 0,
              width: "260px",
              padding: "15px 0px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PerformTransaction;
