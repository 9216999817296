import {
  providers,
  Wallet,
  Contract,
  BytesLike,
  ethers,
  BigNumber,
  BigNumberish,
  Bytes,
} from "ethers";
import {
  EntryPoint__factory,
  TokenPaymaster__factory,
  Token__factory,
} from ".//types/ethers-contracts";
import { IUserOperation } from "userop";
import {
  CallDataType,
  EMPTY_CALLDATA,
  EntryPoint_Address,
  ExecuteCall,
  FactoryData,
  simulateHandleOps,
  Paymaster_Owner_Address,
  simulateValidation,
  sendUserOp,
  TransferData,
  Paymaster_Token_Address,
  getPartialUserOpForDepositPaymaster,
  getExecuteCallDataForBundler,
  getBatchDataForBundler,
  getNonceForBuilder,
  SALT,
} from ".";
import { signUserOp } from ".//utils/helper";
import axios from "axios";
// import 'dotenv/config';
import { arrayify } from "ethers/lib/utils";
import { getTokenDollarValue } from "../utils/portfolio";

const rpcEndpoint =
  "https://polygon-mainnet.g.alchemy.com/v2/HBxGEElD4fSo3gWukvZFV9YTKO4OvCnw";
const bundlerRPC =
  "https://api.stackup.sh/v1/node/221b5cfa6d4f5cff2e221d693b2e953d49d9797d0f18f2e6d119482223a92a37";
const USDC = "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174";
const NATIVE = "0x";
const ZERO = "0";

const DEFAULT_GAS_LIMITS = "5000000";
const DEFAULT_PRE_VERIFICATION_GAS = "81000";
const DEPLOYMENT_GAS = "200000";

interface CallLimits {
  verificationGasLimit: BigNumber;
  callGasLimit: BigNumber;
}

interface SponsorData {
  tokenToPayIn: string;
  beneficiary: string;
  sponsor: string;
  tokenPrice: BigNumber;
  sponsorWallet: Wallet;
}

// (async function () {
//     /**
//      * @notice: The wallet paying for the gas needs to have at least $5 worth of asset depposited
//      *          into it.
//      */
//     await txSubmissionOrderPostpaid();
// })();

/**
 * @notice The paymaster should have atleast have 3.7 matic (i.e 180 gas price) Matic for the execution to be simulated sucessfully.
 *         On ethereum mainnet, the paymaster should have 0.4 Eth (i.e 40 gas price) ether to cater for larger transactions.
 *         These are only estimations, actual gas will be very less compared to what we have.
 */
export async function txSubmissionOrderPostpaid({
  sponsorWallet,
  currentWallet,
  sponsorAccountAddress,
  counterfactual,
  userCallDataArray,
  selectedDepositTokenAddress,
  transferTokenDecimal,
  isHighFees,
  isAccountDeployed,
}: {
  sponsorWallet: Wallet;
  sponsorAccountAddress: string;
  currentWallet: Wallet;
  counterfactual: string;
  userCallDataArray: ExecuteCall[];
  selectedDepositTokenAddress: string;
  transferTokenDecimal: number;
  isHighFees: boolean;
  isAccountDeployed: boolean;
}) {
  //   const sponsorWallet = new Wallet(
  //     "cd4e40cfd75d04b93dfe319614ba85f972110bbe90aa4c4023f596be2dd724dd"
  //   );
  const provider = new ethers.providers.JsonRpcProvider(rpcEndpoint);

  //   const userWallet = new Wallet(
  //     "cd4e40cfd75d04b93dfe319614ba85f972110bbe90aa4c4023f596be2dd724dd"
  //   );

  const userWallet = currentWallet;

  //   const counterfactual = "0x37eA4e0f7d1A15225AEe4a05CfB9FcF1d2B4e4Ed";
  //   const beneficiary = "0x37eA4e0f7d1A15225AEe4a05CfB9FcF1d2B4e4Ed";

  //   let userCallDataArray: ExecuteCall[] = [];

  // ------------------------- Dummy action ---------------------------------------
  // Assuming the user wants to transfer some token using postpaid transaction.
  //   const tokenAddress = "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174";
  //   const userAction = Token__factory.createInterface().encodeFunctionData(
  //     "transfer",
  //     [Paymaster_Owner_Address, "10"]
  //   );
  //   userCallDataArray.push({
  //     to: tokenAddress,
  //     value: "0",
  //     calldata: userAction,
  //   });

  // const tokenAddress = "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE";
  // const userAction = TokenPaymaster__factory.createInterface().encodeFunctionData("addDeposit", [
  //     tokenAddress, counterfactual, "3000000000000000000"
  // ]);
  // userCallDataArray.push({ to: Paymaster_Token_Address, value: "3000000000000000000", calldata: userAction });

  const chainId = 137;
  let nonce: any;
  let feeData: any = {};
  if (chainId == 137) {
    let res;
    console.log("file: sasdasdasdsads.ts:309  res:", res);
    try {
      const [data, nonceData] = await Promise.all([
        axios.get(
          "https://gpoly.blockscan.com/gasapi.ashx?apikey=key&method=gasoracle"
        ),
        getNonceForBuilder(counterfactual, SALT, provider),
      ]);
      res = data;
      nonce = nonceData;

      console.log("file: sasdasdasdsads.ts:313  res:", res);
    } catch (error: any) {
      console.error("There was an error. Reason: ", error.message);
    }
    if (res?.status == 200 && res?.data.message) {
      const SLACK_FEES = isHighFees ? 20 : 12;

      const freshfeeData = res.data.result;
      // Get the base fee & add about 30% increase in gas (Case where the block gets full consecutively
      // for 4 blocks)
      feeData.baseFee = freshfeeData.suggestBaseFee;
      feeData.fastFee = freshfeeData.FastGasPrice;
      feeData.approximateFastFee = (
        parseFloat(freshfeeData.FastGasPrice) + SLACK_FEES
      ).toString();
    }
  }

  const depositedDollarPrice = await getTokenDollarValue(
    selectedDepositTokenAddress
  );
  // const normalizedTokenPrice = ethers.utils.parseUnits("0.6135", "ether");
  const normalizedTokenPrice = ethers.utils.parseUnits(
    String(depositedDollarPrice),
    "ether"
  );
  const ISNATIVE =
    selectedDepositTokenAddress ==
      "0x0000000000000000000000000000000000000000" ||
    selectedDepositTokenAddress == "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270";

  let userCallDataArray1: ExecuteCall[] = [...userCallDataArray];
  let sponsorData: SponsorData = {
    tokenToPayIn: ISNATIVE
      ? "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE"
      : selectedDepositTokenAddress, // Native
    beneficiary: counterfactual,
    sponsor: sponsorAccountAddress,
    tokenPrice: normalizedTokenPrice,
    sponsorWallet: sponsorWallet,
  };

  const preOp = await preFlightPostpaid(
    counterfactual,
    userWallet,
    provider,
    userCallDataArray1,
    sponsorData,
    isHighFees,
    counterfactual,
    isAccountDeployed,
    feeData,
    nonce
  );

  // const preSimulateHandleOpsSucess = await simulateHandleOps(
  //   preOp,
  //   {
  //     to: preOp.sender,
  //     value: "0",
  //     calldata: preOp.callData,
  //   },
  //   provider
  // );
  // if (!preSimulateHandleOpsSucess) {
  //   throw new Error("Pre: HandleOps Simulation failed.");
  // }

  // const preSimulateValidationOpsSucess = await simulateValidation(
  //   preOp,
  //   provider
  // );
  // if (!preSimulateValidationOpsSucess) {
  //   throw new Error("Pre: Simulation Validation failed.");
  // }

  // console.log("Pre: Simulation sucessful.");

  // 5. Hand over the userOp to get near approximate values of calldataGasLimit &verificationGasLimit
  let limits: CallLimits = await getGasLimitsWithOp(preOp, provider);

  // 6. Repeat step 2-4
  let userCallDataArray2: ExecuteCall[] = [...userCallDataArray];
  const finalOp = await postFlightPostpaid(
    counterfactual,
    userWallet,
    provider,
    limits,
    userCallDataArray2,
    sponsorData,
    preOp,
    isHighFees,
    feeData
  );

  const totalGasLimit = limits.callGasLimit
    .add(limits.verificationGasLimit)
    .add(DEFAULT_PRE_VERIFICATION_GAS);
  // 7. Perform invariant checks

  const [
    simulateHandleOpsSucess,
    simulateValidationOpsSucess,
    feeInToken,
    feeInUSDC,
  ] = await Promise.all([
    simulateHandleOps(
      finalOp,
      {
        to: finalOp.sender,
        value: "0",
        calldata: finalOp.callData,
      },
      provider
    ),
    simulateValidation(finalOp, provider),
    getTokensAgainstGas(
      BigNumber.from(finalOp.maxFeePerGas),
      totalGasLimit,
      selectedDepositTokenAddress,
      transferTokenDecimal
    ),
    getTokensAgainstGas(
      BigNumber.from(finalOp.maxFeePerGas),
      totalGasLimit,
      "0x2791bca1f2de4661ed88a30c99a7a9449aa84174", // USDC token address for Polygon
      6
    ),
  ]);

  // const simulateHandleOpsSucess = await simulateHandleOps(
  //   finalOp,
  //   {
  //     to: finalOp.sender,
  //     value: "0",
  //     calldata: finalOp.callData,
  //   },
  //   provider
  // );
  if (!simulateHandleOpsSucess)
    throw new Error("Post: HandleOps Simulation failed.");

  // const simulateValidationOpsSucess = await simulateValidation(
  //   finalOp,
  //   provider
  // );
  if (!simulateValidationOpsSucess)
    throw new Error("Post: Simulation Validation failed.");
  console.log("Post: Simulation sucessful.");

  // const [feeInToken, feeInUSDC] = await Promise.all([
  //   getTokensAgainstGas(
  //     BigNumber.from(finalOp.maxFeePerGas),
  //     totalGasLimit,
  //     selectedDepositTokenAddress,
  //     transferTokenDecimal
  //   ),
  //   getTokensAgainstGas(
  //     BigNumber.from(finalOp.maxFeePerGas),
  //     totalGasLimit,
  //     "0x2791bca1f2de4661ed88a30c99a7a9449aa84174", // USDC token address for Polygon
  //     6
  //   ),
  // ]);
  const feesInEther = BigNumber.from(finalOp.maxFeePerGas).mul(totalGasLimit);
  if (sponsorData.tokenToPayIn == NATIVE) {
    console.log(
      "Approximate fee (ether)  : ",
      ethers.utils.formatEther(feesInEther),
      "Eth"
    );
  }
  console.log(
    {
      finalOp,
      usdcFee: ethers.utils
        .formatUnits(BigNumber.from(feeInUSDC), transferTokenDecimal)
        .toString(),
      usdcFeeInBigNumber: ethers.utils.formatUnits(
        BigNumber.from(feeInUSDC),
        transferTokenDecimal
      ),
      transferGasFee: ethers.utils
        .formatUnits(BigNumber.from(feeInToken), transferTokenDecimal)
        .toString(),
      transferGasFeeBigNumber: ethers.utils.formatUnits(
        BigNumber.from(feeInToken),
        transferTokenDecimal
      ),
    },
    "FINAL RESULT"
  );
  return {
    finalOp,
    usdcFee: ethers.utils.formatUnits(BigNumber.from(feeInUSDC), 6).toString(),
    usdcFeeInBigNumber: ethers.utils.formatUnits(BigNumber.from(feeInUSDC), 6),
    transferGasFee: ethers.utils
      .formatUnits(BigNumber.from(feeInToken), transferTokenDecimal)
      .toString(),
    transferGasFeeBigNumber: ethers.utils.formatUnits(
      BigNumber.from(feeInToken),
      transferTokenDecimal
    ),
  };

  // 8. Send the transaction to bundler for on-chain execution.
  // const response = await sendUserOp(finalOp, bundlerRPC, rpcEndpoint);
  // const userOPResponse: any = await response.wait();
  // console.log("Tx Hash :", userOPResponse?.transactionHash)
  // console.log("success status :", userOPResponse?.args.success)
  // console.log("actualGasCost  :", Number(userOPResponse?.args.actualGasCost))
  // console.log("actualGasUsed  :", Number(userOPResponse?.args.actualGasUsed))
}

async function preFlightPostpaid(
  beneficiary: string,
  userWallet: Wallet,
  provider: providers.JsonRpcProvider,
  userCallDataArray: ExecuteCall[],
  sponsorData: SponsorData,
  isHighFees: boolean,
  counterfactual: string,
  isAccountDeployed: boolean,
  apiFeeData: any,
  nonce: any
) {
  /**  Alternatively, get chainId this from by,
   *   const chainId = (await provider.getNetwork()).chainId
   */
  const chainId = 137;
  let feeData = apiFeeData;
  // let nonce: any;
  // // 1. Get the maxFeePerGas from the API.
  // let feeData: any = {};
  // if (chainId == 137) {
  //   let res;
  //   try {
  //     const [data, nonceData] = await Promise.all([
  //       axios.get(
  //         "https://gpoly.blockscan.com/gasapi.ashx?apikey=key&method=gasoracle"
  //       ),
  //       getNonceForBuilder(counterfactual, SALT, provider),
  //     ]);
  //     res = data;
  //     nonce = nonceData;
  //   } catch (error: any) {
  //     console.error("There was an error. Reason: ", error.message);
  //   }
  //   if (res?.status == 200 && res?.data.message) {
  //     const SLACK_FEES = isHighFees ? 20 : 12;

  //     const freshfeeData = res.data.result;
  //     // Get the base fee & add about 30% increase in gas (Case where the block gets full consecutively
  //     // for 4 blocks)
  //     feeData.baseFee = freshfeeData.suggestBaseFee;
  //     feeData.fastFee = freshfeeData.FastGasPrice;
  //     feeData.approximateFastFee = (
  //       parseFloat(freshfeeData.FastGasPrice) + SLACK_FEES
  //     ).toString();
  //   }
  // }

  // 2. Pre-fill reasonable calldataGasLimit, verificationGasLimit & maxFeePerGas in userOp
  //    that will not revert on validation simulation.

  // const isDeployed = await provider.getCode(beneficiary);

  let resultOp;
  if (!isAccountDeployed) {
    resultOp = await getPartialUserOpForDepositPaymaster(
      userWallet.address,
      FactoryData.FactoryCreateSender,
      CallDataType.Batch,
      userCallDataArray,
      rpcEndpoint,
      counterfactual,
      nonce
    );
  } else {
    resultOp = await getPartialUserOpForDepositPaymaster(
      userWallet.address,
      FactoryData.Empty,
      CallDataType.Batch,
      userCallDataArray,
      rpcEndpoint,
      counterfactual,
      nonce
    );
  }

  resultOp.maxFeePerGas = ethers.utils.parseUnits(
    Math.ceil(feeData.approximateFastFee).toString(),
    "9"
  );
  resultOp.maxPriorityFeePerGas = ethers.utils.parseUnits(
    Math.ceil(feeData.approximateFastFee).toString(),
    "9"
  );
  resultOp.preVerificationGas = BigNumber.from(DEFAULT_PRE_VERIFICATION_GAS);

  resultOp.callGasLimit = BigNumber.from(DEFAULT_GAS_LIMITS);
  resultOp.verificationGasLimit = BigNumber.from(DEFAULT_GAS_LIMITS);

  let callDataUserOp: BytesLike;
  if (userCallDataArray.length == 1) {
    callDataUserOp = getExecuteCallDataForBundler(
      CallDataType.Execute,
      userCallDataArray[0]
    );
  } else {
    callDataUserOp = getBatchDataForBundler(
      CallDataType.Batch,
      userCallDataArray
    );
  }
  resultOp.callData = callDataUserOp;

  console.log("feeData", feeData);

  // Get the nonce of the person who is getting sponsored. In this case it is self
  const ItokenPaymasterConnect = await TokenPaymaster__factory.connect(
    Paymaster_Token_Address,
    provider
  );
  const receiverNonce = await ItokenPaymasterConnect.receiverNonce(beneficiary);

  const hash = await ItokenPaymasterConnect.getHash(
    sponsorData.tokenToPayIn,
    sponsorData.sponsor,
    sponsorData.beneficiary,
    sponsorData.tokenPrice
  );

  let sig = await sponsorData.sponsorWallet.signMessage(arrayify(hash));

  let dataToBeSignedToBeConcatenated = ethers.utils.solidityPack(
    ["address", "address", "address", "address", "uint256", "uint256"],
    [
      Paymaster_Token_Address,
      sponsorData.tokenToPayIn,
      sponsorData.sponsor, // jis se paise katne hen; root
      sponsorData.beneficiary,
      receiverNonce,
      sponsorData.tokenPrice,
    ]
  );

  const finalPaymasterAndData =
    dataToBeSignedToBeConcatenated + sig.split("0x")[1];

  const signedPaymasterOp = {
    ...resultOp,
    paymasterAndData: finalPaymasterAndData,
  };

  // 4. Sign the tx form the user
  let signature = signUserOp(
    signedPaymasterOp,
    userWallet,
    EntryPoint_Address,
    chainId
  );

  const finalOp = {
    ...signedPaymasterOp,
    signature: signature,
  };

  console.log("Modified resultOp (Pre): ", finalOp);

  return finalOp;
}

async function postFlightPostpaid(
  beneficiary: string,
  userWallet: Wallet,
  provider: providers.JsonRpcProvider,
  callLimits: CallLimits | null,
  userCallDataArray: ExecuteCall[],
  sponsorData: SponsorData,
  userOp: IUserOperation,
  isHighFees: boolean,
  apiFeeData: any
) {
  /**  Alternatively, get chainId this from by,
   *   const chainId = (await provider.getNetwork()).chainId
   */
  const chainId = 137;
  // 1. Get the maxFeePerGas from the API.
  // let feeData: any = {};
  // if (chainId == 137) {
  //   let res;
  //   try {
  //     res = await axios.get(
  //       "https://gpoly.blockscan.com/gasapi.ashx?apikey=key&method=gasoracle"
  //     );
  //   } catch (error: any) {
  //     console.error("There was an error. Reason: ", error.message);
  //   }
  //   if (res?.status == 200 && res?.data.message) {
  //     const SLACK_FEES = isHighFees ? 20 : 12;

  //     const freshfeeData = res.data.result;
  //     // Get the base fee & add about 30% increase in gas (Case where the block gets full consecutively
  //     // for 4 blocks)
  //     feeData.baseFee = freshfeeData.suggestBaseFee;
  //     feeData.fastFee = freshfeeData.FastGasPrice;
  //     feeData.approximateFastFee = (
  //       parseFloat(freshfeeData.FastGasPrice) + SLACK_FEES
  //     ).toString();
  //   }
  // }

  let feeData = apiFeeData;

  // 2. Pre-fill reasonable calldataGasLimit, verificationGasLimit & maxFeePerGas in userOp
  //    that will not revert on validation simulation.
  let resultOp = userOp;

  resultOp.maxFeePerGas = ethers.utils.parseUnits(
    Math.ceil(feeData.approximateFastFee).toString(),
    "9"
  );
  resultOp.maxPriorityFeePerGas = ethers.utils.parseUnits(
    Math.ceil(feeData.approximateFastFee).toString(),
    "9"
  );
  resultOp.preVerificationGas = BigNumber.from(DEFAULT_PRE_VERIFICATION_GAS);

  resultOp.callGasLimit = callLimits!.callGasLimit;
  resultOp.verificationGasLimit = callLimits!.verificationGasLimit;

  if (resultOp.initCode.length > 2) {
    resultOp.callGasLimit = callLimits!.callGasLimit.add(DEPLOYMENT_GAS);
    resultOp.verificationGasLimit =
      callLimits!.verificationGasLimit.add(DEPLOYMENT_GAS);
  }

  let callDataUserOp: BytesLike;
  if (userCallDataArray.length == 1) {
    callDataUserOp = getExecuteCallDataForBundler(
      CallDataType.Execute,
      userCallDataArray[0]
    );
  } else {
    callDataUserOp = getBatchDataForBundler(
      CallDataType.Batch,
      userCallDataArray
    );
  }
  resultOp.callData = callDataUserOp;

  console.log("feeData", feeData);

  // Get the nonce of the person who is getting sponsored. In this case it is self
  const ItokenPaymasterConnect = await TokenPaymaster__factory.connect(
    Paymaster_Token_Address,
    provider
  );
  const receiverNonce = await ItokenPaymasterConnect.receiverNonce(beneficiary);

  const hash = await ItokenPaymasterConnect.getHash(
    sponsorData.tokenToPayIn,
    sponsorData.sponsor,
    sponsorData.beneficiary,
    sponsorData.tokenPrice
  );

  let sig = await sponsorData.sponsorWallet.signMessage(arrayify(hash));

  let dataToBeSignedToBeConcatenated = ethers.utils.solidityPack(
    ["address", "address", "address", "address", "uint256", "uint256"],
    [
      Paymaster_Token_Address,
      sponsorData.tokenToPayIn,
      sponsorData.sponsor, // jis se paise katne hen; root
      sponsorData.beneficiary,
      receiverNonce,
      sponsorData.tokenPrice,
    ]
  );

  const finalPaymasterAndData =
    dataToBeSignedToBeConcatenated + sig.split("0x")[1];

  const signedPaymasterOp = {
    ...resultOp,
    paymasterAndData: finalPaymasterAndData,
  };

  // 4. Sign the tx form the user
  let signature = signUserOp(
    signedPaymasterOp,
    userWallet,
    EntryPoint_Address,
    chainId
  );

  const finalOp = {
    ...signedPaymasterOp,
    signature: signature,
  };

  console.log("Modified resultOp (Pre): ", finalOp);

  return finalOp;
}

async function getGasLimitsWithOp(
  userOp: IUserOperation,
  provider: providers.JsonRpcProvider
): Promise<CallLimits> {
  let verificationGasLimit;
  let callGasLimit;
  let preVerificationGas;
  const IEntryPoint = EntryPoint__factory.connect(EntryPoint_Address, provider);
  try {
    await IEntryPoint.callStatic.simulateHandleOp(
      userOp,
      userOp.sender,
      userOp.callData
    );
  } catch (error: any) {
    //@ts-ignore
    if (error.errorName === "ExecutionResult") {
      const _verificationGasLimitPreOp = error.errorArgs.preOpGas.sub(
        userOp.preVerificationGas
      );
      // console.log("_verificationGasLimit: ", Number(_verificationGasLimitPreOp));
      verificationGasLimit = _verificationGasLimitPreOp;
      console.log("_verificationGasLimit : ", Number(verificationGasLimit));

      const _callGasLimitWithMultiplier = error.errorArgs.paid;
      // console.log("_callGasLimitWithMultiplier: ", Number(_callGasLimitWithMultiplier));
      const _callGasLimit = _callGasLimitWithMultiplier.div(
        userOp.maxFeePerGas
      );
      // console.log("_callGasLimit: ", Number(_callGasLimit));
      callGasLimit = _callGasLimit;
      console.log("_callGasLimit : ", Number(callGasLimit));

      preVerificationGas = userOp.preVerificationGas;
      console.log("_preVerificationGas : ", Number(preVerificationGas));
    } else {
      throw error;
    }
  }

  return { verificationGasLimit, callGasLimit };
}

// async function getTokensAgainstGas(gasPrice: BigNumber, gasCost: BigNumber) {
//   // 1. Get the USD value of ether against the provided token.
//   let res;
//   try {
//     res = await axios.get(
//       "https://gpoly.blockscan.com/gasapi.ashx?apikey=key&method=gasoracle"
//     );
//   } catch (error: any) {
//     console.error("There was an error. Reason: ", error.message);
//   }
//   if (res?.status == 200 && res?.data.message) {
//     const price = ethers.utils.parseUnits(res.data.result.UsdPrice, "18");

//     /// @notice: price = GAS_PRICE (In Wei) * GAS_COST * NATIVE_PRIVE (In USD) * TOKEN_DECIMALS / 1e36
//     const result = gasPrice
//       .mul(gasCost)
//       .mul(price)
//       .mul(ethers.utils.parseUnits("1", 6))
//       .div(ethers.utils.parseUnits("1", 36));
//     // 2. Convert the gas to USDC

//     // 3. Return the no. of tokens to dedeuct as fees.
//     // console.log(result)
//     return result.toString();
//   }
// }

async function getTokensAgainstGas(
  gasPrice: BigNumber,
  gasCost: BigNumber,
  tokenAddress: string,
  tokenDecimal: number
) {
  console.log("file: prepaidGasasdasdasd.ts:531  tokenDecimal:", tokenDecimal);
  // 1. Get the USD value of ether against the provided token.
  try {
    // let res = await axios.get(
    //   "https://gpoly.blockscan.com/gasapi.ashx?apikey=key&method=gasoracle"
    // );

    const assetTokenAddress =
      tokenAddress == "0x"
        ? "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270"
        : tokenAddress;
    const tokenPrice = await getTokenDollarValue(assetTokenAddress);
    // const usdPrice = 83;
    console.log(
      "file: prepaidGasasdasdasd.ts:539  usdPrice:",
      tokenPrice,
      tokenAddress
    );
    const usdPrice = tokenPrice;
    // if (res?.status == 200 && res?.data.message) {
    const price = ethers.utils.parseUnits(String(usdPrice), "18");

    /// @notice: price = GAS_PRICE (In Wei) * GAS_COST * NATIVE_PRIVE (In USD) * TOKEN_DECIMALS / 1e36
    const result = gasPrice
      .mul(gasCost)
      .mul(price)
      .mul(ethers.utils.parseUnits("1", String(tokenDecimal)))
      .div(ethers.utils.parseUnits("1", 36));
    console.log("file: prepaidGasasdasdasd.ts:549  result:", result);
    // 2. Convert the gas to USDC

    // 3. Return the no. of tokens to dedeuct as fees.
    // console.log(result)
    return result.toString();
    // }
  } catch (error: any) {
    console.error("There was an error. Reason: ", error.message);
    throw error;
  }
}
