import React, { FC, useState } from "react";

import { Typography, useTheme } from "@mui/material";

import GreaterThan from "assets/greater-than.svg";
import WhiteGreaterThan from "assets/white-greater-than.svg";

import "./styles.css";
import { WelcomeBoxProps } from "interfaces";

const WelcomeBox: FC<WelcomeBoxProps> = ({
  title,
  description,
  onClick,
  icon,
  style,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const theme = useTheme();

  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  return (
    <div
      className="welcome-box"
      onClick={onClick}
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
      style={style}
    >
      <div>
        <div className="flex-row-center">
          {icon}
          <Typography
            variant="body1"
            sx={{
              fontWeight: "600",
            }}
          >
            {title}
          </Typography>
        </div>
        {description && (
          <Typography
            variant="subtitle2"
            sx={{
              color: theme.palette.text.lightGray,
              fontWeight: "400",
              marginTop: 1,
            }}
          >
            {description}
          </Typography>
        )}
      </div>
      <div>
        <img src={isHovered ? WhiteGreaterThan : GreaterThan} />
      </div>
    </div>
  );
};

export default WelcomeBox;
