import React, { FC, useState } from 'react'
import { Box, Input, Typography } from '@mui/material'
import SearchIcon from 'assets/SearchInputIcon.svg'

import SearchOptionsIcon from 'assets/searchOptionsIcon.svg'
import CenterItem from '../../../../components/CenterItem'
import SearchInput from '../../../../components/SearchInput'
import TokensList from './TokensList'
// import { useAppSelector } from "../../../../store/store";

const TokensListTable: FC<{
  transactionForm?: boolean
  nextStep?: () => void
}> = ({ transactionForm = false, nextStep = () => {} }) => {
  // const { nfts, activeAccount, activeNetwork } = useAppSelector(
  //   (state) => state.app
  // );
  const [searchText, setSearchText] = useState('')

  // const profileNfts = nfts[activeAccount.smartAccountAddress].nfts;
  // console.log("file: index.tsx:13  TokensListTable  nfts:", nfts, profileNfts);

  return (
    <div>
      <Box display='flex'>
        <Box flex={1} alignItems={'center'}>
          <SearchInput
            handleOnChange={e => {
              setSearchText(e.target.value)
            }}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
            justifyContent: 'space-between',
            paddingLeft: '12px',
          }}
        >
          <CenterItem>
            <img src={SearchOptionsIcon} />
          </CenterItem>
          {!transactionForm && (
            <CenterItem>
              <div className='tab-chip-box' style={{ maxWidth: 110 }}>
                <Typography
                  variant={'h5'}
                  fontWeight={500}
                  color='white'
                  fontSize={12}
                  fontFamily={'Helvetica Neue'}
                  textAlign='center'
                >
                  + ADD TOKEN
                </Typography>
              </div>
            </CenterItem>
          )}
        </Box>
      </Box>
      <Box>
        <TokensList
          transactionForm={transactionForm}
          nextStep={nextStep}
          filterText={searchText}
        />
      </Box>
    </div>
  )
}

export default TokensListTable
