import { styled } from "@mui/system";
import Select from "@mui/material/Select";
import { InputBase, TextField } from "@mui/material";
import { CSSProperties } from "react";

export const DropDownBox = styled(Select)(() => ({
  textAlign: "start",
  color: "#6A27EA",
  fontWeight: "500",
  height: "60px",
  fontSize: "16px",
  marginBottom: "20px",
  ".MuiButtonBase-root-MuiMenuItem-root.Mui-selected": {
    backgroundColor: "red",
  },
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: "#6A27EA",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#6A27EA",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#6A27EA",
  },
  ".MuiSvgIcon-root ": {
    fill: "#6A27EA !important",
  },
  ".MuiSelect-iconOpen": {
    rotate: "rotate(190deg)",
  },
}));

export const InputField2 = styled(TextField)(() => ({
  width: "100%",
  borderRadius: "6px",
  backgroundColor: "#f9f8fd",
  border: "1px solid rgba(0, 229, 153, 1)",
  color: "rgba(0, 229, 153, 1)",
  // '.MuiOutlinedInput-notchedOutline': {
  //     border: '1px solid rgba(0, 229, 153, 1) !important',
  // },
  // '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
  //     border: '1px solid rgba(0, 229, 153, 1) !important',
  // },
  // '&:hover .MuiOutlinedInput-notchedOutline': {
  //     border: '1px solid rgba(0, 229, 153, 1) !important',
  // },
  "&:focus-visible": {
    outline: "3px solid red",
  },
}));

export const InputField = styled(InputBase, {
  shouldForwardProp: prop =>
    prop !== "padding" && prop !== "inputStyle" && prop !== "width",
})<{
  padding?: number;
  inputStyle?: CSSProperties;
  width?: CSSProperties;
}>(({ padding = 6, inputStyle }) => ({
  // color: "rgba(0, 229, 153, 1)",
  width: "100%",
  height: "40px",
  borderRadius: "10px",
  fontSize: "14px",
  fontFamily: "Space Grotesk",
  fontWeight: "500",
  // border: "1px solid rgba(0, 229, 153, 1)",
  padding: "10px 15px",

  "& .MuiInputBase-input": {
    // padding: `${padding}px`,
    paddingTop: 8,
    width: "100%",
    fontFamily: "Space Grotesk",
    ...inputStyle,
  },
  "& .MuiInputBase-input::placeholder": {
    "-webkit-text-fill-color": "black",
    color: "#1A1C20",
    fontSize: "12px",
    fontWeight: "500",
  },
  "& .MuiInputBase-input:disabled": {
    color: "#1A1C20",
    "-webkit-text-fill-color": "#1A1C20",
    fontSize: "12px",
    fontWeight: "500",
  },
}));

export const TokenInputField = styled(InputBase, {
  shouldForwardProp: prop =>
    prop !== "padding" && prop !== "inputStyle" && prop !== "width",
})<{
  padding?: number;
  inputStyle?: CSSProperties;
  width?: CSSProperties;
}>(({ padding = 6, inputStyle }) => ({
  color: "#1A1C20",
  width: "100%",
  height: "80px",
  borderRadius: "10px",
  fontSize: "25px",
  fontFamily: "Space Grotesk",
  fontWeight: "700",
  border: "0.5px solid rgba(26, 28, 32, 0.50)",
  padding: "20px 30px",

  "& .MuiInputBase-input": {
    // padding: `${padding}px`,
    paddingTop: 8,
    width: "100%",
    fontFamily: "Space Grotesk",
    ...inputStyle,
  },
  "& .MuiInputBase-input:disabled": {
    color: "#1A1C20",
    "-webkit-text-fill-color": "#1A1C20",
  },
}));
