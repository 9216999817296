import React, { FC } from "react";
import { IconButton, Input, InputAdornment, Typography } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { InputProps } from "interfaces";
import "./styles.css";

const TextInput: FC<InputProps> = ({
  title,
  value,
  type,
  showEndIcon,
  secure,
  showSecure,
  onChange,
  style,
  customClassName = "",
  placeholderName,
  onKeyPress,
  customIcon,
  useRef,
  iconText,
}) => {
  return (
    <div className="text-input-box" style={style}>
      <Typography
        variant="caption"
        sx={{
          margin: "5px 5px 30px 0px",
          fontWeight: "200",
          fontSize: 11,
          color: "rgba(26, 28, 32, 0.5)",
          fontFamily: "Helvetica Neue",
        }}
      >
        {title}
      </Typography>
      <div>
        <Input
          style={{ color: "black", width: "100%" }}
          value={value}
          type={type || "text"}
          onChange={(e) => onChange(e.target.value)}
          className={customClassName}
          placeholder={placeholderName}
          onKeyPress={onKeyPress}
          ref={useRef}
          endAdornment={
            <InputAdornment position="end">
              {showEndIcon && (
                <IconButton
                  aria-label="toggle password visibility"
                  edge="end"
                  tabIndex={-1}
                >
                  {secure ? (
                    <VisibilityOff onClick={showSecure} />
                  ) : (
                    <Visibility onClick={showSecure} />
                  )}
                </IconButton>
              )}
              {customIcon && !showEndIcon && (
                <img
                  src={customIcon}
                  width={32}
                  height={32}
                  className="text-input-custom-icon"
                />
              )}
              {iconText?.label && (
                <Typography variant="body2" sx={iconText?.styles}>
                  {iconText.label}
                </Typography>
              )}
            </InputAdornment>
          }
        />
      </div>
    </div>
  );
};

export default TextInput;
