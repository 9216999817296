import { Box } from "@mui/system";
import React, { ReactNode } from "react";

const NFTMetaDataItem = ({
  title,
  RightComponent,
}: {
  title: string;
  RightComponent: ReactNode;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          color: "rgba(26, 28, 32, 0.50)",
          fontFamily: "Space Grotesk",
          fontStyle: "normal",
          fontWeight: "400",
        }}
      >
        {title}
      </Box>
      <Box>{RightComponent}</Box>
    </Box>
  );
};

export default NFTMetaDataItem;
