import React, { FC } from "react";

import "./styles.css";
import { CircularProgress, Typography } from "@mui/material";
import { BtnProps } from "interfaces";

const Button: FC<BtnProps> = ({
  onClick,
  title,
  loading,
  disabled,
  style,
  textStyle,
  useRef,
}) => {
  return loading ? (
    <div
      style={{
        width: "100%",
        textAlign: "center",
        margin: "20px 0px",
      }}
      ref={useRef}
    >
      <CircularProgress color="inherit" />
    </div>
  ) : (
    <div
      className="btn-box"
      onClick={disabled ? () => {} : onClick}
      style={{ cursor: disabled ? "not-allowed" : "pointer", ...style }}
    >
      <Typography
        variant="h5"
        sx={{
          fontWeight: "700",
          color: "white",
          textAlign: "center",
        }}
        style={{ ...textStyle }}
      >
        {title}
      </Typography>
    </div>
  );
};

export default Button;
