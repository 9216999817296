import React, { useEffect, useState } from "react";

import SendInput from "components/SendInput";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Web3 from "web3";

import WalletIcon from "assets/wallet.svg";

import "./index.css";
import { Box, Input, Typography } from "@mui/material";
import BasicButton from "components/Button";
import BasicModal from "components/BasicModal";
import ModalHeader from "components/ModalHeader";
import AssetsView from "components/AssetsView";
import History from "components/History";
import { Token } from "interfaces";
import { useAppDispatch, useAppSelector } from "store/store";
import { SUPPORTED_NETWORKS } from "constants/chains";
import {
  decryptMessage,
  getSmartAccountAddress,
  isEnsExist,
  showAlert,
} from "utils/utils";
import abi from "abis/erc20abi.json";
import paymasterAbi from "abis/paymasterabi.json";

import { setSelectedToken, setTxStatus } from "@slices/walletSlice";
import { fetchGasPrice } from "utils/gas";
import { APPROVE_AMOUNT, NATIVE_ADDRESS } from "constants/";
import { ethers } from "ethers";
import {
  CallDataType,
  FactoryData,
  PaymasterType,
} from "../../contract-integration/constants";
import { getUserOpHashData } from "utils/jiffyscan";
import { setPendingTx } from "@slices/appSlice";
import { useNavigate } from "react-router-dom";
import { getEnsAddress, getToAddress } from "utils/ens";
import CustomizedSteppers from "components/Stepper";
import NavigatorHeading from "components/NavigatorHeading";
import CloseButton from "components/CloseButton";
import NetworksList from "components/NetworksList";
import Networks from "components/Networks";

const Send = () => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0);
  const [to, setTo] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [tokens, setTokens] = useState<Token[]>([]);
  const [gasPrice, setGasPrice] = useState(0);
  const [max, setMax] = useState(false);
  const [filter, setFilter] = useState(0);
  const [depositedAmount, setDepositedAmount] = useState(0);
  const [step, setStep] = useState(0);

  const navigate = useNavigate();
  const { activeAccount, holdings, activeNetwork } = useAppSelector(
    (state) => state.app
  );

  const { selectedToken } = useAppSelector((state) => state.wallet);
  const { hashedPassword, txStatus } = useAppSelector((state) => state.wallet);

  const { nativeTokenName, symbol, icon, rpc } =
    SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS];

  const { nativeBalance, nativeBalanceUsd, nativeTokenPrice } =
    holdings[activeAccount.smartAccountAddress];

  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      const { average } = await fetchGasPrice(activeNetwork);
      console.log(
        "GASSSSSSSSSSs",
        average,
        Number(Web3.utils.fromWei(Number(average).toString(), "ether"))
      );
      setGasPrice(average);
    })();
  }, []);

  useEffect(() => {
    let userTokens: Token[] =
      holdings[activeAccount.smartAccountAddress]?.tokens || [];

    setTokens(userTokens);
  }, [activeAccount, holdings]);

  useEffect(() => {
    (async () => {
      const web3 = new Web3(rpc);
      const { paymasterAddress } = SUPPORTED_NETWORKS[activeNetwork];
      const paymasterContract = new web3.eth.Contract(
        paymasterAbi,
        paymasterAddress
      );
      const depositInfo = await paymasterContract.methods
        .depositInfo(NATIVE_ADDRESS, activeAccount.smartAccountAddress)
        .call();

      console.log(depositInfo.amount);
      setDepositedAmount(
        Number(Web3.utils.fromWei(depositInfo.amount, "ether"))
      );
    })();
  }, []);

  const isSelectedToken = selectedToken.length > 0;
  const tokenSymbol = isSelectedToken ? selectedToken[0].tokenSymbol : symbol;
  const tokenIcon = isSelectedToken ? selectedToken[0].image : icon;
  const tokenName = isSelectedToken
    ? selectedToken[0].tokenName
    : nativeTokenName;
  const tokenBalance = isSelectedToken
    ? selectedToken[0].tokenBalance
    : nativeBalance;
  const tokenBalanceInUsd = isSelectedToken
    ? selectedToken[0].priceInUSD
    : nativeBalanceUsd;
  const tokenPriceInUsd = isSelectedToken
    ? selectedToken[0].tokenPrice
    : nativeTokenPrice;
  const tokenAddress = isSelectedToken ? selectedToken[0].tokenAddress : "";

  // const { dummyToken } = SUPPORTED_NETWORKS[activeNetwork];

  useEffect(() => {
    setValue(0);
  }, [selectedToken]);

  useEffect(() => {
    if (max && Number(value) < tokenBalance) {
      setFilter(0);
    } else if (Number(value) < tokenBalance * filter) {
      setFilter(0);
    }
  }, [value]);

  const sendTransaction = async () => {
    try {
      setLoading(true);
      const toAddress = await getToAddress(to);

      console.log(toAddress);

      if (toAddress) {
        let valueToSent = value;

        const web3 = new Web3(rpc);
        const nonce = await web3.eth.getTransactionCount(
          activeAccount.smartAccountAddress,
          "latest"
        );
        const pkey = decryptMessage(activeAccount.secret, hashedPassword);
        const provider = new ethers.providers.JsonRpcProvider(rpc);
        const wallet = new ethers.Wallet(pkey, provider);

        let transaction = {};

        if (!tokenAddress) {
          transaction = {
            to: toAddress,
            value: valueToSent.toString().includes(".")
              ? (valueToSent * 10 ** 18).toFixed()
              : valueToSent * 10 ** 18,
            // gasPrice,
            gasLimit: 21000,
            nonce,
            calldata: "0x",
          };
        } else {
          console.log(tokenAddress);
          //@ts-ignore
          const contract = new web3.eth.Contract(abi.abi, tokenAddress);
          const gasLimit = await contract.methods
            .transfer(toAddress, 123)
            .estimateGas({ from: activeAccount.smartAccountAddress });
          console.log(gasLimit);

          transaction = {
            value: "0x0",
            to: tokenAddress,
            // gasPrice,
            gasLimit: web3.utils.toHex((gasLimit * 1.15).toFixed()),
            nonce: nonce,
            calldata: contract.methods
              .transfer(toAddress, web3.utils.toWei(valueToSent.toString()))
              .encodeABI(),
          };
        }

        if (!!Number(gasPrice)) {
          transaction.gasPrice = gasPrice;
        }
        console.log(transaction);
        console.log(activeNetwork);

        const counterfactual = activeAccount.smartAccountAddress;

        console.log("counterfactual", counterfactual);

        const smartAccountNonce = await web3.eth.getTransactionCount(
          counterfactual,
          "latest"
        );

        console.log(smartAccountNonce);

        // const contract = new web3.eth.Contract(
        //   //@ts-ignore
        //   abi.abi,
        //   "0x6ffc7a6fb650bbdd4dcd99699a9f91ae3d4cd44d"
        // );

        // const gasLimit = await contract.methods
        //   .approve("0x2385d8c41a781954279db7787c19453d9b68bc64", APPROVE_AMOUNT)
        //   .estimateGas({ from: activeAccount.smartAccountAddress });

        // let tx2 = {
        //   value: "0x0",
        //   to: "0x6ffc7a6fb650bbdd4dcd99699a9f91ae3d4cd44d",
        //   // gasPrice,
        //   gasLimit: web3.utils.toHex((gasLimit * 1.15).toFixed()),
        //   nonce: nonce,
        //   calldata: contract.methods
        //     .approve("0x2385d8c41a781954279db7787c19453d9b68bc64", APPROVE_AMOUNT)
        //     .encodeABI(),
        // };

        // console.log(tx2);

        // const res = await submitTransaction(
        //   { chainId: activeNetwork },
        //   wallet,
        //   smartAccountNonce === 0
        //     ? FactoryData.FactoryCreateSender
        //     : FactoryData.Empty,
        //   CallDataType.Execute,
        //   transaction,
        //   // tx2,
        //   PaymasterType.TokenReceiver,
        //   NATIVE_ADDRESS
        // );

        // console.log("res", res);

        // dispatch(setPendingTx(res.userOpHash));

        showAlert(
          "Soon you can see your transaction in the transactions tab",
          "Transaction Submitted",
          `<a href="https://polygonscan.com/address/${activeAccount.smartAccountAddress}" target="_blank">View on Polygonscan</a>`
        );
        setLoading(false);

        navigate("/");
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log("err", error);
      showAlert("Transaction Failed");
      setLoading(false);
    }
  };

  const depositTransaction = async () => {
    try {
      setLoading(true);

      const web3 = new Web3(rpc);
      const nonce = await web3.eth.getTransactionCount(
        activeAccount.smartAccountAddress,
        "latest"
      );
      const pkey = decryptMessage(activeAccount.secret, hashedPassword);
      const provider = new ethers.providers.JsonRpcProvider(rpc);
      const wallet = new ethers.Wallet(pkey, provider);
      const counterfactual = activeAccount.smartAccountAddress;

      console.log("counterfactual", counterfactual);

      const smartAccountNonce = await web3.eth.getTransactionCount(
        counterfactual,
        "latest"
      );

      console.log(smartAccountNonce);

      let transaction = {};

      const { paymasterAddress } = SUPPORTED_NETWORKS[activeNetwork];

      // APPROVAL

      // const contract = new web3.eth.Contract(
      //   //@ts-ignore
      //   abi.abi,
      //   dummyToken
      // );

      // const gasLimit = await contract.methods
      //   .approve(paymasterAddress, APPROVE_AMOUNT)
      //   .estimateGas({ from: activeAccount.smartAccountAddress });

      // let tx2 = {
      //   value: "0x0",
      //   to: dummyToken,
      //   // gasPrice,
      //   gasLimit: web3.utils.toHex((gasLimit * 1.15).toFixed()),
      //   nonce: nonce,
      //   calldata: contract.methods
      //     .approve(paymasterAddress, APPROVE_AMOUNT)
      //     .encodeABI(),
      // };

      // const paymasterContract = new web3.eth.Contract(
      //   paymasterAbi,
      //   paymasterAddress
      // );
      // const gasLimit = await paymasterContract.methods
      //   .addDepositFor(
      //     tokenAddress,
      //     activeAccount.smartAccountAddress,
      //     Web3.utils.toWei(value.toString())
      //   )
      //   .estimateGas({ from: activeAccount.smartAccountAddress });
      // console.log(gasLimit);

      // transaction = {
      //   value: "0x0",
      //   to: paymasterAddress,
      //   // gasPrice,
      //   gasLimit: web3.utils.toHex((gasLimit * 1.15).toFixed()),
      //   nonce: nonce,
      //   calldata: paymasterContract.methods
      //     .addDepositFor(
      //       tokenAddress,
      //       activeAccount.smartAccountAddress,
      //       Web3.utils.toWei(value.toString())
      //     )
      //     .encodeABI(),
      // };

      transaction = {
        value: Web3.utils.toWei(value.toString()),
        to: paymasterAddress,
        // gasPrice,
        gasLimit: 91000,
        nonce,
        calldata: "0x",
      };

      // const res = await submitTransaction(
      //   { chainId: activeNetwork },
      //   wallet,
      //   smartAccountNonce === 0
      //     ? FactoryData.FactoryCreateSender
      //     : FactoryData.Empty,
      //   CallDataType.Execute,
      //   transaction,
      //   // tx2,
      //   PaymasterType.None,
      //   NATIVE_ADDRESS
      // );

      // console.log("res", res);

      // dispatch(setPendingTx(res.userOpHash));

      showAlert(
        "Soon you can see your transaction in the transactions tab",
        "Transaction Submitted",
        `<a href="https://polygonscan.com/address/${activeAccount.smartAccountAddress}" target="_blank">View on Polygonscan</a>`
      );

      setLoading(false);

      navigate("/");
    } catch (error) {
      setLoading(false);
      console.log(error);
      const formattedError = JSON.parse(error.body);
      console.log("err", formattedError.error.message);
      showAlert(formattedError.error.message);
    }
  };

  const calculateValue = (num: number) => {
    setValue(tokenBalance * num);
    setFilter(num);

    if (num === 1) {
      setMax(true);
    } else {
      setMax(false);
    }
  };

  const selectTokenHandler = (tk: Token) => {
    dispatch(setSelectedToken([tk]));
    setOpenModal(false);
  };

  const isValid = Number(value) && to && Number(value) <= Number(tokenBalance);
  const isDepositValid = Number(value) && Number(value) <= Number(tokenBalance);

  return (
    <>
      <BasicModal open={openModal} onClose={() => setOpenModal(false)}>
        <>
          <ModalHeader
            title="Select asset"
            onClose={() => setOpenModal(false)}
            showBackIcon
          />
          <AssetsView tokens={tokens} selectTokenHandler={selectTokenHandler} />
        </>
      </BasicModal>
      <Box mt={6}>
        <NavigatorHeading
          title="Send Crypto"
          RightComponent={
            <CloseButton
              handleOnClick={() => {
                navigate("/crypto");
              }}
            />
          }
        />
      </Box>
      <Box mt={6}>
        <CustomizedSteppers
          step={step}
          changeStep={(selectedStep: number) => {
            //eg. if user is on step 3 he should be able to move at step 1 or 2 on clicking step icon
            if (selectedStep < step) {
              setStep(selectedStep);
            }
          }}
        />
      </Box>
      {step == 0 && (
        <NetworksList title="Select which network you want to send crypto on" />
      )}

      {/* Old Code */}
      <Typography variant="body2" style={{ marginTop: 30 }}>
        Current Amount Deposited: {depositedAmount} MATIC
      </Typography>
      <div className="send-box">
        <div className="send-input-box">
          <SendInput value={value} setValue={setValue} mode={tokenSymbol} />
        </div>

        {Number(tokenBalanceInUsd) === 0 && (
          <Typography variant="body2" style={{ marginTop: 30 }}>
            You don't have any {tokenName} to send. Try buying some to get
            started.
          </Typography>
        )}
        <div className="send-btns">
          <BasicButton
            title="25%"
            onClick={() => calculateValue(0.25)}
            className={`send-input-btn ${filter === 0.25 && "active-btn"}`}
            id="25%"
          />
          <BasicButton
            title="50%"
            onClick={() => calculateValue(0.5)}
            className={`send-input-btn ${filter === 0.5 && "active-btn"}`}
            id="50%"
          />
          <BasicButton
            title="75%"
            onClick={() => calculateValue(0.75)}
            className={`send-input-btn ${filter === 0.75 && "active-btn"}`}
            id="75%"
          />
          <BasicButton
            title="Send All"
            onClick={() => calculateValue(1)}
            className={`send-input-btn ${filter === 1 && "active-btn"}`}
            id="all"
          />
        </div>
        <div className="send-token-box">
          <div style={{ width: "100%" }}>
            <Typography variant="body2" style={{ marginBottom: 20 }}>
              Pay With
            </Typography>
            <div className="token-details">
              <img src={tokenIcon} width={30} height={40} />
              <Typography
                style={{ fontWeight: "bold", marginLeft: 10, marginTop: 5 }}
              >
                {tokenName}
              </Typography>
            </div>
          </div>
          <div
            style={{ margin: "auto", cursor: "pointer" }}
            onClick={() => setOpenModal(true)}
          >
            <KeyboardArrowRightIcon />
          </div>
        </div>

        {depositedAmount != 0 && (
          <div className="send-token-box">
            <div style={{ width: "100%" }}>
              <Typography variant="body2" style={{ marginBottom: 20 }}>
                To
              </Typography>
              <div className="token-details">
                <img src={WalletIcon} />
                <Input
                  id="amount-box"
                  placeholder={"Enter Address"}
                  value={to}
                  onChange={(e) =>
                    e.target.value.length < 50 && setTo(e.target.value)
                  }
                  style={{ width: 600 }}
                  autoComplete="off"
                />
              </div>
            </div>
          </div>
        )}

        {depositedAmount == 0 ? (
          <BasicButton
            title={"Deposit"}
            onClick={depositTransaction}
            className="transfer-btn"
            loading={loading}
            mode={isDepositValid ? "active" : "not-active"}
            id="send"
          />
        ) : (
          <BasicButton
            title={"Send"}
            onClick={sendTransaction}
            className="transfer-btn"
            loading={loading}
            mode={isValid ? "active" : "not-active"}
            id="send"
          />
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography variant="body2">{tokenSymbol} Balance</Typography>
          <Typography variant="body2">
            {tokenBalance} {tokenSymbol} ≈ ${tokenBalanceInUsd}
          </Typography>
        </div>

        {!!Number(gasPrice) && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography variant="body2">Gas Price</Typography>
            <Typography variant="body2">
              {Web3.utils.fromWei(Number(gasPrice).toString(), "ether")}{" "}
              {symbol}
            </Typography>
          </div>
        )}

        <History />
      </div>
    </>
  );
};

export default Send;
