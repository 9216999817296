import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";

// import RecoverLogo from "assets/recover.png";
import RecoverLogo from "assets/recoverUpdated.svg";


import "./styles.css";
import WelcomeBox from "components/WelcomeBox";
import AuthHeader from "components/AuthHeader";

const Recover = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        backgroundImage: `url(${RecoverLogo})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: {
          xs: "50%",
          sm: "50%",
          md: "inherit",
          lg: "inherit",
          xl: "inherit",
        },
        backgroundClip: "content-box",
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        right: 0,
        backgroundPositionY: "bottom",
        backgroundPositionX: "right",
      }}
    >
      <div className="recover-container">
        <AuthHeader />
        <div className="create-account-box recover-account-wrapper">
          <Typography
            variant="h2"
            sx={{
              margin: "30px 0px 30px 0px",

              fontWeight: "700",
            }}
          >
            Recover Account
          </Typography>

          <Typography
            variant="body2"
            sx={{
              margin: "10px 0px 0px 0px",
              color: "rgba(26, 28, 32, 0.5)",

              fontWeight: "500",
              textAlign: "center",
              // width: '25%',
            }}
          >
            Forgot your details?
          </Typography>
          <Typography
            variant="body2"
            sx={{
              margin: "0px 0px 10px 0px",
              color: "rgba(26, 28, 32, 0.5)",
              marginBottom: "30px",
              fontWeight: "500",
              textAlign: "center",
              wordWrap: "break-word",
            }}
          >
            You can recover your Stashed account with your credentials +
            recovery key
          </Typography>

          <div className="recover-box-container">
            <div className="recover-box-main">
              <div className="recover-box recover-top-box">
                <WelcomeBox
                  title="Update Sign In Method"
                  description=""
                  onClick={() => navigate("/recover-stashed-tag")}
                />
              </div>
              <div className="recover-middle-box">
                <WelcomeBox
                  title="Forgot Password"
                  description=""
                  onClick={() =>
                    navigate("/forgot-password/recover-choose-method")
                  }
                />
              </div>
              <div className="recover-box recover-bottom-box">
                <WelcomeBox
                  title="Forgot Pin"
                  description=""
                  onClick={() => navigate("/forgot-pin/recover-choose-method")}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div className='recover-img-box'>
        <img src={RecoverLogo} className='recover-img' height={450} />
      </div> */}
      </div>
    </Box>
  );
};

export default Recover;
