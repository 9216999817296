import { Fade, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import CodeInput from "./CodeInput";
import CenterItem from "../../../../components/CenterItem";
import { applyCode } from "utils/contact";

import { useAppDispatch, useAppSelector } from "store/store";
import { SPECIAL_CHARACTERS_REGEX } from "constants/";
import { decryptMessage, initalizeWeb3, showAlert } from "utils/utils";
import { setExternalReferralCode } from "@slices/appSlice";

const ApplyReferralCode = () => {
  const [code, setCode] = useState("");
  const [loader, setLoader] = useState(false);

  const dispatch = useAppDispatch();

  const { rootAccountInfo, activeNetwork, externalReferralCode } =
    useAppSelector((state) => state.app);
  const { hashedPassword } = useAppSelector((state) => state.wallet);

  const isSpecialCharacters = (myString: string) => {
    return SPECIAL_CHARACTERS_REGEX?.test(myString);
  };

  const applyCodeHandler = async (e: any) => {
    e.preventDefault();
    setLoader(true);

    const { address } = rootAccountInfo || {};

    if (!code) {
      showAlert("Please Add Code");
      setLoader(false);
      return;
    }

    if (code && code?.length > 12) {
      showAlert("Please add upto 12 characters ");
      setLoader(false);
      return;
    }

    if (isSpecialCharacters(code)) {
      showAlert("Please Add Valid Code");
      setLoader(false);
      return;
    }

    try {
      const web3 = initalizeWeb3(activeNetwork);
      const pkey = decryptMessage(rootAccountInfo.secret, hashedPassword);
      const signature = await web3.eth.accounts.sign(code, pkey);
      const apiRes = await applyCode({
        address,
        referralCode: code,
        signature: signature.signature,
      });

      showAlert("Your referral code has been successfully applied", "Success");
      dispatch(setExternalReferralCode(code));
      setCode("");
      setLoader(false);
    } catch (err) {
      console.log(err);
      setLoader(false);
      setCode("");

      if (err?.response?.data?.msg?.toLowerCase().includes("code again")) {
        showAlert("You already applied the referral code");
        dispatch(setExternalReferralCode("ssd"));
      } else {
        showAlert(err?.response?.data?.msg || "Invalid Referral Code");
      }
    }
  };

  return (
    <Fade in={true} timeout={500} easing={"ease-in-out"}>
      <Box className="card-box" height="100%">
        <Box paddingY={2} paddingX={3} height={"85%"}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
          >
            <Box display="flex" gap="10px" flexDirection="column">
              <Typography fontWeight="bold" variant="h5">
                Apply Referral Code
              </Typography>
              <Typography
                fontWeight="thin"
                variant="body2"
                fontSize={15}
                color="#1A1C20B2"
              >
                Get Stashed benefits when you apply a referral code!
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              gap={1}
            >
              <Box>
                <Typography color="#1A1C20" variant="body2" fontWeight={500}>
                  Apply a code
                </Typography>
              </Box>
              <Box>
                <Grid container spacing={1}>
                  <Grid item md={9} lg={9} alignItems={"center"}>
                    <CodeInput
                      handleOnChange={(e: any) => {
                        setCode(e.target.value);
                      }}
                      value={externalReferralCode ? "Applied" : code}
                      disabled={!!externalReferralCode}
                    />
                  </Grid>

                  <Grid
                    item
                    md={3}
                    lg={3}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      justifyContent: "space-between",
                      paddingLeft: "12px",
                    }}
                  >
                    <CenterItem>
                      <div
                        className="tab-chip-box"
                        style={{
                          background: `${
                            code && !externalReferralCode ? "#15121d" : "grey"
                          }`,
                          cursor:
                            code && !externalReferralCode
                              ? "pointer"
                              : "not-allowed",
                        }}
                      >
                        <Typography
                          variant={"h5"}
                          fontWeight={500}
                          color="white"
                          fontSize={12}
                          fontFamily={"Helvetica Neue"}
                          whiteSpace={"nowrap"}
                          letterSpacing={2}
                          onClick={
                            loader || !code || externalReferralCode
                              ? () => {}
                              : applyCodeHandler
                          }
                        >
                          {loader ? "Loading..." : "Apply"}
                        </Typography>
                      </div>
                    </CenterItem>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Fade>
  );
};

export default ApplyReferralCode;
