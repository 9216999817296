import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import GoogleLogo from "assets/googleLogo.svg";
import PasswordIcon from "assets/lockIcon.svg";

import PinIcon from "assets/pinIcon.svg";
import { useAppSelector } from "../../../../../../store/store";
import CollapeWrapper from "../../CollapeWrapper";
import UpdateGoogleShare from "components/UpdateGoogleShare";
import UpdatePinShare from "components/UpdatePinShare";
import UpdatePasswordShare from "components/UpdatePasswordShare";

const UpdateDetails = () => {
  const { activeAccount, rootAccountInfo, user } = useAppSelector(
    (state) => state.app
  );

  return (
    <>
      {/* <Snackbar
         open={showSnackbar}
         autoHideDuration={6000}
         onClose={() => setShowSnackbar(false)}
         message="Stashed tag copied!"
       /> */}
      <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <Box>
          <Typography variant="body2">Update details</Typography>
        </Box>

        <CollapeWrapper
          title={user.email}
          icon={GoogleLogo}
          darkIcon={GoogleLogo}
          BodyComponent={
            <>
              <UpdateGoogleShare />
            </>
          }
          background="#FFFFFF"
        />
        <CollapeWrapper
          title="Password"
          icon={PasswordIcon}
          darkIcon={PasswordIcon}
          BodyComponent={
            <>
              <UpdatePasswordShare />
            </>
          }
          background="#FFFFFF"
        />
        <CollapeWrapper
          title="Pin"
          icon={PinIcon}
          darkIcon={PinIcon}
          BodyComponent={
            <>
              <UpdatePinShare />
            </>
          }
          background="#FFFFFF"
        />
      </Box>
    </>
  );
};

export default UpdateDetails;
