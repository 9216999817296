import { FC, Key, useEffect, useState } from "react";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";

import SearchInput from "components/SearchInput";
import EthereumTokens from "../../Tokens/Ethereum.json";
import PolygonTokens from "../../Tokens/Polygon.json";
import { useAppDispatch, useAppSelector } from "store/store";
import { setSwapDetails } from "@slices/appSlice";
import { fetchPrice, formatAmount } from "utils/utils";
import { fetchUniswapSupportedTokens, getSwapQuote } from "utils/swap";
import TokenBox from "components/TokenBox";
import CashIcon from "assets/CashIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";

const ReceiveTokenList: FC<{
  nextStep: () => void;
  setIsCashSelected: () => void;
  isCashSupported?: boolean;
  setSelectedTokenB?: (token: {}) => void;
  swapTokens: [];
  loading?: boolean;
  type?: boolean;
}> = ({
  nextStep,
  setIsCashSelected,
  isCashSupported,
  setSelectedTokenB,
  swapTokens,
  loading,
  type,
}) => {
  // const tokens: {
  //   [chainId: number]: UniswapToken[];
  // } = {
  //   [1]: EthereumTokens,
  //   [137]: [],
  // };

  const {
    swapDetails: { chainId, tokenA },
    holdings,
    activeAccount: { smartAccountAddress, accountName },
    activeNetwork,
  } = useAppSelector((state) => state.app);

  const dispatch = useAppDispatch();

  const [value, setValue] = useState("");

  const [filteredTokens, setFilteredTokens] = useState<any>([swapTokens]);

  const { pathname } = useLocation();

  const onTokenSelection = async (token: any) => {
    const { address } = token;
    nextStep();
    setIsCashSelected();
    const selectedTokenDetails = {
      id: 1,
      image: token.logoURI,
      amountInUSD: 0,
      tokenAddress: token.address,
      tokenBalance: 0,
      tokenDecimal: token.decimals,
      tokenName: token.name,
      tokenPrice: 0,
      tokenSymbol: token.symbol,
      amount: 0,
    };
    if (setSelectedTokenB) setSelectedTokenB(token);
    dispatch(setSwapDetails({ tokenB: selectedTokenDetails }));
  };
  useEffect(() => {
    const searchedTokens = swapTokens.filter(
      (token) =>
        token.name.toLowerCase().includes(value.toLowerCase()) ||
        token.symbol.toLowerCase().includes(value.toLowerCase())
    );
    console.log("🚀 ~ filteredTokens:", searchedTokens);

    const firstListMap = new Map();
    Object.keys(holdings).length > 0 &&
      holdings[smartAccountAddress].tokens.forEach((item) => {
        firstListMap.set(item?.id?.toLowerCase(), item);
      });

    let updatedSecondList = searchedTokens.map((item) => {
      const lowerCaseAddress = item.address.toLowerCase();
      if (firstListMap.has(lowerCaseAddress)) {
        const firstListItem = firstListMap.get(lowerCaseAddress);
        return {
          ...item,
          tokenBalance: firstListItem.tokenBalance,
          priceInUSD: firstListItem.priceInUSD,
        };
      }
      return {
        ...item,
      };
    });

    // Sorting the updatedSecondList by tokenBalance in descending order
    updatedSecondList = updatedSecondList.sort(
      (a, b) => b.tokenBalance - a.tokenBalance
    );

    console.log("🚀 ~ updatedSecondList:", updatedSecondList);

    setFilteredTokens(updatedSecondList); // Note this change
  }, [value, holdings]); // Include all dependencies

  return (
    <div style={{ width: "100%" }}>
      <SearchInput
        handleOnChange={(e) => {
          setValue(e.target.value);
        }}
        value={value}
      />

      {loading ? (
        <div style={{ width: "100%", textAlign: "center", marginTop: 5 }}>
          <CircularProgress color="inherit" />
        </div>
      ) : [value ? filteredTokens : swapTokens].flat().length > 0 ? (
        <>
          {isCashSupported && (!value || value.toLowerCase() === "cash") && (
            <TokenBox
              tokenIcon={CashIcon}
              tokenSymbol={"Cash"}
              onClick={() => {
                setIsCashSelected();
                nextStep();
              }}
              isCash
              ens={`@${accountName}`}
              isEns
            />
          )}
          {[value ? filteredTokens : swapTokens].flat().map(
            (
              token: {
                address: any;
                logoURI: any;
                name: any;
                tokenBalance?: number;
                symbol: any;
                decimals?: number;
                chainId?: number;
                priceInUSD?: number;
              },
              index: Key | null | undefined
            ) => (
              <Stack
                key={index}
                display={"flex"}
                flexDirection={"row"}
                margin={2}
                alignItems={"center"}
                style={{
                  backgroundColor: "#EDEEF2",
                  cursor: "pointer",
                  opacity:
                    !type &&
                    token.address.toLowerCase() ===
                      tokenA.tokenAddress.toLocaleLowerCase()
                      ? 0.5
                      : 1,
                  pointerEvents:
                    !type &&
                    token.address.toLowerCase() ===
                      tokenA.tokenAddress.toLocaleLowerCase()
                      ? "none"
                      : "all",
                  borderRadius: "10px",
                  margin: "15px 0px 0px 0px",
                  boxShadow: "0px 8px 24px -4px rgba(24, 39, 75, 0.08)",
                  padding: 15,
                }}
                onClick={() => onTokenSelection(token)}
              >
                <img
                  src={token.logoURI}
                  alt="tokenIcon"
                  width={33}
                  height={33}
                />
                <Stack
                  marginLeft={2}
                  display={"flex"}
                  justifyContent={"space-between"}
                  flexDirection={"row"}
                  alignItems={"center"}
                  width={"100%"}
                >
                  <Stack>
                    <Typography variant="h6"> {token.name}</Typography>
                  </Stack>
                  <Typography
                    variant="caption"
                    style={{
                      color: token.priceInUSD ? "#1A1C20" : "grey",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: token.priceInUSD ? "700" : "400",
                      lineHeight: "normal",
                    }}
                  >
                    {token.priceInUSD
                      ? `$${formatAmount(token.priceInUSD || 0)}`
                      : "price not available"}
                  </Typography>
                </Stack>
              </Stack>
            )
          )}
        </>
      ) : (
        <Typography
          variant="subtitle1"
          style={{ textAlign: "center", marginTop: "1em" }}
        >
          No results found
        </Typography>
      )}
    </div>
  );
};

export default ReceiveTokenList;
