import React, { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../store/store";
import { numFormatter } from "../../../../../../utils/utils";
import { Grid } from "@mui/material";
import TokenImage from "../../../../../../components/TokenImage";
import DarkGas from "../../../../../../assets/darkGas.svg";
import { tokensForGas } from "constants/topTokensConf";
import { getAssetsDollarWorth } from "utils/portfolio";
import paymasterAbi from "abis/paymasterabi.json";
import Web3 from "web3";
// import { Paymaster_Token_Address } from "../../../contract-integration/constants";
import { provider } from "web3-core";

const Paymaster_Token_Address = "0xB536ad7C360f443C010703263374F79998FFBFB5";

const ReviewAssetListComponent: FC<{
  //   asset: Asset;
  nextStep?: () => void;
  value: number;
  isGasComponent?: boolean;
  transactionForm?: boolean;
}> = ({
  nextStep = () => {},
  transactionForm = false,
  value,
  isGasComponent = false,
}) => {
  //   const navigate = useNavigate();
  const [cummulativeGas, setCummulativeGas] = useState(0);
  const [loading, setLoading] = useState(false);

  const { accounts } = useAppSelector((state) => state.app);
  const activeAccountAddress = Object.values(accounts)[0].smartAccountAddress;

  const { nfts, activeAccount, activeNetwork } = useAppSelector(
    (state) => state.app
  );
  const { selectedToken } = useAppSelector((state) => state.wallet);
  console.log("file: index.tsx:89  Send  selectedToken:", selectedToken);
  console.log("file: index.tsx:17  activeNetwork:", activeNetwork);
  const { amount: tokenBalance, symbol: numberSymbol } = numFormatter(
    selectedToken[0]?.tokenBalance,
    4
  );
  const dispatch = useAppDispatch();
  const { amount: usdBalance, symbol: usdSymbol } = numFormatter(
    value * selectedToken[0]?.tokenPrice,
    4
  );

  const readAccountHoldings = async (
    tokenAddress: string,
    address: string,
    rpc: provider,
    decimal: number
  ) => {
    const web3 = new Web3(rpc);
    const paymasterContract = new web3.eth.Contract(
      paymasterAbi as any,
      Paymaster_Token_Address
    );
  };

  useEffect(() => {
    // Create an object to keep track of cumulative balances for each token
    type CumulativeBalances = { [tokenAddress: string]: number };
    const cumulativeBalances: CumulativeBalances = {};

    // Iterate through the accounts and fetch balances for each
    async function fetchBalances() {
      setLoading(true);
      let tokenAddressesForMobula = tokensForGas.map(
        (tokenInfo) => tokenInfo.tokenAddressForMobula
      );
      let depositedTokenObject: Array<any> = [];

      // Call the getAssetsDollarWorth function with the tokenAddressesForMobula array as the argument
      const tokenData = await getAssetsDollarWorth(tokenAddressesForMobula);

      // Extract the prices from the returned object
      let tokenWorthInUSD = Object.values(tokenData).map(
        (token) => token.price
      );

      // Now you can use the tokenWorthInUSD array in your loop
      for (let i = 0; i < tokensForGas.length; i++) {
        const tokenInfo = tokensForGas[i];
        const { tokenAddress, rpc, decimal } = tokenInfo;
        const balance = await readAccountHoldings(
          tokenAddress,
          activeAccountAddress,
          rpc,
          decimal
        );

        const tokenWorthInUSDForCurrentToken = tokenWorthInUSD[i];
        const balanceInUSD = balance * tokenWorthInUSDForCurrentToken;
        if (balance > 0) {
          depositedTokenObject.push({ ...tokenInfo, balance, balanceInUSD });
        }

        // Add the balance to the cumulative balance for the token
        if (!cumulativeBalances[tokenAddress]) {
          cumulativeBalances[tokenAddress] = balanceInUSD;
        } else {
          cumulativeBalances[tokenAddress] += balanceInUSD;
        }
      }
      // Calculate the total cumulative balance
      let totalBalance = 0;
      for (const tokenAddress of Object.keys(cumulativeBalances)) {
        totalBalance += cumulativeBalances[tokenAddress];
      }

      setCummulativeGas(totalBalance);
      setLoading(false);
    }

    fetchBalances();
  }, [accounts]);

  console.log(cummulativeGas, "cummulativeGas");

  return (
    <Grid
      sx={{
        padding: "10px",
        display: "flex",
        width: "75%",
        alignSelf: "center",
        justifyContent: "space-between",
        borderRadius: "10px",
        backgroundColor: "#F7F7F7",
        "&:hover": {
          backgroundColor: "lightgray",
        },
      }}
    >
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
        }}
      >
        {isGasComponent ? (
          <img
            src={DarkGas}
            alt="DarkGas"
            style={{
              width: "50px",
              height: "36px",
              objectFit: "contain",
            }}
          />
        ) : (
          <TokenImage logo={selectedToken[0]?.image} />
        )}

        <Grid
          sx={{
            color: "#1A1C20",
            display: "flex",
            flexDirection: "row",
            gap: "5px",
            fontFamily: "Space Grotesk",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "normal",
            textAlign: "left",
            alignItems: "center",
          }}
        >
          <div>{isGasComponent ? "Gas" : selectedToken[0]?.tokenName}</div>
          <div
            style={{
              fontFamily: "Space Grotesk",
              fontSize: "9px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "normal",
              marginTop: "2px",
              display: isGasComponent ? "none" : "block",
            }}
          >
            <span
              style={{
                color: "rgba(26, 28, 32, 0.50)",
              }}
            >
              {/* (Ethereum) */}
            </span>
          </div>
        </Grid>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Grid
          sx={{
            color: "#1A1C20",
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            fontFamily: "Space Grotesk",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "normal",
            textAlign: "right",
          }}
        >
          <div>
            ${isGasComponent ? cummulativeGas || 0 : usdBalance + usdSymbol}
          </div>
          <div
            style={{
              fontFamily: "Helvetica Neue",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
              display: isGasComponent ? "none" : "block",
            }}
          >
            <span
              style={{
                color: "rgba(26, 28, 32, 0.50)",
              }}
            >
              {value} {selectedToken[0].tokenSymbol}
            </span>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ReviewAssetListComponent;
