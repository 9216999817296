import React, { useState } from "react";

import "./styles.css";
import AuthHeader from "components/AuthHeader";
import { Typography } from "@mui/material";
import TextInput from "components/TextInput";
import SeedPhraseTextInput from "components/SeedPhraseTextInput";
import Button from "components/NewButton";
import { useNavigate } from "react-router-dom";
import { ethers } from "ethers";
import { deriveAccount, isUserExists, showAlert } from "utils/utils";
import { useAppDispatch } from "store/store";
import { saveMnemonic } from "@slices/appSlice";

const ImportWallet = () => {
  const [loading, setLoading] = useState(false);
  const [words, setWords] = useState(12);
  const [seedPhrase, setSeedPhrase] = useState(
    Array.from({ length: words }, () => "")
  );

  console.log(seedPhrase);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onPaste = (e: any) => {
    e.preventDefault();
    const pasted = e.clipboardData.getData("text/plain");

    if (pasted.split(" ").length > 1) {
      setSeedPhrase(pasted.split(" ").slice(0, seedPhrase.length));
    } else {
      setSeedPhrase((state: string[]) =>
        state.map((val, i) => (i != e.target.id ? val : pasted))
      );
    }
  };

  const importWallet = async () => {
    const formattedSeedphrase = seedPhrase.join(" ");
    const isValidSeedphrase = ethers.utils.isValidMnemonic(formattedSeedphrase);

    if (
      formattedSeedphrase.split(" ").length < 12 ||
      formattedSeedphrase.split(" ").length > 24
    ) {
      showAlert("Secret Recovery Phrase contain 12,15,18,21, or 24 words");
    } else if (!isValidSeedphrase) {
      showAlert("Seed Phrase is incorrect.");
    } else {
      setLoading(true);

      const { address } = deriveAccount(formattedSeedphrase, 0, "deviceKey");
      const userData = await isUserExists("a", "a", address);
      if (userData) {
        showAlert(
          "The seed phrase you entered is linked with an existing account. Please try logging in instead."
        );

        setSeedPhrase(Array(words).fill(1));
      } else {
        dispatch(saveMnemonic(formattedSeedphrase));

        setSeedPhrase(Array(words));

        navigate("/choose-method");
      }
      setLoading(false);
    }
  };

  return (
    <div>
      <AuthHeader />
      <div className="create-account-box">
        <Typography
          variant="h2"
          sx={{
            margin: "0px 0px 30px 0px",

            fontWeight: "700",
          }}
        >
          Enter your seed phrase
        </Typography>

        <div className="words-tab-box">
          <div
            className={`word-tab ${words === 12 && "active-word-tab"}`}
            onClick={() => setWords(12)}
          >
            <Typography variant="h6">12 Words</Typography>
          </div>
          <div
            className={`word-tab ${words === 18 && "active-word-tab"}`}
            onClick={() => setWords(18)}
          >
            <Typography variant="h6">18 Words</Typography>
          </div>
          <div
            className={`word-tab ${words === 24 && "active-word-tab"}`}
            onClick={() => setWords(24)}
          >
            <Typography variant="h6">24 Words</Typography>
          </div>
        </div>

        <div className="seed-phrase-main-container">
          {Array(words)
            .fill(1)
            .map((phrase, index) => {
              let number = (index + 1).toString();
              number = number.length === 1 ? "0" + number : number;
              return (
                <div className="seed-phrase-box" key={index}>
                  <SeedPhraseTextInput
                    number={number}
                    index={index}
                    seedPhrase={seedPhrase}
                    setSeedPhrase={setSeedPhrase}
                    key={index}
                    onPaste={onPaste}
                  />
                </div>
              );
            })}
        </div>

        <div className="welcome-box-container">
          <Button title="Continue" onClick={importWallet} loading={loading} />
        </div>
      </div>
    </div>
  );
};

export default ImportWallet;
