import { FC, useCallback, useEffect, useState } from "react";

import { Box, Slide, Typography, useTheme } from "@mui/material";
import CardLayout from "components/CardLayout";

import Globe from "assets/globe.svg";
import DigitalCash from "assets/digital-cash.svg";
import DepositIcon from "assets/deposit.svg";
import WithdrawIcon from "assets/withdraw.svg";
import SendIcon from "assets/send-icon.svg";
import ExchangeIcon from "assets/exchange.svg";

import Button from "components/NewButton";
import ChipButton from "components/ChipButton";
import ExpandableChart from "components/ExpandableChart";
import InfoCard from "components/InfoCard";

import CashBackground from "assets/backgrounds/cash.png";
import InfoCardAmount from "../../../components/InfoCardAmount";
import { useAppDispatch, useAppSelector } from "store/store";
import { fetchCashAccountChartData } from "utils/portfolio";
import { timeInSeconds } from "../Crypto/TokenDetailPage";
import BasicModal from "components/BasicModal";
import ModalHeader from "components/ModalHeader";
import CashCard from "assets/cashCard.svg";
import { useNavigate } from "react-router-dom";
import { decryptMessage, fetchUsdPriceFromMobula } from "utils/utils";
import {
  CASH_SUPPORTED_NETWORK,
  SUPPORTED_NETWORKS,
  SupportedChainId,
} from "constants/chains";
import abi from "abis/erc20abi.json";
import Web3 from "web3";
import { setRootAccountUsdBalance } from "@slices/appSlice";
import AccountDetailsBox from "components/AccountDetailsBox";
import { stat } from "fs";

const CashView: FC<{ accountName: string }> = ({ accountName }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { rootAccountInfo, history } = useAppSelector((state) => state.app);
  const { hashedPassword } = useAppSelector((state) => state.wallet);

  const [cashAccTotalBal, setCashAccTotalBal] = useState(0);
  const [chartData, setChartData] = useState([]);
  const [chartTimestamps, setChartTimestamps] = useState([]);
  const [openComingSoonModal, setOpenComingSoonModal] = useState(false);
  const [openAccountDetailsModal, setOpenAccountDetailsModal] = useState(false);

  const selectedTime = timeInSeconds[2];

  const getPortfolioBalance = async () => {
    const web3 = new Web3(CASH_SUPPORTED_NETWORK[SupportedChainId.BASE].rpc);
    const usdcContract = new web3.eth.Contract(
      //@ts-ignore
      abi.abi,
      CASH_SUPPORTED_NETWORK[SupportedChainId.BASE].usdcAddress
    );
    const balance = await usdcContract.methods
      .balanceOf(rootAccountInfo.address)
      .call();

    const pkey = decryptMessage(rootAccountInfo.secret, hashedPassword);
    console.log(pkey);

    const decimals = await usdcContract.methods.decimals().call();

    setCashAccTotalBal(balance / 10 ** decimals);
    dispatch(setRootAccountUsdBalance(balance / 10 ** decimals));
  };

  const getCashAccGraph = async () => {
    const response = await fetchCashAccountChartData(
      Math.floor(Date.now() / 1000) - selectedTime,
      Math.floor(Date.now() / 1000),
      rootAccountInfo?.address,
      "USD Coin"
    );
    console.log("response", response);
    if (response) {
      const chartData = response.map((item) => item[1]);
      const chartTimestamps = response.map((item) => item[0]);

      console.log("chartData", chartData);
      console.log("chartTimestamps", chartTimestamps);
      setChartData(chartData);
      setChartTimestamps(chartTimestamps);
    } else {
      setChartData([]);
      setChartTimestamps([]);
    }
  };

  // useEffect(() => {
  //   console.log('history.interval workinnnggg', history.interval)
  //   getCashAccGraph()
  // }, [history.interval])

  useEffect(() => {
    getCashAccGraph();
    getPortfolioBalance();
  }, [rootAccountInfo?.address]);

  // useCallback for <ExpandableChart /> when chartData & chartTimestamps changes
  const renderChart = useCallback(() => {
    return (
      <ExpandableChart
        //if chartData has only one value, it will not render the chart so we need to push same value twice and timestamp should be current timestamp
        chartData={
          chartData?.length === 1 ? [chartData[0], chartData[0]] : chartData
        }
        chartTimestamps={
          chartTimestamps.length === 1
            ? [chartTimestamps[0], Date.now()]
            : chartTimestamps
        }
        title={`${rootAccountInfo?.name}`}
        amount={cashAccTotalBal?.toString()}
        percentageChange="5"
      />
    );
  }, [chartData, chartTimestamps]);

  return (
    <>
      <BasicModal
        open={openComingSoonModal}
        onClose={() => setOpenComingSoonModal(false)}
      >
        <>
          <ModalHeader title="Coming Soon" textAlign="center" />
        </>
      </BasicModal>
      <Box
        className="flex-row-center"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "20px",
          flexDirection: "row",
          gap: "26px",
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
          },
        }}
      >
        {/* User Info & Balance Area */}
        <Box sx={{ paddingTop: "0px !important", flex: 1, width: "100%" }}>
          {/* <Card title="@igors" ens="" balance="50.67" /> */}
          <InfoCard
            backgroundImage={`url(${CashCard})`}
            backgroundPosition="0px"
            backgroundSize="cover"
            title={`@${rootAccountInfo?.name?.toLowerCase()}`}
            amount={<InfoCardAmount amount={cashAccTotalBal?.toString()} />}
            isCashTab
          />
        </Box>

        {/* Receive Cash Area */}

        <Box sx={{ paddingTop: "0px !important", flex: 1, width: "100%" }}>
          {/* <ExpandableChart
            //if chartData has only one value, it will not render the chart so we need to push same value twice and timestamp should be current timestamp
            chartData={
              chartData?.length === 1 ? [chartData[0], chartData[0]] : chartData
            }
            chartTimestamps={
              chartTimestamps.length === 1
                ? [chartTimestamps[0], Date.now()]
                : chartTimestamps
            }
            title={`${rootAccountInfo?.name}`}
            amount={cashAccTotalBal?.toString()}
            percentageChange='5'
          /> */}
          {renderChart()}
        </Box>
      </Box>

      <Box mt={2.5}>
        <Box
          className="flex-row-center"
          style={{
            display: "flex",
            gap: "4px",
            justifyContent: "flex-start",
            flexFlow: "wrap",
            paddingBottom: "20px",
          }}
        >
          <ChipButton
            title="Deposit"
            onClick={() => {
              navigate("/cash/deposit");
            }}
            icon={DepositIcon}
            fontWeight={400}
            fontSize={14}
            iconHeight={15}
            iconWidth={15}
            fontFamily="Helvetica Neue"
          />
          <ChipButton
            title="Withdraw"
            onClick={() => {
              navigate("/cash/withdraw");
            }}
            icon={WithdrawIcon}
            fontWeight={400}
            fontSize={14}
            iconHeight={4}
            iconWidth={15}
            fontFamily="Helvetica Neue"
          />
          <ChipButton
            title="Send"
            onClick={() => {
              navigate("/cash/send");
            }}
            icon={SendIcon}
            fontWeight={400}
            fontSize={14}
            iconHeight={11}
            iconWidth={14}
            fontFamily="Helvetica Neue"
          />
          <ChipButton
            title="Exchange"
            onClick={() => {
              navigate("/cash/exchange");
            }}
            icon={ExchangeIcon}
            fontWeight={400}
            fontSize={14}
            iconHeight={28}
            iconWidth={22}
            fontFamily="Helvetica Neue"
          />
        </Box>

        <Box
          className="flex-row-center"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "40px",
            paddingBottom: "40px",
            flexDirection: "row",
            gap: "26px",
            [theme.breakpoints.down("md")]: {
              flexDirection: "column",
            },
          }}
        >
          <CardLayout
            backgroundColor="white"
            style={{ flex: 1, width: "100%" }}
          >
            <Slide in={true} timeout={500} direction={"down"}>
              <Box
                className="flex-col-center"
                style={{
                  padding: "20px 0px",
                  height: "275px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                <Typography
                  variant={"h5"}
                  fontWeight={700}
                  color="rgba(26, 28, 32, 1)"
                  fontSize={25}
                  style={{ cursor: "pointer" }}
                >
                  Receive Cash instantly
                </Typography>
                <Typography
                  variant={"h5"}
                  fontWeight={400}
                  color="rgba(26, 28, 32, 0.5)"
                  fontSize={12}
                  style={{ cursor: "pointer" }}
                >
                  with zero fees
                </Typography>
                <img
                  src={Globe}
                  width={"100%"}
                  height={"100%"}
                  style={{
                    overflow: "hidden",
                  }}
                />
                <Box p={2.5} height="27px">
                  <AccountDetailsBox
                    title={`@${rootAccountInfo.name}`}
                    isCashTab={true}
                    open={openAccountDetailsModal}
                    handleOpen={() => setOpenAccountDetailsModal(true)}
                    handleClose={() => setOpenAccountDetailsModal(false)}
                    isShowWithOutTitle={true}
                  />
                </Box>

                <div
                  style={{
                    width: "70%",
                    maxWidth: "305px",
                    marginTop: "-10%",
                  }}
                >
                  <Button
                    title="Receive"
                    style={{
                      backgroundColor: "rgba(235, 236, 238, 1)",
                      margin: 0,
                    }}
                    textStyle={{ color: "rgba(26, 28, 32, 1)" }}
                    onClick={() => {
                      navigate("/cash/receive", {
                        state: {
                          title: `@${rootAccountInfo.name}`,
                          isCashTab: true,
                          isShowWithOutTitle: true,
                        },
                      });
                    }}
                  />
                </div>
              </Box>
            </Slide>
          </CardLayout>
          <CardLayout
            backgroundColor="white"
            style={{
              flex: 1,
              width: "100%",
            }}
          >
            <Slide in={true} timeout={500} direction={"down"}>
              <Box
                className="flex-col-center"
                style={{
                  padding: "20px 10%",
                  height: "275px",
                  display: "flex",
                  flexDirection: "column",
                  gap: 15,
                  justifyContent: "flex-start",
                }}
              >
                <img src={DigitalCash} />
                <Typography
                  variant={"h5"}
                  fontWeight={700}
                  color="rgba(26, 28, 32, 1)"
                  fontSize={20}
                  style={{ cursor: "pointer", margin: "10px 0px" }}
                >
                  Your Digital Cash
                </Typography>
                <Typography
                  variant={"h5"}
                  fontWeight={400}
                  color="rgba(101, 109, 126, 1)"
                  fontSize={14}
                  style={{ cursor: "pointer" }}
                  textAlign={"center"}
                >
                  You can use your digital cash to buy crypto, make payments,
                  send to friends, and withdraw to your bank account. Your
                  digital cash is held in USDC and always redeemable 1:1 for
                  fiat.
                </Typography>
              </Box>
            </Slide>
          </CardLayout>
        </Box>
      </Box>
    </>
  );
};

export default CashView;
