import { Box } from "@mui/system";
import React, { ReactNode } from "react";
import TransactionMetaDataItem from "./TransactionMetaDataItem";
import {
  SUPPORTED_NETWORKS,
  SupportedChainNames,
} from "../../../../constants/chains";
interface ChildComponentProps {
  props: {
    date: string;
    status: string;
    toEns: string;
    network: string;
    chain: string;
    networkFee?: string;
    scanUrl?: string;
  };
}

const TransactionMetaData: React.FC<ChildComponentProps> = ({ props }) => {
  const { date, status, toEns, network, networkFee, chain, scanUrl } = props;

  function formatWalletAddress(
    walletAddress: string,
    charsToDisplay: number = 6
  ): string {
    if (walletAddress.length < charsToDisplay) {
      return walletAddress;
    }

    const start = walletAddress.slice(0, charsToDisplay);
    const end = walletAddress.slice(-charsToDisplay);

    return `${start}...${end}`;
  }

  const newDate = new Date(date);

  const options: any = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  };

  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
    newDate
  );

  return (
    <Box display={"flex"} flexDirection={"column"} gap={2}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          padding: "20px",
          borderRadius: "10px",
          background: "#FFFFFF",
        }}
      >
        <TransactionMetaDataItem
          title="Date"
          RightComponent={
            //   <a
            //     target="_blank"
            //     // href={`${url}/address/${contractAddress}`}
            //     style={{ textDecoration: "none" }}
            //   >
            <Box
              sx={{
                fontFamily: "Space Grotesk",
                fontWeight: 500,
              }}
            >
              {formattedDate}
              {/* {formatWalletAddress(contractAddress, 4)} */}
            </Box>
            //   </a>
          }
        />
        <TransactionMetaDataItem
          title="Status"
          RightComponent={
            <Box
              sx={{
                fontFamily: "Space Grotesk",
                fontWeight: 500,
              }}
            >
              {status}
            </Box>
          }
        />
        {/* <TransactionMetaDataItem
          title='To'
          RightComponent={
            <Box
              sx={{
                color: '#1A1C20',
                fontFamily: 'Space Grotesk',
                fontWeight: 500,
              }}
            >
              {toEns}
            </Box>
          }
        /> */}
        <TransactionMetaDataItem
          title="Network"
          RightComponent={
            <Box
              sx={{
                color: "#1A1C20",
                fontFamily: "Space Grotesk",
                fontWeight: 500,
              }}
            >
              {chain}
            </Box>
          }
        />

        <TransactionMetaDataItem
          title="Network Fee"
          RightComponent={
            <Box
              sx={{
                color: "#1A1C20",
                fontFamily: "Space Grotesk",
                fontWeight: 500,
              }}
            >
              {networkFee}
            </Box>
          }
        />
        {/* {lastUpdated && (
        <TransactionMetaDataItem
          title="Last Updated"
          RightComponent={
            <Box
              sx={{
                color: "#1A1C20",
                fontFamily: "Space Grotesk",
                fontWeight: 500,
              }}
            >
              {lastUpdated}
            </Box>
          }
        />
      )}
      {lastUpdated && (
        <TransactionMetaDataItem
          title="Last Updated"
          RightComponent={
            <Box
              sx={{
                color: "#1A1C20",
                fontFamily: "Space Grotesk",
                fontWeight: 500,
              }}
            >
              {lastUpdated}
            </Box>
          }
        />
      )} */}
      </Box>
      <a href={scanUrl} target="_blank">
        <Box textAlign={"center"}>View on Polygonscan</Box>
      </a>
    </Box>
  );
};

export default TransactionMetaData;
