import BasicButton from "components/Button";
import Receive from "components/Receive";
import Send from "components/Send";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const SendReceive = () => {
  const [activeTab, setActiveTab] = useState("send");

  const location = useLocation();

  useEffect(() => {
    if (location?.state?.active) {
      setActiveTab(location?.state?.active);
    }
  }, []);

  return (
    <div className="main-view">
      <div className="send-header">
        <BasicButton
          title="Send"
          onClick={() => setActiveTab("send")}
          className={`send-btn ${activeTab === "send" && "active-send-btn"}`}
        />
        <BasicButton
          title="Receive"
          onClick={() => setActiveTab("receive")}
          className={`send-btn ${activeTab === "receive" && "active-send-btn"}`}
        />
      </div>
      {activeTab === "send" ? <Send /> : <Receive />}
    </div>
  );
};

export default SendReceive;
