// import { Col, Row, Text } from '@nextui-org/react'

/**
 * Types
 */
interface IProps {
  methods: string[];
}

/**
 * Component
 */
export default function RequestMethodCard({ methods }: IProps) {
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h5>Methods</h5>
        <p data-testid="request-methods">
          {methods.map((method) => method).join(", ")}
        </p>
      </div>
    </div>
  );
}
