import { Input, InputAdornment } from "@mui/material";
import React, { FC } from "react";

const SendInput: FC<{ value: number; setValue: (e) => void; mode: string }> = ({
  value,
  setValue,
  mode,
}) => {
  const handleChange = e => {
    const valid = /^\d*\.?(?:\d{1,20})?$/;
    if (e.target.value.length < 20) {
      if (valid.test(e.target.value)) {
        setValue(e.target.value);
      }
    }
  };

  return (
    <div className='input' id='send-input'>
      {/* <FormControl variant='standard'> */}
      <Input
        id='input-with-icon-adornment'
        value={value}
        onChange={handleChange}
        startAdornment={
          <InputAdornment position='start' className='adornment'>
            {mode}
          </InputAdornment>
        }
        autoComplete='off'
        style={{ color: "black" }}
      />
      {/* </FormControl> */}
    </div>
  );
};

export default SendInput;
