import axios from "axios";
import { IN_INVENTORY, SANDBOX_CASE, MOBULA_API } from "constants/";
import { SUPPORTED_NETWORKS } from "constants/chains";
import { isIPFSLink } from "./isIpfsLink";

export const fetchOwnedNftsFromMobula = async (address: string) => {
  try {
    // Replace with your Alchemy API key:

    // console.log("file: nfts.ts:71  fetchOwnedNftsFromMobula  nfts:", nfts);
    const baseURL = `${MOBULA_API}/wallet/nfts`;
    // // Replace with the wallet address you want to query:
    // // const ownerAddr = "0xF5FFF32CF83A1A614e15F25Ce55B0c0A6b5F8F2c";
    const fetchURL = `${baseURL}?wallet=${address}&force=true`;
    const { data } = await axios.get(fetchURL, {
      headers: {
        Authorization: process.env.REACT_APP_MOBULA_KEY,
      },
    });
    console.log("file: nfts.ts:72  fetchOwnedNftsFromMobula  data:", data);
    const res = data.data;

    const nftData = res.reduce((result: any[], key: any) => {
      let metadata = JSON.parse(key.metadata);
      console.log("file: nfts.ts:79  nftDatametadata:", metadata);
      if (metadata) {
        console.log("file: nfts.ts:79  nftData  metadata:", metadata?.image);
        const IPFS_GATEWAY = "https://ipfs.io/ipfs/";
        if (isIPFSLink(metadata?.image)) {
          metadata.image = `${IPFS_GATEWAY}${metadata?.image?.replace(
            "ipfs://",
            ""
          )}`;
          console.log(
            "file: nfts.ts:93  nftDataasdasd  metadata?.image:",
            metadata?.image
          );
          metadata.prevImage = metadata?.image;
        }
      }

      return [...result, { ...key, metadata }];
    }, []);
    console.log("file: nfts.ts:82  nftDataresres  nftData:", nftData, res);

    const modifiedNfts = await Promise.all(
      nftData.map(async (nft) => {
        const floorPrice = await fetchFloorPrice(
          nft.token_address,

          nft.blockchain
        );

        nft.floorPrice = floorPrice || 0;

        return nft;
      })
    );

    return modifiedNfts;
  } catch (error) {
    console.log("err in fetching owned nfts from alchemy", error);
    return [];
  }
};

export const fetchFloorPrice = async (address: string, network: string) => {
  try {
    const baseURL = `${MOBULA_API}/market/nft`;
    const fetchURL = `${baseURL}?asset=${address}&blockchain=${network}`;
    const { data } = await axios.get(fetchURL, {
      headers: {
        Authorization: process.env.REACT_APP_MOBULA_KEY,
      },
    });
    return data.data.priceETH;
  } catch (error) {
    console.log(error);
    return 0;
  }
};

const calculateAllocation = (arr: any[]) => {
  let totalValue = arr.reduce((sum, obj) => sum + obj.nfts.length, 0);

  arr = arr?.map((nft) => {
    nft.allocation = Number(
      (nft.allocation = ((nft.nfts.length / totalValue) * 100).toFixed(2))
    );
    return nft;
  });

  return arr;
};
