import {
  setContacts,
  setHoldings,
  setNFTs,
  setNewNfts,
  setPortfolio,
  setHistory,
  setUserEns,
  setTransactions,
  setBalances,
  setUserSpendingDetails,
  setCashTransactions,
  setConnectedDapps2,
  setGasInfo,
} from "@slices/appSlice";
import CustomLogo from "../assets/morflax.svg";
import {
  setIsAccountDeployed,
  setSelectedToken,
  setWalletLoading,
} from "@slices/walletSlice";
import { SUPPORTED_NETWORKS } from "constants/chains";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/store";
import { fetchUserHoldings } from "utils/holdings";
import { fetchOwnedNftsFromMobula } from "utils/nfts";
import { io } from "socket.io-client";
import {
  fetchCashAccountHistory,
  fetchCryptoAccHistory,
  fetchWalletPortfolio,
  getAssetsDollarWorth,
} from "utils/portfolio";
import {
  decryptMessage,
  fetchEncryptedKeysByName,
  getAllTransactions,
} from "utils/utils";

import {
  History,
  Holdings,
  NFTHoldings,
  Portfolio,
  newNFTHoldings,
  userContacts,
} from "../interfaces";

import { Interval } from "../enums";
import { getAllUserContacts } from "../utils/contact";
import { Core } from "@walletconnect/core";
import { BASE_URL } from "constants/index";
import axios from "axios";
import { ethers } from "ethers";
import {
  EMPTY_CALLDATA,
  Paymaster_Token_Address,
} from "../contract-integration";
import ModalStore from "../walletConnectStore/ModalStore";
import { ClientJS } from "clientjs";
import { SocketContext } from "../socketContext";
import { tokensForGas } from "constants/topTokensConf";
import Web3 from "web3";
import paymasterAbi from "abis/paymasterabi.json";
import { provider } from "web3-core";

const useApp = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const {
    accounts,
    testnetNetworks,
    nfts,
    newNFTs,
    history,
    connectedDapps,
    connectedDapp2,
    holdings,
    swapDetails: { tokenB },
    transactions: walletTransactions,
    pendingTx,
  } = useAppSelector((state) => state.app);
  const { socket } = useContext(SocketContext);
  const location = useLocation();
  useEffect(() => {
    console.log(
      "🚀 ~ file: useApp.tsx:58 ~ useApp ~ connectedDapps:",
      connectedDapps
    );
  }, [connectedDapps]);

  const { hashedPassword, txStatus } = useAppSelector((app) => app.wallet);

  const {
    activeNetwork,
    activeAccount,
    rootAccountInfo,
    isAccountCreated,
    transactions,
    contacts,
  } = useAppSelector((state) => state.app);
  console.log(
    "file: useApp.tsx:60  useApp getAllUserContacts transactions:",
    transactions,
    history,
    rootAccountInfo,
    contacts
  );

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    console.log("account change, useEffect 1");
    if (location.pathname === "/cash" || location.pathname === "/crypto") {
      (async () => {
        console.log("NETWORK SWITCH HORAHA", activeNetwork);

        if (
          Object.keys(accounts).length > 0 &&
          activeAccount.address &&
          hashedPassword
        ) {
          const { nativeTokenName, symbol, icon, rpc } =
            SUPPORTED_NETWORKS[
              activeNetwork as keyof typeof SUPPORTED_NETWORKS
            ];
          await dispatch(setWalletLoading(true));

          let holdings: Holdings = {};
          let nftsHoldings: NFTHoldings = {};
          let newNFTsHoldings: newNFTHoldings = {};
          let walletPortfolio: Portfolio;
          let walletHistory: History;

          console.log(
            "file: useApp.tsx:56 asdasdsadsad Object.keys  acc:",
            activeAccount.smartAccountAddress
          );

          nftsHoldings[activeAccount.smartAccountAddress] = { nfts };

          walletHistory = await fetchCryptoAccHistory(
            activeAccount.smartAccountAddress,
            2
          );
          // activeAccount.smartAccountAddress
          walletPortfolio = await fetchWalletPortfolio(
            activeAccount.smartAccountAddress
          );
          console.log(
            "file: useApp.tsx:177  walletPortfolio:",
            walletPortfolio,
            accounts
          );
          // activeAccount.smartAccountAddress

          await updateWalletHistory(walletHistory);
          dispatch(setPortfolio({ ...walletPortfolio }));

          if (walletPortfolio.assets.length > 0) {
            walletPortfolio.assets.map((token) => {
              if (
                token?.asset?.name?.toLowerCase() === "polygon" &&
                token?.asset?.symbol?.toLowerCase() == "matic"
              ) {
                holdings[activeAccount.smartAccountAddress] = {
                  nativeBalance: token?.asset?.token_balance,
                  nativeBalanceUsd: token?.asset?.estimated_balance,
                  nativeTokenPrice: token?.asset?.price,
                  tokens: [],
                };

                dispatch(
                  setHoldings({
                    ...holdings,
                  })
                );
              }
            });
          } else {
            holdings[activeAccount.smartAccountAddress] = {
              nativeBalance: 0,
              nativeBalanceUsd: 0,
              nativeTokenPrice: 0,
              tokens: [],
            };
            dispatch(
              setHoldings({
                ...holdings,
              })
            );
          }

          dispatch(
            setNFTs({
              ...nftsHoldings,
            })
          );

          await dispatch(setWalletLoading(false));

          console.log("ENDED///////////////////////////////");
        }
      })();
    }
  }, [
    activeAccount.address,
    txStatus,
    activeNetwork,
    accounts,
    hashedPassword,
    isLoggedIn,
    location.pathname,
  ]);

  const isAccountDeployed = async () => {
    const rpc =
      SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS].rpc;

    const provider = new ethers.providers.JsonRpcProvider(rpc);

    const accountByteCode = await provider.getCode(
      activeAccount.smartAccountAddress
    );

    const isDeployed = accountByteCode != EMPTY_CALLDATA;

    dispatch(setIsAccountDeployed(!!isDeployed));
    return isDeployed;
  };

  useEffect(() => {
    isAccountDeployed();
  }, [activeAccount, activeNetwork]);

  useEffect(() => {
    if (location.pathname == "/nfts") {
      (async () => {
        console.log("NETWORK SWITCH HORAHA", activeNetwork);
        if (
          Object.keys(accounts).length > 0 &&
          activeAccount.address &&
          hashedPassword
        ) {
          await dispatch(setWalletLoading(true));

          let newNFTsHoldings: newNFTHoldings = {};

          const mobulaNfts = await fetchOwnedNftsFromMobula(
            activeAccount.smartAccountAddress
          );
          // accounts[acc].smartAccountAddress

          newNFTsHoldings[activeAccount.smartAccountAddress] = mobulaNfts;

          dispatch(
            setNewNfts({
              ...newNFTsHoldings,
            })
          );

          await dispatch(setSelectedToken([]));
          await dispatch(setWalletLoading(false));

          console.log("ENDED///////////////////////////////");
        }
      })();
    }
  }, [
    activeAccount.address,
    txStatus,
    activeNetwork,
    accounts,
    hashedPassword,
    location.pathname,
  ]);

  useEffect(() => {
    // This async function will handle the fetching of data and updating the state
    const fetchBalances = async () => {
      let smartWalletBalance: {
        [address: string]: number;
      } = {};
      let newGrandTotal = 0;

      for (const key in accounts) {
        if (accounts.hasOwnProperty(key)) {
          const activeAccount = accounts[key];

          try {
            // Fetch the wallet portfolio using the smartAccountAddress of the active account
            const walletPortfolio = await fetchWalletPortfolio(
              activeAccount.smartAccountAddress
            );

            // If 'total_wallet_balance' exists, update the individual balances and grand total
            if (
              walletPortfolio &&
              walletPortfolio.total_wallet_balance !== undefined
            ) {
              const balance = parseFloat(walletPortfolio.total_wallet_balance);
              smartWalletBalance[activeAccount.smartAccountAddress] = balance;
              newGrandTotal += balance;
            } else {
              smartWalletBalance[activeAccount.smartAccountAddress] = 0;
              newGrandTotal += 0;
              console.error(
                `No balance information found for account: ${activeAccount.smartAccountAddress}`
              );
            }
          } catch (error) {
            console.error(
              `Failed to retrieve portfolio for account: ${activeAccount.smartAccountAddress}`,
              error
            );
            // Depending on your error handling strategy, you might want to set balances to zero or keep the last known value
          }
        }
      }

      dispatch(
        setBalances({
          accumulatedAllAccounts: newGrandTotal,
          smartWalletBalance,
        })
      );
    };

    fetchBalances();
  }, [accounts]);

  useEffect(() => {
    if (
      location.pathname == "/settings" ||
      location.pathname.includes("send")
    ) {
      (async () => {
        console.log("NETWORK SWITCH HORAHA", activeNetwork);
        if (
          Object.keys(accounts).length > 0 &&
          activeAccount.address &&
          hashedPassword
        ) {
          await dispatch(setWalletLoading(true));

          let userContacts: userContacts = [];

          // await Promise.all(
          // Object.keys(accounts).map(async (acc) => {
          const contacts = await getAllUserContacts({
            address: rootAccountInfo.address,
          });
          console.log(
            "file: getAllUserContacts useApp.tsx:323  Object.keys  contacts:",
            contacts
          );
          // accounts[acc].smartAccountAddress

          userContacts = contacts;
          // })
          // );

          dispatch(setContacts([...userContacts]));

          console.log("ENDED///////////////////////////////");
        }
      })();
    }
  }, [
    activeAccount.address,
    txStatus,
    activeNetwork,
    accounts,
    hashedPassword,
    location.pathname,
  ]);
  useEffect(() => {
    (async () => {
      console.log("NETWORK SWITCH HORAHA", activeNetwork);
      if (
        Object.keys(accounts).length > 0 &&
        activeAccount.address &&
        hashedPassword
      ) {
        await dispatch(setWalletLoading(true));

        let newTransactions: any = {};

        const cryptoTransactions = await getAllTransactions(
          activeAccount.smartAccountAddress
        );
        // activeAccount.smartAccountAddress

        newTransactions[activeAccount.smartAccountAddress] = cryptoTransactions;

        console.log(
          "file: useApp.tsx:257  Object.keys  newTransactions:",
          newTransactions
        );

        dispatch(
          setTransactions({
            ...newTransactions,
          })
        );

        dispatch(setWalletLoading(false));

        const cashTransactions = await fetchCashAccountHistory(
          rootAccountInfo.address
        );

        console.log("XAHHHHHHHHHHH transactions", cashTransactions);

        dispatch(setCashTransactions(cashTransactions));

        await dispatch(setWalletLoading(false));

        console.log("ENDED///////////////////////////////");
      }
    })();
  }, [
    activeAccount.address,
    txStatus,
    activeNetwork,
    accounts,
    hashedPassword,
  ]);

  // useEffect(() => {
  //   (async () => {
  //     if (activeAccount.address && hashedPassword) {
  //       const { data, profit, sum, timestamps } = await fetchGraphData(
  //         holdings,
  //         activeAccount.smartAccountAddress,
  //         type,
  //         activeNetwork
  //       );
  //       dispatch(setPortfolio({ profit, sum, data, timestamps, type }));
  //     }
  //   })();
  // }, [type, activeAccount, hashedPassword, txStatus, activeNetwork, holdings]);

  // useEffect(() => {
  //   // console.log("account change, useEffect 2");
  //   (async () => {
  //     if (activeAccount.address && hashedPassword) {
  //       const { data, profit, sum, timestamps } = await fetchGraphData(
  //         holdings,
  //         activeAccount.smartAccountAddress,
  //         type,
  //         activeNetwork
  //       );
  //       dispatch(setPortfolio({ profit, sum, data, timestamps, type }));
  //     }
  //   })();
  // }, [type, activeAccount, hashedPassword, txStatus, activeNetwork, holdings]);

  useEffect(() => {
    console.log("account change, useEffect 3");
    if (Object.keys(accounts).length > 0 && isAccountCreated) {
      setIsLoggedIn(true);
      if (!hashedPassword) {
        navigate("/login");
      }
    } else {
      setIsLoggedIn(false);
    }
  }, [accounts, isAccountCreated, hashedPassword]);

  useEffect(() => {
    if (hashedPassword) {
      (async () => {
        let isPatternSet = false;
        console.log("🚀 ~ file: useApp.tsx:512 ~ isPatternSet:", isPatternSet);
        const request = await axios.get(
          `${BASE_URL}/transaction/isFirstTx/${activeAccount.smartAccountAddress}`
        );
        console.log("🚀 ~ file: useApp.tsx:516 ~ request:", request);
        const isFirstTx = request.data.isFirstTx;
        console.log("🚀 ~ file: useApp.tsx:517 ~ isFirstTx:", isFirstTx);

        const request1 = await axios.get(
          `${BASE_URL}/security-question/getUser/${rootAccountInfo.address.toLocaleLowerCase()}`
        );
        const request2 = await fetchEncryptedKeysByName(rootAccountInfo.name);
        console.log("🚀 ~ file: useApp.tsx:523 ~ request2:", request2);
        let path = [];

        if (request2?.pattern) {
          isPatternSet = true; //
          let parsedPath = decryptMessage(request2.pattern, hashedPassword);
          console.log("🚀 ~ file: useApp.tsx:530 ~ path:", parsedPath);
          path = JSON.parse(parsedPath);
          console.log("🚀 ~ file: useApp.tsx:534 ~ path:", path);
        }

        const request3 = await axios.get(
          `${BASE_URL}/transaction/isSpentExceed/${rootAccountInfo.address.toLocaleLowerCase()}`
        );
        console.log(
          "🚀 ~ file: useApp.tsx:533 ~ request3:",
          request3.data.isDailyLimitExceed
        );
        const isDailyLimitExceed = request3.data.isDailyLimitExceed;

        console.log(isPatternSet, isFirstTx, "33333333333333333333");
        if (request1.data.spendingLimit) {
          dispatch(
            setUserSpendingDetails({
              isPatternSet,
              isSpendingLimitSaved: true,
              isFirstTx,
              path,
              isDailyLimitExceed,
            })
          );
        } else {
          dispatch(
            setUserSpendingDetails({
              isPatternSet,
              isFirstTx,
              path,
              isDailyLimitExceed,
            })
          );
        }
      })();
    }
  }, [tokenB, hashedPassword]);

  useEffect(() => {
    (async () => {
      const transactionsRequest = await axios.get(
        `${BASE_URL}/transaction/byAction?address=${rootAccountInfo.address}&action=Swapped`
      );
      console.log(
        "🚀 ~ file: useApp.tsx:538 ~ transactionsRequest:",
        transactionsRequest.data
      );

      const newTransactions = [
        ...walletTransactions[activeAccount.smartAccountAddress],
        ...transactionsRequest.data.transactions,
      ];
      console.log(
        "🚀 ~ file: useApp.tsx:548 ~ newTransactions:",
        newTransactions
      );

      dispatch(
        setTransactions({
          [activeAccount.smartAccountAddress]: newTransactions,
        })
      );
    })();
  }, [pendingTx]);

  // For interval updates
  // useEffect(() => {
  //   ;(async () => {
  //     const walletHistory = await fetchWalletHistory(
  //       activeAccount.smartAccountAddress,
  //       history.interval
  //     )
  //     await updateWalletHistory(walletHistory)
  //   })()
  // }, [history.interval])

  useEffect(() => {
    dispatch(
      setHistory({
        interval: Interval.DAY,
      })
    );
  }, []);

  const updateWalletHistory = async (walletHistory: any) => {
    // Extract data from the API response
    const balanceHistory = walletHistory;
    const chartData = balanceHistory.map((item: any[]) => item[1]);
    const chartTimestamps = balanceHistory.map((item: any[]) => item[0]);

    dispatch(
      setHistory({
        ...walletHistory.data,
        chart_data: chartData,
        chart_timestamps: chartTimestamps,
      })
    );
  };
  useEffect(() => {
    // Create a new ClientJS object
    const client = new ClientJS();

    // Get the client's fingerprint id
    const fingerprint = client.getFingerprint();
    console.log(
      "🚀 ~ file: useApp.tsx:678 ~ useEffect ~ fingerprint:",
      fingerprint
    );

    // Print the 32bit hash id to the console
    console.log(fingerprint);
    // fingerprint is your unique browser id.
    // This is well tested

    // const socket = io("http://localhost:4000");
    // // socket.emit('messageFromReactApp1', { message: 'Hello from React App 1!' })

    // socket.on("eth_requestAccounts", (data) => {
    //   // Handle the message from the server here.
    //   console.log(data, "The dat");
    //   socket.emit("connect_account", { address: activeAccount.address });
    // });

    // socket.on("onTransactionSend", (data) => {
    //   // Handle the message from the server here.
    //   console.log(data.payload, "onTransactionSend");
    //   ModalStore.open("SdkSendTransactionModal", {
    //     payload: data.payload,
    //   });
    // });
    return () => {
      // socket.disconnect();
    };
  }, []);

  const readAccountHoldings = async (
    tokenAddress: string,
    address: string,
    rpc: provider,
    decimal: number
  ) => {
    const web3 = new Web3(rpc);
    const paymasterContract = new web3.eth.Contract(
      paymasterAbi as any,
      Paymaster_Token_Address
    );

    try {
      const accountHolding = await paymasterContract.methods
        .balances(tokenAddress, address)
        .call();
      return Number(ethers.utils.formatUnits(String(accountHolding), decimal));
    } catch (error) {
      console.error("Error reading account holdings:", error);
      return 0; // Handle the error gracefully
    }
  };

  useEffect(() => {
    // Create an object to keep track of cumulative balances for each token

    if (activeAccount.smartAccountAddress) {
      type CumulativeBalances = { [tokenAddress: string]: number };
      const cumulativeBalances: CumulativeBalances = {};

      // Iterate through the accounts and fetch balances for each
      async function fetchBalances() {
        // setLoading(true);
        let tokenAddressesForMobula = tokensForGas.map(
          (tokenInfo) => tokenInfo.tokenAddressForMobula
        );
        let depositedTokenObject: Array<any> = [];

        // Call the getAssetsDollarWorth function with the tokenAddressesForMobula array as the argument
        const tokenData = await getAssetsDollarWorth(tokenAddressesForMobula);

        // Extract the prices from the returned object
        let tokenWorthInUSD = Object.values(tokenData).map(
          (token) => token.price
        );

        // Now you can use the tokenWorthInUSD array in your loop
        for (let i = 0; i < tokensForGas.length; i++) {
          const tokenInfo = tokensForGas[i];
          const { tokenAddress, rpc, decimal } = tokenInfo;
          const balance = await readAccountHoldings(
            tokenAddress,
            activeAccount.smartAccountAddress,
            rpc,
            decimal
          );

          const tokenWorthInUSDForCurrentToken = tokenWorthInUSD[i];
          const balanceInUSD = balance * tokenWorthInUSDForCurrentToken;
          if (balance > 0) {
            depositedTokenObject.push({ ...tokenInfo, balance, balanceInUSD });
          }

          // Add the balance to the cumulative balance for the token
          if (!cumulativeBalances[tokenAddress]) {
            cumulativeBalances[tokenAddress] = balanceInUSD;
          } else {
            cumulativeBalances[tokenAddress] += balanceInUSD;
          }
        }
        // Calculate the total cumulative balance
        let totalBalance = 0;
        for (const tokenAddress of Object.keys(cumulativeBalances)) {
          totalBalance += cumulativeBalances[tokenAddress];
        }
        console.log("depositedTokenObject", depositedTokenObject);

        dispatch(
          setGasInfo({
            totalGas: totalBalance,
            depositedTokens: depositedTokenObject,
          })
        );

        // setLoading(false);
      }

      fetchBalances();
    }
  }, [pendingTx, activeAccount]);

  useEffect(() => {
    console.log("socket in wallet", socket);
    // const socket = io("http://localhost:4000");
    // // socket.emit('messageFromReactApp1', { message: 'Hello from React App 1!' })

    socket?.on("eth-server-request-account", (data) => {
      // Handle the message from the server here.
      console.log(data, "The dat");

      console.log("we are sending this", {
        address: activeAccount.smartAccountAddress,
        dappId: data.payload.dappId,
        holding: holdings,
      });

      socket.emit("eth-wallet-response-account", {
        address: activeAccount.smartAccountAddress,
        dappId: data.payload.dappId,
        holding: holdings,
      });
    });

    socket?.on("send-dapps-ids", (data) => {
      console.log("send-dapps-ids response data", data);
      const { payload } = data;
      let updatedDapps: any[] = [];
      if (payload?.dappIds.length > 0) {
        // if (connectedDapp2.length > 0) {
        // updatedDapps = connectedDapp2.filter(dapp => payload?.dappIds.includes(dapp?.description))
        payload?.dappIds.forEach((dappId: any) => {
          updatedDapps.push({
            name: `DappXYZ`,
            description: dappId,
            url: "https://www.google.com",
            icons: [CustomLogo],
            _id: dappId,
          });
        });
        console.log("send-dapps-ids IF chala ", updatedDapps);
        // } else {
        //   payload?.dappIds.forEach(dappId => {
        //     updatedDapps.push({
        //       name: `DappXYZ-${dappId}`,
        //       description: dappId,
        //       url: "https://www.google.com",
        //       icons: [CustomLogo],
        //       _id: dappId
        //     })
        //   })
        //   console.log("send-dapps-ids ELSE chala ", updatedDapps)
        //   // const dappDetails = {
        //   //   name: "DappXYZ",
        //   //   description: data.payload.dappId,
        //   //   url: "https://www.google.com",
        //   //   icons: [CustomLogo],
        //   // };
        // }
        dispatch(setConnectedDapps2(updatedDapps));
      } else {
        dispatch(setConnectedDapps2([]));
      }
    });

    socket?.on("server-request-network-change", (data) => {
      console.log("🚀 ~ server-request-network-changeta:", data);
      socket?.emit("wallet-response-network-change", {
        dappId: data.payload.dappId,
      });
    });

    socket?.on("server-request-sign-transaction", (data) => {
      // Handle the message from the server here.
      console.log(data.payload, "onTransactionSend");
      ModalStore.open("SdkSendTransactionModal", {
        payload: data.payload.payload,
      });
    });
    return () => {
      // if (socket) socket.disconnect();
    };
  }, [socket]);

  return { isLoggedIn };
};

export default useApp;
