import axios from "axios";

import {
  SupportedChainId,
  SUPPORTED_NETWORKS,
  TESTNET_NETWORKS,
} from "constants/chains";
import { SingleEthHistory, TransactionHistoryResult } from "interfaces";
import { checkSum, getAllTokenIdsAndImpactPrice, initalizeWeb3 } from "./utils";
// import { ETHToken } from "assets";
import { tokensData } from "constants/tokens";
import paymasterAbi from "abis/paymasterabi.json";
import { Paymaster_Token_Address } from "../contract-integration/constants";
import Web3 from "web3";

const { abi } = require("abis/erc20abi.json");

export const fetchHoldings = async (
  address: string,
  network: SupportedChainId
) => {
  try {
    // 0x7431310e026B69BFC676C0013E12A1A11411EEc9
    const web3 = initalizeWeb3(network);
    const url =
      SUPPORTED_NETWORKS[network as keyof typeof SUPPORTED_NETWORKS].api;
    const key =
      SUPPORTED_NETWORKS[network as keyof typeof SUPPORTED_NETWORKS].api_key;

    const icon =
      SUPPORTED_NETWORKS[network as keyof typeof SUPPORTED_NETWORKS].icon;

    const obj: {
      [key: string]: TransactionHistoryResult;
    } = {};
    let erc20 = [];

    const { data } = await axios.get(
      `${url}?module=account&action=tokentx&address=${address}&sort=asc&apikey=${key}`
    );

    const result: TransactionHistoryResult[] = data.result.slice(0, 500);

    if (result.length > 0 && result && Array.isArray(result)) {
      erc20 = await Promise.all(
        result
          ?.map(async (res) => {
            if (!obj[res.contractAddress]) {
              obj[res.contractAddress] = {
                tokenName: res.tokenName,
                tokenSymbol: res.tokenSymbol,
                tokenDecimal: res.tokenDecimal,
                contractAddress: checkSum(res.contractAddress),
              };

              const contract = new web3.eth.Contract(abi, res.contractAddress);

              const tokenBalance = await contract.methods
                .balanceOf(address)
                .call();
              console.log(
                "tokenbalance sjkkjs",
                res.tokenName,
                tokenBalance,
                Number(res.tokenDecimal),
                Number(tokenBalance) / 10 ** Number(res.tokenDecimal),
                res.contractAddress,
                res
              );
              if (
                Number(tokenBalance) &&
                res.tokenName &&
                res.contractAddress !==
                  "0xdeaddeaddeaddeaddeaddeaddeaddeaddead0000"
              ) {
                console.log(
                  "tokenbalance sjkkjs ye wale jaenge",
                  res.tokenName,
                  tokenBalance
                );
                return {
                  id: checkSum(res.contractAddress),
                  tokenName: res.tokenName,
                  tokenSymbol: res.tokenSymbol,
                  tokenBalance: Number(
                    Number(tokenBalance) / 10 ** Number(res.tokenDecimal)
                  ),
                  tokenBalanceRawInteger: tokenBalance,
                  tokenAddress: checkSum(res.contractAddress),
                  tokenDecimal: +res.tokenDecimal,
                  priceInUSD:
                    1 *
                    Number(
                      Number(tokenBalance) / 10 ** Number(res.tokenDecimal)
                    ),
                  image:
                    tokensData[res?.tokenSymbol?.toLowerCase()]?.image || icon,
                  tokenPrice: 1, //! get coingeckoid to get token price is usd for testnet tokens
                  allocation: 0,
                };
              }
            }
          })
          .sort((a, b) => b?.priceInUSD - a?.priceInUSD)
      );

      let filtered: SingleEthHistory[] = [];

      erc20?.forEach((token) => {
        if (token) {
          filtered.push(token);
        }
      });

      // if (filtered.length > 15) {
      //   filtered = filtered.splice(0, 30);
      // }
      let updatedData = calculateAllocation(filtered);

      const data = calculatePriceImpactAndUsdPrice(updatedData, network);
      console.log("data", data);
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.log(error);
  }
};

export const fetchUserHoldings = async (
  address: string,
  network: SupportedChainId,
  testnetNetworks: SupportedChainId[]
) => {
  if (testnetNetworks.includes(network)) {
    const data = await fetchHoldings(address, network);
    return data;
  } else {
    const data = await fetchHoldings(address, network);
    return data;
  }
};

const calculatePriceImpactAndUsdPrice = async (
  data: any[],
  network: SupportedChainId
) => {
  const allTokenAddresses = data.map((token) =>
    token.tokenAddress.toLowerCase()
  );

  const allTokenIdsAnPriceImpact = await getAllTokenIdsAndImpactPrice(
    allTokenAddresses
  );

  let erc20WithAllData;
  if (allTokenIdsAnPriceImpact && allTokenIdsAnPriceImpact.length) {
    erc20WithAllData = data.map((token) => {
      const result = allTokenIdsAnPriceImpact.find(
        (obj) => obj.symbol.toLowerCase() === token.tokenSymbol.toLowerCase()
      );
      if (result) {
        return {
          ...token,
          priceImpact: result.price_change_percentage_24h,
          tokenId: result.id,
          priceInUSD: result.current_price * Number(Number(token.tokenBalance)),
          image: result.image || "ETHToken",
          tokenPrice: result.current_price, //
        };
      } else {
        return {
          ...token,
          priceInUSD: TESTNET_NETWORKS.includes(network) ? token.priceInUSD : 0,

          priceImpact: 0,
          tokenId: 0,
        };
      }
    });
  } else {
    erc20WithAllData = data.map((token) => {
      return {
        ...token,
        priceInUSD: TESTNET_NETWORKS.includes(network) ? token.priceInUSD : 0,
        priceImpact: 0,
        tokenId: 0,
      };
    });
  }

  return erc20WithAllData.sort((a, b) => b?.priceInUSD - a?.priceInUSD);
};

const calculateAllocation = (arr: any[]) => {
  let totalValue = arr.reduce((sum, obj) => sum + obj.tokenBalance, 0);

  arr = arr?.map((token) => {
    token.allocation = Number(
      ((token.tokenBalance / totalValue) * 100).toFixed(2)
    );
    return token;
  });

  return arr;
};

export const readAccountHoldings = async (
  tokenAddress: string,
  address: string,
  rpc: string
) => {
  const web3 = new Web3(rpc);
  const paymasterContract = new web3.eth.Contract(
    paymasterAbi as any,
    Paymaster_Token_Address
  );
  console.log("file: index.tsx:236  Send depositableTokens paymasterContract:");
  // Create an object to keep track of cumulative balances for each token

  try {
    const accountHolding = await paymasterContract.methods
      .balances(tokenAddress, address)
      .call();
    console.log(
      "file: index.tsx:241 depositableTokens  Send  accountHolding:",
      accountHolding,
      tokenAddress
    );
    return Number(accountHolding);
  } catch (error) {
    console.error("Error reading depositableTokens account holdings:", error);
    return 0; // Handle the error gracefully
  }
};
