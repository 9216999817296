import React, { ReactNode, useEffect, useState } from "react";
import { getCounterFactualAddress } from "../../../../contract-integration";
import Web3 from "web3";
import MidArrow from "assets/midArrow.svg";

import "./index.css";
import {
  Box,
  FormControl,
  Grid,
  SelectChangeEvent,
  Snackbar,
  Typography,
} from "@mui/material";
import BasicModal from "components/BasicModal";
import ModalHeader from "components/ModalHeader";
import AssetsView from "components/AssetsView";
import { Token } from "interfaces";
import { useAppDispatch, useAppSelector } from "store/store";

import { SUPPORTED_NETWORKS, SupportedChainId } from "constants/chains";
import {
  decryptMessage,
  extractTokenData,
  numFormatter,
  showAlert,
  truncateToken,
} from "utils/utils";
import abi from "abis/erc20abi.json";
import paymasterAbi from "abis/paymasterabi.json";

import { setSelectedToken } from "@slices/walletSlice";
import { fetchGasPrice } from "utils/gas";
import { BASE_URL, NATIVE_ADDRESS } from "constants/";
import { ethers, utils } from "ethers";
import {
  EMPTY_CALLDATA,
  ExecuteCall,
  Paymaster_Owner_Address,
  Paymaster_Token_Address,
  TransferData,
} from "../../../../contract-integration/constants";
import {
  setPendingTx,
  setPendingTxDetails,
  setUserSpendingDetails,
} from "@slices/appSlice";
import { useNavigate } from "react-router-dom";
import CustomizedSteppers from "components/Stepper";
import NavigatorHeading from "components/NavigatorHeading";
import CloseButton from "components/CloseButton";
import NetworksList from "components/NetworksList";
import TokensListTable from "../TokensListTable";
import TokenInputForm from "components/TokenInputForm";
import { sendUserOp } from "../../../../contract-integration";
import { txSubmissionOrderPrepaid } from "../../../../contract-integration/prepaidGas";
import { getEthDollarValue } from "../../../../utils/portfolio";

import CashIcon from "assets/cash-token.svg";
import { depositCalldata } from "utils/bridge";
import TopTokenSelectComponent from "components/TopTokenSelect";
import { tokensForGasObject } from "constants/topTokensConf";
import FeeUrgencyComponent from "components/SendTxComponent/FeeUrgencyComponent";
import TxTypeSwitch from "components/SendTxComponent/TxTypeSwitch";
import SendTxComponent from "components/SendTxComponent";
import GasTokenSelect from "components/GasTokenSelect";
import FeeUIComponent from "components/SendTxComponent/FeeUIComponent";
import axios from "axios";
import CryptoFiatTabs from "components/CryptoFiatTabs";

import ReceiveTokenList from "components/ReceiveTokenList";
import SwapSummary from "components/SwapSummary";
import BuyFiat from "components/Buyfiat";
import GBPIcon from "assets/GBPIcon.svg";
import BuyFiatPreview from "components/BuyFiatPreview";
import { fetchUniswapSupportedTokens } from "utils/swap";

const CryptoSell = () => {
  const [selectedDepositTokenAddress, setSelectedDepositTokenAddress] =
    useState("");
  const [depositableTokens, setDepositableTokens] = useState<Array<any>>([]);
  const [finalOpState, setFinalOpState] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [to, setTo] = useState("");
  const [txByDeposited, setTxByDeposited] = useState(false);
  const [error, setError] = useState("");

  const [gasFeeInUSD, setGasFeeInUSD] = useState("0");
  const [openModal, setOpenModal] = useState(false);
  const [tokens, setTokens] = useState<Token[]>([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [step, setStep] = useState(0);
  const [feeGasUrgency, setFeeGasUrgency] = React.useState("Normal");
  const [tokenForPayment, SetTokenForPayment] = React.useState("");
  const [tokenForPaymentDecimal, setTokenForPaymentDecimal] = useState<
    number | null
  >(null);
  const [sameTokens, setSameTokens] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState("crypto");

  const [isCashSelected, setIsCashSelected] = useState(false);

  const [paymentMethod, setPaymentMethod] = useState(false);
  const [activeCurrency, setActiveCurrency] = useState("GBP");
  const [activeCurrencyIcon, setActiveCurrencyIcon] = useState(GBPIcon);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({});
  const [activeCryptoOnRamperId, setActiveCryptoOnRamperId] = useState("");
  const [swapTokens, setSwapTokens] = useState<any>([]);
  const [fiatList, setFiatList] = useState([{}]);
  const [cryptoList, setCryptoList] = useState([{}]);

  const navigate = useNavigate();
  const {
    activeAccount,
    holdings,
    accounts,
    activeNetwork,
    portfolio,
    rootAccountInfo,
    balances,
    userSpendingDetails,
  } = useAppSelector((state) => state.app);

  const { selectedToken, isAccountDeployed } = useAppSelector(
    (state) => state.wallet
  );
  console.log("file: index.tsx:89 Send selectedToken:", selectedToken);
  const { hashedPassword, txStatus } = useAppSelector((state) => state.wallet);

  const { nativeTokenName, symbol, icon, rpc, block_explorer_url } =
    SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS];

  const { nativeBalance, nativeBalanceUsd, nativeTokenPrice } =
    holdings[activeAccount.smartAccountAddress];

  const dispatch = useAppDispatch();

  const web3 = new Web3(rpc);

  const readAccountHoldings = async (
    tokenAddress: string,
    address: string,
    rpc: string
  ) => {
    const web3 = new Web3(rpc);
    const paymasterContract = new web3.eth.Contract(
      paymasterAbi as any,
      Paymaster_Token_Address
    );
    console.log(
      "file: index.tsx:236  Send depositableTokens paymasterContract:"
    );
    // Create an object to keep track of cumulative balances for each token

    try {
      const accountHolding = await paymasterContract.methods
        .balances(tokenAddress, address)
        .call();
      console.log(
        "file: index.tsx:241 depositableTokens  Send  accountHolding:",
        accountHolding,
        tokenAddress
      );
      return Number(accountHolding);
    } catch (error) {
      console.error("Error reading depositableTokens account holdings:", error);
      return 0; // Handle the error gracefully
    }
  };

  async function fetchBalances() {
    const allAccountsAddress = Object.keys(accounts);

    const firstAccountAddress =
      accounts[allAccountsAddress[0]].smartAccountAddress;
    console.log(
      "file: index.tsx:256  fetchBalances depositableTokens  firstAccountAddress:",
      firstAccountAddress
    );

    let depositedTokenObject: Array<any> = [];
    for (const tokenInfo of tokensForGasObject[activeNetwork]) {
      const { tokenAddress, rpc } = tokenInfo;
      console.log(
        "file: index.tsx:265  fetchBalances depositableTokens  tokenAddress:",
        tokenAddress
      );
      const balance = await readAccountHoldings(
        tokenAddress,
        firstAccountAddress,
        rpc
      );
      console.log(
        "file: index.tsx:271 depositableTokens fetchBalances  balance:",
        balance
      );

      if (balance > 0) {
        depositedTokenObject.push({ ...tokenInfo, balance });
      }
    }
    setDepositableTokens(depositedTokenObject);
  }
  useEffect(() => {
    // Create an object to keep track of cumulative balances for each token
    type CumulativeBalances = { [tokenAddress: string]: number };
    const cumulativeBalances: CumulativeBalances = {};

    // Iterate through the accounts and fetch balances for each
    console.log(
      "file: index.tsx:271 depositableTokens fetchBalances  balance:"
    );

    fetchBalances();
  }, [accounts, activeAccount, activeNetwork]);

  useEffect(() => {
    let userTokens: Token[] =
      holdings[activeAccount.smartAccountAddress]?.tokens || [];

    setTokens(userTokens);
  }, [activeAccount, holdings]);

  useEffect(() => {
    const data = extractTokenData(activeNetwork, portfolio.assets);
    // SetTokenForPayment(data.address);
    // setTokenForPaymentDecimal(data.decimal);
    setSameTokens(data.sameTokens);
  }, [activeNetwork, portfolio.assets]);

  useEffect(() => {
    (async () => {
      setLoading(true);

      const tokens = await fetchUniswapSupportedTokens(activeNetwork);
      setSwapTokens(tokens);

      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await axios.get("https://api.onramper.com/supported", {
          headers: {
            Authorization: process.env.ONRAMP_API_KEY,
          },
        });
        const { message } = data;

        const { crypto, fiat } = message;
        setFiatList(fiat);
        setCryptoList(crypto);
      } catch (error) {
        console.log("E>>>>>>>>>>>", error);
        setLoading(false);
      }
    })();
  }, []);

  const isSelectedToken = selectedToken.length > 0;
  const tokenSymbol = isSelectedToken ? selectedToken[0].tokenSymbol : symbol;
  const tokenIcon = isSelectedToken ? selectedToken[0].image : icon;
  const tokenName = isSelectedToken
    ? selectedToken[0].tokenName
    : nativeTokenName;
  const tokenBalance = isSelectedToken
    ? selectedToken[0].tokenBalance
    : nativeBalance;
  const tokenBalanceInUsd = isSelectedToken
    ? selectedToken[0].priceInUSD
    : nativeBalanceUsd;
  const tokenPriceInUsd = isSelectedToken
    ? selectedToken[0].tokenPrice
    : nativeTokenPrice;
  const tokenAddress = isSelectedToken ? selectedToken[0].tokenAddress : "";

  // const { dummyToken } = SUPPORTED_NETWORKS[activeNetwork];

  useEffect(() => {
    console.log("selectedtoken", selectedToken);
    if (
      tokenSymbol &&
      cryptoList.length > 0 &&
      step === 3 &&
      activeTab === "fiat"
    ) {
      const ISNATIVE =
        tokenAddress == "0x0000000000000000000000000000000000000000" ||
        tokenAddress == "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270";

      const availableCrypto = ISNATIVE
        ? cryptoList.find(
            (cr) =>
              cr?.address?.toLowerCase() ===
                "0x0000000000000000000000000000000000001010" &&
              cr.network === "polygon" &&
              cr.symbol === "matic"
          )
        : cryptoList.find(
            (cr) =>
              cr?.address?.toLowerCase() === tokenAddress.toLowerCase() &&
              cr.network === "polygon"
          );

      console.log("availableCrypto", availableCrypto);

      if (!availableCrypto) {
        showAlert(`${tokenSymbol} currently not supported for onRamp`);
        setStep(1);
      } else {
        console.log("availableCrypto", availableCrypto);
        setActiveCryptoOnRamperId(availableCrypto);
      }
    }
  }, [tokenSymbol, cryptoList, step, activeTab]);

  useEffect(() => {
    calculateGasInEth(tokenForPayment);
  }, [selectedToken, tokenForPayment, step, feeGasUrgency]);

  useEffect(() => {
    setPaymentMethod(false);
  }, [activeTab]);

  async function calculateGasInEth(tokenAddressForPayment: string) {
    try {
      const rpc =
        SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS]
          .alchemy_url;

      const web3 = new Web3(rpc);

      const [{ gas, gasWithSlack }, ethDollarPrice] = await Promise.all([
        calculateTransferGas({
          address: selectedToken[0]?.tokenAddress,
        }),
        getEthDollarValue({}),
      ]);

      const filteredData = (tokenAddress: string) =>
        portfolio.assets.filter((item) => {
          if (item && item.cross_chain_balances) {
            const chainKeys = Object.keys(item.cross_chain_balances);

            for (const chain of chainKeys) {
              const address = item.cross_chain_balances[chain].address;

              if (address?.toLowerCase() == tokenAddress?.toLowerCase()) {
                return true;
              }
            }
          }
          return false;
        });

      const gasForTwoTransfer = gasWithSlack * 2;
      const USDGas = Number(gasForTwoTransfer) * ethDollarPrice;
      const { amount } = numFormatter(USDGas, 4);
      setGasFeeInUSD(String(amount));

      const tokenAsPayment = filteredData(tokenAddressForPayment);
      console.log(
        "file: index.tsx:369  calculateGasInEth tokenAsPaymentBalance  tokenAsPayment:",
        tokenAsPayment
      );
      const tokenAsPaymentBalance =
        tokenAsPayment.length > 0 ? tokenAsPayment[0].token_balance : 0;

      const ethGasInUSD = gasForTwoTransfer * ethDollarPrice;

      const hasEnoughAssets = tokenAsPaymentBalance - USDGas > 0;
      console.log(
        "file: index.tsx:375  calculateGasInEth  tokenAsPaymentBalance:",
        tokenAsPaymentBalance,
        tokenAsPayment[0].token_balance
      );

      return {
        gasForTwoTransfer,
        tokenAsPaymentBalance,
        hasEnoughAssets,
        ethUSDPrice: ethDollarPrice,
        ethGasInUSD,
        USDGas,
      };
    } catch (e) {
      return {
        gasForTwoTransfer: 0,
        tokenAsPaymentBalance: 0,
        hasEnoughAssets: true,
        ethUSDPrice: 0,
        ethGasInUSD: 0,
        USDGas: 0,
      };
    }
  }

  const calculateTransferGas = async ({ address }: { address: string }) => {
    try {
      console.log(
        "file: index.tsx:311  calculateTransferGas  calculateGasInEth address:",
        address
      );
      if (!address) {
        return { gasWithSlack: 0, gas: 0 };
      }
      const rpc =
        SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS]
          .rpc;

      const web3 = new Web3(rpc);
      const contractAddress = address;

      const contractAbi = abi.abi;
      const transferTokenContract = new web3.eth.Contract(
        contractAbi as any,
        contractAddress
      );
      console.log(
        "file: index.tsx:329  calculateTransferGas calculateGasInEth transferTokenContract:",
        rpc
      );

      const transferFunctionCall = await transferTokenContract.methods.transfer(
        Paymaster_Owner_Address,
        ethers.utils
          .parseUnits(String(value), String(selectedToken[0].tokenDecimal))
          .toString()
      );
      console.log(
        "file: index.tsx:340 calculateGasInEth  calculateTransferGas  transferFunctionCall:",
        transferFunctionCall
      );

      const [estimatedGas, latestBlock] = await Promise.all([
        transferFunctionCall.estimateGas({
          from: activeAccount.smartAccountAddress,
        }),
        web3.eth.getBlock("latest"),
      ]);
      console.log(
        "file: index.tsx:343  calculateTransferGas calculateGasInEth estimatedGas:",
        estimatedGas
      );
      const blockGas = latestBlock.gasLimit;
      const finalGas = blockGas * estimatedGas;
      const finalGasInEther = web3.utils.fromWei(finalGas.toString(), "ether");

      const slackEthPercentage = feeGasUrgency == "Normal" ? 0.1 : 0.5;
      const gasCostEthWithSlack =
        Number(finalGasInEther.toString()) +
        Number(finalGasInEther.toString()) * slackEthPercentage;

      return {
        gasWithSlack: Number(gasCostEthWithSlack),
        gas: Number(finalGasInEther),
      };
    } catch (e) {
      console.log(
        "file: index.tsx:377 calculateGasInEth  calculateTransferGas  e:",
        e
      );
      return {
        gasWithSlack: 0,
        gas: 0,
      };
    }
  };

  const handleDepositTokenChange = (
    event: SelectChangeEvent<string>,
    child: ReactNode
  ) => {
    console.log(
      "file: index.tsx:1812  Send  depositableTokens:",
      depositableTokens
    );
    const decimal = depositableTokens.filter(
      (token) =>
        token.tokenAddress.toLowerCase() == event.target.value.toLowerCase()
    );
    console.log("file: index.tsx:40  handleChangeasdasd  decimal:", decimal);
    setTokenForPaymentDecimal(decimal[0].decimal);
    setSelectedDepositTokenAddress(event.target.value as string);
  };

  const selectTokenHandler = (tk: Token) => {
    dispatch(setSelectedToken([tk]));
    setOpenModal(false);
  };

  const isValid =
    !error && Number(value) && to && Number(value) <= Number(tokenBalance);
  const isDepositValid =
    !error && Number(value) && Number(value) <= Number(tokenBalance);

  const handleSelectTokenForPayment = (address: string) => {
    SetTokenForPayment(address);
  };

  useEffect(() => {
    if (
      selectedToken.length > 0 &&
      tokenForPayment &&
      value &&
      !txByDeposited
    ) {
      sendCryptoTransaction();
    }
  }, [selectedToken, tokenForPayment, step]);

  async function sendCryptoTransaction() {
    try {
      setLoading(true);
      const {
        chainId,
        accrossAddress,
        alchemy_url,
        usdcAddress,
        usdcDecimals,
        swapRouter02,
      } = SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS];

      const pkey = decryptMessage(activeAccount.secret, hashedPassword);
      console.log("file: index.tsx:448  newSendTransaction  pkey:", pkey);
      const provider = new ethers.providers.JsonRpcProvider(alchemy_url);
      const wallet = new ethers.Wallet(pkey, provider);
      console.log(
        "file: index.tsx:332  newSendTransaction  wallet:",
        wallet.address
      );
      const web3 = new Web3(alchemy_url);
      const nonce = await web3.eth.getTransactionCount(
        activeAccount.smartAccountAddress,
        "latest"
      );

      console.log("selectedtoken", selectedToken);

      let tokenAmountToTransfer = "1000";

      const tokenAddress = selectedToken[0].tokenAddress;

      const ISNATIVE =
        tokenAddress.toLowerCase() ==
          "0x0000000000000000000000000000000000000000" ||
        tokenAddress.toLowerCase() ==
          "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270";

      let transferData: TransferData = {
        tokenAddress: ISNATIVE ? "0x" : tokenForPayment,
        tokenAmount: tokenAmountToTransfer,
      };

      let decimals;
      let userCallDataArray: ExecuteCall[] = [];

      const gasLimits = {
        approve: "59103",
        swapExactInput: "135300",
        swapExactOutput: "141660",
        deposit: "92452",
      };

      if (ISNATIVE) {
        decimals = 18;
      } else {
        //@ts-ignore
        const contract = new web3.eth.Contract(abi.abi, tokenAddress);
        decimals = await contract.methods.decimals().call();
        console.log("decimals", decimals);
      }

      const { secret } = activeAccount;

      const data = {
        recipient: rootAccountInfo.address,
        sender: activeAccount.smartAccountAddress,
        originToken: tokenAddress,
        originTokenDecimals: Number(decimals),
        amount: utils.parseUnits(value.toString(), decimals).toString(), // incase of exactInput send the originToken value
        destinationChainId: SupportedChainId.BASE,
        message: "0x",
        maxCount:
          "115792089237316195423570985008687907853269984665640564039457584007913129639935",
        payable: ISNATIVE,
        nonce,
        paymentToken: tokenForPayment,
      };

      const transactionOpts = {
        toAddress: accrossAddress,
        gasPrice: 0,
      };

      console.log(
        "depositCalldata",
        data,
        transactionOpts,
        activeNetwork,
        gasLimits
      );

      const txns = await depositCalldata(
        data,
        transactionOpts,
        activeNetwork,
        gasLimits
      );
      console.log("txns", txns);

      txns.map((tx) => {
        userCallDataArray.push({
          calldata: tx.data,
          to: tx.to,
          value: tx.value,
        });
      });

      console.log("userCallDataArray", userCallDataArray);

      console.log(
        "file: index.tsx:485  newSendTransaction  counterfactual:",
        EMPTY_CALLDATA,
        wallet.address,
        0,
        provider
      );

      console.log("tokenForPaymentDecimal", tokenForPaymentDecimal);
      const isHighFees = feeGasUrgency == "Lightning";

      const { blockaid } =
        SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS];

      const { data: data1 } = await axios.post(`${BASE_URL}/api/blockaid`, {
        options: ["simulation"],

        data: {
          from: activeAccount.smartAccountAddress,
          data: userCallDataArray[0].calldata,
          value: userCallDataArray[0].value,
          to: userCallDataArray[0].to,
        },
        metadata: { domain: "https://app.1inch.io" },
        network: blockaid,
      });

      console.log("DATAAAAAAAAAAa", data1);

      if (data1.simulation.error) {
        showAlert("This transaction might fail", "Warning");
      }

      const [counterfactual, { finalOp }] = await Promise.all([
        getCounterFactualAddress(EMPTY_CALLDATA, wallet.address, 0, provider),
        txSubmissionOrderPrepaid({
          transferData,
          userCallDataArray,
          counterfactual: activeAccount.smartAccountAddress,
          wallet,
          transferTokenDecimal: tokenForPaymentDecimal ?? 18,
          isHighFees,
          isAccountDeployed,
        }),
      ]);
      console.log(
        "file: index.tsx:483  newSendTransaction  counterfactual:",
        counterfactual,
        userCallDataArray
      );

      console.log("Clicked!");

      console.log(
        "file: index.tsx:1058  sendCryptoTransactionfinal  finalOp:",
        finalOp
      );

      setFinalOpState(finalOp);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      showAlert("Something went wrong");
    }
  }

  const executeBridgeCrypto = async () => {
    try {
      setLoading(true);
      if (finalOpState) {
        const response = await sendUserOp(
          finalOpState,
          "https://api.stackup.sh/v1/node/221b5cfa6d4f5cff2e221d693b2e953d49d9797d0f18f2e6d119482223a92a37",
          "https://polygon-mainnet.g.alchemy.com/v2/HBxGEElD4fSo3gWukvZFV9YTKO4OvCnw"
        );
        const userOPResponse: any = await response.wait();
        console.log("userOp Hash :", response.userOpHash);
        console.log("Tx Hash :", userOPResponse?.transactionHash);
        console.log("success status :", userOPResponse?.args.success);
        console.log(
          "actualGasCost  :",
          Number(userOPResponse?.args.actualGasCost)
        );
        // const response = await sendUserOp(finalOp, bundlerRPC, rpcEndpoint);
        // const userOPResponse: any = await response.wait();
        // console.log("Tx Hash :", userOPResponse?.transactionHash);
        // console.log("success status :", userOPResponse?.args.success);
        console.log(
          "actualGasCost  :",
          Number(userOPResponse?.args.actualGasCost)
        );
        console.log(
          "actualGasUsed  :",
          Number(userOPResponse?.args.actualGasUsed)
        );
        dispatch(
          setPendingTxDetails({
            value: value,
            valueIn$: String(
              Number(value) * Number(selectedToken[0].tokenPrice ?? 0)
            ),
            transferAmount: value,
            transactionMethod: "Crypto",
            scanLink: block_explorer_url,
            eoaEns: rootAccountInfo.name,
            addressEns: activeAccount.accountName,
            toAddressEns: rootAccountInfo.name,
            toAddress: rootAccountInfo.address,
            assetName: selectedToken[0].tokenName,
            networkFeesIn$: gasFeeInUSD,
            iconURL: selectedToken[0].image,
            txByDesposited: false,
            action: "Exchanged",
          })
        );
        dispatch(setPendingTx(response.userOpHash));

        showAlert(
          "Soon you can see your transaction in the transactions tab",
          "Transaction Submitted",
          `<a href="https://polygonscan.com/tx/${userOPResponse?.transactionHash}" target="_blank">View on Polygonscan</a>`
        );
      }
      if (
        !userSpendingDetails.isFirstTx &&
        !userSpendingDetails.isFirstTxInApp
      ) {
        dispatch(
          setUserSpendingDetails({
            isFirstTxInApp: true,
          })
        );
        navigate("/transaction-success");
      } else {
        setFinalOpState(null);
        setLoading(false);
        navigate("/crypto");
      }
    } catch (error) {
      setLoading(false);
      showAlert("Transaction Failed");
    }
  };

  const handleAmountChange = (value) => {
    const inputValue = value;

    if (/[^0-9.]/.test(inputValue)) {
      setError("Special characters are not allowed");
    } else if ((inputValue.match(/\./g) || []).length > 1) {
      setError("Only one decimal point is allowed");
    } else if (!/^\d{0,10}(\.\d{0,15})?$/.test(inputValue)) {
      setError(
        "Maximum of 10 digits before decimals and 15 digits after decimals are allowed"
      );
    } else if (parseFloat(inputValue) * tokenPriceInUsd < 2.01) {
      setError("Value should not be less than $2.01");

      setValue(inputValue);
    } else {
      setError("");

      setValue(inputValue);
    }
  };

  const handleSelectTokenForPaymentWithDecimal = ({
    address,
    decimal,
  }: {
    address: string;
    decimal: number;
  }) => {
    SetTokenForPayment(address);
    setTokenForPaymentDecimal(decimal);
  };

  const handleKeyDown = (event) => {
    if (event.key === "ArrowUp") {
      event.preventDefault();

      // Parse the current value to a float
      const numericValue = parseFloat(value || "0");

      // Increment the value by 1
      const newValue = (numericValue + 1).toFixed(2);
      if (/[^0-9.]/.test(newValue)) {
        setError("Special characters are not allowed");
      } else if (parseFloat(newValue) * tokenPriceInUsd < 2.01) {
        setError("Value should not be less than $2.01");

        setValue(newValue);
      } else {
        setValue(newValue);
        setError("");
      }
    }

    if (event.key === "ArrowDown") {
      event.preventDefault();

      // Parse the current value to a float
      const numericValue = parseFloat(value || "0");

      // Ensure the value doesn't go below 0.01
      const newValue = (numericValue - 1).toFixed(2);
      if (/[^0-9.]/.test(newValue)) {
        setError("Special characters are not allowed");
      } else if (parseFloat(newValue) * tokenPriceInUsd < 2.01) {
        setError("Value should not be less than $2.01");

        setValue(newValue);
      } else {
        if (Number(newValue) > 0) {
          setValue(newValue);
        }
        setError("");
      }
    }
  };

  const onTop20Select = () => {
    if (loading) {
      return;
    }
    setTxByDeposited(false);
    setFinalOpState(null);
    SetTokenForPayment("");
    setSelectedDepositTokenAddress("");
  };

  const onDepositSelect = () => {
    if (loading) {
      return;
    }
    setTxByDeposited(true);
    setFinalOpState(null);
    SetTokenForPayment("");
    setSelectedDepositTokenAddress("");
  };

  return (
    <>
      <BasicModal open={openModal} onClose={() => setOpenModal(false)}>
        <>
          <ModalHeader
            title="Select asset"
            onClose={() => setOpenModal(false)}
            showBackIcon
          />
          <AssetsView tokens={tokens} selectTokenHandler={selectTokenHandler} />
        </>
      </BasicModal>
      <Box mt={6}>
        <NavigatorHeading
          title="Sell"
          RightComponent={
            <CloseButton
              handleOnClick={() => {
                navigate("/crypto");
              }}
            />
          }
        />
      </Box>
      <Box mt={6}>
        <CustomizedSteppers
          step={step}
          steps={["Network", "Asset", "Amount", "Method", "Sell"]}
          changeStep={(selectedStep: number) => {
            //eg. if user is on step 3 he should be able to move at step 1 or 2 on clicking step icon
            if (selectedStep < step) {
              setStep(selectedStep);
            }
          }}
        />

        <Box mt={5}>
          {step == 0 && (
            <Grid container display="flex" justifyContent="center">
              <Grid
                item
                lg={6}
                sm={12}
                style={{
                  flexBasis: "65%",
                  maxWidth: "100%",
                }}
              >
                <NetworksList
                  nextStep={() => setStep(1)}
                  title="Select which network you want to receive crypto on"
                />
              </Grid>
            </Grid>
          )}
          {step == 1 && (
            <Grid container display="flex" justifyContent="center">
              <Grid
                item
                lg={6}
                sm={12}
                style={{
                  flexBasis: "65%",
                  maxWidth: "100%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "17px",
                    textAlign: "left",
                    color: "rgba(26, 28, 32, 0.5)",
                    fontFamily: "Helvetica Neue",
                    fontWeight: 500,
                    width: "100%",
                    paddingBottom: "20px",
                  }}
                >
                  Select the token you want to Sell
                </Typography>
                <TokensListTable
                  transactionForm={true}
                  nextStep={() => setStep(2)}
                  chainId={activeNetwork}
                  isApplyFilter={false}
                  isShowTokenAmountUnderName
                />
              </Grid>
            </Grid>
          )}
          {step == 2 && (
            <Grid container display="flex" justifyContent="center" py={4}>
              <Grid
                item
                lg={6}
                sm={12}
                style={{
                  flexBasis: "65%",
                  maxWidth: "100%",
                }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Typography
                    sx={{
                      fontSize: "17px",
                      textAlign: "left",
                      color: "rgba(26, 28, 32, 0.5)",
                      fontFamily: "Helvetica Neue",
                      fontWeight: 500,
                      width: "100%",
                    }}
                  >
                    Enter amount to sell
                  </Typography>
                  <TokenInputForm
                    placeHolder={tokenBalance}
                    title="Crypto Tag"
                    addBorder
                    type="number"
                    onChange={handleAmountChange}
                    onKeydown={handleKeyDown}
                    value={value}
                    receiverENS={to}
                    isDepositValid={!!isValid || !!isDepositValid}
                    // isDepositValid={true}
                    nextStep={() => setStep(3)}
                    tokenName={tokenSymbol}
                    tokenIcon={tokenIcon}
                    buttonTitle="Continue"
                    balance={tokenBalance}
                    errorMessage={error}
                  />
                </Box>
              </Grid>
            </Grid>
          )}

          {step == 3 && (
            <Grid container display="flex" justifyContent="center">
              <Grid
                item
                lg={6}
                sm={12}
                style={{
                  flexBasis: "65%",
                  maxWidth: "100%",
                }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Typography
                    sx={{
                      fontSize: "17px",
                      textAlign: "left",
                      color: "rgba(26, 28, 32, 0.5)",
                      fontFamily: "Helvetica Neue",
                      fontWeight: 500,
                      width: "100%",
                      margin: "5px 0px 5px 0px",
                    }}
                  >
                    Select how you want to pay
                  </Typography>

                  <CryptoFiatTabs
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />

                  {activeTab === "crypto" ? (
                    <>
                      <ReceiveTokenList
                        nextStep={() => setStep(4)}
                        setIsCashSelected={() =>
                          setIsCashSelected(!isCashSelected)
                        }
                        isCashSupported
                        swapTokens={swapTokens}
                        loading={loading}
                      />
                    </>
                  ) : (
                    <BuyFiat
                      setPaymentMethod={setPaymentMethod}
                      setActiveCurrency={setActiveCurrency}
                      setActiveCurrencyIcon={setActiveCurrencyIcon}
                      setSelectedPaymentMethod={setSelectedPaymentMethod}
                      activeCurrency={activeCurrency}
                      paymentMethod={paymentMethod}
                      nextStep={setStep}
                      tokenAddress={tokenAddress}
                      setActiveCryptoOnRamperId={setActiveCryptoOnRamperId}
                      tokenSymbol={tokenSymbol}
                      activeCryptoOnRamperId={activeCryptoOnRamperId}
                      fiatList={fiatList}
                      type="sell"
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          )}

          {step == 4 &&
            (activeTab === "crypto" && !isCashSelected ? (
              <SwapSummary />
            ) : activeTab === "fiat" ? (
              <BuyFiatPreview
                selectedPaymentMethod={selectedPaymentMethod}
                currencyIcon={activeCurrencyIcon}
                currencySymbol={activeCurrency}
                tokenIcon={tokenIcon}
                tokenPriceInUsd={tokenPriceInUsd}
                tokenSymbol={tokenSymbol}
                value={value}
                activeCryptoOnRamperId={activeCryptoOnRamperId}
                nextStep={() => setStep(2)}
                type="sell"
              />
            ) : (
              <Grid container display="flex" justifyContent="center" py={4}>
                <Grid
                  item
                  lg={6}
                  sm={12}
                  style={{
                    flexBasis: "65%",
                    maxWidth: "100%",
                  }}
                >
                  <Box mt={2.5} position={"relative"}>
                    <div
                      className="input-container"
                      style={{ marginBottom: 10 }}
                    >
                      <FormControl sx={{ flex: 1 }}>
                        <Box
                          sx={{
                            backgroundColor: "#EDEEF2",
                            color: "#1A1C20",
                            width: "100%",
                            // height: "80px",
                            borderRadius: "10px",
                            fontSize: "25px",
                            fontFamily: "Space Grotesk",
                            fontWeight: "700",
                            border: "0.5px solid rgba(26, 28, 32, 0.50)",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                paddingTop: 15,
                              }}
                            >
                              <Box mr={1.25}>
                                {/* <img src={tokenIcon} height={33} width={33} /> */}
                                <img
                                  src={tokenIcon}
                                  height={33}
                                  style={{ padding: 10 }}
                                />
                              </Box>
                              <Box
                                sx={{
                                  color: "#1A1C20",
                                  fontFamily: "Space Grotesk",
                                  fontSize: "18px",
                                  fontStyle: "normal",
                                  fontWeight: "600",
                                  lineHeight: "normal",
                                  marginTop: -1,
                                  marginLeft: -1.5,
                                }}
                              >
                                {tokenSymbol}
                              </Box>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                marginRight: 10,
                              }}
                            >
                              <Box
                                mr={1.25}
                                sx={{
                                  color: "#1A1C20",
                                  fontFamily: "Space Grotesk",
                                  fontSize: "16px",
                                  fontStyle: "normal",
                                  fontWeight: "600",
                                  lineHeight: "normal",
                                }}
                              >
                                ${(value * tokenPriceInUsd).toFixed(3)}
                              </Box>
                              <Box
                                sx={{
                                  color: "#8C8D8F",
                                  fontFamily: "Space Grotesk",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  lineHeight: "normal",
                                }}
                              >
                                {value} {tokenSymbol}
                              </Box>
                            </div>
                          </Box>
                        </Box>
                      </FormControl>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: "40%",
                        left: "50%",
                        zIndex: 2,
                      }}
                    >
                      <img src={MidArrow} />
                    </div>

                    <div
                      className="input-container"
                      style={{ marginBottom: 10 }}
                    >
                      <FormControl sx={{ flex: 1 }}>
                        <Box
                          sx={{
                            backgroundColor: "#EDEEF2",
                            color: "#1A1C20",
                            width: "100%",
                            // height: "80px",
                            borderRadius: "10px",
                            fontSize: "25px",
                            fontFamily: "Space Grotesk",
                            fontWeight: "700",
                            border: "0.5px solid rgba(26, 28, 32, 0.50)",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                paddingTop: 15,
                              }}
                            >
                              <Box mr={1.25}>
                                <img src={CashIcon} />
                              </Box>
                              <Box
                                sx={{
                                  color: "#1A1C20",
                                  fontFamily: "Space Grotesk",
                                  fontSize: "18px",
                                  fontStyle: "normal",
                                  fontWeight: "600",
                                  lineHeight: "normal",
                                  marginTop: -2,
                                  marginLeft: -1.5,
                                }}
                              >
                                Cash
                              </Box>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                marginRight: 10,
                              }}
                            >
                              <Box
                                mr={1.25}
                                sx={{
                                  color: "#1A1C20",
                                  fontFamily: "Space Grotesk",
                                  fontSize: "16px",
                                  fontStyle: "normal",
                                  fontWeight: "600",
                                  lineHeight: "normal",
                                }}
                              >
                                ${(value * tokenPriceInUsd).toFixed(3)}
                              </Box>
                              <Box
                                sx={{
                                  color: "#8C8D8F",
                                  fontFamily: "Space Grotesk",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  lineHeight: "normal",
                                }}
                              >
                                {(value * tokenPriceInUsd).toFixed(3)} USDCB
                              </Box>
                            </div>
                          </Box>
                        </Box>
                      </FormControl>
                    </div>
                  </Box>

                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gap={8}
                    alignItems={"center"}
                    sx={{ marginTop: 10 }}
                  >
                    <FeeUrgencyComponent
                      feeGasUrgency={feeGasUrgency}
                      setFeeGasUrgency={setFeeGasUrgency}
                    />
                    <TxTypeSwitch
                      loading={loading}
                      txByDeposited={txByDeposited}
                      onTop20Select={onTop20Select}
                      onDepositSelect={onDepositSelect}
                    />
                    <SendTxComponent
                      loading={loading}
                      SetTokenForPayment={SetTokenForPayment}
                      gasTokenSelect={
                        <GasTokenSelect
                          selectedDepositTokenAddress={
                            selectedDepositTokenAddress
                          }
                          handleDepositTokenChange={handleDepositTokenChange}
                        />
                      }
                      top20TokenSelect={
                        <TopTokenSelectComponent
                          handleSelectToken={handleSelectTokenForPayment}
                          handleSelectTokenWithDecimal={
                            handleSelectTokenForPaymentWithDecimal
                          }
                          paymentTokenAddress={tokenForPayment}
                          sameTokens={sameTokens}
                        />
                      }
                      txByDeposited={txByDeposited}
                      handleSend={executeBridgeCrypto}
                      finalOpState={finalOpState}
                      isSwap={false}
                      error={error}
                      isCashout
                    >
                      <FeeUIComponent gasFeeInUSD={gasFeeInUSD} />
                    </SendTxComponent>
                  </Box>
                </Grid>
              </Grid>
            ))}
        </Box>
      </Box>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        message="Funds are not enough to use as gas fees"
      />
    </>
  );
};

export default CryptoSell;
