import { SUPPORTED_NETWORKS } from "constants/chains";
import { initalizeWeb3 } from "./utils";

export const initalizeBlockNumbers = async () => {
  console.log(Object.keys(SUPPORTED_NETWORKS));
  const obj = {};

  await Promise.all(
    Object.keys(SUPPORTED_NETWORKS).map(async id => {
      const web3 = initalizeWeb3(id);
      const blockNumber = await web3.eth.getBlockNumber();
      obj[id] = blockNumber || 0;
    })
  );

  return obj;
};
