import React from "react";
import { SETTINGS_OPENED_OPTIONS } from "../../../../../../interfaces";
import { useAppSelector } from "../../../../../../store/store";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import CenterItem from "../../../../../../components/CenterItem";
import LanguageIcon from "assets/languageIcon.svg";

import ThemeIcon from "assets/themeIcon.svg";

import DollarIcon from "assets/dollarIcon.svg";

const PreferencesMenu = () => {
  const { settingsOpenOption } = useAppSelector((state) => state.app);

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} gap={3}>
        <>
          <Box
            fontWeight={200}
            fontSize={14}
            paddingX={3}
            paddingY={2}
            borderRadius={"10px"}
            lineHeight={1.4}
            display={"flex"}
            gap={2}
            alignItems={"center"}
            flexDirection={"row"}
            sx={{ background: "#FFFFFF" }}
          >
            <img src={ThemeIcon} height={20} width={20} />
            <Box
              display={"flex"}
              alignItems={"flex-start"}
              flexDirection={"column"}
            >
              <Typography
                fontWeight={500}
                variant="body1"
                fontSize={"16px"}
              >{`Theme`}</Typography>
              <Typography
                fontWeight={500}
                variant="body1"
                fontSize={"14px"}
                sx={{
                  color: "rgba(26, 28, 32, 0.50)",
                  fontFamily: "Helvetica Neue",
                }}
              >{`Light . Default`}</Typography>
            </Box>
          </Box>
          <Box
            fontWeight={200}
            fontSize={14}
            paddingX={3}
            paddingY={2}
            borderRadius={"10px"}
            lineHeight={1.4}
            display={"flex"}
            gap={2}
            alignItems={"center"}
            flexDirection={"row"}
            sx={{ background: "#FFFFFF" }}
          >
            <img src={DollarIcon} height={20} width={20} />
            <Box
              display={"flex"}
              alignItems={"flex-start"}
              flexDirection={"column"}
            >
              <Typography
                fontWeight={500}
                variant="body1"
                fontSize={"16px"}
              >{`Currency`}</Typography>
              <Typography
                fontWeight={500}
                variant="body1"
                fontSize={"14px"}
                sx={{
                  color: "rgba(26, 28, 32, 0.50)",
                  fontFamily: "Helvetica Neue",
                }}
              >{`USD`}</Typography>
            </Box>
          </Box>
          <Box
            fontWeight={200}
            fontSize={14}
            paddingX={3}
            paddingY={2}
            borderRadius={"10px"}
            lineHeight={1.4}
            display={"flex"}
            gap={2}
            alignItems={"center"}
            flexDirection={"row"}
            sx={{ background: "#FFFFFF" }}
          >
            <img src={LanguageIcon} height={20} width={20} />
            <Box
              display={"flex"}
              alignItems={"flex-start"}
              flexDirection={"column"}
            >
              <Typography
                fontWeight={500}
                variant="body1"
                fontSize={"16px"}
              >{`Language`}</Typography>
              <Typography
                fontWeight={500}
                variant="body1"
                fontSize={"14px"}
                sx={{
                  color: "rgba(26, 28, 32, 0.50)",
                  fontFamily: "Helvetica Neue",
                }}
              >{`English`}</Typography>
            </Box>
          </Box>
        </>
      </Box>
    </>
  );
};

export default PreferencesMenu;
