import { useRef } from "react";
import Logo from "assets/ember.svg";

const useDynamicTitleAndFavicon = () => {
  const intervalRef = useRef<number | null>(null);
  const originalTitleRef = useRef<string>(document.title);
  const originalFaviconRef = useRef<string>("");

  const fetchOriginalFavicon = () => {
    const linkElement = document.querySelector(
      "link[rel~='icon']"
    ) as HTMLLinkElement | null;
    if (linkElement) {
      originalFaviconRef.current = linkElement.href;
    }
  };

  const startBlinkingTitle = (interval: number = 500) => {
    originalTitleRef.current = document.title;
    fetchOriginalFavicon();

    let alternateTitle = "";

    intervalRef.current = window.setInterval(() => {
      document.title =
        document.title === originalTitleRef.current
          ? alternateTitle
          : originalTitleRef.current;
    }, interval);

    // Set new favicon
    let link = document.querySelector(
      "link[rel~='icon']"
    ) as HTMLLinkElement | null;
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = Logo;
  };

  const stopBlinkingTitle = () => {
    if (intervalRef.current !== null) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
      document.title = originalTitleRef.current; // Restore original title
      const link = document.querySelector(
        "link[rel~='icon']"
      ) as HTMLLinkElement | null;
      if (link) {
        link.href = originalFaviconRef.current; // Restore original favicon
      }
    }
  };

  return { startBlinkingTitle, stopBlinkingTitle };
};

export default useDynamicTitleAndFavicon;
