import {
  Box,
  CircularProgress,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import { useState } from "react";

import NavigatorHeading from "../../../components/NavigatorHeading";
import SearchInput from "../../../components/SearchInput";
import CenterItem from "../../../components/CenterItem";
import SearchOptionsIcon from "assets/searchOptionsIcon.svg";
import TransactionList from "./TransactionList";
import { useAppSelector } from "../../../store/store";
import NoDataUI from "components/NoDataCard";
import { initialPendingTxValues } from "@slices/appSlice";

const NFTTransactionsView2 = () => {
  const [searchText, setSearchText] = useState("");
  const { activeAccount, portfolio, transactions } = useAppSelector(
    (state) => state.app
  );
  const { walletLoading } = useAppSelector((state) => state.wallet);

  const txs = transactions[activeAccount.smartAccountAddress];
  const allTransactions = txs?.length > 0 ? txs : [];
  console.log(
    "🚀 ~ file: View.tsx:21 ~ NFTTransactionsView ~ allTransactions:",
    allTransactions
  );

  const allCryptoTxs = allTransactions.filter(
    (tx: any) =>
      tx.transactionMethod.toLowerCase() == "Crypto".toLowerCase() ||
      tx.transactionMethod.toLowerCase() == "Send".toLowerCase() ||
      tx.transactionMethod.toLowerCase() == "Swap".toLowerCase() ||
      tx.transactionMethod.toLowerCase() == "NFT".toLowerCase()
  );

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  function groupTimestampData(
    data: Array<typeof initialPendingTxValues & { timestamp: string }>
  ) {
    const groupedData: any = {};
    const today = new Date();
    const yesterday = new Date();
    data
      .reverse()
      .forEach(
        (item: typeof initialPendingTxValues & { timestamp: string }) => {
          const timestamp = new Date(item.timestamp);
          const dateKey =
            timestamp.getDate() + " " + monthNames[timestamp.getMonth()] + " ";

          if (timestamp.toDateString() === today.toDateString()) {
            if (!groupedData["Today"]) {
              groupedData["Today"] = [];
            }
            groupedData["Today"].push(item);
          } else if (timestamp.toDateString() === yesterday.toDateString()) {
            if (!groupedData["Yesterday"]) {
              groupedData["Yesterday"] = [];
            }
            groupedData["Yesterday"].push(item);
          } else {
            if (!groupedData[dateKey]) {
              groupedData[dateKey] = [];
            }
            groupedData[dateKey].push(item);
          }

          // groupedData[dateKey].push(item);
        }
      );

    return groupedData;
  }
  // const [todayGroup, yesterdayGroup, earlierGroup] =
  //   groupTimestamps(allCryptoTxs);
  const groupedByDateArray = groupTimestampData(allCryptoTxs);
  console.log(
    "file: View.tsx:47  NFTTransactionsView  todayGroup, yesterdayGroup, earlierGroup:",
    groupedByDateArray
  );

  return walletLoading ? (
    <Grid paddingY={5}>
      <NavigatorHeading
        title={"Transactions"}
        RightComponent={
          <Grid
            container
            spacing={1}
            width={{
              xs: "300px",
              sm: "400px",
              md: "500px",
              lg: "500px",
              xl: "500px",
            }}
          >
            <Grid item sm={12} md={12} lg={12} alignItems={"center"}>
              <Skeleton variant="rectangular" />
            </Grid>
          </Grid>
        }
      />
      <Grid py={2} display={"flex"} gap={1} flexDirection={"column"}>
        <>
          <Skeleton variant="rectangular" height={50} />
          <Skeleton variant="rectangular" height={50} />
          <Skeleton variant="rectangular" height={50} />
          <Skeleton variant="rectangular" height={50} />
          <Skeleton variant="rectangular" height={50} />
          <Skeleton variant="rectangular" height={50} />
        </>
      </Grid>
    </Grid>
  ) : (
    <Grid paddingY={5}>
      <NavigatorHeading
        title={"Transactions"}
        RightComponent={
          <Grid
            container
            spacing={1}
            width={{
              xs: "300px",
              sm: "400px",
              md: "500px",
              lg: "500px",
              xl: "500px",
            }}
          >
            <Grid item sm={11} md={11} lg={11} alignItems={"center"}>
              <SearchInput
                handleOnChange={(e: any) => {
                  setSearchText(e.target.value);
                }}
                padding="0px 20px"
                placeholder="Search Transactions"
              />
            </Grid>

            <Grid
              item
              sm={1}
              md={1}
              lg={1}
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                justifyContent: "end",
                paddingLeft: "12px",
              }}
            >
              <CenterItem>
                <img src={SearchOptionsIcon} />
              </CenterItem>
            </Grid>
          </Grid>
        }
      />
      <Grid py={2} display={"flex"} gap={1} flexDirection={"column"}>
        {/* <Grid container py={1}>
          <Box sx={{ color: "#8C8D8F" }}>Transactions</Box>
        </Grid> */}
        {allCryptoTxs?.length > 0 && (
          <>
            {Object.keys(groupedByDateArray).map((key) => (
              <>
                {groupedByDateArray[key].filter((item: any) =>
                  item.assetName
                    .toLowerCase()
                    .includes(searchText.toLocaleLowerCase())
                )?.length > 0 && (
                  <>
                    <Typography
                      variant="h5"
                      py={2}
                      fontWeight={400}
                      sx={{ color: "#1A1C20BF" }}
                    >
                      {key}
                    </Typography>
                    {groupedByDateArray[key]
                      .filter((item: any) =>
                        item.assetName
                          .toLowerCase()
                          .includes(searchText.toLocaleLowerCase())
                      )
                      .map((item: any, index: number) => {
                        return <TransactionList props={{ tx: item }} />;
                      })}
                  </>
                )}
              </>
            ))}
          </>
        )}
        {allCryptoTxs?.length === 0 && (
          <Box
            py={15}
            sx={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <NoDataUI
              title="No Activity Yet!"
              description="You transaction history will be displayed here"
            />
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default NFTTransactionsView2;
