import React, { useEffect, useState } from "react";
import pbkdf2 from "pbkdf2";

import { Grid, Typography } from "@mui/material";
import TextInput from "components/TextInput";
import Button from "components/NewButton";
import { useLocation, useNavigate } from "react-router-dom";
import AuthHeader from "components/AuthHeader";
import { encryptMessage } from "utils/utils";
import { useAppDispatch, useAppSelector } from "store/store";
import { setHashedPassword } from "@slices/walletSlice";
import { saveMnemonic } from "@slices/appSlice";
import { Box } from "@mui/system";
import CustomizedSteppers from "components/Stepper";
import { stepsCount } from "../../../../../src/constants";
import StepperForPages from "components/Stepper/StepperForPages";
import BlackTick from "assets/black-tick.svg";
import CancelIcon from "assets/cancel.svg";

import "./styles.css";

const CreatePassword = () => {
  const [securePassword, setSecurePassword] = useState(true);
  const [secureConfirmPassword, setSecureConfirmPassword] = useState(true);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [isNumber, setIsNumber] = useState(false);
  const [isUppercase, setIsUppercase] = useState(false);
  const [isLowercase, setIsLowercase] = useState(false);
  const [isSpecialCharacter, setIsSpecialCharacter] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    mnemonic,
    user: { email },
  } = useAppSelector((state) => state.app);

  const location = useLocation();

  const { state } = location || {};
  const { currentStep, pathName } = state || {};

  const [step, setStep] = useState(parseInt(currentStep) || 0);

  useEffect(() => {
    if (!email) {
      navigate("/create-account");
    }
  }, []);

  const handlePasswordContinue = async () => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-_=#$@!%&*?])[A-Za-z\d-_=#$@!%&*?]{8,30}$/;
    setLoading(true);
    if (password !== confirmPassword) {
      setError(
        "The passwords you entered do not match. Please check and try again!"
      );

      setLoading(false);
    } else {
      if (password.length < 8) {
        setError("Passwords must be 8 characters long");

        setLoading(false);
      } else if (!regex.test(password)) {
        setError(
          "Password must include numbers, lowercase letters, uppercase letters and special characters"
        );

        setLoading(false);
      } else {
        setError("");
        const hashedPassword = pbkdf2
          .pbkdf2Sync(password, "salt", 1, 32, "sha512")
          .toString("hex");

        if (mnemonic) {
          const encryptMnemonic = encryptMessage(mnemonic, hashedPassword);
          dispatch(saveMnemonic(encryptMnemonic));
        }

        dispatch(setHashedPassword(hashedPassword));

        setLoading(false);

        navigate("/pin", {
          state: { currentStep: step + 1, pathName: pathName },
        });
      }
    }
  };

  const handleKeyPress = (e: any) => {
    const { key, keyCode } = e || {};
    if (key === "Enter" || keyCode === 13 || key === "Tab" || keyCode === 9) {
      e.preventDefault();
      handlePasswordContinue();
    }
  };

  const onPasswordChange = (e) => {
    const newPassword = e;
    const hasNumber = /[0-9]/.test(newPassword);
    const hasLowerCase = /[a-z]/.test(newPassword);
    const hasUpperCase = /[A-Z]/.test(newPassword);
    const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(newPassword);
    const isLengthValid = newPassword.length >= 8;

    setPassword(e);

    if (hasNumber) setIsNumber(true);
    else setIsNumber(false);
    if (hasLowerCase) setIsLowercase(true);
    else setIsLowercase(false);
    if (hasUpperCase) setIsUppercase(true);
    else setIsUppercase(false);
    if (hasSpecialCharacter) setIsSpecialCharacter(true);
    else setIsSpecialCharacter(false);
    // else {
    //   setIsNumber(false);
    //   setIsLowercase(false);
    //   setIsUppercase(false);
    //   setIsSpecialCharacter(false);
    // }
  };

  const totalSteps = stepsCount[pathName] || 3;

  return (
    <div className="create-account-container">
      <Grid container spacing={2}>
        <Grid item xs={8} className="auth-header-with-steps">
          <AuthHeader />
        </Grid>
        <Grid
          item
          xs={16}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box
            className="auth-header-steps"
            width={"50%"}
            style={{
              position: "absolute",
              top: "54px",
            }}
          >
            <StepperForPages
              step={step}
              steps={new Array(totalSteps).fill("")}
              changeStep={(selectedStep: number) => {
                //eg. if user is on step 3 he should be able to move at step 1 or 2 on clicking step icon
                if (selectedStep < step) {
                  setStep(selectedStep);
                  navigate(-1);
                }
              }}
            />
          </Box>
        </Grid>
      </Grid>

      <div className="create-account-box password-box">
        <Typography
          variant="h2"
          sx={{
            margin: "30px 0px 30px 0px",

            fontWeight: "700",
          }}
        >
          Create your password
        </Typography>

        <div className="welcome-box-container">
          <TextInput
            title="Password"
            value={password}
            onChange={onPasswordChange}
            onKeyPress={(e) => handleKeyPress(e)}
            showSecure={() => setSecurePassword(!securePassword)}
            showEndIcon
            secure={securePassword}
            type={securePassword ? "password" : "text"}
            style={{
              width: "370px",
            }}
          />
          <div className="password-validator-box">
            <div className="password-validator">
              {isNumber ? (
                <img src={BlackTick} alt="" />
              ) : (
                <img src={CancelIcon} alt="" height={20} />
              )}
              <Typography variant="body2" className="password-validator-text">
                Password must include numbers
              </Typography>
            </div>

            <div className="password-validator">
              {isLowercase && isUppercase ? (
                <img src={BlackTick} alt="" />
              ) : (
                <img src={CancelIcon} alt="" height={20} />
              )}
              <Typography variant="body2" className="password-validator-text">
                Password must include lower and uppercase letters
              </Typography>
            </div>

            <div className="password-validator">
              {isSpecialCharacter ? (
                <img src={BlackTick} alt="" />
              ) : (
                <img src={CancelIcon} alt="" height={20} />
              )}
              <Typography variant="body2" className="password-validator-text">
                Password must include special characters
              </Typography>
            </div>

            <div className="password-validator">
              {password.length > 8 ? (
                <img src={BlackTick} alt="" />
              ) : (
                <img src={CancelIcon} alt="" height={20} />
              )}
              <Typography variant="body2" className="password-validator-text">
                Password must be 8 characters long
              </Typography>
            </div>
          </div>
          <TextInput
            title="Confirm Password"
            value={confirmPassword}
            onKeyPress={(e) => handleKeyPress(e)}
            onChange={setConfirmPassword}
            showSecure={() => setSecureConfirmPassword(!secureConfirmPassword)}
            showEndIcon
            secure={secureConfirmPassword}
            type={secureConfirmPassword ? "password" : "text"}
            style={{
              width: "370px",
            }}
          />

          {error && (
            <div className="password-validator-box">
              <Typography
                style={{ color: "red", fontSize: 14, marginTop: -20 }}
              >
                {error}
              </Typography>
            </div>
          )}

          <Button
            onClick={handlePasswordContinue}
            title="Continue"
            loading={loading}
            disabled={
              !password ||
              !confirmPassword ||
              !isNumber ||
              !isLowercase ||
              !isUppercase ||
              !isSpecialCharacter
            }
            style={{
              width: "390px",
              padding: "15px 0px",
              margin: "20px 0px 0px 0px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CreatePassword;
