import { Box, IconButton, InputBase, Stack, useTheme } from "@mui/material";
import React from "react";
import SearchIcon from "assets/search.svg";

export const SearchAndView = ({ searchVar, setFiltered }) => {
  return (
    <Stack
      direction={"row"}
      justifyContent='flex-start'
      gap='20px'
      sx={{
        marginBottom: "25px",
        width: "93%",
        paddingTop: "16px",
        paddingLeft: "16px",
      }}
    >
      <Box
        display='flex'
        borderRadius='6px'
        flex={8}
        sx={{
          border: "1px solid #F3F5F9",
          backgroundColor: "white",
          padding: 1,
        }}
      >
        <IconButton type='button' sx={{ p: 1 }}>
          <img src={SearchIcon} alt='search' />
        </IconButton>
        <InputBase
          sx={{ ml: 2, flex: 1, fontSize: "14px" }}
          placeholder='Search By Name or Collection'
          value={searchVar}
          onChange={e => setFiltered(e)}
          autoComplete='off'
        />
      </Box>
    </Stack>
  );
};
