import { Box, Stack, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import AddApps from "assets/AddApps.svg";
import CustomLogo from "assets/morflax.svg";
import DappConnectionModal from "./DappConnectionModal";
import TabChip from "./TabChip";
import { Caption, ConnectedDappBoxWrapper, MediumTitle } from "./Styled";

interface ConnectedDappBoxProps {
  name?: string;
  image?: string;
  description?: string;
  onDisconnect?: () => void;
}

const ConnectedDappBox: FC<ConnectedDappBoxProps> = (props) => {
  //   const { image, name, description } = props;
  const [openModal, setOpenModal] = useState(false);

  const ConnectionBox = () => (
    <>
      <Stack
        gap={2}
        direction="column"
        alignItems={"center"}
        justifyContent={"center"}
      >
        <img src={CustomLogo} />

        <MediumTitle variant={"body2"} color="#1A1C20">
          Connect to Custom App
        </MediumTitle>
        <Caption variant={"caption"}>
          Connect to any application via wallet connect
        </Caption>
        <TabChip
          title="+ADD APP"
          onClick={() => setOpenModal(true)}
          mode={"chip"}
        />
        {/* <img
          src={AddApps}
          style={{ marginTop: "20px", marginBottom: "20px" }}
          onClick={() => setOpenModal(true)}
        /> */}
      </Stack>
      <DappConnectionModal
        open={openModal}
        onClose={() => setOpenModal(false)}
      />
    </>
  );
  return (
    <ConnectedDappBoxWrapper>
      {props?.name ? (
        <Stack>
          <Stack
            gap={2}
            direction="column"
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
            width={"100%"}
            // height={"236px"}
            sx={{ overflowY: "auto", height: "227px" }}
          >
            <Stack
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              width={"100%"}
            >
              <img
                src={props?.image || CustomLogo}
                style={{ width: "40px", height: "40px", borderRadius: "40px" }}
              />

              {/* <TabChip
              title="Disconnect"
              onClick={() => {
                if (props?.onDisconnect) props.onDisconnect();
              }}
              mode={"chip"}
              isShowStartIcon
            /> */}
            </Stack>

            <MediumTitle variant={"body2"} color="#1A1C20">
              {props?.name}
            </MediumTitle>

            <Caption
              variant={"caption"}
              sx={{ textAlign: "start", overflowWrap: "break-word" }}
            >
              {props?.description || ""}
            </Caption>
          </Stack>
          <Box width={"100%"} display={"flex"} justifyContent={"center"}>
            <TabChip
              title="Disconnect"
              onClick={() => {
                if (props?.onDisconnect) props.onDisconnect();
              }}
              mode={"chip"}
              isShowStartIcon={false}
            />
          </Box>
        </Stack>
      ) : (
        <ConnectionBox />
      )}
    </ConnectedDappBoxWrapper>
  );
};

export default ConnectedDappBox;
