import {
  Box,
  Button,
  Snackbar,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { FC, useState } from "react";

import WalletIcon from "assets/walletIcon.svg";
import CopyIcon from "assets/copyIcon.svg";
import ProfilePic from "assets/profilePic.svg";
import ProfilePicCover from "assets/profileCover.svg";
import styled from "@emotion/styled";
import { useAppSelector } from "store/store";

import "./index.css";

const TopbarWrapper = styled(Box)({
  borderBottom: "2px solid #E8ECF5",
  height: "350px",
});

const Banner: FC<{ collectionsCount?: number; nftsCount?: number }> = ({
  collectionsCount,
  nftsCount,
}) => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const { activeAccount, accounts, rootAccountInfo } = useAppSelector(
    state => state.app
  );
  const name = accounts[activeAccount.address].name;
  const { smartAccountAddress } = activeAccount;

  const onCopy = () => {
    setShowSnackbar(true);
    navigator.clipboard.writeText(activeAccount.smartAccountAddress);
  };

  return (
    <TopbarWrapper>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        message='Address copied!'
      />
      <Box>
        <Box className='coverForTopBar'>
          <Box
            sx={{
              width: "100%",
              height: "200px",
              overflow: "hidden",
              borderBottomLeftRadius: "16px",
              borderBottomRightRadius: "16px",
              postion: "relative",
            }}
          >
            <img
              src={ProfilePicCover}
              alt={"profile"}
              style={{ width: "100%" }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              marginLeft: 7,
              position: "absolute",
              bottom: -120,
              width: "95%",
            }}
          >
            <Box sx={{ position: "relative" }}>
              <img
                src={ProfilePic}
                alt={"profile"}
                style={{
                  borderRadius: "155px",
                  border: "3px solid white",
                  width: "155px",
                  height: "155px",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "space-between",
                marginLeft: "12px",
                marginTop: "5px",
              }}
            >
              <Typography
                variant='h3'
                color={"black"}
                fontWeight={500}
                marginBottom={1}
              >
                {" "}
                {name}.{rootAccountInfo.name}
              </Typography>
              <Stack direction='row'>
                <img
                  src={WalletIcon}
                  alt='copy'
                  style={{ marginRight: "5px" }}
                />
                <Typography
                  variant='subtitle2'
                  color={"black"}
                  fontWeight={400}
                >
                  {smartAccountAddress}
                </Typography>
                <img
                  src={CopyIcon}
                  alt='copy'
                  style={{ marginLeft: "10px", cursor: "pointer" }}
                  onClick={onCopy}
                  id='banner_copy_address'
                />
              </Stack>
              {!!collectionsCount && (
                <div className='nft-count'>
                  <Typography variant='body2'>
                    <span style={{ fontWeight: "bold" }}>
                      {collectionsCount}
                    </span>{" "}
                    Collections
                  </Typography>
                  <Typography variant='body2' style={{ marginLeft: 30 }}>
                    <span style={{ fontWeight: "bold" }}>{nftsCount}</span> NFTs
                  </Typography>
                </div>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </TopbarWrapper>
  );
};

export default Banner;
