import React from "react";
import NFTView from "./View";
import { useAppSelector } from "store/store";

const NFT = () => {
  const { activeAccount } = useAppSelector((state) => state.app);

  const viewProps = {
    accountName: activeAccount.accountName,
  };

  return (
    <>
      <NFTView {...viewProps} />
    </>
  );
};

export default NFT;
