import { Box } from "@mui/material";
import React, { FC } from "react";

interface TokenBoxProps {
  tokenIcon: string;
  tokenSymbol: string;
  value?: number;
  backgroundColor?: string;
  onClick?: () => void;
  isCash?: boolean;
  isEns?: boolean;
  ens?: string;
}

const TokenBox: FC<TokenBoxProps> = ({
  tokenIcon,
  tokenSymbol,
  value,
  backgroundColor,
  onClick,
  isCash,
  isEns,
  ens,
}) => {
  return (
    <Box
      sx={{
        backgroundColor: backgroundColor || "#EDEEF2",
        color: "#1A1C20",
        width: "100%",
        // height: "80px",
        borderRadius: "10px",
        fontSize: "25px",
        fontFamily: "Space Grotesk",
        fontWeight: "700",
        margin: "15px 0px 0px 0px",
        boxShadow: "0px 8px 24px -4px rgba(24, 39, 75, 0.08)",
        cursor: "pointer",

        // box-shadow: 0px -2px 12px -6px rgba(24, 39, 75, 0.12);
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingTop: 8,
          }}
        >
          <Box mr={1.25}>
            {/* <img src={tokenIcon} height={33} width={33} /> */}
            <img
              src={tokenIcon}
              height={isCash ? "auto" : 33}
              style={{ padding: isCash ? 0 : 10 }}
            />
          </Box>
          <Box
            sx={{
              color: "#1A1C20",
              fontFamily: "Space Grotesk",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "normal",
              marginTop: isCash ? -1.5 : -1,
              marginLeft: -1.5,
            }}
          >
            {tokenSymbol}
          </Box>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginRight: 10,
          }}
        >
          <Box
            mr={1.25}
            sx={{
              color: "#1A1C20",
              fontFamily: "Space Grotesk",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "normal",
            }}
          >
            {isEns ? ens : value ? `${value && value.toFixed(3)}` : ""}
          </Box>
        </div>
      </Box>
    </Box>
  );
};

export default TokenBox;
