import { Grid, Slide, Typography } from "@mui/material";
import React from "react";
import BoardApeLogo from "assets/boardApeLogo.png";
import BlueTick from "assets/BlueTick.svg";
import CenterItem from "../../../../components/CenterItem";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../store/store";
import CardLayout from "../../../../components/CardLayout";
import Globe from "assets/emptyNFTList.svg";

import { Box } from "@mui/system";
import Button from "../../../../components/NewButton";

const NFTList = ({
  searchFilterText = "",
  activeAccount,
  newNFTs,
}: {
  searchFilterText?: string;
  activeAccount: any;
  newNFTs: any;
}) => {
  const navigate = useNavigate();
  // const { activeAccount, newNFTs } = useAppSelector((state) => state.app);
  const allNfts = newNFTs[activeAccount.smartAccountAddress];

  // Todo: newNFTs[activeAccount.address] will be the actual nfts collection for current User

  const ListComponent = ({
    numberOfOwned,
    name,
    imageSrc,
    floorPrice,
  }: {
    numberOfOwned: number;
    name: string;
    imageSrc: string;
    floorPrice: number;
  }) => {
    console.log("file: NFTList.tsx:25  NFTList  imageSrc:", imageSrc);
    return (
      <Grid
        sx={{
          padding: "10px",
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          cursor: "pointer",
          borderRadius: "10px",
          "&:hover": {
            backgroundColor: "lightgray",
          },
        }}
        onClick={() => {
          navigate(encodeURIComponent(name));
        }}
      >
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <CenterItem>
            <img
              src={
                imageSrc ??
                "https://thientu.vn/userfiles/files/nyan-cat-game%204.gif"
              }
              height={40}
              width={40}
              style={{ borderRadius: "100%", objectFit: "cover" }}
            />
          </CenterItem>
          <Grid
            sx={{
              color: "#1A1C20",
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              fontFamily: "Space Grotesk",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "normal",
            }}
          >
            <div>
              {name}
              <img
                src={BlueTick}
                style={{ marginLeft: "5px" }}
                alt={"blue tick"}
              />
            </div>
            <div
              style={{
                // color: "rgba(26, 28, 32, 0.50)",
                fontFamily: "Helvetica Neue",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }}
            >
              {numberOfOwned}
              <span
                style={{
                  color: "rgba(26, 28, 32, 0.50)",
                  marginLeft: "5px",
                }}
              >
                Owned
              </span>
            </div>
          </Grid>
        </Grid>
        {(floorPrice == 0 || floorPrice) && (
          <Grid
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <Grid
              sx={{
                color: "#1A1C20",
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                fontFamily: "Space Grotesk",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "normal",
              }}
            >
              <div>{floorPrice} MATIC</div>
              <div
                style={{
                  // color: "rgba(26, 28, 32, 0.50)",
                  fontFamily: "Helvetica Neue",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "normal",
                  textAlign: "right",
                }}
              >
                Min Price
              </div>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };

  if (!newNFTs) {
    return <>Loading...</>;
  }

  if (!!allNfts && allNfts.length > 0) {
    // const nftsByCollection = allNfts.reduce(item=>{
    //   return {

    //   }
    // })

    const nftsByCollection = allNfts.reduce(
      (result: { [name: string]: any[] }, obj) => {
        // Extract the name from the object
        const name = obj.name;
        if (!result[name]) {
          result[name] = [];
        }
        // Add the object to the result using the name as the key
        result[name].push(obj);

        return result;
      },
      {}
    );
    console.log(
      "file: NFTList.tsx:153  NFTList  nftsByCollection:",
      nftsByCollection
    );
    const filteredKeys = Object.keys(nftsByCollection).filter((key) =>
      key?.toLowerCase().includes(searchFilterText.toLowerCase())
    );

    if (filteredKeys.length === 0) {
      // Display message when no results are found
      return (
        <Grid
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 2, // you can change this value
          }}
        >
          <Typography variant="subtitle1">No results found</Typography>
        </Grid>
      );
    }

    // If there are matches, we continue to render the list as before
    return (
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          width: "100%",
          paddingY: "40px",
        }}
      >
        {filteredKeys.map((key) => (
          <ListComponent
            numberOfOwned={nftsByCollection[key].length}
            name={key}
            imageSrc={nftsByCollection[key][0]?.metadata?.image}
            floorPrice={nftsByCollection[key][0]?.floorPrice}
          />
        ))}
      </Grid>
    );
  }

  return (
    <CardLayout
      backgroundColor="white"
      style={{
        flex: 1,
        width: "100%",
        marginTop: "40px",
        marginBottom: "40px",
        overflow: "hidden",
      }}
    >
      <Slide in={true} timeout={500} direction={"down"}>
        <Box
          className="flex-col-center"
          style={{
            padding: "20px 0px",
            height: "275px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          <Typography
            variant={"h5"}
            fontWeight={700}
            color="rgba(26, 28, 32, 1)"
            fontSize={25}
            style={{ cursor: "pointer" }}
          >
            Showcase your collectibles
          </Typography>
          <Typography
            variant={"h5"}
            fontWeight={400}
            color="rgba(26, 28, 32, 0.5)"
            fontSize={12}
            style={{ cursor: "pointer" }}
          >
            Get started!
          </Typography>
          <img
            src={Globe}
            width={"100%"}
            height={"100%"}
            style={{
              overflow: "hidden",
            }}
          />

          <div
            style={{
              width: "70%",
              maxWidth: "305px",
              marginTop: "-6%",
            }}
          >
            <Button
              title="Receive"
              style={{
                backgroundColor: "rgba(235, 236, 238, 1)",
                margin: 0,
              }}
              textStyle={{ color: "rgba(26, 28, 32, 1)" }}
              onClick={() => {
                navigate("/nfts/receive");
              }}
            />
          </div>
        </Box>
      </Slide>
    </CardLayout>
  );
};

export default NFTList;
