// import SettingsStore from '@/store/SettingsStore'

// import { createOrRestoreCosmosWallet } from '@/utils/CosmosWalletUtil'
// import { createOrRestoreEIP155Wallet } from '@/utils/EIP155WalletUtil'

// import { createOrRestoreSolanaWallet } from '@/utils/SolanaWalletUtil'
// import { createOrRestorePolkadotWallet } from '@/utils/PolkadotWalletUtil'
// import { createOrRestoreNearWallet } from '@/utils/NearWalletUtil'
// import { createOrRestoreMultiversxWallet } from '@/utils/MultiversxWalletUtil'
// import { createOrRestoreTronWallet } from '@/utils/TronWalletUtil'
// import { createOrRestoreTezosWallet } from '@/utils/TezosWalletUtil'
// import { createSignClient, signClient } from '@/utils/WalletConnectUtil'

// import { createOrRestoreKadenaWallet } from '@/utils/KadenaWalletUtil'
import { useCallback, useEffect, useRef, useState } from "react";
import { createOrRestoreEIP155Wallet } from "../utils/EIP155WalletUtil";
import { createSignClient, signClient } from "../utils/WalletConnectUtil";
import { useSnapshot } from "valtio";
import SettingsStore from "../walletConnectStore/SettingsStore";
import { useAppDispatch, useAppSelector } from "store/store";
import { decryptMessage } from "utils/utils";
import { toggleInitialized } from "@slices/appSlice";

export default function useInitialization() {
  const [initialized, setInitialized] = useState(false);
  const prevRelayerURLValue = useRef<string>("");

  const { relayerRegionURL } = useSnapshot(SettingsStore.state);
  const { activeAccount } = useAppSelector((state) => state.app);
  const { mnemonic, accounts } = useAppSelector((state) => state.app);
  const { hashedPassword } = useAppSelector((state) => state.wallet);

  const dispatch = useAppDispatch();
  //   const dispatch = useAppDispatch();

  const onInitialize = useCallback(async () => {
    try {
      console.log("mnemonic, hashedPassword", { mnemonic, hashedPassword });
      // const generatedMnemonic = decryptMessage(mnemonic, hashedPassword);
      // console.log("outside eip155", generatedMnemonic);
      // const { eip155Addresses } =
      // createOrRestoreEIP155Wallet(generatedMnemonic);
      //   const { cosmosAddresses } = await createOrRestoreCosmosWallet()
      //   const { solanaAddresses } = await createOrRestoreSolanaWallet()
      //   const { polkadotAddresses } = await createOrRestorePolkadotWallet()
      //   const { nearAddresses } = await createOrRestoreNearWallet()
      //   const { multiversxAddresses } = await createOrRestoreMultiversxWallet()
      //   const { tronAddresses } = await createOrRestoreTronWallet()
      //   const { tezosAddresses } = await createOrRestoreTezosWallet()
      //   const { kadenaAddresses } = await createOrRestoreKadenaWallet()

      // SettingsStore.setEIP155Address(eip155Addresses[0]);
      //   SettingsStore.setCosmosAddress(cosmosAddresses[0])
      //   SettingsStore.setSolanaAddress(solanaAddresses[0])
      //   SettingsStore.setPolkadotAddress(polkadotAddresses[0])
      //   SettingsStore.setNearAddress(nearAddresses[0])
      //   SettingsStore.setMultiversxAddress(multiversxAddresses[0])
      //   SettingsStore.setTronAddress(tronAddresses[0])
      //   SettingsStore.setTezosAddress(tezosAddresses[0])
      //   SettingsStore.setKadenaAddress(kadenaAddresses[0])
      await createSignClient(relayerRegionURL);
      setInitialized(true);
      dispatch(toggleInitialized());
    } catch (err: unknown) {
      alert(err);
    }
  }, [relayerRegionURL, activeAccount.address, hashedPassword]);

  // restart transport if relayer region changes
  const onRelayerRegionChange = useCallback(() => {
    try {
      signClient.core.relayer.restartTransport(relayerRegionURL);
      prevRelayerURLValue.current = relayerRegionURL;
    } catch (err: unknown) {
      alert(err);
    }
  }, [relayerRegionURL]);

  useEffect(() => {
    console.log(
      hashedPassword,
      "hash------------------------------0,",
      !initialized
    );
    // if (!initialized) {
    onInitialize();
    // }
    if (prevRelayerURLValue.current !== relayerRegionURL) {
      onRelayerRegionChange();
    }
  }, [
    initialized,
    onInitialize,
    relayerRegionURL,
    onRelayerRegionChange,
    hashedPassword,
  ]);

  return initialized;
}
