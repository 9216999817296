import { Typography } from '@mui/material'
import React from 'react'

const InfoCardAmount = ({ amount = '0' }: { amount?: string }) => {
  const splittedBalance = (amount ? amount : '').split('.')

  return (
    <>
      {Number(amount) <= 0 ? (
        <Typography
          variant={'h5'}
          fontWeight={700}
          color='rgba(26, 28, 32, 1)'
          fontSize={45}
          style={{ cursor: 'pointer' }}
          align='center'
        >
          $0
        </Typography>
      ) : (
        <>
          <Typography
            variant={'h5'}
            fontWeight={700}
            color='rgba(26, 28, 32, 1)'
            fontSize={45}
            style={{ cursor: 'pointer' }}
            align='center'
          >
            ${splittedBalance[0]}.
          </Typography>
          <Typography
            variant={'h5'}
            fontWeight={700}
            color='rgba(26, 28, 32, 0.5)'
            fontSize={25}
            style={{ cursor: 'pointer' }}
            align='center'
          >
            {splittedBalance[1] ? splittedBalance[1].substring(0, 2) : '00'}
          </Typography>
        </>
      )}
    </>
  )
}

export default InfoCardAmount
