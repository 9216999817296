import React, { FC } from "react";
import CheckIcon from "@mui/icons-material/Check";
import Bitcoin from "assets/bitcoin.svg";
import { TokenItemProps } from "interfaces";
import { useAppSelector } from "store/store";
import { numFormatter } from "utils/utils";

const TokenItem: FC<TokenItemProps> = ({
  amount,
  image,
  name,
  symbol,
  usdPrice,
  tokenAddress,
}) => {
  const { selectedToken } = useAppSelector(state => state.wallet);

  const isActive =
    selectedToken.length > 0 && selectedToken[0].tokenAddress === tokenAddress;

  const { amount: usdAmount, symbol: usdSymbol } = numFormatter(
    Number(usdPrice)
  );
  const { amount: tokenAmount, symbol: tokenSymbol } = numFormatter(
    Number(amount)
  );

  return (
    <div
      className={`token-item ${isActive && "active-token-item"}`}
      id='account_item_active_account'
    >
      <img src={image} width={40} />

      <div className='token-name'>
        <div className='name'>
          <p>{symbol}</p>
        </div>

        <div className='address'>
          <p>{name}</p>
        </div>
      </div>

      {amount && (
        <div className='token-balance'>
          <p
            style={{
              fontSize: 12,
              marginTop: 0,
              marginBottom: 0,
              textAlign: "right",
            }}
          >
            ${usdAmount}
            {usdSymbol}
          </p>
          <p style={{ fontWeight: "bold", marginTop: 2, marginBottom: 0 }}>
            {tokenAmount} {tokenSymbol} {symbol}
          </p>
        </div>
      )}
      {isActive && <CheckIcon />}
    </div>
  );
};

export default TokenItem;
