import { OtpInputProps } from "interfaces";
import React, { FC, useState } from "react";
import OtpInput from "react-otp-input";

const PinInput: FC<OtpInputProps> = ({
  otp,
  onChange,
  onKeyPress,
  autoFocus,
}) => {
  return (
    <div className="otp-input">
      <OtpInput
        shouldAutoFocus={autoFocus}
        placeholder="131674"
        value={otp}
        onChange={onChange}
        numInputs={6}
        renderSeparator={<span></span>}
        renderInput={(props, index) => (
          <input onKeyPress={onKeyPress} {...props} maxLength={1} key={index} />
        )}
        containerStyle={{ marginTop: "5%" }}
        inputStyle={{
          height: 75,
          width: 40,
          marginRight: 10,
          border: "1px solid #b2b2b2",
          borderRadius: 10,
          fontSize: 25,
          backgroundColor: "rgba(247, 247, 247, 1)",
          fontFamily: "Space Grotesk",
          fontWeight: "500",
        }}
        inputType="tel"
      />
    </div>
  );
};

export default PinInput;
