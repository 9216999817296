export function isIPFSLink(url: string) {
  return url.startsWith("ipfs://");
}
export function isImage(url: string): boolean {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
}

export function isVideo(url: string): boolean {
  return /\.(mp4|webm|ogg)$/.test(url);
}
