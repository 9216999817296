import React, { useState } from "react";
import { Grid } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import RiskIcon from "assets/risk.svg";

import "./styles.css";
import { Box } from "@mui/system";

const Simulation = () => {
  const [open, setOpen] = useState(false);
  const toggleCollapse = () => {
    setOpen(!open);
  };

  return (
    <>
      <Box
        sx={{
          padding: "15px 10px",
          display: "flex",
          width: "73%",
          alignSelf: "center",
          justifyContent: "space-between",
          cursor: "pointer",
          borderRadius: "10px",
          backgroundColor: "#F7F7F7",
          marginTop: -3,
        }}
        onClick={toggleCollapse}
      >
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",

            alignItems: "center",
          }}
        >
          <Grid
            sx={{
              color: "#1A1C20",
              display: "flex",
              flexDirection: "column",

              fontFamily: "Space Grotesk",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "normal",
            }}
          >
            <span
              style={{
                color: "red",
                fontSize: 16,
              }}
            >
              High Risk
            </span>
          </Grid>
        </Grid>
        {/* <ExpandMoreIcon
          style={{
            transform: open ? "rotate(180deg)" : "rotate(270deg)",

            color: "#8C8D8F",
          }}
        /> */}
      </Box>

      {/* {open && (
        <Grid
          sx={{
            padding: "15px 10px",
            display: "flex",
            width: "75%",
            alignSelf: "center",
            flexDirection: "column",
            cursor: "pointer",
            borderRadius: "10px",
            backgroundColor: "#F7F7F7",
            marginTop: -6,
          }}
        >
          <div style={{ margin: "10px 0px" }}>
            <span>We found 3 risky signlas</span>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <img src={RiskIcon} alt="" style={{ marginLeft: -7 }} />
              <span>Unknown Wallet</span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <img src={RiskIcon} alt="" style={{ marginLeft: -7 }} />
              <span>Unknown Wallet</span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <img src={RiskIcon} alt="" style={{ marginLeft: -7 }} />
              <span>Unknown Wallet</span>
            </div>
          </div>
        </Grid>
      )} */}
    </>
  );
};

export default Simulation;
