import { useAppSelector } from "store/store";
import CryptoView, { CryptoViewProps } from "./view";
import { useEffect } from "react";

const Crypto = () => {
  const { activeAccount, portfolio, history } = useAppSelector(
    (state) => state.app
  );

  useEffect(() => {
    console.log("chart data history", { history });

    return () => {};
  }, [history]);

  const viewProps: CryptoViewProps = {
    accountName: activeAccount.accountName,
    totalWalletBalance: portfolio.total_wallet_balance,
    chartData: history.chart_data,
    chartTimestamps: history.chart_timestamps,
  };

  return <CryptoView {...viewProps} />;
};

export default Crypto;
