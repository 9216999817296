import { PaletteMode, SelectChangeEvent } from "@mui/material";
import { SupportedChainId } from "constants/chains";
import { Interval } from "enums";
import { BigNumberish } from "ethers";
import { Dispatch, ReactElement, ReactNode } from "react";

export interface Token {
  id: string;
  tokenName: string;
  tokenSymbol: string;
  tokenBalance: number;
  tokenBalanceRawInteger?: string;
  tokenAddress: string;
  tokenDecimal?: string;
  priceInUSD: number;
  image: string;
  tokenPrice?: string;
  allocation?: string;
  coingeckoId?: string;
}

export interface SwapToToken {
  tokenName: string;
  tokenSymbol: string;
  tokenAddress: string;
  tokenDecimal?: string;
  image: string;
}

interface User {
  mode: string;
  name: string;
  email: string;
  profileImage: string;
}

export interface Accounts {
  [address: string]: {
    address: string;
    secret: string;
    smartAccountAddress: string;
    type: string;
    name: string;
  };
}

export interface Holdings {
  [address: string]: {
    nativeBalance: number;
    nativeBalanceUsd: number;
    nativeTokenPrice: number;
    tokens: Array<any>;
  };
}

export interface NFTHoldings {
  [address: string]: {
    nfts: [
      {
        address: string;
        contractMetadata: {
          contractDeployer: string;
          deployedBlockNumber: string;
          name: string;
          symbol: string;
          tokenType: string;
          totalSupply: String;
          openSea: { lastIngestedAt: string };
        };
        nfts: [];
      }
    ];
  };
}

export type NFTDetailsType = Array<{
  amount: string;
  blockchain: string;
  contract_type: string;
  metadata: {
    name: string;
    description: string;
    image: string;
    pdf: string;
    attributes: Array<{
      trait_type: string;
      value: string;
    }>;
    signature: string;
    signer: string;
  };
  name: string;
  symbol: string;
  token_address: string;
  token_hash: string;
  token_id: string;
  token_uri: string;
  verified_collection: boolean;
  floorPrice: number;
}>;

export interface newNFTHoldings {
  [address: string]: NFTDetailsType;
}

export interface allTransactions {
  [address: string]: any;
}

export type userContacts = Array<{
  _id: string;
  name: string;
  nameHash: string;
  address: string;
  subDomains: Array<{
    name: string;
    nameHash: string;
    address: string;
  }>;
  createdAt: string;
  isRegistered: false;
}>;
export type TransactionType = Array<{
  _id: string;
  address: string;
  addressEns: string;
  hash: string;
  amount: number;
  transferAmount: number;
  userReferalActive: false;
  distributionActive: false;
  eoa: string;
  transactionMethod: string;
  scanLink: string;
  eoaEns: string;
  toAddress: string;
  toAddressEns: string;
  network: string;
  referedByUserReward: number;
  stashedFees: number;
  referedToUserCashback: number;
}>;
export interface cryptoTransactions {
  [address: string]: TransactionType;
}

export interface Portfolio {
  total_wallet_balance: number;
  wallet: string;
  assets: Array<any>;
}

export interface History {
  wallet: string | string[];
  balance_usd: number;
  balance_history: Array<number[]>;
  chart_data: number[];
  chart_timestamps: number[];
  interval: Interval;
}

export interface Asset {
  asset: {
    name: string;
    symbol: string;
    id: number;
    contracts: string[];
    logo: string;
  };
  price: number;
  estimated_balance: number;
  token_balance: number;
  cross_chain_balances: {
    [chain: string]: {
      address: string;
      balance: number;
      balanceRaw: string;
      chainId: number;
    };
  };
}

export interface ActiveAccount {
  address: string;
  secret: string;
  smartAccountAddress: string;
  accountName: string;
}

export interface BackdropProps {
  open: boolean;
}

export interface Activity {
  [address: string]: {
    activity: [];
  };
}

export interface LocalActivity {
  [address: string]: {
    localActivity: [];
  };
}

export enum SETTINGS_OPENED_OPTIONS {
  RECOVERY = "RECOVERY",
  SEEDPHRASE = "SEEDPHRASE",
}
export type SwapToken = {
  id: string;
  image: string;
  amountInUSD: number;
  tokenAddress: string;
  tokenBalance: number;
  tokenDecimal: number;
  tokenName: string;
  tokenPrice: number;
  tokenSymbol: string;
  amount: number;
};

export interface AppReduxState {
  cashExchangeTxs: string[];
  rootAccountInfo: {
    address: string;
    name: string;
    secret: "";
    usdTokenBalance: number;
  };

  referredTo: string[];
  ownReferralCode: string;
  externalReferralCode: string;
  encryptedSeedPhraseWithPassword: string;
  pendingTx: string;
  pendingTxDetails: {
    value: string;
    valueIn$: string;
    transferAmount: string;
    transactionMethod: string;
    txByDesposited: boolean;
    scanLink: string;
    eoaEns: string;
    addressEns: string;
    toAddressEns: string;
    toAddress: string;
    assetName: string;
    networkFeesIn$: string;
    iconURL: string;
    action: string;
  };
  accessToken: string;
  isAccountCreated: boolean;
  expirationTime: number;
  user: User;
  mnemonic: string;
  holdings: Holdings;
  activity: Activity;
  localActivity: LocalActivity;
  accounts: Accounts;
  activeAccount: ActiveAccount;
  lastCreatedAccount: ActiveAccount;
  activeNetwork: SupportedChainId;
  nfts: NFTHoldings;
  newNFTs: newNFTHoldings;
  transactions: allTransactions;
  cashTransactions: [];
  settingsOpenOption: SETTINGS_OPENED_OPTIONS | null;
  portfolio: Portfolio;
  history: History;
  mainnetNetworks: SupportedChainId[];
  testnetNetworks: SupportedChainId[];
  contacts: userContacts;
  connectedDapps: any[];
  dappsList: any[];
  homeScreenMainTabByDefault: number;
  userEns: string;
  isDappRoutes: boolean;
  currentDappRoute: string;
  blockNumbers: {
    [address: string]: number;
  };
  fetchedKeys: {
    rootAddress: string;
    key1: string;
    key2: string;
    key3: string;
    key4: string;
  };
  balances: {
    accumulatedAllAccounts: number;
    smartWalletBalance: {
      [address: string]: number;
    };
  };
  swapDetails: {
    chainId: number;
    tokenA: SwapToken;
    tokenB: SwapToken;
    paymentToken: string;
  };
  connectedDapp2: any[];
  userSpendingDetails: {
    path: number[];
    isPatternSet: boolean;
    isSpendingLimitSaved: boolean;
    isFirstTx: boolean;
    isDailyLimitExceed: boolean;
    spendinglimit: number;
    isFirstTxInApp: boolean;
  };
  isInitialized: boolean;
  gasInfo: {
    totalGas: number;
    depositedTokens: [];
  };
}

export interface WalletReduxState {
  hashedPassword: string;
  walletMode: string;
  selectedToken: Token[];
  fromToken: Token[];
  toToken: Token[];
  txStatus: boolean;
}

export interface BtnProps {
  title: string;
  onClick: () => void;
  mode?: string;
  className?: string;
  style?: {};
  loading?: boolean;
  showIcon?: boolean;
  icon?: string;
  id?: string;
  disabled?: boolean;
  textStyle?: {};
  onKeyPress?: (e: any) => void;
  useRef?: any;
}

export interface InputProps {
  title: string;
  mode?: string;
  disabled?: boolean;
  value?: string;
  onChange?: any;
  type?: string;
  style?: {};
  addBorder?: boolean;
  showStartIcon?: boolean;
  length?: number;
  showEndIcon?: boolean;
  secure?: boolean;
  endIconAction?: () => void;
  showSecure?: () => void;
  customClassName?: string;
  placeholderName?: string;
  onKeyPress?: (e: any) => void;
  customIcon?: any;
  useRef?: any;
  iconText?: any;
}

export interface SeedPhraseBoxProps {
  number: string;
  index: number;
  seedPhrase: string[];
  setSeedPhrase: Function;
  onPaste: (e: any) => any;
}

export interface LockedSeedPhraseprops {
  onClick: () => any;
}

export interface PasswordValidationProps {
  passwordFilters: {
    [n: number]: boolean;
  };
}

export interface QuestionBoxProps {
  questions: string[];
  question: string;
  number: number;
  show: boolean;
  handleChange: (event: SelectChangeEvent<unknown>, child: ReactNode) => void;
}

export interface ModalProps {
  open: boolean;
  onClose: () => {};
}

export interface AccountItemProps {
  name: string;
  balance: number;
  active: boolean;
  avatar: ReactElement;
  accountAd?: any;
  onClick?: () => void;
  activeTick?: boolean;
}

export interface TokenItemProps {
  symbol: string;
  name: string;
  image: string;
  usdPrice: number;
  amount: number;
  tokenAddress: string;
}

export type TransactionHistoryResult = {
  tokenName: string;
  tokenSymbol: string;
  tokenDecimal: string;
  contractAddress: string;
};

export type SingleEthHistory = {
  tokenName: string;
  tokenSymbol: string;
  priceInUSD: number; //balanceInUsd
  tokenBalance: number;
  tokenBalanceRawInteger: string;
  tokenAddress: string;
  image: string;
  tokenDecimal: number;
  chain?: number;
  tokenPrice: number; //tokenPrice in USD
  allocation: number;
};

export type GraphQueryData = {
  [coingeckoId: string]: number;
};

export interface WelcomeBoxProps {
  title: string;
  description?: string;
  onClick: () => void;
  icon?: React.ReactElement;
  style?: {};
}

export interface OtpInputProps {
  otp: string;
  onChange: (otp: string) => void;
  onKeyPress?: (e: any) => void;
  autoFocus?: boolean;
}

export type bridgeData = {
  recipient: string;
  originToken: string;
  amount: string;
  destinationChainId: string;
  message: string;
  maxCount: string;
};

export type transactionOpts = {
  toAddress: string;
  gasPrice: string | BigNumberish;
  gasLimit: string | BigNumberish;
};

export type transactionOptsCalldata = {
  toAddress: string;
  gasPrice: string | BigNumberish;
};

export type transactionGasLimit = {
  approve: string;
  swap: string;
  deposit: string;
};

export type transaction = {
  to: string;
  value: string;
  data: string;
  gasPrice: string;
  gasLimit: string;
  nonce: string;
};

export type uniswapData = {
  recipient: string;
  originToken: string;
  amount: string;
  destinationChainId: string;
  toAddress: string;
  gasPrice: string | BigNumberish;
};

export interface FetchChainBalancesChartDataParams {
  wallet?: string;
  blockchains?: string;
  cache?: boolean;
  stale?: number;
}
