import { Grid } from "@mui/material";

import React from "react";
import InfoCard from "../../components/InfoCard";
import ExpandableChart from "../../components/ExpandableChart";
import NFTBackground from "assets/backgrounds/nftCardBg.png";
import { DARK_GREEN_COLOR } from "../../constants";
import ChipButton from "../../components/ChipButton";
import DepositIcon from "assets/deposit.svg";
import WithdrawIcon from "assets/withdraw.svg";
import SendIcon from "assets/send-icon.svg";
import ExchangeIcon from "assets/exchange.svg";
import { useAppSelector } from "store/store";

const Tokens = () => {
  const { activeAccount } = useAppSelector((state) => state.app);

  return (
    <>
      <Grid container spacing={1.5} mt={5.5}>
        {/* User Info & Balance Area */}
        <Grid item xs={12} md={6} sx={{ paddingTop: "0px !important" }}>
          {/* <Card title="@igors" ens="" balance="50.67" /> */}
          <InfoCard
            backgroundImage={`url(${NFTBackground})`}
            backgroundPosition="0px -52.419px"
            backgroundSize="100% 385.461%"
            title={`@${activeAccount?.accountName?.toLowerCase()}`}
            amount="50.67"
          />
        </Grid>

        {/* Receive Cash Area */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{ paddingTop: "0 !important", height: "100%" }}
        >
          <ExpandableChart
            data={[45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10]}
            title="igor"
            chartColor={"#8F5CFF"}
            chartStrokeColor="#FEF7D9"
            amount="5392.49"
            percentageChange="5"
            expandedHeight="600px"
          />
        </Grid>
      </Grid>
      <Grid
        paddingY={2}
        sx={{ display: "flex", flexDirection: "row", gap: "10px" }}
      >
        <ChipButton title="Receive" onClick={() => {}} icon={DepositIcon} />

        <ChipButton title="Send" onClick={() => {}} icon={SendIcon} />
      </Grid>
      {/* <Banner /> */}
    </>
  );
};

export default Tokens;
