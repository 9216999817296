import {
  Box,
  // Button,
  CircularProgress,
  Input,
  Stack,
  Typography,
} from "@mui/material";
import { parseUri } from "@walletconnect/utils";
// import PageHeader from '@/components/PageHeader'
// import QrReader from '@/components/QrReader'
// import { signClient } from '@/utils/WalletConnectUtil'

// import { Button, Input, Loading, Text } from '@nextui-org/react'
import { Fragment, useState } from "react";
import { styledToast } from "../utils/HelperUtil";
// import { styledToast } from '@/utils/HelperUtil'

import { signClient } from "../utils/WalletConnectUtil";
import { useSnapshot } from "valtio";
import ModalStore from "../walletConnectStore/ModalStore";
import { useAppSelector } from "../store/store";
import SettingsStore from "../walletConnectStore/SettingsStore";
import { fontWeight } from "@mui/system";
import BasicButton from "./Button";
import TextInput from "./TextInput";
import Button from "./NewButton";

export default function WalletConnectPage({
  uri,
  setUri,
  loading,
  setLoading,
  onConnect,
}) {
  return (
    <Fragment>
      {/* <PageHeader title="WalletConnect" />

      <QrReader onConnect={onConnect} /> */}

      <Stack
        direction={"column"}
        sx={{
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          // border: "1px solid red",
        }}
      >
        {/* <Input
          sx={{
            color: "#67686C",
            height: "80px",
            width: "80%",
            fontSize: "12px",
            fontWeight: 500,
          }}
          // bordered
          placeholder="e.g. wc:a281567bb3e4..."
          onChange={(e) => setUri(e.target.value)}
          value={uri}
          data-testid="uri-input"
          startAdornment={
            <Typography
              variant="caption"
              color="#67686C"
              fontSize="10px"
              fontWeight={400}
              sx={{ width: "180px" }}
            >
              {" "}
              Enter Wallet Connect URL{" "}
            </Typography>
          }
        /> */}

        {/* <Button
          //   size="xs"
          // variant=""
          disabled={!uri}
          sx={{
            height: "54px",
            width: "750px",
            borderRadius: "3px",
            backgroundColor: "#00E599",
            border: "1px solid #00E599",
            color: "#121212",
          }}
          // style={{ marginLeft: -60 }}
          onClick={() => onConnect(uri)}
          //   color="gradient"
          data-testid="uri-connect-button"
        >
          {loading ? <CircularProgress size="sm" /> : "Connect"}
        </Button> */}

        <Stack
          direction={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ width: "80%" }}
        >
          <Typography
            variant="h4"
            color="#121212"
            fontSize="22px"
            fontWeight={600}
            // sx={{ width: "180px" }}
          >
            {" "}
            Connect To Custom App{" "}
          </Typography>
          <Box sx={{ width: "100%" }}>
            <TextInput
              title="Enter URL"
              // value={password}
              // onChange={setPassword}
              onChange={(val) => setUri(val)}
              value={uri}
              // showSecure={() => setSecurePassword(!securePassword)}
              // showEndIcon
              // secure={securePassword}
              type={"text"}
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            <Button
              disabled={!uri}
              title="Connect"
              onClick={() => onConnect(uri)}
              id="handle_login"
              loading={loading}
            />
          </Box>
        </Stack>
        {/* 
        <BasicButton
          title='Send'
          onClick={() => onConnect(uri)}
          className={`send-btn ${activeTab === "send" && "active-send-btn"}`}
        /> */}
      </Stack>
    </Fragment>
  );
}
