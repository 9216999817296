import React from 'react'
import NoImg from 'assets/noImg.svg'

interface WrapperProps {
  logo: string
  size?: number
  borderColor?: string
  borderWidth?: number
}

const TokenImage: React.FC<WrapperProps> = ({
  logo,
  size = 33,
  borderColor = '#1A1C20',
  borderWidth = 5,
}) => {
  return (
    <img
      src={logo}
      alt={'alt'}
      style={{
        width: size,
        height: size,
        border: `${borderWidth}px solid ${borderColor}`,
        borderRadius: '50%',
      }}
      onError={e => {
        e.currentTarget.src = NoImg
      }}
    />
  )
}

export default TokenImage
