import React, { FC, useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  Typography,
} from "@mui/material";
import MidArrow from "assets/midArrow.svg";
import ApplePayIcon from "assets/applePayIcon.svg";
import BankIcon from "assets/bank.svg";
import DropDownIcon from "assets/drop-down.svg";
import DropUpIcon from "assets/drop-up.svg";
import TokenBox from "components/TokenBox";
import BasicButton from "components/Button";

import "./styles.css";
import axios from "axios";
import { useAppSelector } from "store/store";
import { showAlert } from "utils/utils";

interface BuyFiatPreviewProps {
  tokenIcon: string;
  tokenSymbol: string;
  value: number;
  tokenPriceInUsd: number;
  currencyIcon: string;
  currencySymbol: string;
  selectedPaymentMethod: {};
  activeCryptoOnRamperId: {};
  nextStep: () => {};
  type: string;
}

const BuyFiatPreview: FC<BuyFiatPreviewProps> = ({
  tokenIcon,
  tokenSymbol,
  tokenPriceInUsd,
  value,
  currencyIcon,
  currencySymbol,
  selectedPaymentMethod,
  activeCryptoOnRamperId,
  nextStep,
  type,
}) => {
  const [loading, setLoading] = useState(false);
  const [showProvidersList, setShowProvidersList] = useState(false);
  const [provider, setProvider] = useState("");
  const [quoteId, setQuoteId] = useState("");
  const [transactionUrl, setTransactionUrl] = useState("");
  const [rate, setRate] = useState(0);
  const [tokensLoading, setTokensLoading] = useState(false);
  const [providersList, setProvidersList] = useState([]);
  const [activeProvider, setActiveProvider] = useState({});

  console.log("selectedPaymentMethod", selectedPaymentMethod);

  const { rootAccountInfo } = useAppSelector((state) => state.app);

  useEffect(() => {
    (async () => {
      if (currencyIcon && value) {
        try {
          setTokensLoading(true);
          let url = "";
          if (type === "sell") {
            url = `https://api.onramper.com/quotes/${activeCryptoOnRamperId.id}/${currencySymbol}?amount=${value}&paymentMethod=${selectedPaymentMethod.paymentTypeId}&type=${type}&network=polygon`;
          } else {
            url = `https://api.onramper.com/quotes/${currencySymbol}/${activeCryptoOnRamperId.id}?amount=${value}&paymentMethod=${selectedPaymentMethod.paymentTypeId}&type=${type}&network=polygon`;
          }
          const { data } = await axios.get(url, {
            headers: {
              Authorization: process.env.ONRAMP_API_KEY,
            },
          });

          console.log("DAATA", data);

          if (data.length > 0 && data[0].errors?.length > 0) {
            setTokensLoading(false);

            showAlert(`${data[0].errors[0].message}`);
            nextStep();
          } else {
            setRate(data.length > 0 && data[0].payout.toFixed(4));
            setProvider(data.length > 0 && data[0].ramp);
            setQuoteId(data.length > 0 && data[0].quoteId);
            setProvidersList(data.length > 0 ? data : []);
            setActiveProvider(data[0]);

            console.log("data", data);

            setTokensLoading(false);
          }
        } catch (error) {
          console.log("E>>>>>>>>>>>", error);

          setTokensLoading(false);
        }
      }
    })();
  }, [currencyIcon]);

  const createIntent = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        "https://api.onramper.com/checkout/intent",
        {
          onramp: provider,
          source: type === "sell" ? activeCryptoOnRamperId.id : currencySymbol,
          destination:
            type === "sell" ? currencySymbol : activeCryptoOnRamperId.id,
          amount: value,
          type,
          paymentMethod: selectedPaymentMethod.paymentTypeId,
          network: "polygon",

          originatingHost:
            type === "sell" ? "sell.onramper.com" : "buy.onramper.com",
          metaData: {
            quoteId: quoteId,
          },
          wallet: {
            address: rootAccountInfo.address,
          },
        },
        {
          headers: {
            Authorization: process.env.ONRAMP_API_KEY,
          },
        }
      );
      console.log("DATAAAAAAAA", data);
      setTransactionUrl(data.message.transactionInformation.url);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return tokensLoading ? (
    <div style={{ width: "100%", textAlign: "center", margin: "25% 0px" }}>
      <CircularProgress color="inherit" />
    </div>
  ) : (
    <Grid container display="flex" justifyContent="center" py={4}>
      {transactionUrl && (
        <iframe
          title="MyIFrame"
          width="600"
          height="400"
          src={transactionUrl}
          style={{ display: "block" }}
        ></iframe>
      )}
      <Grid
        item
        lg={6}
        sm={12}
        style={{
          flexBasis: "65%",
          maxWidth: "100%",
        }}
      >
        <Box mt={2.5} position={"relative"}>
          <div className="input-container" style={{ marginBottom: 10 }}>
            <FormControl sx={{ flex: 1 }}>
              <Box
                sx={{
                  backgroundColor: "#EDEEF2",
                  color: "#1A1C20",
                  width: "100%",
                  // height: "80px",
                  borderRadius: "10px",
                  fontSize: "25px",
                  fontFamily: "Space Grotesk",
                  fontWeight: "700",
                  border: "0.5px solid rgba(26, 28, 32, 0.50)",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      paddingTop: 15,
                    }}
                  >
                    <Box mr={1.25}>
                      {/* <img src={tokenIcon} height={33} width={33} /> */}
                      <img
                        src={type === "sell" ? tokenIcon : currencyIcon}
                        height={33}
                        style={{ padding: 10 }}
                      />
                    </Box>
                    <Box
                      sx={{
                        color: "#1A1C20",
                        fontFamily: "Space Grotesk",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "600",
                        lineHeight: "normal",
                        marginTop: -1,
                        marginLeft: -1.5,
                      }}
                    >
                      {type === "sell"
                        ? tokenSymbol
                        : currencySymbol.toUpperCase()}
                    </Box>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginRight: 10,
                    }}
                  >
                    <Box
                      mr={1.25}
                      sx={{
                        color: "#1A1C20",
                        fontFamily: "Space Grotesk",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "600",
                        lineHeight: "normal",
                      }}
                    >
                      {type === "sell"
                        ? `${(value * tokenPriceInUsd).toFixed(3)}`
                        : rate}
                    </Box>
                    <Box
                      sx={{
                        color: "#8C8D8F",
                        fontFamily: "Space Grotesk",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }}
                    >
                      {type === "sell" && `${value} ${tokenSymbol}`}
                    </Box>
                  </div>
                </Box>
              </Box>
            </FormControl>
          </div>
          <div
            style={{
              position: "absolute",
              top: "40%",
              left: "50%",
              zIndex: 2,
            }}
          >
            <img src={MidArrow} />
          </div>

          <div className="input-container" style={{ marginBottom: 10 }}>
            <FormControl sx={{ flex: 1 }}>
              <Box
                sx={{
                  backgroundColor: "#EDEEF2",
                  color: "#1A1C20",
                  width: "100%",
                  // height: "80px",
                  borderRadius: "10px",
                  fontSize: "25px",
                  fontFamily: "Space Grotesk",
                  fontWeight: "700",
                  border: "0.5px solid rgba(26, 28, 32, 0.50)",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      paddingTop: 15,
                    }}
                  >
                    <Box mr={1.25}>
                      <img
                        src={type === "sell" ? currencyIcon : tokenIcon}
                        height={33}
                        style={{ margin: "0px 10px 0px 10px", padding: 10 }}
                      />
                    </Box>
                    <Box
                      sx={{
                        color: "#1A1C20",
                        fontFamily: "Space Grotesk",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "600",
                        lineHeight: "normal",
                        marginTop: -1,
                        marginLeft: -1.5,
                      }}
                    >
                      {type === "sell"
                        ? currencySymbol.toUpperCase()
                        : tokenSymbol}
                    </Box>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginRight: 10,
                    }}
                  >
                    <Box
                      mr={1.25}
                      sx={{
                        color: "#1A1C20",
                        fontFamily: "Space Grotesk",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "600",
                        lineHeight: "normal",
                      }}
                    >
                      {type === "sell"
                        ? rate
                        : `${(value * tokenPriceInUsd).toFixed(3)}`}
                    </Box>
                    <Box
                      sx={{
                        borderRadius: "6.02px",
                        backgroundColor: "#00E599",
                        padding: "5px",
                      }}
                    >
                      <Typography
                        style={{
                          color: "#1A1C20",
                          fontFamily: "Space Grotesk",
                          fontSize: "7.23px",
                          fontStyle: "normal",
                          fontWeight: "600",
                          lineHeight: "normal",
                        }}
                      >
                        {type === "sell"
                          ? "ESTIMATED"
                          : `${value} ${tokenSymbol}`}
                      </Typography>
                    </Box>
                  </div>
                </Box>
              </Box>
            </FormControl>
          </div>
        </Box>

        <div>
          <div className={"paymentMethod"}>
            <Typography>Payment method</Typography>

            <img src={selectedPaymentMethod.icon} alt="" />
          </div>

          <Typography>Provider</Typography>
          <div
            className={"providers-box"}
            onClick={() => setShowProvidersList(!showProvidersList)}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <img src={BankIcon} style={{ marginTop: 6, marginRight: 6 }} />

              <Typography>
                {activeProvider && activeProvider.ramp
                  ? `${activeProvider.ramp[0].toUpperCase()}${activeProvider.ramp.slice(
                      1,
                      activeProvider.ramp.length
                    )}`
                  : ""}
              </Typography>
            </div>
            <div>
              {providersList.length > 1 && (
                <img
                  src={showProvidersList ? DropUpIcon : DropDownIcon}
                  style={{ marginTop: 6, cursor: "pointer" }}
                />
              )}
            </div>

            {/* {showProvidersList && (
            <div className={"providers"}>
              <TokenBox tokenSymbol="Transak" tokenIcon={BankIcon} />
              <TokenBox tokenSymbol="Alchmey" tokenIcon={BankIcon} />
            </div>
          )} */}
          </div>
          <div>
            {showProvidersList && providersList.length > 1 && (
              <div className={"providers"}>
                {providersList
                  .filter((pr) => pr.ramp !== activeProvider.ramp && pr.rate)
                  .map((pr) => {
                    return (
                      <TokenBox
                        tokenSymbol={`${pr.ramp[0].toUpperCase()}${pr.ramp.slice(
                          1,
                          pr.ramp.length
                        )}`}
                        tokenIcon={BankIcon}
                        onClick={() => {
                          setActiveProvider(pr);
                          setRate(pr.payout.toFixed(4));
                        }}
                      />
                    );
                  })}
              </div>
            )}
          </div>
          <BasicButton
            title="Sell"
            onClick={createIntent}
            loading={loading}
            disabled={!rate || !quoteId || !provider}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default BuyFiatPreview;
