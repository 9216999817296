import * as React from "react";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Typography } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "30%",
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    width: "100%",
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function BasicSelect({ properties }) {
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  return (
    <div>
      <FormControl sx={{ m: 1, width: "50%", mt: 3 }}>
        <Select
          displayEmpty
          value={personName}
          input={<OutlinedInput />}
          renderValue={selected => {
            if (selected.length === 0) {
              return (
                <Typography variant='fs14' fontWeight={500} color={"#798BA3"}>
                  Properties
                </Typography>
              );
            }

            return selected.join(", ");
          }}
          MenuProps={MenuProps}
          inputProps={{ "aria-label": "Without label" }}
          // IconComponent={() => (
          //   <img src={SelectIcon} style={{ marginRight: "20px" }} alt='add' />
          // )}
        >
          {properties.length > 0 &&
            properties.map(name => (
              <MenuItem
                key={
                  (name.trait_type || name.type) +
                  "" +
                  (name.value || name.description)
                }
                value={name.value || name.description}
                style={getStyles(name.value, personName, theme)}
              >
                <Typography
                  variant='fs14'
                  fontWeight={400}
                  // color={colors.black[400]}
                >
                  {name.trait_type || name.type}:{" "}
                  {name.value || name.description}
                </Typography>
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
}
