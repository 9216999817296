import React, { FC } from "react";

import "./styles.css";
import { Typography } from "@mui/material";

const CryptoFiatTabs: FC<{
  activeTab: string;
  setActiveTab: (tab: string) => void;
}> = ({ activeTab, setActiveTab }) => {
  return (
    <div className="tabs">
      <div
        className={`${activeTab === "crypto" && "activeTab"} tab`}
        onClick={() => setActiveTab("crypto")}
      >
        <Typography
          className={`${activeTab === "crypto" ? "activeTabText" : "tabText"}`}
        >
          Crypto
        </Typography>
      </div>
      <div
        className={`${activeTab === "fiat" && "activeTab"} tab`}
        onClick={() => setActiveTab("fiat")}
      >
        <Typography
          className={`${activeTab === "fiat" ? "activeTabText" : "tabText"}`}
        >
          Fiat
        </Typography>
      </div>
    </div>
  );
};

export default CryptoFiatTabs;
