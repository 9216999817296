import React, { useState } from "react";

import StashedCreatedLogo from "assets/stash-created.svg";

import SuccessLogo from "assets/success.svg";

import "../../pages/auth/TransactionFlow/TransactionSuccess/styles.css";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import Button from "components/NewButton";
import AuthHeader from "components/AuthHeader";
import { useAppDispatch } from "store/store";
import { setIsAccountCreated } from "@slices/appSlice";

const TransactionSuccess = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleNavigateForward = () => {
    navigate("/security-pattern");
  };

  const handleNavigateBack = () => {
    // navigate("/cash");
    navigate("/skip-settings");
  };

  return (
    <div>
      <div
        className="transaction-box"
        style={{
          marginTop: "10px",
          alignItems: "center",
        }}
      >
        <img src={SuccessLogo} height={100} />
        <Typography
          variant="h2"
          sx={{
            margin: "20px 0px 20px 0px",
            fontWeight: "700",
            textAlign: "center",
            fontSize: "28px",
          }}
        >
          Congratulations on your first transaction
        </Typography>

        <Typography
          variant="body2"
          sx={{
            margin: "0px 0px 20px 0px",
            color: "rgba(26, 28, 32, 0.5)",
            fontWeight: "500",
            textAlign: "center",
            width: "100%",
            fontSize: "17px",
            fontFamily: "Helvetica Neue",
          }}
        >
          Would you like to set up additional security features?
        </Typography>

        <div className="transaction-wrapper" style={{ top: 20 }}>
          <Typography
            variant="body2"
            sx={{
              color: "#1A1C20",
              fontWeight: "700",
              textAlign: "center",
              fontSize: "17px",
              fontFamily: "Space Grotesk",
              cursor: "pointer",
              margin: "auto auto auto 50px",
            }}
            onClick={handleNavigateBack}
          >
            Not Now
          </Typography>
          <Button
            title="Continue"
            onClick={handleNavigateForward}
            loading={loading}
            disabled={false}
            style={{
              margin: 0,
              width: "260px",
              padding: "15px 0px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TransactionSuccess;
