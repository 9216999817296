import { Provider } from "@ethersproject/providers";
import { Box } from "@mui/system";
import { tokensForGas } from "constants/topTokensConf";
import React, { FC, useEffect, useState } from "react";
import { useAppSelector } from "store/store";
import { getAssetsDollarWorth } from "utils/portfolio";
import Web3 from "web3";
import paymasterAbi from "abis/paymasterabi.json";
import { Paymaster_Token_Address } from "../../../contract-integration/constants";
import { ethers } from "ethers";
import GasIcon from "assets/gas-icon.svg";
import WhiteGasIcon from "assets/white-gas-icon.svg";
import GreyGasIcon from "assets/grey-gas-icon.svg";

import WalletIcon from "assets/wallet-icon.svg";
import BlackWalletIcon from "assets/black-wallet-icon.svg";

import { Typography } from "@mui/material";

const TxTypeSwitch: FC<{
  loading: boolean;
  onTop20Select: any;
  onDepositSelect: any;
  txByDeposited: boolean;
}> = ({ loading, onTop20Select, onDepositSelect, txByDeposited }) => {
  const [cummulativeGas, setCummulativeGas] = useState(0);

  const { accounts } = useAppSelector((state) => state.app);
  const activeAccountAddress = Object.values(accounts)[0].smartAccountAddress;

  const readAccountHoldings = async (
    tokenAddress: string,
    address: string,
    rpc: Provider,
    decimal: number
  ) => {
    const web3 = new Web3(rpc);
    const paymasterContract = new web3.eth.Contract(
      paymasterAbi as any,
      Paymaster_Token_Address
    );

    try {
      const accountHolding = await paymasterContract.methods
        .balances(tokenAddress, address)
        .call();
      return Number(ethers.utils.formatUnits(String(accountHolding), decimal));
    } catch (error) {
      console.error("Error reading account holdings:", error);
      return 0; // Handle the error gracefully
    }
  };

  useEffect(() => {
    // Create an object to keep track of cumulative balances for each token
    type CumulativeBalances = { [tokenAddress: string]: number };
    const cumulativeBalances: CumulativeBalances = {};

    // Iterate through the accounts and fetch balances for each
    async function fetchBalances() {
      let tokenAddressesForMobula = tokensForGas.map(
        (tokenInfo) => tokenInfo.tokenAddressForMobula
      );
      let depositedTokenObject: Array<any> = [];

      // Call the getAssetsDollarWorth function with the tokenAddressesForMobula array as the argument
      const tokenData = await getAssetsDollarWorth(tokenAddressesForMobula);

      // Extract the prices from the returned object
      let tokenWorthInUSD = Object.values(tokenData).map(
        (token) => token.price
      );

      // Now you can use the tokenWorthInUSD array in your loop
      for (let i = 0; i < tokensForGas.length; i++) {
        const tokenInfo = tokensForGas[i];
        const { tokenAddress, rpc, decimal } = tokenInfo;
        const balance = await readAccountHoldings(
          tokenAddress,
          activeAccountAddress,
          rpc,
          decimal
        );

        const tokenWorthInUSDForCurrentToken = tokenWorthInUSD[i];
        const balanceInUSD = balance * tokenWorthInUSDForCurrentToken;
        if (balance > 0) {
          depositedTokenObject.push({ ...tokenInfo, balance, balanceInUSD });
        }

        // Add the balance to the cumulative balance for the token
        if (!cumulativeBalances[tokenAddress]) {
          cumulativeBalances[tokenAddress] = balanceInUSD;
        } else {
          cumulativeBalances[tokenAddress] += balanceInUSD;
        }
      }
      // Calculate the total cumulative balance
      let totalBalance = 0;
      for (const tokenAddress of Object.keys(cumulativeBalances)) {
        totalBalance += cumulativeBalances[tokenAddress];
      }

      setCummulativeGas(totalBalance || 0);
    }

    fetchBalances();
  }, [accounts]);

  console.log(cummulativeGas, "cummulativeGas");

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      gap={2}
    >
      <Box sx={{ color: "#8C8D8F", textAlign: "center" }}>PAY FEES WITH</Box>

      <Box
        display={"flex"}
        flexDirection={"row"}
        gap={2}
        sx={{
          border: "0px",
          background: "#F7F7F7",
          width: "fit-content",
          justifyContent: "center",
          borderRadius: "10px",
          padding: "5px",
        }}
      >
        <Box
          sx={{
            // Active styling
            padding: "10px",
            borderRadius: "10px",
            cursor: "pointer",
            opacity: loading ? 0.5 : 1,
            pointerEvents: loading ? "none" : "all",
            backgroundColor: !txByDeposited ? "#1A1C20" : "",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
          onClick={() => {
            onTop20Select();
            // if (loading) {
            //   return;
            // }
            // setTxByDeposited(false);
            // setFinalOpState(null);
            // SetTokenForPayment("");
            // setSelectedDepositTokenAddress("");
          }}
        >
          <img
            src={!txByDeposited ? WalletIcon : BlackWalletIcon}
            alt=""
            height={30}
            style={{ marginRight: 5 }}
          />

          <Typography
            variant="h6"
            sx={{
              // Active styling

              color: !txByDeposited ? "white" : "",
            }}
          >
            From Wallet
          </Typography>
        </Box>
        <Box
          sx={{
            // Active styling
            padding: "10px",
            borderRadius: "10px",
            backgroundColor: txByDeposited ? "#1A1C20" : "",

            pointerEvents:
              loading || cummulativeGas === 0 ? "none !important" : "all",
            cursor:
              loading || cummulativeGas === 0
                ? "not-allowed !important"
                : "pointer",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
          onClick={() => {
            onDepositSelect();
            // if (loading) {
            //   return;
            // }
            // setTxByDeposited(true);
            // setFinalOpState(null);
            // SetTokenForPayment("");
            // setSelectedDepositTokenAddress("");
          }}
        >
          <img
            src={
              txByDeposited
                ? WhiteGasIcon
                : loading || cummulativeGas === 0
                ? GreyGasIcon
                : GasIcon
            }
            alt=""
            style={{ marginRight: 5 }}
            height={30}
          />
          <Typography
            variant="h6"
            sx={{
              // Active styling

              color: txByDeposited
                ? "white"
                : loading || cummulativeGas === 0
                ? "grey"
                : "#15121d",
            }}
          >
            From Gas Tank
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TxTypeSwitch;
