import axios from "axios";
import moment from "moment";
import { SupportedChainId, SUPPORTED_NETWORKS } from "constants/chains";
import Web3 from "web3";
import dummyImage from "assets/dummy-token-image.svg";

export const fetchTxHistory = async (
  address: string,
  network: SupportedChainId
) => {
  try {
    const { api, api_key, symbol, icon } =
      SUPPORTED_NETWORKS[network as keyof typeof SUPPORTED_NETWORKS];

    let sentTxs = [];
    let receivedTxs = [];

    const { data: tx } = await axios.get(
      `${api}?module=account&action=txlist&address=${address}&sort=desc&apikey=${api_key}`
    );

    const { data } = await axios.get(
      `${api}?module=account&action=tokentx&address=${address}&sort=desc&apikey=${api_key}`
    );

    const result = data.result.slice(0, 50);

    tx.result.slice(0, 50).map(tx => {
      if (!tx.contractAddress && !tx.functionName) {
        if (tx.from.toLowerCase() === address.toLowerCase()) {
          sentTxs.push({
            icon,
            tokenName: symbol,
            amount:
              Number(Web3.utils.fromWei(tx.value, "ether")) < 0.001
                ? "0.0..."
                : Web3.utils.fromWei(tx.value, "ether"),
            timeStamp: tx.timeStamp,
            date: moment(tx.timeStamp * 1000).format("MMM D, YYYY"),
            time: moment(tx.timeStamp * 1000).format("hh:mm a"),
            type: "Sent",

            hash: tx.hash,
          });
        } else {
          receivedTxs.push({
            icon,
            tokenName: symbol,
            amount:
              Number(Web3.utils.fromWei(tx.value, "ether")) < 0.001
                ? "0.0..."
                : Web3.utils.fromWei(tx.value, "ether"),
            timeStamp: tx.timeStamp,
            date: moment(tx.timeStamp * 1000).format("MMM D, YYYY"),
            time: moment(tx.timeStamp * 1000).format("hh:mm a"),
            type: "Received",
            hash: tx.hash,
          });
        }
      }
    });

    result.map(tx => {
      if (tx.from.toLowerCase() === address.toLowerCase()) {
        sentTxs.push({
          icon: dummyImage,
          tokenName: tx.tokenName,
          amount:
            tx.value / 10 ** Number(tx.tokenDecimal) < 0.001
              ? "0.0..."
              : tx.value / 10 ** Number(tx.tokenDecimal),
          timeStamp: tx.timeStamp,
          date: moment(tx.timeStamp * 1000).format("MMM D, YYYY"),
          time: moment(tx.timeStamp * 1000).format("hh:mm a"),
          type: "Sent",

          hash: tx.hash,
        });
      } else {
        receivedTxs.push({
          icon: dummyImage,
          tokenName: tx.tokenName,
          amount:
            tx.value / 10 ** Number(tx.tokenDecimal) < 0.001
              ? "0.0..."
              : tx.value / 10 ** Number(tx.tokenDecimal),
          timeStamp: tx.timeStamp,
          date: moment(tx.timeStamp * 1000).format("MMM D, YYYY"),
          time: moment(tx.timeStamp * 1000).format("hh:mm a"),
          type: "Received",
          hash: tx.hash,
        });
      }
    });
    return { sentTxs, receivedTxs };
  } catch (error) {
    console.log(error);
    throw error;
  }
};
