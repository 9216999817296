import React, { FC, useEffect, useState } from "react";
import { ApexOptions } from "apexcharts";
import {
  LIGHT_GREEN_COLOR,
  DARK_GREEN_COLOR,
  areaChartCryptoColors,
  areaChartCashColors,
} from "constants/";
import ReactApexChart from "react-apexcharts";
import CardLayout from "components/CardLayout";
import { Box, IconButton, Skeleton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ChipButton from "components/ChipButton";
import { useAppDispatch, useAppSelector } from "store/store";
import { Interval } from "./../../enums";
import { setHistory } from "@slices/appSlice";
import NoDataUI from "../NoDataCard";
import NoAssetIcon from "assets/noAssetIcon.svg";
import NoNFTIcon from "assets/noNFTIcon.svg";
import { timeInSeconds } from "pages/app/Crypto/TokenDetailPage";
import {
  fetchCashAccountChartData,
  fetchCryptoAccHistory,
} from "utils/portfolio";

interface ChartProps {
  chartData: number[];
  chartTimestamps: number[];
  isExpanded?: boolean;
  setIsExpanded?: Function;
  title?: string;
  amount?: string;
  percentageChange?: string;
  chartColor?: string;
  chartStrokeColor?: string;
  expandedHeight?: string;
  isCryptoScreen?: boolean;
  isNFTScreen?: boolean;
  chartLoader?: boolean;
}

function customTimestampFormatter(timestamp: number) {
  // You can use any JavaScript date formatting library or custom logic here
  // Example: Using the built-in Date object to format the timestamp
  const date = new Date(timestamp);
  const formattedDate = `${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()} ${
    date.getHours() % 12
  }:${date.getMinutes()}:${date.getSeconds()}`;
  return formattedDate;
}

const Chart: FC<ChartProps> = ({
  chartData,
  chartTimestamps,
  isExpanded,
  setIsExpanded,
  chartColor,
  chartStrokeColor,
  chartLoader,
  isCryptoScreen,
}) => {
  const {
    history: { interval },
  } = useAppSelector((state) => state.app);
  const updatedChartData =
    chartData.length === 1 ? [chartData[0], chartData[0]] : chartData;
  const updatedChartTimestamps =
    chartTimestamps.length === 1
      ? [chartTimestamps[0], Date.now()]
      : chartTimestamps;

  // Define the radial gradient style
  const radialGradient = {
    background:
      "radial-gradient(118.67% 92.78% at 50.06% 7.22%, rgba(83, 238, 185, 0.6) 0%, rgba(203, 245, 193, 0.6) 58.66%, rgba(255, 255, 255, 0) 100%)",
  };

  const startingData = updatedChartData.slice(0, 15);
  const endingData = updatedChartData.slice(-15);

  let mergedData = [...startingData, ...endingData];

  const chartObj = {
    series: [
      {
        name: "Portfolio",
        data: mergedData
          // .slice(
          //   0,
          //   isExpanded
          //     ? updatedChartData.length
          //     : interval !== Interval.MONTH
          //     ? 20
          //     : 40
          // )
          .map((value, index) => {
            return {
              x: updatedChartTimestamps[index],
              y: value,
            };
          }),
      },
    ],
    options: {
      tooltip: {
        enabled: isExpanded,
        y: {
          formatter: function (value) {
            return value.toFixed(4);
          },
        },
      },
      grid: {
        show: false,
      },
      chart: {
        height: "100%",
        width: "100%",
        type: "area",
        parentHeightOffset: 0,
        toolbar: {
          show: false,
        },
        sparkline: {
          enabled: true,
        },
        selection: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      // Add the radial gradient to the fill property
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 1,
          opacityTo: 0.5,
          stops: isCryptoScreen
            ? areaChartCryptoColors.stops
            : areaChartCashColors.stops, // Adjust stops based on your gradient
          colorStops: [
            {
              offset: 0,
              color: isCryptoScreen
                ? areaChartCryptoColors.firstColor
                : areaChartCashColors.firstColor, // First color
              opacity: 1,
            },
            {
              offset: 58.66,
              color: isCryptoScreen
                ? areaChartCryptoColors.secondColor
                : areaChartCashColors.secondColor, // Second color
              opacity: 1,
            },
            {
              offset: 100,
              color: isCryptoScreen
                ? areaChartCryptoColors.thirdColor
                : areaChartCashColors.thirdColor, // Third color
              opacity: 1,
            },
          ],
        },
      },
      stroke: {
        // curve: "smooth",
        colors: [chartStrokeColor],
        width: 0,

        show: true,
        curve: "straight",
        lineCap: "butt",

        dashArray: 0,
      },
      xaxis: {
        tickPlacement: "between",
        labels: {
          show: false,
        },
        type: "datetime", // Set x-axis type to datetime
        labels: {
          show: true,
          formatter: customTimestampFormatter, // Use the custom formatting function
        },
        categories: chartTimestamps || [],
      },
      yaxis: {
        min: 0,
        max: Math.max(...chartData) * 1.1,
        forceNiceScale: true,
        labels: {
          show: false,
        },
      },
      noData: {
        text: "No transactions found in selected time period",
        align: "center" as const,
        verticalAlign: "middle" as const,
        offsetX: 0,
        offsetY: 0,
        style: {
          color: "rgba(26, 28, 32, 0.75)",
          fontSize: "16px",
          fontFamily: "Space Grotesk",
        },
      },
      colors: [chartColor],
    },
  };

  console.log(chartLoader, "chartLoader");

  return (
    <>
      {chartLoader ? (
        <>
          <Skeleton
            variant="rectangular"
            width="90%"
            height="100%"
            sx={{ margin: "auto" }}
          />
        </>
      ) : chartData?.length === 0 ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Typography
            variant={"h5"}
            fontWeight={700}
            color="rgba(26, 28, 32, 0.5)"
            fontSize={14}
            style={{ cursor: "pointer" }}
            align="center"
          >
            No transactions found in selected time period
          </Typography>
        </Box>
      ) : (
        <ReactApexChart
          options={chartObj.options}
          series={chartObj.series}
          onClick={() => (setIsExpanded as any)(true)} // Toggle expanded state on click
          type="area"
          height="80%"
          width="100%"
        />
      )}
    </>
  );
};

const ExpandedChart: FC<ChartProps> = (props) => {
  const splittedBalance = (props.amount ? props.amount : "").split(".");
  const {
    history: { interval },
    rootAccountInfo,
    activeAccount,
  } = useAppSelector((state) => state.app);

  const dispatch = useAppDispatch();

  const [chartData, setChartData] = useState<number[]>([]);
  const [chartLoader, setChartLoader] = useState<boolean>(false);
  const [chartTimestamps, setChartTimestamps] = useState<number[]>([]);

  const onChangeInterval = (interval: Interval) => {
    dispatch(
      setHistory({
        interval,
      })
    );
  };

  const getCashAccGraph = async (isCryptoScreen: boolean) => {
    console.log(activeAccount, "asfaasfas");

    setChartLoader(true);
    const selectedTime = timeInSeconds[interval];

    try {
      let response;

      if (isCryptoScreen) {
        response = await fetchCryptoAccHistory(
          activeAccount?.smartAccountAddress,
          interval
        );
      } else {
        response = await fetchCashAccountChartData(
          Math.floor(Date.now() / 1000) - selectedTime,
          Math.floor(Date.now() / 1000),
          isCryptoScreen
            ? activeAccount?.smartAccountAddress
            : rootAccountInfo?.address,
          isCryptoScreen ? "" : "USD Coin"
        );
      }

      if (response) {
        // Format the response based on its structure
        const formattedResponse = isCryptoScreen
          ? {
              data: {
                wallet: [activeAccount?.smartAccountAddress],
                balance_usd: response[0][1],
                balance_history: response,
              },
            }
          : {
              data: {
                wallet: [
                  isCryptoScreen
                    ? activeAccount?.smartAccountAddress
                    : rootAccountInfo?.address,
                ],
                balance_usd: response[0][1],
                balance_history: response,
              },
            };

        const chartData = formattedResponse.data.balance_history.map(
          (item: any) => item[1]
        );
        const chartTimestamps = formattedResponse.data.balance_history.map(
          (item: any) => item[0]
        );
        setChartData(chartData);
        setChartTimestamps(chartTimestamps);
        setChartLoader(false);
      } else {
        setChartData([]);
        setChartTimestamps([]);
        setChartLoader(false);
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);
      setChartLoader(false);
    }
  };

  useEffect(() => {
    getCashAccGraph(props?.isCryptoScreen as boolean);
  }, [interval]);

  return (
    <>
      <Box display="flex" flexDirection="column" height="100%">
        <Box display="flex" justifyContent="space-between" p={2.5}>
          <Box display="flex" alignItems="center">
            <Typography
              variant={"h5"}
              fontWeight={700}
              color="#1A1C20"
              fontSize={20}
              style={{ cursor: "pointer" }}
              align="center"
              mr={0.5}
            >
              Portfolio
            </Typography>
            <Typography
              variant={"h5"}
              fontWeight={500}
              color="rgba(26, 28, 32, 0.5)"
              fontSize={14}
              style={{ cursor: "pointer" }}
              align="center"
            >
              @{props.title}
            </Typography>
          </Box>
          <Box>
            <IconButton
              aria-label="settings"
              disableRipple={true}
              size="small"
              sx={{
                backgroundColor: "rgba(235, 236, 238, 1)",
              }}
              onClick={() => {
                (props.setIsExpanded as any)(false);
                onChangeInterval(Interval.DAY);
              }} // Toggle expanded state on click
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box display="flex" px={2.5} alignItems="baseline">
          {Number(props?.amount) <= 0 ? (
            <Typography
              variant={"h5"}
              fontWeight={700}
              color="#1A1C20"
              fontSize={45}
              style={{ cursor: "pointer" }}
              align="center"
            >
              $0
            </Typography>
          ) : (
            <>
              <Typography
                variant={"h5"}
                fontWeight={700}
                color="#1A1C20"
                fontSize={45}
                style={{ cursor: "pointer" }}
                align="center"
              >
                ${splittedBalance[0]}.
              </Typography>
              <Typography
                variant={"h5"}
                fontWeight={700}
                color="rgba(26, 28, 32, 0.5)"
                fontSize={25}
                style={{ cursor: "pointer" }}
                align="center"
                mr={1}
              >
                {splittedBalance[1] ? splittedBalance[1].substring(0, 2) : "00"}
              </Typography>
            </>
          )}
        </Box>
        <Box flexGrow={1}>
          <Chart
            {...props}
            chartData={chartData}
            chartTimestamps={chartTimestamps}
            chartLoader={chartLoader}
            // {...props}
          />
        </Box>
        <Box p={2.5} display="flex" justifyContent="center">
          <Box mr={7.5}>
            <ChipButton
              fontWeight={700}
              title="1H"
              disabled={interval != Interval.HOUR}
              onClick={() => {
                onChangeInterval(Interval.HOUR);
              }}
              paddingInline={"10px"}
              width="20px"
            />
          </Box>
          <Box mr={7.5}>
            <ChipButton
              fontWeight={700}
              title="1D"
              disabled={interval != Interval.DAY}
              onClick={() => {
                onChangeInterval(Interval.DAY);
              }}
              paddingInline={"10px"}
              width="20px"
            />
          </Box>
          <Box mr={7.5}>
            <ChipButton
              fontWeight={700}
              title="1W"
              disabled={interval != Interval.WEEK}
              onClick={() => {
                onChangeInterval(Interval.WEEK);
              }}
              paddingInline={"10px"}
              width="20px"
            />
          </Box>
          <Box>
            <ChipButton
              fontWeight={700}
              title="1M"
              disabled={interval != Interval.MONTH}
              onClick={() => {
                onChangeInterval(Interval.MONTH);
              }}
              paddingInline={"10px"}
              width="20px"
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

const ExpandableChart: FC<ChartProps> = ({
  chartData,
  chartTimestamps,
  title,
  amount,
  percentageChange,
  chartColor = LIGHT_GREEN_COLOR,
  chartStrokeColor = DARK_GREEN_COLOR,
  expandedHeight = "85vh",
  isCryptoScreen = false,
  isNFTScreen = false,
  chartLoader,
}) => {
  isCryptoScreen &&
    console.log("🚀 ~ file: index.tsx:389 ~ chartData:", chartData);
  const [isExpanded, setIsExpanded] = useState(false);

  const chartProps = {
    isExpanded,
    setIsExpanded,
    chartData,
    chartTimestamps,
    title,
    amount,
    percentageChange,
    chartColor,
    chartStrokeColor,
    isCryptoScreen,
    chartLoader,
  };

  const commonStyles = {
    cursor: "pointer",
    // transition: "width 0.3s ease-out, height 0.3s ease-out",
  };

  return (
    <>
      {chartData?.length === 0 ? (
        <CardLayout
          backgroundColor="white"
          style={{
            height: "205px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <NoDataUI
            title="No assets yet!"
            description="Deposit or receive to get started"
            icon={isNFTScreen ? NoNFTIcon : NoAssetIcon}
          />
        </CardLayout>
      ) : (
        <CardLayout
          backgroundColor="white"
          style={
            isExpanded
              ? {
                  ...commonStyles,
                  position: "absolute",
                  top: 20,
                  left: 0,
                  width: "100%",
                  height: expandedHeight,
                  zIndex: 1,
                }
              : {
                  ...commonStyles,
                  height: 205,
                  overflow: "hidden",
                }
          }
        >
          {isExpanded ? (
            <ExpandedChart {...chartProps} />
          ) : (
            <Chart {...chartProps} />
          )}
        </CardLayout>
      )}
    </>
  );
};

export default ExpandableChart;
