import React, { FC, useEffect, useState } from "react";
import { Grid, Box, Typography } from "@mui/material";
import NFTCard from "../NFTCard";
import CenterItem from "../../../../components/CenterItem";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../../store/store";
import { Link } from "react-router-dom";

// import { NFTDetailsType } from "../../../../interfaces"; // Uncomment if you're using this type

const NFTCollectionList: FC<{ searchText?: string }> = ({
  searchText = "",
}) => {
  const { activeAccount, newNFTs } = useAppSelector((state) => state.app);
  const { NftColName } = useParams<{ NftColName: string }>();

  // Since newNFTs structure and activeAccount.smartAccountAddress existence isn't clear,
  // it's assumed they're always present and correctly structured for this example.
  const allNFTsByCollection = newNFTs[activeAccount.smartAccountAddress].filter(
    (item) => item.name === NftColName
  );

  // State for filtered NFTs
  const [filteredNFTs, setFilteredNFTs] = useState(allNFTsByCollection);
  console.log("file: index.tsx:23  filteredNFTs:", filteredNFTs);

  // Effect to handle filtering whenever the search text or the collection changes
  useEffect(() => {
    if (searchText) {
      // If there's search text, we want to filter by it
      const searchLower = searchText.toLowerCase();
      const results = allNFTsByCollection.filter((nft) =>
        nft.name.toLowerCase().includes(searchLower)
      );
      setFilteredNFTs(results);
    } else {
      // If there's no search text, all NFTs in the collection are relevant
      setFilteredNFTs(allNFTsByCollection);
    }
  }, [searchText, allNFTsByCollection]);

  // Rendering
  if (filteredNFTs.length > 0) {
    return (
      <Grid container spacing={2}>
        {filteredNFTs.length > 0 ? (
          filteredNFTs.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <Link
                to={`${item.token_id}/${item.blockchain}`}
                style={{ textDecoration: "none" }}
              >
                <NFTCard
                  name={item.name}
                  imageSrc={
                    item?.metadata?.image ??
                    "https://thientu.vn/userfiles/files/nyan-cat-game%204.gif"
                  }
                  nftId={item.token_id}
                  chainName={item.blockchain}
                />
              </Link>
            </Grid>
          ))
        ) : (
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 2, // you can change this value
            }}
          >
            <Typography variant="subtitle1">No results found</Typography>
          </Grid>
        )}
      </Grid>
    );
  }

  return (
    <CenterItem>
      <Box>No NFT Found</Box>
    </CenterItem>
  );
};

export default NFTCollectionList;
