import { Grid, Typography } from "@mui/material";
import React, { FC, useEffect } from "react";
import { SUPPORTED_NETWORKS } from "constants/chains";
import { formatAmount, numFormatter } from "utils/utils";
import TransactionCollapeWrapper from "components/TransactionCollapse";
import TokenImage from "components/TokenImage";
import TransactionMetaData from "./TransactionMetadata";
import CashIcon from "assets/cash-token.svg";
import { useAppSelector } from "store/store";

const TransactionList: FC<{
  props: {
    tx: any;
    // title: string;
    // id: string;
    // actualValue: string;
    // belowValue: string;
    // status: string;
    // time: string;
    // imgSrc: string;
  };
}> = ({ props }) => {
  const { tx } = props;
  const { cashExchangeTxs } = useAppSelector((state) => state.app);
  console.log("file: index.tsx:12  asdasdasdtx:", tx.valueIn$);

  const { amount } = numFormatter(tx.valueIn$ ?? "", 8);

  const { amount: formattedAmount } = numFormatter(tx.amount ?? "", 4);

  const date = new Date(tx.timestamp);

  const options: any = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  const timeFormatter = new Intl.DateTimeFormat("en-US", options);
  const formattedTime = timeFormatter.format(date);

  const chainName =
    SUPPORTED_NETWORKS[tx.network as keyof typeof SUPPORTED_NETWORKS]?.name;

  return (
    <TransactionCollapeWrapper
      TitleComponent={
        <Grid
          sx={{
            padding: "15px 15px",
            display: "flex",

            width: "100%",
            justifyContent: "space-between",
            cursor: "pointer",
            borderRadius: "10px",
          }}
          //   onClick={() => {
          //     navigate(asset.asset.name, { state: { asset } });
          //   }}
        >
          <Grid
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <img src={CashIcon} style={{ marginRight: -10 }} />
            <Grid
              sx={{
                color: "#1A1C20",
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                fontFamily: "Space Grotesk",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "normal",
              }}
            >
              <div>
                {cashExchangeTxs.includes(tx.hash)
                  ? "Exchanged"
                  : tx.type.toLowerCase() === "buy"
                  ? "Received"
                  : "Sent"}{" "}
                {tx.asset.name.toLowerCase() === "usd coin"
                  ? "Cash"
                  : tx.asset.name}
              </div>
              <div
                style={{
                  // color: "rgba(26, 28, 32, 0.50)",
                  fontFamily: "Helvetica Neue",
                  fontSize: "14px",
                  lineHeight: "normal",
                }}
              >
                <Typography
                  fontWeight={400}
                  fontSize={14}
                  style={{
                    color: "rgba(26, 28, 32, 0.50)",
                  }}
                >
                  {formattedTime}
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <Grid
              sx={{
                color: "#1A1C20",
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                fontFamily: "Space Grotesk",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "normal",
                textAlign: "right",
              }}
            >
              {/* <div
                style={{
                  // color: "rgba(26, 28, 32, 0.50)",
                  fontFamily: "Helvetica Neue",
                  fontSize: "17px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "normal",
                }}
              >
                - ${formatAmount(tx.amount)}
              </div> */}
              <Typography
                fontWeight={400}
                fontSize={14}
                style={{
                  color: "rgba(26, 28, 32, 0.50)",
                }}
              >
                ${formattedAmount}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      }
      BodyComponent={
        <>
          <TransactionMetaData
            props={{
              chain: "Base" ?? "",
              date: tx.timestamp ?? "Time",
              network: tx.network ?? "",
              status: "Successful",
              from: tx.from,
              toEns: tx.to,
              networkFee: `$${tx.networkFeesIn$}`,
              scanUrl: `https://basescan.org/tx/${tx.hash}`,
              type: tx.type,
              hash: tx.hash,
            }}
          />
        </>
      }
    />
  );
};
export default TransactionList;
