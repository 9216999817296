import React, { FC } from "react";

import "./styles.css";
import { Typography } from "@mui/material";
import Disconnect from "assets/Disconnect.svg";

const TabChip: FC<{
  title: string;
  onClick: () => void;
  mode: string;
  isShowStartIcon?: boolean;
}> = ({ title, onClick, mode, isShowStartIcon = false }) => {
  return mode === "chip" ? (
    <div className="tab-chip-box" onClick={onClick}>
      {isShowStartIcon && (
        <img
          alt="DisconnectImage"
          src={Disconnect}
          width={15}
          height={15}
          style={{ marginRight: "5px" }}
        />
      )}
      <Typography variant={"h5"} fontWeight={500} color="white" fontSize={12}>
        {title}
      </Typography>
    </div>
  ) : (
    <div className="tab-chip-text" onClick={onClick}>
      <Typography
        variant={"h5"}
        fontWeight={500}
        color="rgba(26, 28, 32, 0.5)"
        fontSize={12}
        style={{ cursor: "pointer" }}
      >
        {title}
      </Typography>
    </div>
  );
};

export default TabChip;
