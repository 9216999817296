import React, { useEffect, useState } from "react";

// import StashedCreatedLogo from 'assets/stash-created.png'
import StashedCreatedLogo from "assets/stash-created.svg";
import Lottie from "react-lottie";

import SuccessLogo from "assets/success.svg";

import "./styles.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import Button from "components/NewButton";
import AuthHeader from "components/AuthHeader";
import { useAppDispatch, useAppSelector } from "store/store";
import { setIsAccountCreated } from "@slices/appSlice";
import TxStatusCompleteAnimation from "../../../../animations/tx-status-complete.json";
import { Box } from "@mui/system";
import CustomizedSteppers from "components/Stepper";
import { stepsCount } from "../../../../../src/constants";
import StepperForPages from "components/Stepper/StepperForPages";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: TxStatusCompleteAnimation,
  renderer: "svg",
};

const AccountCreated = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const location = useLocation();

  const { state } = location || {};
  const { currentStep, pathName } = state || {};

  const [step, setStep] = useState(parseInt(currentStep) || 0);

  const {
    user: { email },
  } = useAppSelector((state) => state.app);

  useEffect(() => {
    if (!email) {
      navigate("/create-account");
    }
  }, []);

  const handleAccountCreate = () => {
    setLoading(true);
    dispatch(setIsAccountCreated(true));

    navigate("/");
  };

  const totalSteps = stepsCount[pathName] || 3;

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={8} className="auth-header-with-steps">
          <AuthHeader />
        </Grid>
        <Grid
          item
          xs={16}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box
            className="auth-header-steps"
            width={"50%"}
            style={{
              position: "absolute",
              top: "54px",
            }}
          >
            <StepperForPages
              step={step}
              steps={new Array(totalSteps).fill("")}
              changeStep={(selectedStep: number) => {
                //eg. if user is on step 3 he should be able to move at step 1 or 2 on clicking step icon
                if (selectedStep < step) {
                  setStep(selectedStep);
                  navigate(-1);
                }
              }}
            />
          </Box>
        </Grid>
      </Grid>

      <div
        className="create-account-box download-recovery-wrapper"
        style={{
          marginTop: "10px",
          alignItems: "center",
        }}
      >
        <img src={SuccessLogo} height={100} />
        <Typography
          variant="h2"
          sx={{
            margin: "20px 0px 8px 0px",
            fontWeight: "700",
          }}
        >
          Account Created
        </Typography>

        <Typography
          variant="body2"
          sx={{
            margin: "0px 0px 0px 0px",
            color: "rgba(26, 28, 32, 0.5)",
            fontWeight: "500",
            textAlign: "center",
            width: "25%",
            fontSize: "16px",
          }}
        >
          Your account has been successfully created
        </Typography>

        {/* <Lottie
          options={defaultOptions}
          height={400}
          style={{ position: "absolute", top: 100, left: 0 }}
        /> */}

        <img
          src={StashedCreatedLogo}
          style={{
            width: "auto",
            height: "250px",
            margin: "20px 0px 20px 0px",
          }}
        />
        <div className="welcome-box-container" style={{ top: 20 }}>
          <Button
            title="Continue"
            onClick={handleAccountCreate}
            loading={loading}
            disabled={false}
            style={{
              margin: 0,
              width: "370px",
              padding: "15px 0px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AccountCreated;
