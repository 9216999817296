import { Fade, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

interface ChildComponentProps {
  props: {
    title: string
    points: Array<string>
  }
}

const ReferralCard: React.FC<ChildComponentProps> = ({ props }) => {
  const { title, points } = props
  return (
    <Fade in={true} timeout={500} easing={'ease-in-out'}>
      <Box paddingY={2} paddingX={3}>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          gap={2}
          height='100%'
        >
          <Box display='flex' gap='10px' flexDirection='column'>
            <Typography fontWeight='bold' variant='h5'>
              {title}
            </Typography>
          </Box>
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='space-between'
            gap={1}
          >
            {points.map(point => (
              <Box display='flex' flexDirection='row' gap={1}>
                <CheckCircleIcon sx={{ color: '#3AD9A0' }} />
                <Typography color='#1A1C20' variant='body2'>
                  {point}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Fade>
  )
}

export default ReferralCard
