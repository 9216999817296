import React, { FC, ReactNode } from 'react'

import './styles.css'
import { Box, Fade } from '@mui/material'

import CashBackground from 'assets/backgrounds/cash.png'

interface CardLayoutProps {
  children: ReactNode
  backgroundColor: string
  className?: string
  style?: {}
}

const CardLayout: FC<CardLayoutProps> = ({
  children,
  backgroundColor,
  className,
  style,
}) => {
  return (
    <Fade in={true} timeout={500} easing={'ease-in-out'}>
      <Box
        className={`card-box ${className}`}
        style={{
          backgroundColor,
          ...style,
        }}
      >
        {children}
      </Box>
    </Fade>
  )
}

export default CardLayout
