import { SetStateAction, useContext, useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Web3Wallet } from "@walletconnect/web3wallet";

import ConnectedDappBox from "components/ConnectedDappBox";
import { SearchBox } from "components/Styled";
import { useAppDispatch, useAppSelector } from "store/store";
import SearchIcon from "assets/SearchInputIcon.svg";
import { Core } from "@walletconnect/core";
import { getSdkError } from "@walletconnect/utils";
import SettingsStore from "../../../walletConnectStore/SettingsStore";
import { useSnapshot } from "valtio";
import ModalStore from "../../../walletConnectStore/ModalStore";
import { setFilteredDappsList } from "@slices/appSlice";
import { SocketContext } from "../../../socketContext";

const DappConnection = () => {
  const { open, view } = useSnapshot(ModalStore.state);
  const { activeAccount, connectedDapps, connectedDapp2, dappsList } =
    useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();
  const [pairings, setPairings] = useState<any[]>([]);

  const [value, setValue] = useState("");
  const [toggle, setToggle] = useState(false);

  const [filteredDapps, setFilteredDapps] = useState<any[]>([]);
  const { socket } = useContext(SocketContext);

  console.log("connectedDapp2", connectedDapp2);

  useEffect(() => {
    console.log("connectedDapp2 changed!", connectedDapp2);
  }, [connectedDapp2]);

  // async function onDelete(topic: string, expiry: number) {
  //   const core = new Core({
  //     projectId: "02bca0404e214292df5b3fd124b82bca",
  //   });

  //   const web3wallet = await Web3Wallet.init({
  //     core, // <- pass the shared `core` instance
  //     metadata: {
  //       name: "Demo app",
  //       description: "Demo Client as Wallet/Peer",
  //       url: "www.walletconnect.com",
  //       icons: [],
  //     },
  //   });

  //   await web3wallet.disconnectSession({
  //     topic,
  //     reason: getSdkError("USER_DISCONNECTED"),
  //   });

  //   const newPairings = pairings.filter((pairing) => pairing?.topic !== topic);

  //   console.log("newPairings", newPairings);

  //   setPairings(newPairings);
  // }

  async function onDelete(topic: any) {
    // Assuming 'pairings' is an array of pairing objects available in your scope
    // and 'getSdkError' is a function that returns error messages
    ModalStore.open("SessionDisconnectModal", {
      payload: {},
    });
    const core = new Core({
      projectId: "812f6740ea51715efe85aa7929220db1",
      // projectId: "02bca0404e214292df5b3fd124b82bca",
    });

    const web3wallet = await Web3Wallet.init({
      core, // pass the shared `core` instance
      metadata: {
        name: "Demo app",
        description: "Demo Client as Wallet/Peer",
        url: "www.walletconnect.com",
        icons: [],
      },
    });

    // Find the matching pairing
    const matchingPairing = pairings.find((pair) => pair.topic === topic);
    // if (!matchingPairing) {
    //   console.error("No matching pairing found");
    //   return;
    // }

    const nameToMatch = matchingPairing?.peerMetadata.name;

    // Filter out all pairings with the same name
    const pairingsToDelete = pairings.filter(
      (pair) => pair.peerMetadata?.name === nameToMatch
    );

    // Iterate over each pairing to disconnect and remove
    for (const pairing of pairingsToDelete) {
      await web3wallet.disconnectSession({
        topic: pairing.topic,
        reason: getSdkError("USER_DISCONNECTED"),
      });

      // Assuming setPairings is a function to update the pairings state
      setPairings((prevPairings) =>
        prevPairings.filter((p) => p.topic !== pairing.topic)
      );
    }

    // Optionally, log the remaining pairings
    const updatedPairings = await web3wallet.core.pairing.getPairings();
    console.log("Remaining pairings:", updatedPairings);
    const sessions = web3wallet.engine.signClient.session.values;
    const matchingSession = sessions.find(
      (session) => session.pairingTopic === topic
    );
    if (!matchingSession) {
      console.error("No matching session found for the provided pairingTopic");
      return;
    }

    // Use the topic from the matching session in the disconnectSession call
    await web3wallet.disconnectSession({
      topic: matchingSession.topic,
      reason: getSdkError("USER_DISCONNECTED"),
    });
    // Assuming you have a toggle state to trigger a re-render or update
    setToggle((toggle) => !toggle);

    console.log("filteredDapps1 just above above in delete", filteredDapps);
    const filteredDapps1 = filteredDapps.filter((dapp) => dapp.topic !== topic);
    console.log("filteredDapps1 just above in delete in comp", {
      filteredDapps1,
      topic,
    });
    dispatch(setFilteredDappsList(filteredDapps1));
  }

  // useEffect(() => {
  //   (async () => {
  //     const core = new Core({
  //       projectId: "02bca0404e214292df5b3fd124b82bca",
  //     });

  //     const web3wallet = await Web3Wallet.init({
  //       core, // <- pass the shared `core` instance
  //       metadata: {
  //         name: "Demo app",
  //         description: "Demo Client as Wallet/Peer",
  //         url: "www.walletconnect.com",
  //         icons: [],
  //       },
  //     });
  //     let pairings = web3wallet.core.pairing.getPairings();
  //     // New logic starts here
  //     // First, we sort by expiry so that the latest comes last in 'pairings' array
  //     pairings.sort(
  //       (a: { expiry: number }, b: { expiry: number }) => b.expiry - a.expiry
  //     );

  //     // Object to keep track of names we've seen
  //     const seen: any = {};

  //     // Filter pairings, removing earlier instances of duplicates
  //     const filteredPairings = pairings.filter((pairing: any) => {
  //       if (!pairing || !pairing.peerMetadata) {
  //         // Skip this iteration if pairing or pairing.peerMetadata is undefined
  //         return false;
  //       }

  //       const duplicate = seen.hasOwnProperty(pairing.peerMetadata.name);
  //       seen[pairing.peerMetadata.name] = true; // Mark this name as "seen"
  //       return !duplicate; // If it's a duplicate, this will be false and filter it out
  //     });

  //     console.log("filteredPairings", filteredPairings);

  //     setPairings(filteredPairings);

  //     // setPairings(pairings);
  //   })();
  // }, [view]);
  // // Effect for initializing dapps
  // useEffect(() => {
  //   if (connectedDapps.length > 0) {
  //     console.log("connectedDapps", connectedDapps);
  //     // setDapps(connectedDapps);
  //     setFilteredDapps(pairings); // Initialize with the full list
  //   }
  // }, [pairings]);

  // useEffect(() => {
  //   // Filter dapps list based on value
  //   const searchResults = pairings.filter((dapp) =>
  //     dapp?.peerMetadata?.name?.toLowerCase().includes(value.toLowerCase())
  //   );
  //   setFilteredDapps(searchResults);
  // }, [value, pairings, connectedDapps]);

  useEffect(() => {
    (async () => {
      // const core = new Core({
      const dapps = [...dappsList];
      const sortedDappsList = dapps.sort((a, b) => b.timeStamp - a.timeStamp);

      const uniqueDapps: SetStateAction<any[]> = [];
      const seenNames = new Set();

      sortedDappsList.forEach((dapp) => {
        if (!seenNames.has(dapp.name)) {
          uniqueDapps.push(dapp);
          seenNames.add(dapp.name);
        }
      });

      setFilteredDapps(uniqueDapps);
    })();
  }, [view, toggle, dappsList]);

  useEffect(() => {
    // Filter dapps list based on value
    const searchResults = dappsList.filter((dapp) =>
      dapp?.name?.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredDapps(searchResults);
  }, [value, pairings]);
  console.log("filteredDapps", filteredDapps);
  return (
    <div className="main-view2">
      <Box
        sx={{
          paddingTop: "20px",
          paddingBottom: "20px",
          paddingLeft: "60px",
          display: "flex",
          justifyContent: "space-between",

          alignItems: "center",
        }}
      >
        <Typography
          variant="h5"
          color="#1A1C20"
          fontSize="25px"
          fontWeight={700}
          fontFamily={"Space Grotesk"}
        >
          Applications
        </Typography>
        <SearchBox
          placeholder="Search by name or category"
          onChange={(e: { target: { value: string } }) => {
            setValue(e.target.value.trim());
          }}
          value={value}
          startAdornment={
            <Grid
              sx={{
                minHeight: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={SearchIcon}
                style={{
                  // background: "red",
                  height: "18px",
                  width: "18px",
                }}
              />
            </Grid>
          }
        />
      </Box>

      <Grid container spacing={2} sx={{ padding: 5 }}>
        <Grid item xs={12} md={6} lg={4}>
          <ConnectedDappBox />
        </Grid>

        {filteredDapps.length > 0 &&
          filteredDapps.map((dapp) => (
            <Grid item xs={12} md={6} lg={4}>
              <ConnectedDappBox
                name={dapp?.name}
                image={dapp?.icons[1] || dapp?.icons[0]}
                description={dapp?.description}
                onDisconnect={() => {
                  console.log("abc", dapp);
                  onDelete(dapp?.topic);
                }}
              />
            </Grid>
          ))}

        {connectedDapp2.length > 0 &&
          connectedDapp2.map((dapp) => (
            <Grid item xs={12} md={6} lg={4}>
              <ConnectedDappBox
                name={dapp?.name}
                image={dapp?.icons[1] || dapp?.icons[0]}
                description={dapp?.description}
                onDisconnect={() => {
                  // onDelete(dapp?.topic, dapp.expiry)
                  console.log("delete this dapp =>", dapp);
                  socket?.emit("disconnect-from-wallet", {
                    dappId: dapp._id,
                    address: activeAccount.smartAccountAddress,
                  });
                }}
              />
            </Grid>
          ))}
      </Grid>
    </div>
  );
};

export default DappConnection;
