import styled from "@emotion/styled";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { NftPreviewCard } from "components/NftPreviewCard";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const CollectibleWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-evenly",
  width: "98%",
  borderRadius: "16px",
  backgroundColor: "#ffffff",
  boxShadow: "0px 15px 30px rgba(118, 118, 118, 0.2)",
  marginBottom: "30px",
  padding: "20px",
});

const HeaderRow = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  width: "98%",
  paddingBottom: 20,
});

const NftRow = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  width: "98%",
  flexWrap: "wrap",
});

const SeeAllBox = styled(Box)({
  width: "88px",
  height: "34px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: "0.75px solid #6A27EA",
  backgroundColor: "#F1EAFF",
  borderRadius: "7px",
  cursor: "pointer",
});

export const Collectible = ({
  collectionName,
  collectionAddress,
  totalNfts,
  nfts,
}) => {
  return (
    <CollectibleWrapper>
      <HeaderRow>
        <Typography variant='subtitle2' fontWeight='500' color={"black"}>
          {collectionName}
        </Typography>

        <SeeAllBox>
          <Typography variant='fs12' fontWeight='500'>
            {totalNfts}
          </Typography>
        </SeeAllBox>
      </HeaderRow>
      <NftRow>
        {nfts.map((nft, index) => {
          if (index <= nfts.length - 1) {
            return (
              <NftPreviewCard
                image={nft.image}
                collectionName={collectionName}
                tokenId={nft.tokenId}
                nftAddress={nft.nftAddress}
                status={nft.status}
                key={nft.tokenId}
              />
            );
          }
        })}
      </NftRow>
    </CollectibleWrapper>
  );
};
