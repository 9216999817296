import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import BasicButton from 'components/Button'
import React, { useEffect, useState } from 'react'

import Bitcoin from 'assets/bitcoin.svg'
import './index.css'
import { fetchTxHistory } from 'utils/txhistory'
import { useAppSelector } from 'store/store'
import { SUPPORTED_NETWORKS } from 'constants/chains'

const History = () => {
  const [sentTxHistory, setSentTxHistory] = useState([])
  const [receiveTxHistory, setReceiveTxHistory] = useState([])
  const [mode, setMode] = useState('sent')

  const { activeAccount, activeNetwork, holdings } = useAppSelector(
    state => state.app
  )

  const { txStatus } = useAppSelector(state => state.wallet)

  const url =
    SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS]
      .block_explorer_url

  useEffect(() => {
    ;(async () => {
      const { receivedTxs, sentTxs } = await fetchTxHistory(
        activeAccount.smartAccountAddress,
        activeNetwork
      )
      setSentTxHistory(sentTxs.sort((a, b) => b.timeStamp - a.timeStamp))
      setReceiveTxHistory(receivedTxs.sort((a, b) => b.timeStamp - a.timeStamp))
    })()
  }, [holdings, txStatus])

  return (
    <div className='history'>
      <Typography variant='h4' style={{ marginBottom: 20 }}>
        Recent History
      </Typography>
      <div style={{ display: 'flex' }}>
        <BasicButton
          title='Sent'
          onClick={() => setMode('sent')}
          className={`history-btn ${mode === 'sent' && 'active-btn'}`}
        />
        <BasicButton
          title='Received'
          onClick={() => setMode('receive')}
          className={`history-btn ${mode === 'receive' && 'active-btn'}`}
        />
      </div>

      <TableContainer className='table-box'>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Asset</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Time</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Amount</TableCell>
              <TableCell style={{ textAlign: 'center' }}>TxID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(mode === 'sent' ? sentTxHistory : receiveTxHistory).map(
              (row: any, index) => (
                <TableRow
                  key={index}
                  // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    <div style={{ display: 'flex' }}>
                      <img src={row.icon} width={30} />
                      <div style={{ marginLeft: 5 }}>
                        <Typography variant='body1' className='primary-text'>
                          {row.tokenName}
                        </Typography>
                        <Typography variant='body2' className='secondary-text'>
                          {row.type}
                        </Typography>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div>
                      <Typography
                        variant='body1'
                        className='primary-text'
                        style={{ textAlign: 'center' }}
                      >
                        {row.date}
                      </Typography>
                      <Typography
                        variant='body2'
                        style={{
                          textAlign: 'center',
                          color: '#798BA3',
                          fontSize: 12,
                        }}
                      >
                        at {row.time}
                      </Typography>
                      {/* <Typography variant='body2' className='secondary-text'>
                      {row.time}
                    </Typography> */}
                    </div>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant='body1'
                      className='primary-text'
                      style={{ textAlign: 'center' }}
                    >
                      {row.amount}
                    </Typography>
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    <div className='view'>
                      <a href={`${url}/tx/${row.hash}`} target='_blank'>
                        View
                      </a>
                    </div>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default History
