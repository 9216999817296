import React, { useState } from "react";
import QRCode from "react-qr-code";
import { Snackbar, Typography } from "@mui/material";
import WalletIcon from "assets/wallet.svg";
import InfoIcon from "assets/info.svg";
import EmberLogo from "assets/ember-receive.svg";

import "./index.css";
import BasicButton from "components/Button";
import { useAppSelector } from "store/store";
import { SUPPORTED_NETWORKS } from "constants/chains";

const Receive = () => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const { activeAccount, activeNetwork } = useAppSelector(state => state.app);

  const { icon } =
    SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS];

  const chainName =
    SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS]
      .chainName;
  const chainSymbol =
    SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS].symbol;

  const onCopy = () => {
    setShowSnackbar(true);
    navigator.clipboard.writeText(activeAccount.smartAccountAddress);
  };

  return (
    <>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        message='Address copied!'
      />
      <div className='receive-container'>
        <div className='receive-box'>
          <Typography>{chainName}</Typography>
          <Typography variant='body2'>{chainSymbol} Wallet</Typography>
        </div>
        <div className='receive-address-box'>
          <Typography>{chainSymbol} Address</Typography>
          <div className='account-info'>
            <img src={WalletIcon} />
            <Typography
              variant='body2'
              style={{ marginLeft: 10, marginTop: 5 }}
            >
              {activeAccount.smartAccountAddress}
            </Typography>
            <BasicButton title='Copy' onClick={onCopy} className='copy' />
          </div>
        </div>
        <Typography style={{ margin: "20px 0" }}></Typography>

        <div className='qr-box'>
          <Typography>Scan address to deposit payment</Typography>
          <div className='qr-container'>
            <div className='qr'>
              <QRCode value={activeAccount.smartAccountAddress} />
              <img src={icon} className='qr-img' />
            </div>
          </div>
        </div>
        <div className='receive-box info'>
          <div className='info-icon'>
            <img src={InfoIcon} />
          </div>
          <Typography variant='body2'>
            Please only send {chainName} Network tokens to this address
          </Typography>
        </div>
      </div>
    </>
  );
};

export default Receive;
