// import ProjectInfoCard from '@/components/ProjectInfoCard'

import { Box, Button, Divider, Typography } from "@mui/material";
import BasicButton from "../components/Button";
import ProjectInfoCard from "../components/ProjectInfoCard";
import RequestDataCard from "../components/RequestDataCard";
// import RequestDataCard from '@/../components/RequestDataCard'

import RequestDetailsCard from "../components/RequestDetailsCard";
// import RequesDetailsCard from '@/../components/RequestDetalilsCard'

import RequestMethodCard from "../components/RequestMethodCard";
// import RequestMethodCard from '@/../components/RequestMethodCard'

import RequestModalContainer from "../components/RequestModalContainer";
import EthIcon from "../assets/EthIcon.svg";
// import RequestModalContainer from '@/../components/RequestModalContainer'

// import ModalStore from '@/store/ModalStore'

// import { approveEIP155Request, rejectEIP155Request } from '@/utils/EIP155RequestHandlerUtil'

// import { getSignTypedDataParamsData, styledToast } from '@/utils/HelperUtil'

// import { signClient } from '@/utils/WalletConnectUtil'

// import { Button, Divider, Modal, Text } from '@nextui-org/react'
import { Fragment } from "react";
import {
  approveEIP155Request,
  rejectEIP155Request,
} from "../utils/EIP155RequestHandlerUtil";
import { getSignTypedDataParamsData, styledToast } from "../utils/HelperUtil";
import { signClient } from "../utils/WalletConnectUtil";
import ModalStore from "../walletConnectStore/ModalStore";

export default function SessionSignTypedDataModal() {
  // Get request and wallet data from store
  const requestEvent = ModalStore.state.data?.requestEvent;
  const requestSession = ModalStore.state.data?.requestSession;

  // Ensure request and wallet are defined
  if (!requestEvent || !requestSession) {
    return <p>Missing request data</p>;
  }

  // Get required request data
  const { topic, params } = requestEvent;
  const { request, chainId } = params;

  console.log("request: " + request);

  // Get data
  const data = getSignTypedDataParamsData(request.params);

  // Handle approve action (logic varies based on request method)
  async function onApprove() {
    if (requestEvent) {
      const response = await approveEIP155Request(requestEvent);
      try {
        await signClient.respond({
          topic,
          response,
        });
      } catch (e) {
        styledToast((e as Error).message, "error");
        return;
      }
      ModalStore.close();
    }
  }

  // Handle reject action
  async function onReject() {
    if (requestEvent) {
      const response = rejectEIP155Request(requestEvent);
      try {
        await signClient.respond({
          topic,
          response,
        });
      } catch (e) {
        styledToast((e as Error).message, "error");
        return;
      }
      ModalStore.close();
    }
  }

  return (
    <Box
      sx={{
        background: "white",
        overflowY: "scroll",
        width: "50%",
        height: "70%",
        transform: "translateX(50%) translateY(10%)",
        padding: "10px",
      }}
    >
      <RequestModalContainer title="Approval">
        {/* <ProjectInfoCard metadata={requestSession.peer.metadata} /> */}

        <Divider />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "330px",
            height: "140px",
            border: "0.5px solid #B2B2B2",
            margin: "0px auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "330px",
              height: "70px",
              alignItems: "center",
              paddingLeft: "10px",
              justifyContent: "flex-start",
            }}
            gap={2}
          >
            <Typography
              variant="caption"
              fontSize="8px"
              fontWeight={400}
              color="#67686C"
            >
              Sending
            </Typography>

            <img src={EthIcon} />

            <Typography
              variant="body1"
              fontSize="12px"
              fontWeight={500}
              color="#121212"
            >
              0.04900000 ETH
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "330px",
              height: "70px",
              alignItems: "center",
              paddingLeft: "10px",
              justifyContent: "flex-start",
            }}
            gap={2}
          >
            <Typography
              variant="caption"
              fontSize="8px"
              fontWeight={400}
              color="#67686C"
            >
              Sending
            </Typography>

            <img src={EthIcon} />

            <Typography
              variant="body1"
              fontSize="12px"
              fontWeight={500}
              color="#121212"
            >
              0.04900000 ETH
            </Typography>
          </Box>
        </Box>

        {/* <RequestDetailsCard
          chains={[chainId ?? ""]}
          protocol={requestSession.relay.protocol}
        />

        <Divider />

        <RequestDataCard data={data} /> */}

        {/* <Divider /> */}

        {/* <RequestMethodCard methods={[request.method]} /> */}
      </RequestModalContainer>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
        }}
      >
        <BasicButton
          title="Reject1"
          onClick={onReject}
          style={{ width: "120px", marginRight: "30px" }}
          id="account_box_open_new_account_modal"
        />
        <BasicButton
          title="Approve"
          onClick={onReject}
          style={{ width: "120px", marginRight: "30px" }}
          id="account_box_open_new_account_modal"
        />
        {/* <Button
          color="error"
          onClick={onReject}
          data-testid="request-button-reject"
        >
          Reject
        </Button>
        <Button
          color="success"
          onClick={onApprove}
          data-testid="request-button-approve"
        >
          Approve
        </Button> */}
      </div>
    </Box>
  );
}
