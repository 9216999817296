// import ProjectInfoCard from '@/components/ProjectInfoCard'

// import ProposalSelectSection from '@/components/ProposalSelectSection'

// import RequestModalContainer from '@/components/RequestModalContainer'
// import SessionProposalChainCard from '@/components/SessionProposalChainCard'

// import ModalStore from '@/store/ModalStore'

// import { cosmosAddresses } from '@/utils/CosmosWalletUtil'
// import { eip155Addresses } from '@/utils/EIP155WalletUtil'
import { eip155Addresses } from "../utils/EIP155WalletUtil";
// import { polkadotAddresses } from '@/utils/PolkadotWalletUtil'
// import { multiversxAddresses } from '@/utils/MultiversxWalletUtil'
// import { tronAddresses } from '@/utils/TronWalletUtil'
// import { tezosAddresses } from '@/utils/TezosWalletUtil'
// import {
//   isCosmosChain,
//   isEIP155Chain,
//   isSolanaChain,
//   isPolkadotChain,
//   isNearChain,
//   isMultiversxChain,
//   isTronChain,
//   isTezosChain,
//   isKadenaChain,
//   styledToast
// } from '@/utils/HelperUtil'
import { isEIP155Chain, styledToast, truncate } from "../utils/HelperUtil";

// import { solanaAddresses } from '@/utils/SolanaWalletUtil'
// import { signClient } from '@/utils/WalletConnectUtil'
import { signClient } from "../utils/WalletConnectUtil";

// import { Button, Divider, Modal, Text } from '@nextui-org/react'
import { SessionTypes } from "@walletconnect/types";
import { getSdkError, mergeArrays } from "@walletconnect/utils";
import { Fragment, useEffect, useState } from "react";
import ModalStore from "../walletConnectStore/ModalStore";
import SessionProposalChainCard from "../components/SessionProposalChainCard";
import RequestModalContainer from "../components/RequestModalContainer";
import ProposalSelectSection from "../components/ProposalSelectSelection";
import ProjectInfoCard from "../components/ProjectInfoCard";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "store/store";
import SuccessLogo from "assets/success.svg";
import {
  setConnectedDapps,
  setConnectedDapps2,
  setDappsList,
} from "@slices/appSlice";
// import { nearAddresses } from '@/utils/NearWalletUtil'
// import { kadenaAddresses } from '@/utils/KadenaWalletUtil'

export default function SessionProposalModal() {
  // const [selectedAccounts, setSelectedAccounts] = useState<
  //   Record<string, string[]>
  // >({});
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { activeAccount } = useAppSelector((state) => state.app);
  const selectedAccounts: Record<string, string[]> = {
    "required:eip155": [activeAccount.smartAccountAddress],
    "optional:eip155": [activeAccount.smartAccountAddress],
    // "required:eip155": ["0xDbfA076EDBFD4b37a86D1d7Ec552e3926021fB97"],
    // "optional:eip155": ["0xDbfA076EDBFD4b37a86D1d7Ec552e3926021fB97"],
  };
  const hasSelected = Object.keys(selectedAccounts).length;

  // Get proposal data and wallet address from store
  const proposal = ModalStore.state.data?.proposal;

  console.log("selectedAccounts", selectedAccounts);

  // Ensure proposal is defined
  if (!proposal) {
    return <p>Missing proposal data</p>;
  }

  // Get required proposal data
  const { id, params } = proposal;

  const {
    proposer,
    requiredNamespaces,
    optionalNamespaces,
    sessionProperties,
    relays,
    pairingTopic,
  } = params;

  // Add / remove address from EIP155 selection
  // function onSelectAccount(chain: string, account: string) {
  //   if (selectedAccounts[chain]?.includes(account)) {
  //     const newSelectedAccounts = selectedAccounts[chain]?.filter(
  //       (a) => a !== account
  //     );
  //     setSelectedAccounts((prev) => ({
  //       ...prev,
  //       [chain]: newSelectedAccounts,
  //     }));
  //   } else {
  //     const prevChainAddresses = selectedAccounts[chain] ?? [];
  //     setSelectedAccounts((prev) => ({
  //       ...prev,
  //       [chain]: [...prevChainAddresses, account],
  //     }));
  //   }
  // }

  // Hanlde approve action, construct session namespace
  async function onApprove() {
    setLoading(true);
    if (proposal) {
      let namespaces: SessionTypes.Namespaces = {};
      const selectedOptionalNamespaces = [];
      for (const [chain, account] of Object.entries(selectedAccounts)) {
        if (chain.includes("optional")) {
          selectedOptionalNamespaces.push(chain.split(":")[1]);
        }
      }

      Object.keys(requiredNamespaces)
        .concat(selectedOptionalNamespaces)
        .forEach((key) => {
          const accounts: string[] = [];
          if (requiredNamespaces[key] && requiredNamespaces[key]?.chains) {
            requiredNamespaces[key].chains?.map((chain) => {
              selectedAccounts[`required:${key}`].map((acc) =>
                accounts.push(`${chain}:${acc}`)
              );
            });
            namespaces[key] = {
              accounts,
              methods: requiredNamespaces[key].methods,
              events: requiredNamespaces[key].events,
              chains: requiredNamespaces[key].chains,
            };
          }
          if (optionalNamespaces[key] && selectedAccounts[`optional:${key}`]) {
            optionalNamespaces[key].chains?.map((chain) => {
              selectedAccounts[`optional:${key}`].forEach((acc) => {
                if (!accounts.includes(`${chain}:${acc}`)) {
                  accounts.push(`${chain}:${acc}`);
                }
              });
            });
            namespaces[key] = {
              ...namespaces[key],
              accounts,
              methods: mergeArrays(
                namespaces[key].methods,
                optionalNamespaces[key].methods
              ),
              events: mergeArrays(
                namespaces[key].events,
                optionalNamespaces[key].events
              ),
              chains: mergeArrays(
                namespaces[key].chains,
                optionalNamespaces[key].chains
              ),
            };
          }
        });

      console.log("approving namespaces:", namespaces);

      try {
        await signClient.approve({
          id,
          relayProtocol: relays[0].protocol,
          namespaces,
        });
        console.log(
          "🚀 ~ file: SessionProposalModal.tsx:179 ~ onApprove ~ proposer.metadata:",
          proposer.metadata
        );
        const metadata = await proposer.metadata;
        console.log(
          "🚀 ~ file: SessionProposalModal.tsx:183 ~ onApprove ~ metadata:",
          metadata
        );
        const realObject = JSON.parse(JSON.stringify(metadata));
        console.log(
          "🚀 ~ file: SessionProposalModal.tsx:188 ~ onApprove ~ realObject:",
          realObject,
          pairingTopic
        );

        dispatch(
          setDappsList({
            ...realObject,
            topic: pairingTopic,
            timeStamp: Date.now(),
          })
        );
        dispatch(setConnectedDapps(realObject));
        // dispatch(setConnectedDapps2(realObject));
      } catch (e) {
        styledToast((e as Error).message, "error");
        return;
      }
    }
    setLoading(false);
    setTimeout(() => {
      ModalStore.close();
    }, 5000);
  }

  console.log("loading", loading);

  useEffect(() => {
    if (proposal && hasSelected) {
      onApprove();
      console.log("ONAPPROVE CHALGAYA");
    }
  }, [hasSelected, proposal]);

  // Hanlde reject action
  async function onReject() {
    if (proposal) {
      try {
        await signClient.reject({
          id,
          reason: getSdkError("USER_REJECTED_METHODS"),
        });
      } catch (e) {
        styledToast((e as Error).message, "error");
        return;
      }
    }
    ModalStore.close();
  }

  console.log("proposal metadata", proposer.metadata);

  return (
    <Box
      sx={{
        background: "white",
        overflowY: "scroll",
        width: "40%",
        height: "60%",
        // transform: "translateX(80%) translateY(20%)",
        padding: "10px",
        borderRadius: "4px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // opacity: "0",
      }}
    >
      {!loading ? (
        <Stack
          gap={2}
          sx={{ marginTop: "25px" }}
          direction="column"
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Typography
            variant="h4"
            color="#121212"
            fontSize="22px"
            fontWeight={600}
          >
            @{activeAccount?.accountName}
          </Typography>

          <Typography
            variant="body1"
            color="#67686C"
            fontSize="20px"
            fontWeight={400}
          >
            {truncate(activeAccount?.smartAccountAddress, 16)}
          </Typography>

          <img
            src={SuccessLogo}
            style={{ marginTop: "20px", marginBottom: "20px" }}
          />

          <Typography
            variant="h4"
            color="#121212"
            fontSize="22px"
            fontWeight={600}
          >
            Connection Successful
          </Typography>

          <Typography
            variant="body1"
            color="#121212"
            fontSize="20px"
            fontWeight={500}
          >
            Your now connected to
          </Typography>

          <Stack
            gap={1}
            direction="row"
            alignItems={"center"}
            justifyContent={"center"}
          >
            <img
              src={proposer?.metadata?.icons[1] || proposer?.metadata?.icons[0]}
              style={{ width: "35px", height: "35px", borderRadius: "35px" }}
            />
            <Typography
              variant="body1"
              color="#121212"
              fontSize="20px"
              fontWeight={500}
            >
              {proposer?.metadata?.name}
            </Typography>
          </Stack>
          {/* <RequestModalContainer title="Session Proposal">
        <ProjectInfoCard metadata={proposer.metadata} />

        <Divider />
        {Object.keys(requiredNamespaces).length != 0 ? (
          <h4>Required Namespaces</h4>
        ) : null}
        {/* {Object.keys(requiredNamespaces).map((chain) => {
          return (
            <Fragment key={chain}>
              <p
                style={{ marginBottom: "5px" }}
              >{`Review ${chain} permissions`}</p>
              <SessionProposalChainCard
                requiredNamespace={requiredNamespaces[chain]}
                data-testid={`session-proposal-card-req-${chain}`}
              />
              {renderAccountSelection(`required:${chain}`, true)}
              <Divider />
            </Fragment>
          );
        })} 
        {optionalNamespaces && Object.keys(optionalNamespaces).length != 0 ? (
          <h4>Optional Namespaces</h4>
        ) : null}
        {optionalNamespaces &&
          Object.keys(optionalNamespaces).length != 0 &&
          Object.keys(optionalNamespaces).map((chain) => {
            return (
              <Fragment key={chain}>
                <p
                  style={{ marginBottom: "5px" }}
                >{`Review ${chain} permissions`}</p>
                <SessionProposalChainCard
                  requiredNamespace={optionalNamespaces[chain]}
                  data-testid={`session-proposal-card-opt-${chain}`}
                />
                {renderAccountSelection(`optional:${chain}`, false)}
                <Divider />
              </Fragment>
            );
          })}
      </RequestModalContainer> */}

          {/* <div>
        <Button
          color="error"
          onClick={onReject}
          data-testid="session-reject-button"
        >
          Reject
        </Button>

        <Button
          color="success"
          onClick={onApprove}
          disabled={!hasSelected}
          sx={{ opacity: hasSelected ? 1 : 0.4 }}
          data-testid="session-approve-button"
        >
          Approve
        </Button>
      </div> */}
        </Stack>
      ) : (
        <Stack
          gap={2}
          sx={{ marginTop: "25px" }}
          direction="column"
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Typography
            variant="h4"
            color="#121212"
            fontSize="22px"
            fontWeight={600}
          >
            Connecting...
          </Typography>
          <CircularProgress />
        </Stack>
      )}
    </Box>
  );
}
