import React, { FC } from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
const FeeUIComponent: FC<{ gasFeeInUSD: string }> = ({ gasFeeInUSD }) => {
  return (
    <Box sx={{ width: "100%", marginY: "10px" }}>
      <Box
        sx={{
          background: "rgba(91, 194, 65, 0.05)",
          paddingY: "10px",
          paddingX: "12px",
          borderRadius: "10px",
          // marginTop: "-10px",
          width: "fit-content",
          display: "flex",
          marginLeft: "auto",
        }}
      >
        <Typography
          textAlign={"right"}
          color={"rgba(91, 194, 65, 1)"}
          fontWeight={600}
          fontFamily={"Space Grotesk"}
          fontSize={"12px"}
        >
          Fees ${gasFeeInUSD}
        </Typography>
      </Box>
    </Box>
  );
};

export default FeeUIComponent;
