import React, { FC, ReactNode, useEffect, useState } from "react";
import { useAppSelector } from "../../store/store";
import { readAccountHoldings } from "../../utils/holdings";
import { tokensForGas } from "../../constants/topTokensConf";
import {
  CircularProgress,
  Fade,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import TokenImage from "../TokenImage";
import { getAssetsDollarWorth } from "../../utils/portfolio";
import millify from "millify";
import { truncateToken } from "utils/utils";

const GasTokenSelect: FC<{
  selectedDepositTokenAddress: string;
  handleDepositTokenChange: (
    event: SelectChangeEvent<string>,
    child: ReactNode
  ) => void;
}> = ({ selectedDepositTokenAddress, handleDepositTokenChange }) => {
  const [depositableTokens, setDepositableTokens] = useState<Array<any>>([]);
  const [loader, setLoader] = useState<boolean>(false);

  const {
    activeAccount,
    holdings,
    activeNetwork,
    accounts,
    portfolio,
    rootAccountInfo,
    userSpendingDetails,
  } = useAppSelector((state) => state.app);
  console.log(
    "file: index.tsx:104  Send  activeAasdasccount:",
    activeAccount,
    rootAccountInfo,
    accounts
  );

  async function fetchBalances() {
    setLoader(true);
    const allAccountsAddress = Object.keys(accounts);

    let tokenAddressesForMobula = tokensForGas.map(
      (tokenInfo) => tokenInfo.tokenAddressForMobula
    );

    // Call the getAssetsDollarWorth function with the tokenAddressesForMobula array as the argument
    const tokenData = await getAssetsDollarWorth(tokenAddressesForMobula);

    // Extract the prices from the returned object
    let tokenWorthInUSD = Object.values(tokenData).map(
      (token: any) => token.price
    );

    const firstAccountAddress =
      accounts[allAccountsAddress[0]].smartAccountAddress;
    console.log(
      "file: index.tsx:256  fetchBalances depositableTokens  firstAccountAddress:",
      firstAccountAddress
    );

    let depositedTokenObject: Array<any> = [];
    for (let i = 0; i < tokensForGas.length; i++) {
      const tokenInfo = tokensForGas[i];

      const { tokenAddress, rpc } = tokenInfo;
      console.log(
        "file: index.tsx:265  fetchBalances depositableTokensasas  tokenAddress:",
        tokenAddress
      );
      const balanceInWei = await readAccountHoldings(
        tokenAddress,
        firstAccountAddress,
        rpc
      );
      console.log(
        "file: index.tsx:271 depositableTokens fetchBalances  balance:",
        balanceInWei
      );

      const tokenWorthInUSDForCurrentToken = tokenWorthInUSD[i];
      const balance = balanceInWei / 10 ** tokenInfo.decimal;
      const balanceInUSD = balance * tokenWorthInUSDForCurrentToken;
      if (balanceInWei > 0) {
        depositedTokenObject.push({
          ...tokenInfo,
          balance,
          balanceInUSD,
        });
      }
    }
    setDepositableTokens(depositedTokenObject);
    setLoader(false);
  }

  useEffect(() => {
    fetchBalances();
  }, [accounts, activeAccount, activeNetwork]);

  const SELECT_TEXT = "Please select a token";

  return (
    <>
      {/* {depositableTokens.length > 0 && ( */}
      <Select
        value={
          selectedDepositTokenAddress.trim() == ""
            ? SELECT_TEXT
            : selectedDepositTokenAddress
        }
        onChange={handleDepositTokenChange}
        sx={{
          boxShadow: "none",
          ".MuiSelect-select": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingY: "10px",
          },
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: 0,
            },
          width: "100%",
          borderRadius: "10px",
          background: "#F7F7F7",
        }}
      >
        <MenuItem
          sx={{
            color: "#000000 !important",
          }}
          value={SELECT_TEXT}
          selected={false}
          disabled
        >
          {SELECT_TEXT}
        </MenuItem>{" "}
        {loader ? (
          <div style={{ width: "100%", textAlign: "center" }}>
            <CircularProgress color="inherit" />
          </div>
        ) : (
          depositableTokens?.map((option) => (
            <MenuItem
              className="token-select-item"
              key={option.tokenAddress}
              value={option.tokenAddress}
              sx={{
                alignItems: "center",
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  alignItems: "center",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <TokenImage logo={option.logoUrl} />
                <div
                  style={{
                    color: "#000000",
                    marginLeft: "10px",
                    lineHeight: "18px",
                  }}
                >
                  {truncateToken(option.token)}
                  <br />
                  <div
                    style={{
                      color: "#8C8D8F",
                      textAlign: "left",
                      fontFamily: "Helvetica Neue",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "20px",
                    }}
                  >
                    {option.balance < 0.0001
                      ? "< 0.0001"
                      : millify(option.balance, { precision: 4 })}{" "}
                    {option.symbol}
                  </div>
                  {/* <span
                  style={{
                    color: "#8C8D8F",
                    fontFamily: "Helvetica Neue",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "400",
                  }}
                >
                  {option.symbol}
                </span> */}
                </div>
              </div>
              <div style={{ lineHeight: "18px" }}>
                <div
                  style={{
                    color: "#1A1C20",
                    fontFamily: "Space Grotesk",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "700",
                    lineHeight: "normal",
                  }}
                >
                  {option.balanceInUSD
                    ? `$${millify(option.balanceInUSD, { precision: 4 })}`
                    : ""}
                </div>
                {/* <div
                style={{
                  color: "#8C8D8F",
                  textAlign: "right",
                  fontFamily: "Helvetica Neue",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "20px",
                }}
              >
                {millify(option.balance, { precision: 4 })} {option.symbol}
              </div> */}
              </div>
            </MenuItem>
          ))
        )}
      </Select>
      {/* )} */}
    </>
  );
};

export default GasTokenSelect;
