import { CurrencyAmount, Percent, Token, TradeType } from "@uniswap/sdk-core";
import { AlphaRouter, SwapType } from "@uniswap/smart-order-router";
import { SUPPORTED_NETWORKS } from "constants/chains";
import {
  Contract,
  providers,
  Wallet,
  utils,
  BigNumberish,
  BytesLike,
  BigNumber,
} from "ethers";
import Web3 from "web3";
import abi from "abis/erc20abi.json";
import axios from "axios";
import { BASE_URL } from "constants/";
import { bridgeAbi } from "abis/bridgeABI";

let CURRENT_GAS_PRICE: string;

export async function depositCalldata(
  data,
  transactionOpts,
  chainId: number,
  gasLimits
): Promise<any[]> {
  try {
    const { usdcAddress, rpc, accrossAddress } =
      SUPPORTED_NETWORKS[chainId as keyof typeof SUPPORTED_NETWORKS];

    let txns = [];

    const ISTRANSFER_TOKEN_NATIVE =
      data.originToken == "0x0000000000000000000000000000000000000000" ||
      data.originToken == "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270";

    console.log("originToken", data.originToken, usdcAddress);

    // if origintoken != usdc swap
    if (data.originToken.toLowerCase() !== usdcAddress.toLowerCase()) {
      const output = await AcrossExactInputCalldata(
        data,
        transactionOpts,
        chainId,
        gasLimits
      );

      txns = output.txns;
      //update data.amount
      data.amount = output.amountOut;
      // update origin token
      data.originToken = usdcAddress;
    }

    // if bridge does not have enough usdc allowance get it

    // console.log("UNISWAP KA APPPPPPPPPPPPPPPPPPPP", ISTRANSFER_TOKEN_NATIVE);

    if (data.originToken != ISTRANSFER_TOKEN_NATIVE) {
      const web3 = new Web3(rpc);
      //@ts-ignore
      const originToken = new web3.eth.Contract(abi.abi, data.originToken);

      const allowance = await originToken.methods
        .allowance(data.sender, accrossAddress)
        .call();

      console.log("allowance", allowance);

      if (BigInt(allowance.toString()) < BigInt(data.amount)) {
        const txn = await getSwapRouter02ApprovalTransaction(
          data.originToken,
          accrossAddress,
          data.amount,
          transactionOpts.gasPrice,
          gasLimits.approve,
          data.nonce
        );

        txns.push(txn);

        // const transactionResponse1 = await wallet.sendTransaction(txn);

        // console.log('Bridge Approval Transaction Sent:', await transactionResponse1.wait());
      }
    }

    const { data: bridgeData } = await axios.post(
      BASE_URL + "/transaction/getBridgeData",
      {
        originToken: data.originToken,
        chainId: chainId,
        destinationChainId: data.destinationChainId,
        amount: +data.amount,
      }
    );

    // console.log(result.data?.relayFeePct, result.data?.timestamp)

    const calldata = prepareAccrossCalldata(
      data.recipient,
      data.originToken,
      data.amount,
      data.destinationChainId,
      bridgeData?.relayFeePct,
      bridgeData?.timestamp,
      data.message,
      data.maxCount
    );

    const rawTransaction = {
      to: transactionOpts.toAddress,
      value: (0).toString(),
      data: calldata,
      gasPrice: transactionOpts.gasPrice,
      gasLimit: gasLimits.deposit,
      nonce: data.nonce,
    };

    txns.push(rawTransaction);

    console.log(txns);

    return txns;
  } catch (error) {
    // @ts-ignore
    throw error;
  }
}

async function getAlphaRouterResponse(
  inputTokenAddress: string,
  inputTokenDecimals: number,
  outputTokenAddress: string,
  outputTokenDecimals: number,
  recipient: string,
  amount: string,
  tradeType: TradeType,
  swapType: SwapType,
  chainId: number
) {
  // console.log(
  //   inputTokenAddress,
  //   inputTokenDecimals,
  //   outputTokenAddress,
  //   outputTokenDecimals,
  //   recipient,
  //   amount,
  //   tradeType,
  //   swapType
  // );
  // TODO: change it to env rpc
  const { alchemy_url } =
    SUPPORTED_NETWORKS[chainId as keyof typeof SUPPORTED_NETWORKS];
  const provider = new providers.JsonRpcProvider(alchemy_url);
  const alphaRouter = new AlphaRouter({ chainId: chainId, provider: provider });

  console.log("inputTokenAddress", inputTokenAddress, inputTokenDecimals);
  const inputToken = new Token(chainId, inputTokenAddress, inputTokenDecimals);
  console.log("inputToken", inputToken);
  const outputToken = new Token(
    chainId,
    outputTokenAddress,
    outputTokenDecimals
  );
  console.log("outputToken", outputToken);

  console.log("outputToken", outputTokenAddress, outputTokenDecimals);

  const inputAmountCurrency = CurrencyAmount.fromRawAmount(inputToken, amount);
  const response = await alphaRouter.route(
    inputAmountCurrency,
    outputToken,
    tradeType,
    {
      recipient, // address of the wallet to receive the output token
      slippageTolerance: new Percent(10, 100),
      deadline: Math.floor(Date.now() / 1000 + 31560000), // fail transaction if it can't be mined in respective time
      type: swapType, // use Uniswap V3 Router 2 to match expected calldata format
    }
  );
  // console.info('got alpharouter response for profit case:', response);
  if (!response || !response.methodParameters) {
    throw new Error(
      "Uniswap alpha router could not find valid route for profit case"
    );
  }
  return response;
}

export async function AcrossExactInputCalldata(
  data,
  transactionOpts,
  chain: number,
  gasLimits
): Promise<{ amountOut: string; txns: [] }> {
  let txns: [] = [];

  const { usdcAddress, usdcDecimals, rpc, swapRouter02 } =
    SUPPORTED_NETWORKS[chain as keyof typeof SUPPORTED_NETWORKS];

  const response = await getAlphaRouterResponse(
    data.originToken,
    data.originTokenDecimals,
    usdcAddress,
    usdcDecimals,
    data.sender,
    data.amount,
    TradeType.EXACT_INPUT,
    SwapType.SWAP_ROUTER_02,
    chain
  );

  const provider = new providers.JsonRpcProvider(rpc);
  const web3 = new Web3(rpc);

  CURRENT_GAS_PRICE = (await provider.getGasPrice()).toString();
  // console.log(CURRENT_GAS_PRICE)
  const amountOut = response.trade.outputAmount.numerator.toString();
  // console.log("amountOut :: ", amountOut);

  const ISTRANSFER_TOKEN_NATIVE =
    data.originToken == "0x0000000000000000000000000000000000000000" ||
    data.originToken == "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270";

  // if (data.originToken != ISTRANSFER_TOKEN_NATIVE) {
  //@ts-ignore
  const originToken = new web3.eth.Contract(abi.abi, data.originToken);

  console.log(
    "AcrossExactInputCalldata",
    !data.payable,
    BigInt(
      (
        await originToken.methods.allowance(data.sender, swapRouter02).call()
      ).toString()
    ),
    BigInt(data.amount)
  );

  if (
    !data.payable &&
    BigInt(
      (
        await originToken.methods.allowance(data.sender, swapRouter02).call()
      ).toString()
    ) < BigInt(data.amount)
  ) {
    // if swapRouter02 has enough allowance don't bother getting approval
    const txn = await getSwapRouter02ApprovalTransaction(
      data.originToken,
      swapRouter02,
      data.amount,
      transactionOpts.gasPrice,
      gasLimits.approve,
      data.nonce
    );

    txns.push(txn);
    // }
  }

  const rawTransaction = {
    to: swapRouter02,
    value: data.payable ? data.amount : (0).toString(),
    data: response.methodParameters?.calldata!,
    gasPrice: (transactionOpts.gasPrice == 0
      ? CURRENT_GAS_PRICE
      : transactionOpts.gasPrice
    ).toString(),
    gasLimit: gasLimits.swapExactInput,
    nonce: data.nonce,
  };

  txns.push(rawTransaction);

  return { amountOut, txns };
}

export async function getSwapRouter02ApprovalTransaction(
  tokenIn: string,
  spender: string,
  amount: string,
  gasPrice: number | BigNumberish,
  gasLimit: number | BigNumberish,
  nonce: number
) {
  const _erc20Abi = new utils.Interface(abi.abi);
  const approvalCallData = _erc20Abi.encodeFunctionData("approve", [
    spender,
    amount,
  ]);

  console.log("gasPrice", gasPrice, CURRENT_GAS_PRICE);

  const rawTransaction = {
    to: tokenIn,
    value: (0).toString(),
    data: approvalCallData,
    // gasPrice: (gasPrice == 0 ? CURRENT_GAS_PRICE : gasPrice).toString(),
    gasLimit,
    nonce,
  };

  return rawTransaction;
}

export function prepareAccrossCalldata(
  recipient: string,
  originToken: string,
  amount: string,
  destinationChainId: string,
  relayerFeePct: string,
  quoteTimestamp: string,
  message: string,
  maxCount: string
) {
  //FUNCTION TYPE: deposit (address recipient, address originToken, uint256 amount, uint256 destinationChainId, int64 relayerFeePct, uint32 quoteTimestamp, bytes message, uint256, maxCount)
  const bridge = new utils.Interface(bridgeAbi);
  const encodedData = bridge.encodeFunctionData("deposit", [
    recipient,
    originToken,
    amount,
    destinationChainId,
    relayerFeePct,
    quoteTimestamp,
    message,
    maxCount,
  ]);
  return encodedData;
}
