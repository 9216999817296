import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Box } from "@mui/material";
import NftDetails from "components/NftDetails";
import SendView from "components/SendView";
import Banner from "components/Banner";
import { useAppSelector } from "store/store";

const OverviewWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  padding: "30px 50px",
  width: "80vw",
  // height: "100%",
  position: "relative",
}));

const SingleNftDetails = () => {
  const { activeAccount, nfts, activeNetwork } = useAppSelector(
    state => state.app
  );

  const [step, setStep] = useState(0);
  const [totalNfts, setTotalNfts] = useState(0);
  const [totalCollections, setTotalCollections] = useState(0);

  useEffect(() => {
    const collectionWiseProfileNfts =
      nfts[activeAccount.smartAccountAddress].nfts;

    const totalNfts = collectionWiseProfileNfts.map(nft => nft.nfts.flat());

    setTotalNfts(totalNfts.flat().length);
    setTotalCollections(collectionWiseProfileNfts.length);
  }, [activeAccount, activeNetwork, nfts]);

  return (
    <>
      <Banner collectionsCount={totalCollections} nftsCount={totalNfts} />
      <OverviewWrapper>
        {/* <Box sx={{ width: "100%" }}>
        <Topbar />
      </Box> */}
        {step === 0 && <NftDetails setStep={setStep} />}
        {step === 1 && <SendView setStep={setStep} />}
      </OverviewWrapper>
    </>
  );
};

export default SingleNftDetails;
