import axios from "axios";
import { SUPPORTED_NETWORKS, SupportedChainId } from "constants/chains";

export const getUserOpHashData = async (
  opHash: string,
  network: SupportedChainId
) => {
  try {
    const { jiffyScanName } = SUPPORTED_NETWORKS[network];
    const { data } = await axios.get(
      `https://api.jiffyscan.xyz/v0/getUserOp?hash=${opHash}&network=${jiffyScanName}`,
      {
        headers: {
          "x-api-key": process.env.JIFFY_SCAN_API_KEY,
        },
      }
    );
    console.log("🚀 ~ file: jiffyscan.ts:18 ~ data:", data);

    return data;
  } catch (error) {
    throw error;
  }
};
