import LockedContent from "assets/lockedsecretphrase.svg";
import { LockedSeedPhraseprops } from "interfaces";
import { FC } from "react";

export const LockedSecretSeedPhrase: FC<LockedSeedPhraseprops> = ({
  onClick,
}) => {
  return (
    <div
      style={{
        border: "1px solid #E8ECF5",
        borderRadius: "6px",
        background: "#F9F8FD",
        width: "780px",
        display: "flex",
        flexWrap: "wrap",
        height: "400px",
        cursor: "pointer",
      }}
      onClick={onClick}
      id='locked_secret_phrase'
    >
      <img src={LockedContent} style={{ width: "100%" }} />
    </div>
    // <img src={LockedContent} style={{height:"400px", width:"780px"}} />
  );
};
