import styled from "@emotion/styled";
import { Box, Button, InputBase, Typography, useTheme } from "@mui/material";
import { Stack } from "@mui/system";

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/store";
import { NATIVE_ADDRESS, nftTempImage } from "constants/";
import BackIcon from "assets/back.svg";
import BasicButton from "components/Button";
import Web3 from "web3";

import { decryptMessage, initalizeWeb3, showAlert } from "utils/utils";
import { Erc1155ABI } from "abis/Erc1155ABI";
import { Erc721ABI } from "abis/Erc721ABI";
import { fetchGasPrice } from "utils/gas";
import { setTxStatus } from "@slices/walletSlice";
import { ethers } from "ethers";
import { SUPPORTED_NETWORKS } from "constants/chains";
import {
  CallDataType,
  FactoryData,
  PaymasterType,
} from "../../contract-integration/constants";
import { setPendingTx } from "@slices/appSlice";
import { getToAddress } from "utils/ens";

const SendView = ({ setStep }) => {
  const [toAddress, setToAddress] = useState("");
  const [nft, setNft] = useState({});
  const [amount, setAmount] = useState("");
  const [isBackeSpacePressed, setIsBackeSpacePressed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [gasPrice, setGasPrice] = useState(0);

  const navigate = useNavigate();
  const { address, tokenId } = useParams();
  const dispatch = useAppDispatch();

  const { nfts, activeAccount, activeNetwork } = useAppSelector(
    (state) => state.app
  );

  const { hashedPassword, txStatus } = useAppSelector((state) => state.wallet);

  const profileNfts = nfts[activeAccount.smartAccountAddress].nfts;

  useEffect(() => {
    (async () => {
      const { average } = await fetchGasPrice(activeNetwork);
      console.log(
        "GASSSSSSSSSSs",
        average,
        Number(Web3.utils.fromWei(Number(average).toString(), "ether"))
      );
      setGasPrice((average * 1.5).toFixed());
    })();
  }, []);

  useEffect(() => {
    const allUserNfts = profileNfts.map((coll) => coll.nfts.flat()).flat();
    console.log("allUserNfts", allUserNfts);
    setNft(
      allUserNfts.find(
        (nft) => nft.tokenId === tokenId && nft.nftAddress === address
      )
    );
  }, [profileNfts]);

  const onSend = async () => {
    try {
      setLoading(true);
      const { dummyToken } = SUPPORTED_NETWORKS[activeNetwork];
      const to = await getToAddress(toAddress);
      console.log(to);

      if (to) {
        const web3 = initalizeWeb3(activeNetwork);
        const nonce = await web3.eth.getTransactionCount(
          activeAccount.smartAccountAddress,
          "latest"
        );
        const pkey = decryptMessage(activeAccount.secret, hashedPassword);
        const { rpc } =
          SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS];

        const provider = new ethers.providers.JsonRpcProvider(rpc);
        const wallet = new ethers.Wallet(pkey, provider);

        let nftContract = new web3.eth.Contract(
          Number(amount) > 0 ? Erc1155ABI.abi : Erc721ABI.abi,
          address
        );

        const gasLimit =
          Number(amount) > 0
            ? await nftContract.methods
                .safeTransferFrom(
                  activeAccount.smartAccountAddress,
                  to,
                  tokenId,
                  amount,
                  "0x00"
                )
                .estimateGas({ from: activeAccount.smartAccountAddress })
            : await nftContract.methods
                .safeTransferFrom(
                  activeAccount.smartAccountAddress,
                  to,
                  tokenId
                )
                .estimateGas({ from: activeAccount.smartAccountAddress });

        console.log(gasLimit);

        let txObj = {
          to: address,
          value: 0,
          nonce,
          gasLimit,
          gasPrice,
          calldata:
            Number(amount) > 0
              ? nftContract.methods
                  .safeTransferFrom(
                    activeAccount.smartAccountAddress,
                    to,
                    tokenId,
                    amount,
                    "0x00"
                  )
                  .encodeABI()
              : nftContract.methods
                  .safeTransferFrom(
                    activeAccount.smartAccountAddress,
                    to,
                    tokenId
                  )
                  .encodeABI(),
        };

        console.log("txObj", txObj);

        const counterfactual = activeAccount.smartAccountAddress;

        console.log("counterfactual", counterfactual);

        const smartAccountNonce = await web3.eth.getTransactionCount(
          counterfactual,
          "latest"
        );

        // const res = await submitTransaction(
        //   { chainId: activeNetwork },
        //   wallet,
        //   smartAccountNonce === 0
        //     ? FactoryData.FactoryCreateSender
        //     : FactoryData.Empty,
        //   CallDataType.Execute,
        //   txObj,
        //   // tx2,
        //   PaymasterType.TokenReceiver,
        //   NATIVE_ADDRESS
        // );

        // console.log("res", res);

        // dispatch(setPendingTx(res.userOpHash));

        showAlert(
          "Soon you can see your transaction in the transactions tab",
          "Transaction Submitted",
          `<a href="https://polygonscan.com/address/${activeAccount.smartAccountAddress}" target="_blank">View on Polygonscan</a>`
        );
        setLoading(false);

        navigate("/");
      } else {
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  return (
    <>
      <img
        src={BackIcon}
        style={{ marginTop: "10px", cursor: "pointer" }}
        alt="arrow"
        onClick={() => setStep(0)}
      />
      <Stack
        direction="row"
        alignItems={"flex-start"}
        justifyContent="space-between"
        width="100%"
        sx={{ marginTop: "20px" }}
      >
        {/* <Box> */}
        <img
          src={nft?.image}
          alt="nft"
          style={{
            width: "424px",
            height: "418px",
            borderRadius: "7px",
          }}
          onError={(e) => (e.currentTarget.src = nftTempImage)}
        />
        {/* </Box> */}
        <Box
          sx={{
            height: "350px",
            width: "70%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            marginTop: "40px",
            marginLeft: "20px",
          }}
        >
          <Box
            width="100%"
            height="49px"
            // bgcolor={colors.whiteAccent[900]}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="body1"
              fontWeight={400}
              align="center"
              textAlign={"center"}
              width={"100%"}
              // color={colors.black[500]}
            >
              Enter Destination Address
            </Typography>
          </Box>

          <Box
            width="100%"
            height="49px"
            bgcolor={"#F9F8FD"}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "10px",
              "& input": {
                textAlign: "center",
              },
            }}
          >
            <InputBase
              sx={{
                flex: 1,
                fontSize: "14px",
                border: "1px solid #E8ECF5",
                height: "100%",
              }}
              placeholder="Address"
              value={toAddress}
              onChange={(e) =>
                e.target.value.length < 50 && setToAddress(e.target.value)
              }
              autoComplete="off"
            />
          </Box>

          {nft?.contractType === "ERC1155" && (
            <>
              <Box
                width="100%"
                height="49px"
                // bgcolor={colors.whiteAccent[900]}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="body1"
                  fontWeight={400}
                  align="center"
                  textAlign={"center"}
                  width={"100%"}
                  // color={colors.black[500]}
                >
                  Amount
                </Typography>
              </Box>

              <Box
                width="100%"
                height="49px"
                bgcolor={"#F9F8FD"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  "& input": {
                    textAlign: "center",
                  },
                }}
              >
                <InputBase
                  sx={{
                    flex: 1,
                    fontSize: "14px",
                    border: "1px solid #E8ECF5",
                    height: "100%",
                  }}
                  placeholder="Amount"
                  value={amount}
                  autoComplete="off"
                  onChange={(e) => {
                    const { value } = e.target;

                    const reg = /^[0-9\b]+$/;
                    const test = reg.test(value);

                    if (
                      (!test && !isBackeSpacePressed) ||
                      Number(value) > Number(nft?.balance)
                    ) {
                      return;
                    }

                    setAmount(e.target.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.keyCode === 8) {
                      setIsBackeSpacePressed(true);
                    } else {
                      setIsBackeSpacePressed(false);
                    }
                  }}
                />
              </Box>
            </>
          )}
        </Box>
      </Stack>

      <BasicButton
        onClick={onSend}
        title="Send"
        style={{ width: "100%", marginTop: 30 }}
        loading={loading}
        mode={
          (nft?.contractType === "ERC1155" && !amount) || !toAddress
            ? "not-active"
            : !toAddress
            ? "not-active"
            : "active"
        }
      />
    </>
  );
};

export default SendView;
