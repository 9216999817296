import { Box } from "@mui/system";
import React, { FC } from "react";
import ReviewAssetListComponent from "./ReviewAssetListComponent";

const FillAsset: FC<{
  executeSend: () => {};
  value: number;
  nextStep?: () => void;
  isGasComponent?: boolean;
}> = ({ nextStep = () => {}, executeSend, value, isGasComponent = false }) => {
  return (
    <Box width={"100%"}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        textAlign={"center"}
        mt={isGasComponent ? "-10px" : "auto"}
      >
        <ReviewAssetListComponent
          value={value}
          isGasComponent={isGasComponent}
        />
      </Box>
    </Box>
  );
};

export default FillAsset;
