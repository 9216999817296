import { Box, FormControl, IconButton, Input, Typography } from "@mui/material";
import { InputField, TokenInputField } from "pages/auth/styles";
import React, { FC } from "react";
import WalletIcon from "assets/walletIcon.svg";
import CopyIcon from "assets/copy-white.svg";
import TokenImage from "components/TokenImage";
import { formatAmount } from "utils/utils";

export interface TokenInputProps {
  disabled?: boolean;
  value?: string;
  onChange?: any;
  onKeydown?: any;
  showStartIcon?: boolean;
  length?: number;
  tokenName?: string;
  tokenIcon?: string;
  placeHolder?: string;
  balance?: number;
}

const CashTokenInput: FC<TokenInputProps> = ({
  disabled,
  onChange,
  onKeydown,
  value,
  showStartIcon = false,
  length = 50,
  tokenName,
  tokenIcon,
  placeHolder,
  balance,
}) => {
  return (
    <div className="input-container">
      <FormControl sx={{ flex: 1 }}>
        <Box
          sx={{
            color: "#1A1C20",
            width: "100%",
            // height: "80px",
            borderRadius: "10px",
            fontSize: "25px",
            fontFamily: "Space Grotesk",
            fontWeight: "700",
            border: "0.5px solid rgba(26, 28, 32, 0.50)",
          }}
        >
          <Input
            startAdornment={showStartIcon && <img src={WalletIcon} />}
            onChange={(e) => onChange(e.target.value)}
            onKeyDown={(e) => (onKeydown ? onKeydown(e) : {})}
            placeholder={placeHolder}
            style={{
              padding: "10px",
              border: "0.5px solid #1A1C2080",
              background: "#F7F7F7",
              borderRadius: "10px",
            }}
            endAdornment={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box mr={tokenName?.toLowerCase() === "cash" ? 0.2 : 1.25}>
                  <img
                    src={tokenIcon as string}
                    height={tokenName?.toLowerCase() === "cash" ? "auto" : 38}
                  />{" "}
                </Box>

                <Box
                  sx={{
                    color: "#1A1C20",
                    fontFamily: "Space Grotesk",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "normal",
                    textWrap: "nowrap",
                    marginTop: tokenName?.toLowerCase() === "cash" ? -1.5 : -1,
                  }}
                >
                  {tokenName}
                </Box>
              </Box>
            }
            value={value}
            sx={{ width: "100%", padding: "20px 30px" }}
          />
        </Box>
      </FormControl>
    </div>
  );
};

export default CashTokenInput;
