import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { FC } from "react";
import NoDataIcon from "assets/noDataIcon.svg";

interface NoDataProps {
  title: string;
  description: string;
  icon?: any;
  gasCard?: boolean;
}
const NoDataUI: FC<NoDataProps> = ({
  title,
  description,
  gasCard = false,
  icon = NoDataIcon,
}) => {
  if (gasCard) {
    return (
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={1}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <img src={icon} height="50" width="50" />
        <Box>
          <Typography variant="h5" textAlign={"center"}>
            Your Gas Account
          </Typography>
          <Typography variant="h5" textAlign={"center"}>
            lets your pay for transactions
          </Typography>
          <Typography variant="h5" textAlign={"center"}>
            across all of your accounts on any chain!
          </Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <img src={icon} height="50" width="50" />
      <Typography variant="h4" textAlign={"center"}>
        {title}
      </Typography>
      <Typography variant="body2" fontSize={"16px"} sx={{ color: "#656D7E" }}>
        {description}
      </Typography>
    </Box>
  );
};

export default NoDataUI;
