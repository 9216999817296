import { Typography, useTheme } from "@mui/material";
import React from "react";

const NoData = ({ text }) => {
  const theme = useTheme();
  return (
    <Typography variant='caption' color={"black"} fontWeight='500'>
      {text}
    </Typography>
  );
};

export default NoData;
