import { Box } from "@mui/system";
import Banner from "components/Banner";
import { Collectible } from "components/Collectible";
import NoData from "components/NoTokens";
import { SearchAndView } from "components/SearchAndView";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "store/store";

const Collectibles = () => {
  const { activeAccount, nfts, activeNetwork } = useAppSelector(
    (state) => state.app
  );
  console.log(
    "file: Collectibles.tsx:11  Collectibles  activeAccount:",
    activeAccount
  );

  const [collections, setCollections] = useState([]);
  const [searchVar, setSearchVar] = useState("");
  const [searchShow, setSearchShow] = useState(false);
  const [filteredCollections, setFilteredCollections] = useState([]);
  const [totalNfts, setTotalNfts] = useState(0);

  useEffect(() => {
    const collectionWiseProfileNfts = nfts[activeAccount.address]?.nfts ?? [];

    const totalNfts = collectionWiseProfileNfts.map((nft) => nft.nfts.flat());

    setTotalNfts(totalNfts.flat().length);
    setCollections(collectionWiseProfileNfts);
  }, [activeAccount, activeNetwork, nfts]);

  return (
    <div>
      <Banner collectionsCount={collections.length} nftsCount={totalNfts} />
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          paddingBottom: "20px",
        }}
        gap="10px"
        className="main-view"
      >
        <SearchAndView
          searchVar={searchVar}
          setFiltered={(e) => {
            const { value } = e.target;
            setSearchVar(value);
            if (value === "") {
              setSearchShow(false);
              setFilteredCollections([]);
            } else {
              const filtered = collections.filter(
                (coll) =>
                  coll?.contractMetadata?.name &&
                  coll?.contractMetadata?.name
                    .toLowerCase()
                    .includes(value.toLowerCase())
              );

              setFilteredCollections(filtered);
              setSearchShow(true);
            }
          }}
        />

        <Box
          display="flex"
          flexDirection="column"
          width="94%"
          marginBottom="10px"
          marginTop="50px"
          alignItems={"center"}
          justifyContent={"center"}
          pl={1}
        >
          {searchShow && filteredCollections.length > 0 ? (
            filteredCollections.map((collection) => (
              <Collectible
                collectionName={collection.contractMetadata.name}
                collectionAddress={collection.address}
                totalNfts={collection.nfts.length}
                nfts={collection.nfts}
                setSearchVar={setSearchVar}
                setSearchShow={setSearchShow}
              />
            ))
          ) : searchShow && filteredCollections.length === 0 ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <NoData text="No Collections to show!" />
            </Box>
          ) : collections.length > 0 ? (
            collections.map((collection, index) => (
              <Collectible
                collectionName={collection.contractMetadata.name}
                collectionAddress={collection.address}
                totalNfts={collection.nfts.length}
                nfts={collection.nfts}
                key={index}
              />
            ))
          ) : (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <NoData text="No Collections to show!" />
            </Box>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default Collectibles;
