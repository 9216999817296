import { Box } from "@mui/system";
import React, { ReactNode } from "react";
import NFTMetaDataItem from "./NFTMetaDataItem";
import {
  SUPPORTED_NETWORKS,
  SupportedChainNames,
} from "../../../../constants/chains";
import { trimTokenAddress } from "utils/utils";
import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
interface ChildComponentProps {
  props: {
    contractAddress: string;
    tokenId: string;
    tokenStandard: string;
    chain: string;
    lastUpdated?: string;
    creatorEarnings?: string;
  };
}

const NFTMetaData: React.FC<ChildComponentProps> = ({ props }) => {
  const {
    contractAddress,
    tokenId,
    tokenStandard,
    chain,
    lastUpdated,
    creatorEarnings,
  } = props;

  function formatWalletAddress(
    walletAddress: string,
    charsToDisplay: number = 6
  ): string {
    if (walletAddress.length < charsToDisplay) {
      return walletAddress;
    }

    const start = walletAddress.slice(0, charsToDisplay);
    const end = walletAddress.slice(-charsToDisplay);

    return `${start}...${end}`;
  }
  const chainNumber = Number(SupportedChainNames[chain as any]);
  const url =
    SUPPORTED_NETWORKS[chainNumber as keyof typeof SUPPORTED_NETWORKS]
      .block_explorer_url;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <NFTMetaDataItem
        title="Contract Address"
        RightComponent={
          <a
            target="_blank"
            href={`${url}/address/${contractAddress}`}
            style={{ textDecoration: "none" }}
          >
            <Box
              sx={{
                color: "#0161FF",
                fontFamily: "Space Grotesk",
                fontWeight: 500,
              }}
            >
              {formatWalletAddress(contractAddress, 4)}
            </Box>
          </a>
        }
      />
      <NFTMetaDataItem
        title="Token"
        RightComponent={
          // <Tooltip title={tokenId}>
          <Link
            to={`${url}nft/${contractAddress}/${tokenId}`}
            target="_blank"
            style={{
              textDecoration: "none",
            }}
          >
            <Box
              sx={{
                color: "#0161FF",
                fontFamily: "Space Grotesk",
                fontWeight: 500,
              }}
            >
              {trimTokenAddress(tokenId)}
            </Box>
          </Link>
          // </Tooltip>
        }
      />
      <NFTMetaDataItem
        title="Token Standard"
        RightComponent={
          <Box
            sx={{
              color: "#1A1C20",
              fontFamily: "Space Grotesk",
              fontWeight: 500,
            }}
          >
            {tokenStandard}
          </Box>
        }
      />
      <NFTMetaDataItem
        title="Chain"
        RightComponent={
          <Box
            sx={{
              color: "#1A1C20",
              fontFamily: "Space Grotesk",
              fontWeight: 500,
            }}
          >
            {chain}
          </Box>
        }
      />
      {lastUpdated && (
        <NFTMetaDataItem
          title="Last Updated"
          RightComponent={
            <Box
              sx={{
                color: "#1A1C20",
                fontFamily: "Space Grotesk",
                fontWeight: 500,
              }}
            >
              {lastUpdated}
            </Box>
          }
        />
      )}
      {lastUpdated && (
        <NFTMetaDataItem
          title="Last Updated"
          RightComponent={
            <Box
              sx={{
                color: "#1A1C20",
                fontFamily: "Space Grotesk",
                fontWeight: 500,
              }}
            >
              {lastUpdated}
            </Box>
          }
        />
      )}
    </Box>
  );
};

export default NFTMetaData;
