import {
  Grid,
  Input,
  Slide,
  Snackbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import InfoCard from "../../../components/InfoCard";
import { useAppSelector } from "../../../store/store";
import ReferralBackground from "assets/backgrounds/referralBackground.svg";
import { getReferralReward } from "../../../utils/utils";
import InfoCardAmount from "../../../components/InfoCardAmount";
import { Box } from "@mui/system";
import ApplyReferralCode from "./ApplyReferralCode";
import ImageViewer from "../NFT/NFTDetailPage/ImageViewer";
import RewardImage from "assets/reward.svg";
import CenterItem from "../../../components/CenterItem";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReferralCard from "./ApplyReferralCode/ReferralCard";
import ReferredFriends from "./ReferredFriends";
import CopyIcon from "assets/whiteCopyIcon.svg";

const ReferralView = () => {
  const [reward, setReward] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const aboveMedium = useMediaQuery("(min-width: 900px)");

  const { ownReferralCode, referredTo, activeAccount, rootAccountInfo } =
    useAppSelector((state) => state.app);
  console.log(
    "file: View.tsx:24  ReferralView  rootAccountInfo:",
    rootAccountInfo
  );
  const onCopy = () => {
    setShowSnackbar(true);
    navigator.clipboard.writeText(ownReferralCode);
  };
  useEffect(() => {
    (async () => {
      try {
        const res = await getReferralReward(rootAccountInfo.address);
        console.log(res);
        setReward(
          res.totalReferedByUserReward != 0
            ? res.totalReferedByUserReward.toFixed(10)
            : res.totalReferedByUserReward
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [activeAccount.smartAccountAddress]);

  return (
    <>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        message="Referral Code copied!"
      />
      <Grid display="flex" flexDirection="column" gap={3}>
        <Grid container spacing={1.5} mt={5.5}>
          {/* User Info & Balance Area */}
          <Grid item xs={12} md={6}>
            {/* <Card title="@igors" ens="" balance="50.67" /> */}
            <InfoCard
              backgroundImage={`url(${ReferralBackground})`}
              backgroundPosition="-40px -300.419px"
              backgroundSize="150% 385.461%"
              title={`Cash Rewards`}
              amount={
                <InfoCardAmount
                  amount={!reward || reward == 0 ? "0.0" : String(reward)}
                />
              }
              subtitle="Claimable"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <ApplyReferralCode />
          </Grid>
        </Grid>
        <Grid>
          <Slide in={true} timeout={500} direction={"down"}>
            <Grid
              sx={{ background: "#F5F5F5", borderRadius: "15px" }}
              p={4}
              mt={"20px"}
              container
            >
              <Grid item xs={12} md={7}>
                <Grid
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  height="100%"
                >
                  <Grid>
                    <Typography
                      // from h1 to h2, down the variant below 900px
                      variant={aboveMedium ? "h1" : "h2"}
                    >
                      EARN LIFETIME REWARDS
                    </Typography>
                    <Typography variant="body2" color="#1A1C20B2" width="80%">
                      Get cashback with every transaction you make on Stashed
                      and lifetime earnings from everyone who joins via your
                      referral code
                    </Typography>
                  </Grid>
                  <Grid width={aboveMedium ? "80%" : "100%"}>
                    <Typography>Share your link</Typography>
                    <Box display="flex" flexDirection="row" gap={2}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        width="100%"
                        sx={{
                          background: "#ffffff",
                          borderRadius: "10px",
                          padding: "10px 20px",
                        }}
                      >
                        <Box>
                          <CenterItem>
                            <Typography fontWeight="600" variant="h5">
                              {ownReferralCode}
                            </Typography>
                          </CenterItem>
                        </Box>
                        <Box>
                          <CenterItem>
                            <div
                              className="tab-chip-box"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "5px",
                                alignItems: "center",
                              }}
                              onClick={onCopy}
                            >
                              <Typography
                                variant={"h5"}
                                fontWeight={500}
                                color="white"
                                fontSize={12}
                                fontFamily={"Helvetica Neue"}
                                whiteSpace={"nowrap"}
                                letterSpacing={2}
                              >
                                Copy{" "}
                              </Typography>
                              {/* <ContentCopyIcon
                                style={{
                                  color: '#ffffff',
                                  fontSize: '14',
                                  cursor: 'pointer',
                                }}
                              /> */}
                              <img
                                src={CopyIcon}
                                alt="copy icon"
                                style={{
                                  cursor: "pointer",

                                  height: "15px",
                                }}
                              />
                            </div>
                          </CenterItem>
                        </Box>
                      </Box>
                      {/* <Box>
                      <CenterItem>
                        <div className="tab-chip-box">
                          <Typography
                            variant={"h5"}
                            fontWeight={500}
                            color="white"
                            fontSize={12}
                            fontFamily={"Helvetica Neue"}
                            whiteSpace={"nowrap"}
                            letterSpacing={2}
                          >
                            Share
                          </Typography>
                        </div>
                      </CenterItem>
                    </Box> */}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={5}>
                <ImageViewer
                  title="Earn Lifetime Award"
                  imageUrl={RewardImage}
                />
              </Grid>
            </Grid>
          </Slide>
        </Grid>

        <Grid paddingTop={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box className="card-box" height="100%">
                {/* asdsad */}
                <ReferralCard
                  props={{
                    title: "You get",
                    points: [
                      "Lifetime fees from friends",
                      "Cashback on every transaction",
                    ],
                  }}
                />
                {/* asdasd */}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className="card-box" height="100%">
                <ReferralCard
                  props={{
                    title: "They get",
                    points: [
                      "10% Discount on all transactions",
                      "Cashback on every transaction",
                    ],
                  }}
                />
              </Box>{" "}
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <ReferredFriends />
        </Grid>
      </Grid>
    </>
  );
};

export default ReferralView;
