import React, { ReactNode, useEffect } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material";

import millify from "millify";
import { useAppSelector } from "../../store/store";
import TokenImage from "../TokenImage";
import { truncateToken } from "utils/utils";

// Sample data for options

const TopTokenSelectComponent: React.FC<{
  handleSelectToken: (address: string) => void;
  sameTokens: any[];
  handleSelectTokenWithDecimal?: ({
    address,
    decimal,
    tokenBalance,
  }: {
    address: string;
    decimal: number;
    tokenBalance?: number;
  }) => void;
  paymentTokenAddress?: string;
}> = ({
  handleSelectToken,
  paymentTokenAddress = "",
  sameTokens,
  handleSelectTokenWithDecimal = ({
    address = "",
    decimal = 6,
    tokenBalance = 0,
  }) => {},
}) => {
  console.log("file: index.tsx:19  sameTokens:", sameTokens);
  // const handleChange = (event: SelectChangeEvent<string>, child: ReactNode) => {

  const handleChange = (address: string, tokenBalance: number) => {
    console.log(
      "🚀 ~ file: index.tsx:37 ~ handleChange ~ tokenBalance:",
      tokenBalance
    );
    // console.log("🚀 ~ file: index.tsx:35 ~ handleChange ~ event:", event);
    setSelectedOption(address as string);
    handleSelectToken(address);
    const decimal = sameTokens.filter(
      (token) => token.address.toLowerCase() == address.toLowerCase()
    );
    console.log("file: index.tsx:40  handleChangeasdasd  decimal:", decimal);
    handleSelectTokenWithDecimal({
      address,
      decimal: decimal[0].decimals,
      tokenBalance,
    });
  };

  const {
    activeNetwork,
    portfolio: { assets },
  } = useAppSelector((state) => state.app);

  const [selectedOption, setSelectedOption] = React.useState(
    paymentTokenAddress ?? ""
  );

  const getPriceOfOwnedToken = (tokenAddress: string) => {
    let selectedTokenAsPayment = assets;

    selectedTokenAsPayment = selectedTokenAsPayment.filter((item) => {
      let relevantBalanceObject = null;

      Object.keys(item.cross_chain_balances).forEach((key) => {
        const balanceObject = item.cross_chain_balances[key];

        if (
          balanceObject.chainId === activeNetwork &&
          balanceObject?.address?.toLowerCase() == tokenAddress?.toLowerCase()
        ) {
          // If the chainId matches, we remember this balance object
          relevantBalanceObject = balanceObject;
        }
      });

      // We will include items if a matching balance object for the current chainId is found
      // or if cross_chain_balances was null
      return relevantBalanceObject !== null;
    });

    console.log(
      "file: index.tsx:35  selectedTokenAsPayment:",
      selectedTokenAsPayment
    );
    const priceInUSD = selectedTokenAsPayment[0]?.price ?? "";
    const tokenBalance = selectedTokenAsPayment[0]?.token_balance ?? "";
    return {
      priceInUSD: priceInUSD ?? "",
      tokenBalance: tokenBalance ?? "",
    };
  };

  useEffect(() => {
    setSelectedOption(paymentTokenAddress);
  }, [paymentTokenAddress, sameTokens]);

  const SELECT_TEXT = "Please select a token";
  return (
    <FormControl sx={{ width: "100%", display: "flex", alignItems: "center" }}>
      <Select
        value={selectedOption.trim() == "" ? SELECT_TEXT : selectedOption}
        placeholder="Select Token as Fee"
        defaultValue={""}
        // onChange={handleChange}
        sx={{
          boxShadow: "none",
          ".MuiSelect-select": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingY: "10px",
          },
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: 0,
            },
          width: "100%",
          borderRadius: "10px",
          background: "#F7F7F7",
        }}
      >
        <MenuItem
          sx={{
            color: "#000000 !important",
          }}
          value={SELECT_TEXT}
          selected={false}
          disabled
        >
          Please select a token
        </MenuItem>{" "}
        {sameTokens?.map((option) => {
          const { priceInUSD, tokenBalance } = getPriceOfOwnedToken(
            option.address
          );
          return (
            <MenuItem
              key={option.address}
              value={option.address}
              sx={{
                alignItems: "center",
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
              onClick={() => handleChange(option.address, tokenBalance)}
            >
              <div
                style={{
                  alignItems: "center",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <TokenImage logo={option.logoUrl} />
                <div
                  style={{
                    color: "#000000",
                    marginLeft: 8,
                    lineHeight: "18px",
                  }}
                >
                  {truncateToken(option.name)}
                  <br />

                  <div
                    style={{
                      color: "#8C8D8F",
                      textAlign: "left",
                      fontFamily: "Helvetica Neue",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "20px",
                    }}
                  >
                    {option.tokenBalance < 0.0001
                      ? "< 0.0001"
                      : millify(tokenBalance, { precision: 4 })}{" "}
                    {option.symbol}
                  </div>

                  {/* <span
                    style={{
                      color: "#8C8D8F",
                      fontFamily: "Helvetica Neue",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "400",
                    }}
                  >
                    {option.symbol}
                  </span> */}
                </div>
              </div>
              <div
                style={{
                  lineHeight: "18px",
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "flex-end",
                  justifyContent: "center",
                  alignItems: "flex-end",
                }}
              >
                <div
                  style={{
                    color: "#1A1C20",
                    fontFamily: "Space Grotesk",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "700",
                    lineHeight: "normal",
                  }}
                >
                  {priceInUSD
                    ? `$${millify(priceInUSD * tokenBalance, { precision: 4 })}`
                    : ""}
                </div>
                {/* <div
                  style={{
                    color: "#8C8D8F",
                    textAlign: "right",
                    fontFamily: "Helvetica Neue",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "20px",
                  }}
                >
                  {millify(tokenBalance, { precision: 4 })} {option.symbol}
                </div> */}
              </div>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default TopTokenSelectComponent;
