import React, { FC } from "react";

import { Input, Typography } from "@mui/material";
import { InputProps, SeedPhraseBoxProps } from "interfaces";
import "./styles.css";

const SeedPhraseTextInput: FC<SeedPhraseBoxProps> = ({
  index,
  number,
  onPaste,
  seedPhrase,
  setSeedPhrase,
}) => {
  const handleInput = (inputEv: any, index: number) => {
    const value = inputEv.target.value;
    setSeedPhrase((state: string[]) =>
      state.map((val, i) => (i !== index ? val : value))
    );
  };
  return (
    <div className='seed-input-box'>
      <Typography
        variant='caption'
        sx={{
          margin: "5px 0px 30px 0px",

          fontWeight: "200",
          fontSize: 11,
          color: "rgba(26, 28, 32, 0.5)",
        }}
      >
        {number}
      </Typography>
      <div>
        <Input
          style={{ color: "black" }}
          value={seedPhrase[index]}
          onChange={e => handleInput(e, index)}
          onPaste={onPaste}
        />
      </div>
    </div>
  );
};

export default SeedPhraseTextInput;
