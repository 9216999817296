import {
  Box,
  CircularProgress,
  Grid,
  Slide,
  Typography,
  useTheme,
} from "@mui/material";
import CardLayout from "components/CardLayout";

import DepositIcon from "assets/deposit.svg";
import WithdrawIcon from "assets/withdraw.svg";
import SendIcon from "assets/send-icon.svg";
import FillupIcon from "assets/fillup-icon.svg";

import Button from "components/NewButton";
import ChipButton from "components/ChipButton";
import ExpandableChart from "components/ExpandableChart";
import InfoCard from "components/InfoCard";

import CashBackground from "assets/backgrounds/cash.png";
import NoDataUI from "../../../components/NoDataCard";
import GasIcon from "assets/gasIcon.svg";
import GasCard from "assets/gasCard.svg";

import BlankGas from "assets/blankGas.svg";
import InfoCardAmount from "components/InfoCardAmount";
import CenterItem from "components/CenterItem";
import SearchInput from "components/SearchInput";

import SearchOptionsIcon from "assets/searchOptionsIcon.svg";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/store";

import { numFormatter } from "../../../utils/utils";
import TokenImage from "../../../components/TokenImage";

import FilterModal from "components/Modal/FilterModal";

import { filterData } from "constants/chains";

const WithdrawListComponent: FC<{
  asset: any;
  nextStep?: () => void;
  transactionForm?: boolean;
}> = ({ asset, nextStep = () => {}, transactionForm = false }) => {
  console.log(
    "file: WithdrawTokensList.tsx:19  asset: selectedTokenaaaaa",
    asset
  );
  const navigate = useNavigate();
  const { nfts, activeAccount, activeNetwork } = useAppSelector(
    (state) => state.app
  );
  console.log("file: index.tsx:17  activeNetwork:", activeNetwork);
  const { amount: tokenBalance, symbol: tokenSymbol } = numFormatter(
    asset.balance,
    4
  );
  const { amount: tokenUsdBalance, symbol: tokenUsdSymbol } = numFormatter(
    asset.balanceInUSD,
    4
  );
  const dispatch = useAppDispatch();
  // const { amount: usdBalance, symbol: usdSymbol } = numFormatter(
  //   asset.estimated_balance,
  //   2
  // );

  return (
    <Grid
      sx={{
        padding: "10px",
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        cursor: "pointer",
        borderRadius: "10px",
        "&:hover": {
          backgroundColor: "lightgray",
        },
      }}
      onClick={() => {}}
    >
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <TokenImage logo={asset.logoUrl} />
        <Grid
          sx={{
            color: "#1A1C20",
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            fontFamily: "Space Grotesk",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "normal",
          }}
        >
          <div>{asset.token}</div>
          <div
            style={{
              // color: "rgba(26, 28, 32, 0.50)",
              fontFamily: "Helvetica Neue",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
            }}
          >
            <span
              style={{
                color: "rgba(26, 28, 32, 0.50)",
              }}
            >
              {tokenBalance + tokenSymbol + " "} {asset.token}
            </span>
          </div>
        </Grid>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Grid
          sx={{
            color: "#1A1C20",
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            fontFamily: "Space Grotesk",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "normal",
          }}
        >
          <div>${tokenUsdBalance + tokenUsdSymbol}</div>
          {/* <div
              style={{
                // color: "rgba(26, 28, 32, 0.50)",
                fontFamily: "Helvetica Neue",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }}
            >
              Min Price
            </div> */}
        </Grid>
      </Grid>
    </Grid>
  );
};

const GasView = () => {
  const theme = useTheme();
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    accounts,
    gasInfo: { depositedTokens, totalGas },
  } = useAppSelector((state) => state.app);
  const activeAccountAddress = Object.values(accounts)[0].smartAccountAddress;

  const [openModal, setOpenModal] = useState(false);

  async function filterHandler() {
    try {
      setLoading(true);
      onClose();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  const [checked, setChecked] = useState({
    ethereum: false,
    polygon: true,
    bnb: false,
    mantle: false,
  });

  const [selectText, setSelectText] = useState("Select All");
  const [selectedFilter, setSelectedFilter] = useState(
    filterData.map((item) => item?.chainId)
  ); // ["ethereum", "polygon", "bnb", "mantle"]

  const handleCheck = ({ chainName, chainId }) => {
    setChecked((prevState) => ({
      ...prevState,
      [chainName]: !prevState[chainName],
    }));

    setSelectedFilter((prevState) => {
      if (prevState.includes(chainId)) {
        return prevState.filter((item) => item !== chainId);
      } else {
        return [...prevState, chainId];
      }
    });
  };

  const handleSelectAll = (e) => {
    e.preventDefault();

    setChecked((prevState) => ({
      ...prevState,
      ethereum: selectText === "Select All" ? true : false,
      polygon: selectText === "Select All" ? true : false,
      bnb: selectText === "Select All" ? true : false,
      mantle: selectText === "Select All" ? true : false,
    }));

    if (selectText === "Select All") {
      setSelectText("Deselect All");
    } else {
      setSelectText("Select All");
    }

    setSelectedFilter((prevState) => {
      if (selectText === "Select All") {
        return filterData.map((item) => item?.chainId);
      } else {
        return [];
      }
    });
  };

  // if any is false then select all else deselect all
  useEffect(() => {
    const isAllTrue = Object.values(checked)?.every((chain) => chain === true);
    if (isAllTrue) {
      setSelectText("Deselect All");
    } else {
      setSelectText("Select All");
    }
  }, [checked]);

  const filterDataHandler = () => {
    setOpenModal(false);
    // filter assets on the basis of selectedFilter

    // setFilteredAssets(filteredData || []);
  };

  const filterModalProps = {
    open: openModal,
    onClose: () => setOpenModal(false),
    handleSelectAll,
    checked,
    selectText,
    filterData,
    handleCheck,
    filterDataHandler,
    filterHandler,
  };

  return (
    <Box width={"100%"}>
      <Box
        className="flex-row-center"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "20px",
          gap: "26px",
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
          },
        }}
      >
        {/* User Info & Balance Area */}
        <Box
          sx={{
            paddingTop: "0px !important",
            flex: 1,
            width: "100%",
          }}
        >
          {/* <Card title="@igors" ens="" balance="50.67" /> */}
          <InfoCard
            backgroundImage={`url(${GasCard})`}
            backgroundPosition="0px"
            backgroundSize="cover"
            amount={
              loading ? (
                "loading..."
              ) : (
                <InfoCardAmount amount={totalGas.toString()} />
              )
            }
            title={`Gas`}
          />
        </Box>

        {/* Receive Cash Area */}
        {/* <Grid item xs={12} md={6} sx={{ paddingTop: "0 !important" }}>
          <>
          
          </>
        </Grid>
      </Grid> */}
        <Box sx={{ paddingTop: "0px !important", flex: 1, width: "100%" }}>
          {/* <ExpandableChart
            data={[45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10]}
            title='igor'
            amount='5392.49'
            percentageChange='5'
          /> */}
          <CardLayout
            backgroundColor="white"
            style={{
              // height: '100%',
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: 1,
              height: "205px",
            }}
          >
            <NoDataUI
              title={`Your Gas Account\n
                 lets your pay for transactions\n
                 across all of your accounts on any chain!`}
              description=""
              gasCard={true}
              icon={GasIcon}
            />
          </CardLayout>
        </Box>
      </Box>

      <Box mt={2.5}>
        <Box
          className="flex-row-center"
          style={{
            display: "flex",
            gap: "4px",
            justifyContent: "flex-start",
            flexFlow: "wrap",
            paddingBottom: "20px",
          }}
        >
          <ChipButton
            title={totalGas > 0 ? "Top Up" : "Fill Up"}
            onClick={() => {
              navigate("/gas/fillup");
            }}
            icon={FillupIcon}
            fontWeight={400}
            fontSize={14}
            iconHeight={12}
            iconWidth={13}
            fontFamily="Helvetica Neue"
          />
          <ChipButton
            title="Withdraw"
            onClick={() => {
              navigate("/gas/withdraw");
            }}
            icon={SendIcon}
            fontWeight={400}
            fontSize={14}
            iconHeight={11}
            iconWidth={14}
            fontFamily="Helvetica Neue"
          />
        </Box>

        <Box display="flex">
          <Box flex={1} alignItems={"center"}>
            <SearchInput
              handleOnChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              justifyContent: "space-between",
              paddingLeft: "12px",
            }}
            style={{
              cursor: "pointer",
            }}
          >
            <CenterItem>
              <img src={SearchOptionsIcon} onClick={() => setOpenModal(true)} />
            </CenterItem>

            <FilterModal modalProps={filterModalProps} />

            <CenterItem>
              <div className="tab-chip-box" style={{ maxWidth: 110 }}>
                <Typography
                  variant={"h5"}
                  fontWeight={500}
                  color="white"
                  fontSize={12}
                  fontFamily={"Helvetica Neue"}
                  textAlign="center"
                >
                  + ADD TOKEN
                </Typography>
              </div>
            </CenterItem>
          </Box>
        </Box>

        <Box
          className="flex-row-center"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "40px",
            paddingBottom: "40px",
            flexDirection: "column",
            gap: "26px",
            [theme.breakpoints.down("md")]: {
              flexDirection: "column",
            },
          }}
        >
          {loading ? (
            <div
              style={{ width: "100%", textAlign: "center", margin: "5% 0px" }}
            >
              <CircularProgress color="inherit" />
            </div>
          ) : (
            <>
              {depositedTokens && depositedTokens?.length > 0 ? (
                (() => {
                  const filteredAssets = depositedTokens.filter((item) =>
                    item?.token
                      ?.toLowerCase()
                      ?.includes(searchText.toLowerCase())
                  );

                  if (filteredAssets.length === 0) {
                    return (
                      <Box textAlign={"center"} py={5}>
                        <Typography variant="h3">No results found</Typography>
                      </Box>
                    );
                  }
                  console.log(
                    "file: TokensList.tsx:316  filteredAssets:",
                    filteredAssets
                  );

                  return filteredAssets.map((asset) => (
                    <WithdrawListComponent
                      asset={asset}
                      nextStep={() => {}}
                      transactionForm={false}
                    />
                  ));
                })()
              ) : (
                <CardLayout
                  backgroundColor="white"
                  style={{ flex: 1, width: "100%" }}
                >
                  <Slide in={true} timeout={500} direction={"down"}>
                    <Box
                      className="flex-col-center"
                      style={{
                        padding: "20px 0px",
                        height: "275px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        width: "100%",
                        position: "absolute",
                      }}
                    >
                      <Typography
                        variant={"h5"}
                        fontWeight={700}
                        color="rgba(26, 28, 32, 1)"
                        fontSize={25}
                        style={{ cursor: "pointer" }}
                      >
                        Fuel Up and Go
                      </Typography>
                      <Typography
                        variant={"h5"}
                        fontWeight={400}
                        color="rgba(26, 28, 32, 0.5)"
                        fontSize={12}
                        style={{ cursor: "pointer" }}
                      >
                        Get Started!
                      </Typography>
                      <img
                        src={BlankGas}
                        width={"100%"}
                        height={"100%"}
                        style={{
                          overflow: "hidden",
                        }}
                      />

                      <div
                        style={{
                          width: "70%",
                          maxWidth: "305px",
                          marginTop: "-6%",
                        }}
                      >
                        <Button
                          title="Fill Up"
                          style={{
                            backgroundColor: "rgba(235, 236, 238, 1)",
                            margin: 0,
                          }}
                          textStyle={{ color: "rgba(26, 28, 32, 1)" }}
                          onClick={() => {
                            navigate("/gas/fillup");
                          }}
                        />
                      </div>
                    </Box>
                  </Slide>
                </CardLayout>
              )}
              {depositedTokens && depositedTokens?.length > 0 && (
                <Typography
                  style={{
                    color: "rgba(26, 28, 32, 0.50)",
                    fontSize: 16,
                    fontWeight: 400,
                  }}
                >
                  Your gas can be used to pay for transactions across every
                  account!
                </Typography>
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default GasView;
