import React, { useEffect, useState } from "react";
import { Box, Grid, Input, Typography } from "@mui/material";
import SearchIcon from "assets/SearchInputIcon.svg";

import SearchOptionsIcon from "assets/searchOptionsIcon.svg";
import CenterItem from "../../../../components/CenterItem";
import NFTList from "./NFTList";
import SearchInput from "../../../../components/SearchInput";
import FilterModal from "components/Modal/FilterModal";
// import { useAppSelector } from "../../../../store/store";

import { useAppSelector } from "store/store";
import { filterData } from "constants/chains";

const NFTListTable = () => {
  const { activeAccount, newNFTs } = useAppSelector((state) => state.app);

  // const { nfts, activeAccount, activeNetwork } = useAppSelector(
  //   (state) => state.app
  // );
  const [searchText, setSearchText] = useState<string>("");
  const [openModal, setOpenModal] = useState(false);

  // const profileNfts = nfts[activeAccount.smartAccountAddress].nfts;
  // console.log("file: index.tsx:13  NFTListTable  nfts:", nfts, profileNfts);

  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState({
    ethereum: false,
    polygon: true,
    bnb: false,
    mantle: false,
  });
  const [selectText, setSelectText] = useState("Select All");

  const [selectedFilter, setSelectedFilter] = useState(
    filterData.map((item) => item?.chainId)
  ); // ["ethereum", "polygon", "bnb", "mantle"]

  const handleCheck = ({ chainName, chainId }) => {
    setChecked((prevState) => ({
      ...prevState,
      [chainName]: !prevState[chainName],
    }));

    setSelectedFilter((prevState) => {
      if (prevState.includes(chainId)) {
        return prevState.filter((item) => item !== chainId);
      } else {
        return [...prevState, chainId];
      }
    });
  };

  const handleSelectAll = (e) => {
    e.preventDefault();

    setChecked((prevState) => ({
      ...prevState,
      ethereum: selectText === "Select All" ? true : false,
      polygon: selectText === "Select All" ? true : false,
      bnb: selectText === "Select All" ? true : false,
      mantle: selectText === "Select All" ? true : false,
    }));

    if (selectText === "Select All") {
      setSelectText("Deselect All");
    } else {
      setSelectText("Select All");
    }

    setSelectedFilter((prevState) => {
      if (selectText === "Select All") {
        return filterData.map((item) => item?.chainId);
      } else {
        return [];
      }
    });
  };

  // if any is false then select all else deselect all
  useEffect(() => {
    const isAllTrue = Object.values(checked)?.every((chain) => chain === true);
    if (isAllTrue) {
      setSelectText("Deselect All");
    } else {
      setSelectText("Select All");
    }
  }, [checked]);

  const filterDataHandler = () => {
    setOpenModal(false);
    // filter assets on the basis of selectedFilter

    // setFilteredAssets(filteredData || []);
  };

  const filterModalProps = {
    open: openModal,
    onClose: () => setOpenModal(false),
    handleSelectAll,
    checked,
    selectText,
    filterData,
    handleCheck,
    filterDataHandler,
  };

  console.log(newNFTs, "newNFTs");
  console.log(activeAccount, "newNFTs");

  return (
    <div>
      <Box display="flex">
        <Box flex={1} alignItems={"center"}>
          <SearchInput
            handleOnChange={(e: any) => {
              setSearchText(e.target.value);
            }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            justifyContent: "space-between",
            paddingLeft: "12px",
          }}
          style={{
            cursor: "pointer",
          }}
        >
          <CenterItem>
            <img src={SearchOptionsIcon} onClick={() => setOpenModal(true)} />
          </CenterItem>

          <FilterModal modalProps={filterModalProps} />

          <CenterItem>
            <div className="tab-chip-box">
              <Typography
                variant={"h5"}
                fontWeight={500}
                color="white"
                fontSize={12}
                fontFamily={"Helvetica Neue"}
                whiteSpace={"nowrap"}
                letterSpacing={2}
              >
                + ADD NFT
              </Typography>
            </div>
          </CenterItem>
        </Box>
      </Box>
      <Grid>
        <NFTList
          searchFilterText={searchText}
          activeAccount={activeAccount}
          newNFTs={newNFTs}
        />
      </Grid>
    </div>
  );
};

export default NFTListTable;
