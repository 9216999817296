import { FormControl, IconButton, Typography } from "@mui/material";
import { InputProps } from "interfaces";
import { InputField } from "pages/auth/styles";
import React, { FC } from "react";
import WalletIcon from "assets/walletIcon.svg";
import CopyIcon from "assets/copy-white.svg";

const Input: FC<InputProps> = ({
  title,
  mode,
  disabled,
  type = "text",
  onChange,
  style = {},
  value,
  addBorder = false,
  showStartIcon = false,
  showEndIcon = false,
  length = 50,
  endIconAction,
}) => {
  return (
    <div className="input-container">
      <FormControl sx={{ flex: 1 }}>
        <Typography
          color="rgba(26, 28, 32, 0.50)"
          fontFamily="Space Grotesk"
          fontSize={11}
          fontWeight={400}
          sx={{
            position: "absolute",
            top: "10px",
            left: "15px",
            zIndex: 1,
          }}
        >
          {title}
        </Typography>
        <InputField
          className={`text-input ${mode && "question-input"} ${
            addBorder && "add-border"
          }`}
          disabled={disabled}
          // placeholder={title}
          sx={style}
          value={value}
          onChange={(e) =>
            e.target.value.length <= length && onChange(e.target.value)
          }
          type={type !== "text" ? "password" : "text"}
          startAdornment={showStartIcon && <img src={WalletIcon} />}
          endAdornment={
            showEndIcon && (
              <IconButton
                sx={{
                  height: 25,
                  width: 25,
                  backgroundColor: "#1A1C20",
                }}
                disableRipple={true}
                onClick={endIconAction}
              >
                <img src={CopyIcon} />
              </IconButton>
            )
          }
          autoComplete="off"
        />
      </FormControl>
    </div>
  );
};

export default Input;
