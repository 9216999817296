import { Box, styled, Typography, useTheme } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import BlueTick from "assets/BlueTick.svg";
import { ON_LEND1, ON_RENT, CLAIMABLE, nftTempImage } from "constants/";
import { roundOfNumber } from "utils/utils";

const CardWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "10px 10px 10px 10px",
  backgroundColor: "white",
  width: "20%",
  // height: "285px",
  borderRadius: 14,
  boxShadow: "0px 15px 30px rgba(118, 118, 118, 0.2)",
  marginRight: "20px",
  marginBottom: 30,
}));

const StatusStyle = status => {
  switch (status) {
    case ON_LEND1:
      return {
        border: "#FFE28F",
        bgColor: "rgba(255, 226, 143, 0.1)",
      };
    case ON_RENT:
      return {
        border: "#FFE28F",
        bgColor: "rgba(255, 226, 143, 0.1)",
      };
    case CLAIMABLE:
      return {
        border: "#E430D1",
        bgColor: "rgba(228, 48, 209, 0.1)",
      };
    default:
      return {
        border: "#30E46D",
        bgColor: "rgba(48, 228, 109, 0.1)",
      };
  }
};

const StatusBox = styled(Box)(({ theme, status }) => ({
  width: "90px",
  height: "20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: `1px solid ${StatusStyle(status).border}`,
  backgroundColor: `${StatusStyle(status).bgColor}`,
  borderRadius: "3px",
}));

export const NftPreviewCard = ({
  image,
  collectionName,
  tokenId,
  nftAddress,
  status,
  key,
}) => {
  const navigate = useNavigate();
  return (
    <CardWrapper key={key}>
      <img
        src={image || nftTempImage}
        onClick={() => navigate(`/collectibles/${nftAddress}/${tokenId}`)}
        alt={"nft"}
        onError={e => (e.currentTarget.src = nftTempImage)}
        style={{
          width: "100%",
          borderRadius: "11px",
          marginBottom: "15px",
          cursor: "pointer",
        }}
      />
      <Box
        display='flex'
        flexDirection='row'
        width='100%'
        marginBottom='5px'
        justifyContent='space-between'
      >
        <Box display='flex' flexDirection='column'>
          <Box display='flex' flexDirection='row'>
            <Typography
              variant='fs11'
              color={"black"}
              fontWeight={500}
              //   pl={1}
            >
              {collectionName?.slice(0, 15)}
            </Typography>
            <img
              src={BlueTick}
              style={{ marginLeft: "10px" }}
              alt={"blue tick"}
            />
          </Box>
          <Typography
            variant='fs14'
            color={"black"}
            fontWeight={500}
            // pl={1}
          >
            {`#${Number(tokenId) ? roundOfNumber(tokenId) : tokenId}`}
          </Typography>
        </Box>
      </Box>
    </CardWrapper>
  );
};
