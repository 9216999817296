// import ProjectInfoCard from '@/components/ProjectInfoCard'

// import RequesDetailsCard from '@/components/RequestDetalilsCard'

// import RequestMethodCard from '@/components/RequestMethodCard'

// import RequestModalContainer from '@/components/RequestModalContainer'

// import ModalStore from '@/store/ModalStore'

// import { Button, Divider, Modal, Text } from '@nextui-org/react'
import { Box, Button, Divider } from "@mui/material";
import ProjectInfoCard from "../components/ProjectInfoCard";
import RequestDetailsCard from "../components/RequestDetailsCard";
import RequestMethodCard from "../components/RequestMethodCard";
import RequestModalContainer from "../components/RequestModalContainer";
import { Fragment } from "react";
import ModalStore from "../walletConnectStore/ModalStore";

export default function SessionUnsuportedMethodModal() {
  // Get request and wallet data from store
  const requestEvent = ModalStore.state.data?.requestEvent;
  const requestSession = ModalStore.state.data?.requestSession;

  // Ensure request and wallet are defined
  if (!requestEvent || !requestSession) {
    return <p>Missing request data</p>;
  }

  // Get required request data
  const { topic, params } = requestEvent;
  const { chainId, request } = params;

  return (
    <Box
      sx={{
        background: "white",
        overflowY: "scroll",
        width: "50%",
        height: "70%",
        transform: "translateX(50%) translateY(10%)",
        padding: "10px",
      }}
    >
      <RequestModalContainer title="Unsuported Method">
        <ProjectInfoCard metadata={requestSession.peer.metadata} />

        <Divider />

        <RequestDetailsCard
          chains={[chainId ?? ""]}
          protocol={requestSession.relay.protocol}
        />

        <Divider />

        <RequestMethodCard methods={[request.method]} />
      </RequestModalContainer>

      <div>
        <Button color="error" onClick={ModalStore.close}>
          Close
        </Button>
      </div>
    </Box>
  );
}
