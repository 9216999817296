import React, { ReactNode, useState } from "react";
import { Button, Collapse, Paper, Typography, makeStyles } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/system";

function CollapeWrapper({
  LeftComponent,
  BodyComponent,
  defaultOpenState = false,
}: {
  LeftComponent: ReactNode;
  BodyComponent: ReactNode;
  defaultOpenState?: boolean;
}) {
  const [open, setOpen] = useState(defaultOpenState);

  const toggleCollapse = () => {
    setOpen(!open);
  };

  return (
    <Paper
      elevation={3}
      sx={{
        borderRadius: "10px",
        boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Box
        onClick={toggleCollapse}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "25px",
          cursor: "pointer",
        }}
      >
        {LeftComponent}
        {/* <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          <SortOutlinedIcon />
          <Typography variant="h6">{title}</Typography>
        </Box> */}
        <ExpandMoreIcon
          style={{ transform: open ? "rotate(180deg)" : "rotate(0deg)" }}
        />
      </Box>
      <Collapse in={open}>
        <Box sx={{ padding: "0px 30px 30px 30px" }}>{BodyComponent}</Box>
      </Collapse>
    </Paper>
  );
}

export default CollapeWrapper;
