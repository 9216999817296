import React, { useEffect, useState } from "react";
import * as Bip39 from "bip39";
import sss from "shamirs-secret-sharing";
import pbkdf2 from "pbkdf2";

import { CircularProgress, Grid, Typography } from "@mui/material";
import ens from "@ensdomains/eth-ens-namehash";

import TextInput from "components/TextInput";
import Button from "components/NewButton";
import { useLocation, useNavigate } from "react-router-dom";
import AuthHeader from "components/AuthHeader";
import {
  addUser,
  createEVMAccount,
  createEVMSmartAccount,
  decryptMessage,
  encryptMessage,
  initalizeWeb3,
  isEnsExist,
  saveEncryptedKeys,
  saveEns,
  saveSubEns,
  userNameRegexValidation,
} from "utils/utils";
import { useAppDispatch, useAppSelector } from "store/store";
import {
  saveMnemonic,
  setAccounts,
  setActiveAccount,
  setBlockNumbers,
  setEncryptedSeedPhraseWithPassword,
  setExpirationTime,
  setHoldings,
  setOwnReferralCode,
  setReferredTo,
  setRootAccountInfo,
} from "@slices/appSlice";
import { Box } from "@mui/system";
import CustomizedSteppers from "components/Stepper";
import { stepsCount } from "../../../../../src/constants";
import { THRESHOLD, SHARES, LOGIN_EXPIRY } from "constants/";
import { setHashedPin, setRecoveryCode } from "@slices/walletSlice";
import { initalizeBlockNumbers } from "utils/blocknumber";
import StepperForPages from "components/Stepper/StepperForPages";

const CashStashedTag = () => {
  const [accountName, setAccountName] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const {
    user: { email, name },
    activeNetwork,
    accessToken,
    externalReferralCode,
    encryptedSeedPhraseWithPassword,
    rootAccountInfo,
  } = useAppSelector((state) => state.app);

  const { torusKey, hashedPin, recoveryCode, hashedPassword } = useAppSelector(
    (state) => state.wallet
  );

  const location = useLocation();

  const { state } = location || {};
  const { currentStep, pathName } = state || {};

  const [step, setStep] = useState(parseInt(currentStep) || 0);

  useEffect(() => {
    if (!email) {
      navigate("/create-account");
    }
  }, []);

  const bufferToReadable = (buffer) => {
    return buffer.toString("hex");
  };

  const handleFormValidation = (username: string) => {
    if (username.includes(".")) {
      setError("No special characters (letters & numbers only)");
    } else if (!userNameRegexValidation(username)) {
      setError("No special characters (letters & numbers only)");
    } else if (username.length < 4 || username.length > 20) {
      setError("Cash Tag must be 4-20 characters");
    } else {
      return true;
    }
  };

  const createAccount = async () => {
    try {
      setLoading(true);
      const normzalized = ens.normalize(accountName);
      console.log(normzalized);
      const isOk = handleFormValidation(accountName);

      if (isOk) {
        const data = await isEnsExist(normzalized);

        const hashedTorusKey = pbkdf2
          .pbkdf2Sync(torusKey, "salt", 1, 32, "sha512")
          .toString("hex");

        let deriveChildNum = 0;

        console.log("data", data);

        if (!data) {
          let seedphrase = Bip39.generateMnemonic();

          const secretBuffer = Buffer.from(seedphrase);
          const generatedShares = sss.split(secretBuffer, {
            shares: SHARES,
            threshold: THRESHOLD,
          });

          let s = generatedShares.map((s) => bufferToReadable(s));

          const encryptedShare1 = encryptMessage(s[0], hashedTorusKey);
          const encryptedShare2 = encryptMessage(s[1], hashedPassword);
          const encryptedShare3 = encryptMessage(s[2], hashedPin);
          const encryptedShare4 = encryptMessage(s[3], recoveryCode);

          const { address, ens, secret } = await createEVMAccount(
            seedphrase,
            deriveChildNum,
            normzalized,
            hashedPassword
          );

          const {
            newAccount,
            address: cryptoAddress,
            secret: cryptoSecret,
            counterfactual,
            accountName,
          } = await createEVMSmartAccount(
            seedphrase,
            1,
            normzalized,
            hashedPassword,
            activeNetwork
          );
          console.log(
            "🚀 ~ file: index.tsx:116 ~ createAccount ~ newAccount:",
            newAccount
          );

          if (!ens) {
            const web3 = initalizeWeb3(activeNetwork);

            const pkey = decryptMessage(secret, hashedPassword);

            const signature = web3.eth.accounts.sign(normzalized, pkey);
            await saveEns(
              normzalized,
              address.toLowerCase(),
              signature.signature
            );
            await saveSubEns(
              normzalized,
              counterfactual.toLowerCase(),
              address.toLowerCase(),
              signature.signature
            );
            const encryptedSeedPhraseWithPassword = encryptMessage(
              seedphrase,
              hashedPassword
            );
            dispatch(
              setRootAccountInfo({
                address,
                name: normzalized,
                secret,
              })
            );
            dispatch(
              setEncryptedSeedPhraseWithPassword(
                encryptedSeedPhraseWithPassword
              )
            );
          }

          const res = await saveEncryptedKeys(
            accessToken,
            email,
            name,
            address.toLowerCase(),
            encryptedShare1,
            encryptedShare2,
            encryptedShare3,
            encryptedShare4,
            externalReferralCode
          );
          await addUser(address.toLowerCase());

          const blockNumbers = await initalizeBlockNumbers();

          dispatch(setOwnReferralCode(res.referenceCode));
          dispatch(setReferredTo(res.referedTo));
          dispatch(setBlockNumbers(blockNumbers));

          dispatch(setExpirationTime(new Date().getTime() + LOGIN_EXPIRY));

          dispatch(
            setHoldings({
              [counterfactual]: {
                nativeBalance: 0,
                nativeBalanceUsd: 0,
                nativeTokenPrice: 0,
                tokens: [],
              },
            })
          );

          dispatch(setAccounts(newAccount));
          dispatch(
            setActiveAccount({
              address: cryptoAddress,
              secret: cryptoSecret,
              smartAccountAddress: counterfactual,
              accountName,
            })
          );

          dispatch(setHashedPin(""));
          dispatch(setRecoveryCode(""));
          dispatch(saveMnemonic(""));

          setLoading(false);

          setLoading(false);

          navigate("/account-created", {
            state: { currentStep: step + 1, pathName: pathName },
          });
        } else {
          setLoading(false);
          setError("Cash Tag already in use. Try another!");
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error.toString().includes("Illegal char")) {
        setError("No special characters (letters & numbers only)");
        console.log("err", error);
      } else {
        setError(
          error?.response?.data?.message ||
            error?.message ||
            "Something went wrong"
        );
      }
    }
  };

  const handleKeyPress = (e: any) => {
    const { key, keyCode } = e || {};
    if (key === "Enter" || keyCode === 13 || key === "Tab" || keyCode === 9) {
      e.preventDefault(); // Prevent the default behavior of the Tab key
      createAccount();
    }
  };

  const totalSteps = stepsCount[pathName] || 3;

  return (
    <div className="create-account-container">
      <Grid container spacing={2}>
        <Grid item xs={8} className="auth-header-with-steps">
          <AuthHeader />
        </Grid>
        <Grid
          item
          xs={16}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box
            className="auth-header-steps"
            width={"50%"}
            style={{
              position: "absolute",
              top: "54px",
            }}
          >
            <StepperForPages
              step={step}
              steps={new Array(totalSteps).fill("")}
              changeStep={(selectedStep: number) => {
                //eg. if user is on step 3 he should be able to move at step 1 or 2 on clicking step icon
                if (selectedStep < step) {
                  setStep(selectedStep);
                  navigate(-1);
                }
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <div className="create-account-box">
        <>
          <Typography
            variant="h2"
            sx={{
              margin: "30px 0px 30px 0px",

              fontWeight: "700",
            }}
          >
            Create your Stashed-Tag
          </Typography>

          <Typography
            variant="body2"
            sx={{
              margin: "0px 0px 10px 0px",
              color: "rgba(26, 28, 32, 0.5)",

              fontWeight: "500",
              textAlign: "center",
              // width: "25%",
            }}
          >
            Your Stashed Tag will be used for receiving funds on Stashed.
          </Typography>

          <div className="welcome-box-container">
            <TextInput
              title="Stashed Tag"
              value={accountName}
              onChange={setAccountName}
              style={{
                width: "350px",
              }}
              onKeyPress={(e) => handleKeyPress(e)}
            />

            {error && (
              <div className="password-validator-box">
                <Typography
                  style={{ color: "red", fontSize: 14, marginTop: -20 }}
                >
                  {error}
                </Typography>
              </div>
            )}

            <Button
              onClick={createAccount}
              title="Continue"
              loading={loading}
              disabled={!accountName || accountName.length < 4}
              style={{
                margin: 0,
                width: "370px",
                padding: "15px 0px",
              }}
            />
          </div>
        </>
      </div>
    </div>
  );
};

export default CashStashedTag;
