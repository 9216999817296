import axios from "axios";
import { BASE_URL } from "../constants";
import { decryptMessage, initalizeWeb3 } from "./utils";
import Web3 from "web3";
import { SupportedChainId } from "../constants/chains";

export const getAllUserContacts = async ({ address }: { address: string }) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/security-question/getUser/${address.toLocaleLowerCase()}`
    );
    // return { data, error: null, message: null };
    if (data?.contacts) {
      console.log(
        "file: contact.ts:13  getAllUserContacts  data?.contacts:",
        data?.contacts
      );
      return data?.contacts;
    }
    return [];
  } catch (e: any) {
    console.log("file: contact.ts:34  e:", e);
    throw new Error(e.message);
    // return { data: null, error: true, message: e.message };
  }
};
export const addContactService = async ({
  userAddressOrEns,
  privateKey,
  activeNetwork,
  address,
}: {
  userAddressOrEns: string;
  privateKey: string;
  activeNetwork: SupportedChainId;
  address: string;
}) => {
  try {
    const web3 = initalizeWeb3(activeNetwork);
    const isAddress = web3.utils.isAddress(userAddressOrEns);
    if (isAddress) {
      const userENSId = await getENSIdFromAddress({
        text: userAddressOrEns.toLocaleLowerCase(),
      });
      console.log(
        "file: contact.ts:21  addContactService  getEnsFromAddressOrName handleAddContact userENSId:",
        userENSId,
        privateKey
      );

      // const ensId = await getENS({address:address})
      //  const pkey = decryptMessage(rootAccsountInfo.secret, hashedPassword);\
      const signature = web3.eth.accounts.sign(`${userENSId._id}`, privateKey);
      console.log(
        "file: contact.ts:43  addContacts handleAddContact signature:",
        signature
      );

      const { data } = await axios.post(
        `${BASE_URL}/security-question/addContacts`,
        {
          address: address?.toLocaleLowerCase(),
          ensId: userENSId._id,
          signature: signature.signature,
        }
      );
      console.log("file: contact.ts:52 handleAddContact data:", data);

      return data;
    } else {
      const userENSId = await getUserIdFromInput({
        text: userAddressOrEns.toLocaleLowerCase(),
      });
      console.log(
        "file: contact.ts:21  addContactService  getEnsFromAddressOrName handleAddContact userENSId:",
        userENSId,
        privateKey
      );

      // const ensId = await getENS({address:address})
      //  const pkey = decryptMessage(rootAccsountInfo.secret, hashedPassword);\
      const signature = web3.eth.accounts.sign(`${userENSId._id}`, privateKey);
      console.log(
        "file: contact.ts:43  addContacts handleAddContact signature:",
        signature
      );

      const { data } = await axios.post(
        `${BASE_URL}/security-question/addContacts`,
        {
          address: address?.toLocaleLowerCase(),
          ensId: userENSId._id,
          signature: signature.signature,
        }
      );
      console.log("file: contact.ts:52 handleAddContact data:", data);

      return data;
    }
  } catch (e: any) {
    console.log("file: contact.ts:64  e:", e);
    // window.alert(e.message);
    throw new Error(e.message);
  }
};

export const getENSIdFromAddress = async ({ text }: { text: string }) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/ens/getENSIdFromAddress/${text}`
    );
    console.log("file: contact.ts:56  data:", data);
    if (!data) {
      throw new Error("No ENS Found from provided Address");
    }
    // return { data, error: null, message: null };
    return data;
  } catch (e: any) {
    console.log("file: contact.ts:34  e:", e);
    throw new Error(e.message);
    // return { data: null, error: true, message: e.message };
  }
};

export const getUserIdFromInput = async ({ text }: { text: string }) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/ens/getEnsFromAddressOrName/${text}`
    );
    console.log("file: contact.ts:56  data:", data);
    if (!data) {
      throw new Error("No ENS Found from provided Address");
    }
    // return { data, error: null, message: null };
    return data;
  } catch (e: any) {
    console.log("file: contact.ts:34  e:", e);
    throw new Error(e.message);
    // return { data: null, error: true, message: e.message };
  }
};

export const applyCode = async ({
  address = "",
  referralCode = "",
  signature = "",
}: {
  address: string;
  referralCode: string;
  signature: string;
}) => {
  const { data } = await axios.post(
    `${BASE_URL}/security-question/addReferal`,
    {
      address: address,
      referalCode: referralCode,
      signature,
    }
  );

  return data;
};
