import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, Grid, Tooltip } from "@mui/material";
import BlueTick from "assets/BlueTick.svg";
import { Link } from "react-router-dom";
import mime from "mime-types";
import { trimTokenAddress } from "utils/utils";

export default function NFTCard({
  name,
  nftId,
  imageSrc,
  chainName,
}: {
  name: string;
  nftId: string;
  imageSrc: string;
  chainName: string;
}) {
  return (
    // <Link to={`${nftId}/${chainName}`} style={{ textDecoration: "none" }}>
    <Card
      sx={{
        borderRadius: "10px",
        boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <CardActionArea>
        <CardMedia
          component="img"
          height="230"
          image={imageSrc}
          alt={`${nftId} ${chainName}`}
        />
        <CardContent>
          <Grid gap={1} container flexDirection={"column"}>
            <Grid item>
              <Typography
                variant="h6"
                color="rgba(26, 28, 32, 0.50)"
                style={{
                  textDecoration: "none",
                }}
              >
                {name}
                <img
                  src={BlueTick}
                  style={{ marginLeft: "5px", height: "15px" }}
                  alt={"blue tick"}
                />
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                component="div"
                style={{
                  textDecoration: "none",
                }}
              >
                #{trimTokenAddress(nftId)}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
    // </Link>
  );
}
