import { BytesLike, Wallet } from "ethers";
import { IUserOperation } from "userop";
import { arrayify, defaultAbiCoder, keccak256 } from "ethers/lib/utils";
import {
  ecsign,
  toRpcSig,
  keccak256 as keccak256_buffer,
} from "ethereumjs-util";

/**
 * Signs a user operation message.
 *
 * @param {IUserOperation} op - The operation to be signed.
 * @param {Wallet} signer - The wallet instance used for signing.
 * @param {string} entryPoint - The entry point address.
 * @param {number} chainId - The chain ID of the Ethereum network.
 *
 * @returns {BytesLike} - The signed message.
 */
export function signUserOp(
  op: IUserOperation,
  signer: Wallet,
  entryPoint: string,
  chainId: number
): BytesLike {
  const message = getUserOpHash(op, entryPoint, chainId);
  const msg1 = Buffer.concat([
    Buffer.from("\x19Ethereum Signed Message:\n32", "ascii"),
    Buffer.from(arrayify(message)),
  ]);

  const sig = ecsign(
    keccak256_buffer(msg1),
    Buffer.from(arrayify(signer.privateKey))
  );
  // that's equivalent of:  await signer.signMessage(message); but without "async"
  const signedMessage1 = toRpcSig(sig.v, sig.r, sig.s);
  return signedMessage1;
}

/**
 * Computes a hash for a user operation along with the entry point and chain ID.
 *
 * @param {IUserOperation} op - The user operation to be hashed.
 * @param {string} entryPoint - The entry point address for this operation.
 * @param {number} chainId - The chain ID for the Ethereum network.
 * 0xDbfA076EDBFD4b37a86D1d7Ec552e3926021fB97
 * @returns {string} - The resulting keccak256 hash.
 */

function getUserOpHash(
  op: IUserOperation,
  entryPoint: string,
  chainId: number
): string {
  const userOpHash = keccak256(packUserOp(op, true));
  const enc = defaultAbiCoder.encode(
    ["bytes32", "address", "uint256"],
    [userOpHash, entryPoint, chainId]
  );
  return keccak256(enc);
}

/**
 * Packs a user operation into a string format suitable for hashing or signatures.
 *
 * @param {IUserOperation} op - The user operation to pack.
 * @param {boolean} [forSignature=true] - Whether the packed data is intended for a signature.
 *
 * @returns {string} - The packed user operation as a string.
 */

function packUserOp(op: IUserOperation, forSignature = true): string {
  if (forSignature) {
    return defaultAbiCoder.encode(
      [
        "address",
        "uint256",
        "bytes32",
        "bytes32",
        "uint256",
        "uint256",
        "uint256",
        "uint256",
        "uint256",
        "bytes32",
      ],
      [
        op.sender,
        op.nonce,
        keccak256(op.initCode),
        keccak256(op.callData),
        op.callGasLimit,
        op.verificationGasLimit,
        op.preVerificationGas,
        op.maxFeePerGas,
        op.maxPriorityFeePerGas,
        keccak256(op.paymasterAndData),
      ]
    );
  } else {
    // for the purpose of calculating gas cost encode also signature (and no keccak of bytes)
    return defaultAbiCoder.encode(
      [
        "address",
        "uint256",
        "bytes",
        "bytes",
        "uint256",
        "uint256",
        "uint256",
        "uint256",
        "uint256",
        "bytes",
        "bytes",
      ],
      [
        op.sender,
        op.nonce,
        op.initCode,
        op.callData,
        op.callGasLimit,
        op.verificationGasLimit,
        op.preVerificationGas,
        op.maxFeePerGas,
        op.maxPriorityFeePerGas,
        op.paymasterAndData,
        op.signature,
      ]
    );
  }
}
