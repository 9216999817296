import { Box } from "@mui/system";
import React, { FC } from "react";
import ReviewAssetListComponent from "./ReviewAssetListComponent";

const SendAsset: FC<{
  executeSend?: () => {};
  value: number;
  constantValue?: string;

  nextStep?: () => void;
}> = ({
  nextStep = () => {},
  executeSend = () => {},
  value,
  constantValue,
}) => {
  return (
    <Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        textAlign={"center"}
      >
        <ReviewAssetListComponent value={value} constantValue={constantValue} />
      </Box>
    </Box>
  );
};

export default SendAsset;
