import React, { useEffect, useState } from "react";

import { Grid, Typography } from "@mui/material";
import TextInput from "components/TextInput";
import Button from "components/NewButton";
import { useNavigate } from "react-router-dom";
import AuthHeader from "components/AuthHeader";
import { fetchEncryptedKeysByName, showAlert } from "utils/utils";
import { useAppDispatch, useAppSelector } from "store/store";
import {
  setFetchedKeys,
  setOwnReferralCode,
  setReferredTo,
  setUserInfo,
} from "@slices/appSlice";
import { Box } from "@mui/system";
import CustomizedSteppers from "components/Stepper";
import { stepsCount } from "../../../../../../src/constants";
import StepperForPages from "components/Stepper/StepperForPages";

const RecoverStashedTag = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);

  const dispatch = useAppDispatch();

  const handleContinue = async () => {
    try {
      setLoading(true);
      const data = await fetchEncryptedKeysByName(username);
      console.log(data);
      setLoading(false);
      if (!data) {
        setLoading(false);
        showAlert("Cash Tag does not exist");
      } else {
        dispatch(
          setUserInfo({
            email: data.email,
            name: data.username,
          })
        );
        dispatch(setReferredTo(data.referedTo));
        dispatch(setOwnReferralCode(data.referenceCode));

        dispatch(
          setFetchedKeys({
            rootAddress: data.address,
            key1: data.keys[0]?.key1,
            key2: data.keys[0]?.key2,
            key3: data.keys[0]?.key3,
            key4: data.keys[0]?.key4,
          })
        );
        setStep(1);
        navigate("/update-signin-method/recover-password", {
          state: { currentStep: step + 1, pathName: "/recover-stashed-tag" },
        });
      }
    } catch (error) {
      setLoading(false);
      showAlert("Cash Tag does not exist");
      console.log(error);
    }
  };

  const handleKeyPress = (e: any) => {
    const { key, keyCode } = e || {};
    if (key === "Enter" || keyCode === 13 || key === "Tab" || keyCode === 9) {
      e.preventDefault(); // Prevent the default behavior of the Tab key
      handleContinue();
    }
  };

  const totalSteps = stepsCount["/recover-stashed-tag"] || 3;

  return (
    <div className="create-account-container">
      <Grid container spacing={2}>
        <Grid item xs={8} className="auth-header-with-steps">
          <AuthHeader />
        </Grid>
        <Grid
          item
          xs={16}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box
            className="auth-header-steps"
            width={"50%"}
            style={{
              position: "absolute",
              top: "54px",
            }}
          >
            <StepperForPages
              step={step}
              steps={new Array(totalSteps).fill("")}
              changeStep={(selectedStep: number) => {
                //eg. if user is on step 3 he should be able to move at step 1 or 2 on clicking step icon
                if (selectedStep < step) {
                  setStep(selectedStep);
                }
              }}
            />
          </Box>
        </Grid>
      </Grid>

      <div className="create-account-box">
        <Typography
          variant="h2"
          sx={{
            margin: "30px 0px 30px 0px",

            fontWeight: "700",
          }}
        >
          Enter your Stashed Tag
        </Typography>
        <div className="welcome-box-container">
          <TextInput
            title="Stashed Tag"
            value={username}
            onChange={setUsername}
            style={{
              width: "350px",
            }}
            onKeyPress={handleKeyPress}
          />

          <Button
            onClick={handleContinue}
            title="Continue"
            loading={loading}
            style={{
              width: "380px",
              padding: "15px 0px",
              margin: "20px 0px 0px 0px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default RecoverStashedTag;
