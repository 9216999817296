import { Box, Grid, IconButton, Typography, useTheme } from "@mui/material";

import DepositIcon from "assets/deposit.svg";
import SendIcon from "assets/send-icon.svg";
import CashIcon from "assets/cash-out.svg";
import WithdrawIcon from "assets/withdraw.svg";
import BuyIcon from "assets/buyIcon.svg";

import ChipButton from "components/ChipButton";
import ExpandableChart from "components/ExpandableChart";
import InfoCard from "components/InfoCard";
import TokensListTable from "./TokensListTable";

import { FC, useEffect, useState } from "react";

import InfoCardAmount from "../../../components/InfoCardAmount";

import { LIGHT_BLUE_COLOR } from "constants/";
import { DARK_BLUE_COLOR } from "constants/";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setHistory } from "@slices/appSlice";

import Web3 from "web3";
import paymasterAbi from "abis/paymasterabi.json";
import { tokensForGas } from "constants/topTokensConf";
import { provider } from "web3-core";
import { Paymaster_Token_Address } from "../../../contract-integration/constants";
import CryptoCard from "assets/cryptoCard.svg";
import { useAppSelector } from "store/store";
import { ethers } from "ethers";
import { getAssetsDollarWorth } from "utils/portfolio";

export interface CryptoViewProps {
  accountName: string;
  totalWalletBalance: number;
  chartData: number[];
  chartTimestamps: number[];
}

const CryptoView: FC<CryptoViewProps> = ({
  accountName,
  totalWalletBalance,
  chartData,
  chartTimestamps,
}) => {
  const walletBalance = parseFloat(totalWalletBalance + "").toFixed(2);
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();

  const [cummulativeGas, setCummulativeGas] = useState(0);
  const { accounts } = useAppSelector((state) => state.app);
  const activeAccountAddress = Object.values(accounts)[0].smartAccountAddress;

  const readAccountHoldings = async (
    tokenAddress: string,
    address: string,
    rpc: provider,
    decimal: number
  ) => {
    const web3 = new Web3(rpc);
    const paymasterContract = new web3.eth.Contract(
      paymasterAbi as any,
      Paymaster_Token_Address
    );

    try {
      const accountHolding = await paymasterContract.methods
        .balances(tokenAddress, address)
        .call();
      return Number(ethers.utils.formatUnits(String(accountHolding), decimal));
    } catch (error) {
      console.error("Error reading account holdings:", error);
      return 0; // Handle the error gracefully
    }
  };

  useEffect(() => {
    // Create an object to keep track of cumulative balances for each token
    type CumulativeBalances = { [tokenAddress: string]: number };
    const cumulativeBalances: CumulativeBalances = {};

    // Iterate through the accounts and fetch balances for each
    async function fetchBalances() {
      let tokenAddressesForMobula = tokensForGas.map(
        (tokenInfo) => tokenInfo.tokenAddressForMobula
      );

      // Call the getAssetsDollarWorth function with the tokenAddressesForMobula array as the argument
      const tokenData = await getAssetsDollarWorth(tokenAddressesForMobula);

      // Extract the prices from the returned object
      let tokenWorthInUSD = Object.values(tokenData).map(
        (token) => token.price
      );

      // Now you can use the tokenWorthInUSD array in your loop
      for (let i = 0; i < tokensForGas.length; i++) {
        const tokenInfo = tokensForGas[i];
        const { tokenAddress, rpc, decimal } = tokenInfo;
        const balance = await readAccountHoldings(
          tokenAddress,
          activeAccountAddress,
          rpc,
          decimal
        );

        const tokenWorthInUSDForCurrentToken = tokenWorthInUSD[i];
        const balanceInUSD = balance * tokenWorthInUSDForCurrentToken;

        // Add the balance to the cumulative balance for the token
        if (!cumulativeBalances[tokenAddress]) {
          cumulativeBalances[tokenAddress] = balanceInUSD;
        } else {
          cumulativeBalances[tokenAddress] += balanceInUSD;
        }
      }
      // Calculate the total cumulative balance
      let totalBalance = 0;
      for (const tokenAddress of Object.keys(cumulativeBalances)) {
        totalBalance += cumulativeBalances[tokenAddress];
      }

      setCummulativeGas(totalBalance);
    }

    fetchBalances();
  }, [accounts]);

  useEffect(() => {
    dispatch(
      setHistory({
        interval: 1,
      })
    );
  }, []);

  return (
    <Box width={"100%"}>
      <Box
        className="flex-row-center"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "20px",
          // flexDirection: 'row',
          gap: "26px",
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
          },
        }}
      >
        {/* User Info & Balance Area */}
        <Box sx={{ paddingTop: "0px !important", flex: 1, width: "100%" }}>
          {/* <Card title="@igors" ens="" balance="50.67" /> */}
          <InfoCard
            backgroundImage={`url(${CryptoCard})`}
            backgroundPosition="0px"
            backgroundSize="cover"
            title={`@${accountName?.toLowerCase()}`}
            amount={<InfoCardAmount amount={walletBalance} />}
            // amount={walletBalance}
            gas={String(cummulativeGas > 0 ? cummulativeGas.toFixed(4) : 0)}
            isCashTab={false}
          />
        </Box>

        {/* Receive Cash Area */}

        <Box
          sx={{
            paddingTop: "0px !important",
            flex: 1,
            width: "100%",
            height: "100%",
          }}
        >
          <ExpandableChart
            chartData={
              chartData.length === 1 ? [chartData[0], chartData[0]] : chartData
            }
            chartTimestamps={
              chartTimestamps.length === 1
                ? [chartTimestamps[0], Date.now()]
                : chartTimestamps
            }
            title={accountName}
            amount={walletBalance + ""}
            percentageChange="5"
            chartColor={LIGHT_BLUE_COLOR}
            chartStrokeColor={DARK_BLUE_COLOR}
            isCryptoScreen={true}
          />
        </Box>
      </Box>
      <Box mt={2.5}>
        <Box
          className="flex-row-center"
          style={{
            display: "flex",
            gap: "4px",
            justifyContent: "flex-start",
            flexFlow: "wrap",
          }}
        >
          <ChipButton
            title="Receive"
            onClick={() => {
              navigate("/crypto/receive");
            }}
            icon={DepositIcon}
            fontWeight={400}
            fontSize={14}
            fontFamily="Helvetica Neue"
          />
          <ChipButton
            title="Send"
            onClick={() => {
              navigate("/crypto/send");
            }}
            icon={SendIcon}
            fontWeight={400}
            fontSize={14}
            fontFamily="Helvetica Neue"
          />

          <ChipButton
            title="Buy"
            onClick={() => {
              navigate("/crypto/buy");
            }}
            icon={BuyIcon}
            fontWeight={400}
            fontSize={14}
            fontFamily="Helvetica Neue"
          />

          <ChipButton
            title="Sell"
            onClick={() => {
              navigate("/crypto/sell");
            }}
            icon={CashIcon}
            fontWeight={400}
            fontSize={14}
            fontFamily="Helvetica Neue"
          />
        </Box>

        <Grid pt={5}>
          <TokensListTable
            chainId={137}
            isApplyFilter={false}
            isShowTokenAmountUnderName
          />
        </Grid>
      </Box>
    </Box>
  );
};

export default CryptoView;
