import { Box } from "@mui/system";
import React from "react";
import StashedTag from "./StashedTag";
import UpdateDetails from "./UpdateDetails";
import ViewDetails from "./ViewDetails";
import { useAppSelector } from "../../../../../../store/store";
import { SETTINGS_OPENED_OPTIONS } from "../../../../../../interfaces";
import SeedphraseSettings from "../../../SeedphraseSettings";
import SettingsViewRecoveryKey from "components/SettingsViewRecoveryKey";

const AccountMenu = () => {
  const { settingsOpenOption } = useAppSelector((state) => state.app);

  const isOptionOpened =
    settingsOpenOption == SETTINGS_OPENED_OPTIONS.RECOVERY ||
    settingsOpenOption == SETTINGS_OPENED_OPTIONS.SEEDPHRASE;

  const SettingsComponent = {
    [SETTINGS_OPENED_OPTIONS.RECOVERY]: <SettingsViewRecoveryKey />,
    [SETTINGS_OPENED_OPTIONS.SEEDPHRASE]: <SeedphraseSettings />,
  };
  return (
    <>
      <Box display={"flex"} flexDirection={"column"} gap={3}>
        {isOptionOpened ? (
          <>{SettingsComponent[settingsOpenOption]}</>
        ) : (
          <>
            <StashedTag />
            <UpdateDetails />
            <ViewDetails />
          </>
        )}
      </Box>
    </>
  );
};

export default AccountMenu;
