import { providers, Wallet, BytesLike, ethers, BigNumber } from "ethers";
import {
  EntryPoint__factory,
  TokenPaymaster__factory,
  Token__factory,
} from "./types/ethers-contracts";
import { IUserOperation } from "userop";
import {
  CallDataType,
  EMPTY_CALLDATA,
  EntryPoint_Address,
  ExecuteCall,
  FactoryData,
  simulateHandleOps,
  getPartialUserOpForVerifyingPaymaster,
  getVerifierPaymasterSignatureFromServer,
  PaymasterType,
  Paymaster_Owner_Address,
  TransferData,
  simulateValidation,
  sendUserOp,
  getBatchCalldataForBundlerWithToken,
  Paymaster_Token_Address,
  getNonceForBuilder,
  SALT,
} from ".";
import { signUserOp } from "./utils/helper";
import axios from "axios";
import { getSignatureObj } from "../utils/transaction";
import { getAssetsDollarWorth, getTokenDollarValue } from "../utils/portfolio";
// import 'dotenv/config';

const rpcEndpoint =
  "https://polygon-mainnet.g.alchemy.com/v2/HBxGEElD4fSo3gWukvZFV9YTKO4OvCnw";
const bundlerRPC =
  "https://api.stackup.sh/v1/node/221b5cfa6d4f5cff2e221d693b2e953d49d9797d0f18f2e6d119482223a92a37";
const USDC = "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174";
const NATIVE = "0x";
const ZERO = "0";

const DEFAULT_GAS_LIMITS = "5000000";
const DEFAULT_PRE_VERIFICATION_GAS = "81000";
const DEPLOYMENT_GAS = "200000";

interface CallLimits {
  verificationGasLimit: BigNumber;
  callGasLimit: BigNumber;
}

// (async function () {
//     await txSubmissionOrderPrepaid();
// })();

/**
 * @notice The paymaster should have atleast have 3.7 matic (i.e 180 gas price) Matic for the execution to be simulated sucessfully.
 *         On ethereum mainnet, the paymaster should have 0.4 Eth (i.e 40 gas price) ether to cater for larger transactions.
 *         These are only estimations, actual gas will be very less compared to what we have.
 */
export async function txSubmissionOrderPrepaid({
  wallet,
  counterfactual,
  userCallDataArray,
  transferData,
  transferTokenDecimal,
  isHighFees,
  isAccountDeployed,
}: {
  wallet: Wallet;
  counterfactual: string;
  userCallDataArray: ExecuteCall[];
  transferData: TransferData;
  transferTokenDecimal: number;
  isHighFees: boolean;
  isAccountDeployed: boolean;
}) {
  //   const wallet = new Wallet(
  //     "86aa7a7267d9dcbffab6f5a9c1c1139dd8dc653fafda275f017b033cfa74d925"
  //   );
  const paymaster_signer = new Wallet(
    "86aa7a7267d9dcbffab6f5a9c1c1139dd8dc653fafda275f017b033cfa74d925"
  );

  const provider = new ethers.providers.JsonRpcProvider(rpcEndpoint);
  //   const counterfactual = "0xFB9e15Be07349E97b2a74De856b99715568769A9";
  // const counterfactual = "0x37eA4e0f7d1A15225AEe4a05CfB9FcF1d2B4e4Ed";
  // const counterfactual = "0x59d9851af6e4d731ae1b308319ffe3656f4e01d3";
  // const counterfactual = "0xaeff2be73ac8b0bdbc54bf32fdfb68d94a022697";

  //   let userCallDataArray: ExecuteCall[] = [];

  // Assuming the user wants to call approve on some token using prepaid transaction.
  const tokenAddress = USDC;
  // const userAction = Token__factory.createInterface().encodeFunctionData("approve", [
  //     Paymaster_Token_Address, "2000000"
  // ]);
  // const userAction2 = TokenPaymaster__factory.createInterface().encodeFunctionData("addDeposit", [
  //     tokenAddress, counterfactual, "2000000"
  // ]);
  // userCallDataArray.push({ to: tokenAddress, value: ZERO, calldata: userAction });
  //   const userAction = Token__factory.createInterface().encodeFunctionData(
  //     "transfer",
  //     [Paymaster_Token_Address, "10"]
  //   );
  //   userCallDataArray.push({
  //     to: tokenAddress,
  //     value: ZERO,
  //     calldata: userAction,
  //   });
  // userCallDataArray.push({ to: Paymaster_Token_Address, value: ZERO, calldata: userAction2 });

  // should be "0x" or whitelisted token per chain
  //   let transferData: TransferData = {
  //     tokenAddress: "0x", // Eth,
  //     tokenAmount: "1000000000000000",
  //   };

  let userCallDataArray1: ExecuteCall[] = [...userCallDataArray];

  const chainId = 137;
  let nonce: any;
  let feeData: any = {};
  if (chainId == 137) {
    let res;
    console.log("file: sasdasdasdsads.ts:309  res:", res);
    try {
      const [data, nonceData] = await Promise.all([
        axios.get(
          "https://gpoly.blockscan.com/gasapi.ashx?apikey=key&method=gasoracle"
        ),
        getNonceForBuilder(counterfactual, SALT, provider),
      ]);
      res = data;
      nonce = nonceData;

      console.log("file: sasdasdasdsads.ts:313  res:", res);
    } catch (error: any) {
      console.error("There was an error. Reason: ", error.message);
    }
    if (res?.status == 200 && res?.data.message) {
      const SLACK_FEES = isHighFees ? 20 : 12;

      const freshfeeData = res.data.result;
      // Get the base fee & add about 30% increase in gas (Case where the block gets full consecutively
      // for 4 blocks)
      feeData.baseFee = freshfeeData.suggestBaseFee;
      feeData.fastFee = freshfeeData.FastGasPrice;
      feeData.approximateFastFee = (
        parseFloat(freshfeeData.FastGasPrice) + SLACK_FEES
      ).toString();
    }
  }

  const preOp = await preFlightPrepaid(
    counterfactual,
    wallet,
    paymaster_signer,
    provider,
    null,
    transferData,
    userCallDataArray1,
    isHighFees,
    isAccountDeployed,
    feeData,
    nonce
  );

  // const [preSimulateHandleOpsSucess, preSimulateValidationOpsSucess] =
  //   await Promise.all([
  //     simulateHandleOps(
  //       preOp,
  //       {
  //         to: preOp.sender,
  //         value: "0",
  //         calldata: preOp.callData,
  //       },
  //       provider
  //     ),
  //     simulateValidation(preOp, provider),
  //   ]);

  // const preSimulateHandleOpsSucess = await simulateHandleOps(
  //   preOp,
  //   {
  //     to: preOp.sender,
  //     value: "0",
  //     calldata: preOp.callData,
  //   },
  //   provider
  // );
  // if (!preSimulateHandleOpsSucess)
  //   throw new Error("Pre: HandleOps Simulation failed.");

  // // const preSimulateValidationOpsSucess = await simulateValidation(
  // //   preOp,
  // //   provider
  // // );
  // if (!preSimulateValidationOpsSucess)
  //   throw new Error("Pre: Simulation Validation failed.");
  // console.log("Pre: Simulation sucessful.");

  // 5. Hand over the userOp to get near approximate values of calldataGasLimit &verificationGasLimit
  let limits: CallLimits = await getGasLimitsWithOp(preOp, provider);

  // 6. Repeat step 2-4
  let userCallDataArray2: ExecuteCall[] = [...userCallDataArray];
  const finalOp = await postFlightPrepaid(
    counterfactual,
    wallet,
    paymaster_signer,
    provider,
    limits,
    transferData,
    userCallDataArray2,
    preOp,
    transferTokenDecimal,
    isHighFees,
    feeData
  );

  const totalGasLimit = limits.callGasLimit
    .add(limits.verificationGasLimit)
    .add(DEFAULT_PRE_VERIFICATION_GAS);
  // 7. Perform invariant checks
  const [
    simulateHandleOpsSucess,
    simulateValidationOpsSucess,
    feeInToken,
    feeInUSDC,
  ] = await Promise.all([
    simulateHandleOps(
      finalOp,
      {
        to: finalOp.sender,
        value: "0",
        calldata: finalOp.callData,
      },
      provider
    ),
    simulateValidation(finalOp, provider),
    getTokensAgainstGas(
      BigNumber.from(finalOp.maxFeePerGas),
      totalGasLimit,
      transferData.tokenAddress,
      transferTokenDecimal
    ),
    getTokensAgainstGas(
      BigNumber.from(finalOp.maxFeePerGas),
      totalGasLimit,
      "0x2791bca1f2de4661ed88a30c99a7a9449aa84174", // USDC token address for Polygon
      6
    ),
  ]);
  if (!simulateHandleOpsSucess)
    throw new Error("Post: HandleOps Simulation failed.");

  // const simulateValidationOpsSucess = await simulateValidation(
  //   finalOp,
  //   provider
  // );
  if (!simulateValidationOpsSucess)
    throw new Error("Post: Simulation Validation failed.");
  console.log("Post: Simulation sucessful.");

  // const totalGasLimit = limits.callGasLimit
  //   .add(limits.verificationGasLimit)
  //   .add(DEFAULT_PRE_VERIFICATION_GAS);
  // const [feeInToken, feeInUSDC] = await Promise.all([
  //   getTokensAgainstGas(
  //     BigNumber.from(finalOp.maxFeePerGas),
  //     totalGasLimit,
  //     transferData.tokenAddress,
  //     transferTokenDecimal
  //   ),
  //   getTokensAgainstGas(
  //     BigNumber.from(finalOp.maxFeePerGas),
  //     totalGasLimit,
  //     "0x2791bca1f2de4661ed88a30c99a7a9449aa84174", // USDC token address for Polygon
  //     6
  //   ),
  // ]);
  const feesInEther = BigNumber.from(finalOp.maxFeePerGas).mul(totalGasLimit);
  if (transferData.tokenAddress == USDC) {
    console.log(
      "Approximate fee (USDC) : ",
      ethers.utils.formatUnits(BigNumber.from(feeInToken), "6"),
      "USDC"
    );
  } else {
    console.log(
      "Approximate fee (ether)  : ",
      ethers.utils.formatEther(feesInEther),
      "Eth"
    );
  }

  console.log(
    {
      finalOp,
      usdcFee: ethers.utils
        .formatUnits(BigNumber.from(feeInUSDC), transferTokenDecimal)
        .toString(),
      usdcFeeInBigNumber: ethers.utils.formatUnits(
        BigNumber.from(feeInUSDC),
        transferTokenDecimal
      ),
      transferGasFee: ethers.utils
        .formatUnits(BigNumber.from(feeInToken), transferTokenDecimal)
        .toString(),
      transferGasFeeBigNumber: ethers.utils.formatUnits(
        BigNumber.from(feeInToken),
        transferTokenDecimal
      ),
    },
    "FINAL RESULT"
  );
  return {
    finalOp,
    usdcFee: ethers.utils.formatUnits(BigNumber.from(feeInUSDC), 6).toString(),
    usdcFeeInBigNumber: ethers.utils.formatUnits(BigNumber.from(feeInUSDC), 6),
    transferGasFee: ethers.utils
      .formatUnits(BigNumber.from(feeInToken), transferTokenDecimal)
      .toString(),
    transferGasFeeBigNumber: ethers.utils.formatUnits(
      BigNumber.from(feeInToken),
      transferTokenDecimal
    ),
  };
}

async function preFlightPrepaid(
  counterfactual: string,
  wallet: Wallet,
  paymaster_signer: Wallet,
  provider: providers.JsonRpcProvider,
  callLimits: CallLimits | null,
  transferData: TransferData,
  userCallDataArray: ExecuteCall[],
  isHighFees: boolean,
  isAccountDeployed: boolean,
  apiFeeData: any,
  nonce: any
) {
  /**  Alternatively, get chainId this from by,
   *   const chainId = (await provider.getNetwork()).chainId
   */
  const chainId = 137;
  // 1. Get the maxFeePerGas from the API.
  // let feeData: any = {};
  // let nonce: any;
  // if (chainId == 137) {
  //   let res;
  //   console.log("file: sasdasdasdsads.ts:309  res:", res);
  //   try {
  //     const [data, nonceData] = await Promise.all([
  //       axios.get(
  //         "https://gpoly.blockscan.com/gasapi.ashx?apikey=key&method=gasoracle"
  //       ),
  //       getNonceForBuilder(counterfactual, SALT, provider),
  //     ]);
  //     res = data;
  //     nonce = nonceData;

  //     console.log("file: sasdasdasdsads.ts:313  res:", res);
  //   } catch (error: any) {
  //     console.error("There was an error. Reason: ", error.message);
  //   }
  //   if (res?.status == 200 && res?.data.message) {
  //     const SLACK_FEES = isHighFees ? 20 : 12;

  //     const freshfeeData = res.data.result;
  //     // Get the base fee & add about 30% increase in gas (Case where the block gets full consecutively
  //     // for 4 blocks)
  //     feeData.baseFee = freshfeeData.suggestBaseFee;
  //     feeData.fastFee = freshfeeData.FastGasPrice;
  //     feeData.approximateFastFee = (
  //       parseFloat(freshfeeData.FastGasPrice) + SLACK_FEES
  //     ).toString();
  //   }
  // }

  let feeData = apiFeeData;
  // 2. Pre-fill reasonable calldataGasLimit, verificationGasLimit & maxFeePerGas in userOp
  //    that will not revert on validation simulation.

  // const isDeployed = await provider.getCode(counterfactual);

  let resultOp;
  console.log("file: prepaidGas.ts:308  resultOpASDASDS:", resultOp);

  if (!isAccountDeployed) {
    resultOp = await getPartialUserOpForVerifyingPaymaster(
      wallet.address,
      FactoryData.FactoryCreateSender,
      CallDataType.Batch,
      userCallDataArray,
      transferData,
      bundlerRPC,
      rpcEndpoint,
      counterfactual,
      nonce
    );
  } else {
    resultOp = await getPartialUserOpForVerifyingPaymaster(
      wallet.address,
      FactoryData.Empty,
      CallDataType.Batch,
      userCallDataArray,
      transferData,
      bundlerRPC,
      rpcEndpoint,
      counterfactual,
      nonce
    );
  }
  console.log("file: prepaidGas.ts:308  resultOpASDASDS:", resultOp);

  resultOp.maxFeePerGas = ethers.utils.parseUnits(
    Math.ceil(feeData.approximateFastFee).toString(),
    "9"
  );
  resultOp.maxPriorityFeePerGas = ethers.utils.parseUnits(
    Math.ceil(feeData.approximateFastFee).toString(),
    "9"
  );
  resultOp.preVerificationGas = BigNumber.from(DEFAULT_PRE_VERIFICATION_GAS);

  resultOp.callGasLimit = BigNumber.from(DEFAULT_GAS_LIMITS);
  resultOp.verificationGasLimit = BigNumber.from(DEFAULT_GAS_LIMITS);

  let callDataUserOp: BytesLike = getBatchCalldataForBundlerWithToken(
    CallDataType.Batch,
    userCallDataArray,
    transferData
  );
  resultOp.callData = callDataUserOp;

  console.log("file: prepaidGas.ts:309  resultOpASDASDS:", resultOp);

  console.log("feeData", feeData);

  // 3. Give this data to paymaster to sign
  // let signedPaymasterOp = await getVerifierPaymasterSignatureFromServer(
  //   resultOp,
  //   PaymasterType.OffChainVerifier,
  //   paymaster_signer,
  //   bundlerRPC,
  //   rpcEndpoint
  // );
  let signedPaymasterOp = await getSignatureObj({
    userOp: resultOp,
    payMasterType: PaymasterType.OffChainVerifier,
  });
  console.log("file: prepaidGas.ts:305  signedPaymasterOp:", signedPaymasterOp);

  // 4. Sign the tx form the user
  let signature = signUserOp(
    signedPaymasterOp,
    wallet,
    EntryPoint_Address,
    chainId
  );

  const finalOp = (signedPaymasterOp = {
    ...signedPaymasterOp,
    signature: signature,
  });

  console.log("Modified resultOp (Pre): ", finalOp);

  return finalOp;
}

async function postFlightPrepaid(
  counterfactual: string,
  wallet: Wallet,
  paymaster_signer: Wallet,
  provider: providers.JsonRpcProvider,
  callLimits: CallLimits | null,
  transferData: TransferData,
  userCallDataArray: ExecuteCall[],
  userOp: IUserOperation,
  transferTokenDecimal: number,
  isHighFees: boolean,
  apiFeeData: any
) {
  /**  Alternatively, get chainId this from by,
   *   const chainId = (await provider.getNetwork()).chainId
   */
  const chainId = 137;
  // 1. Get the maxFeePerGas from the API.

  let feeData = apiFeeData;
  // 2. Pre-fill reasonable calldataGasLimit, verificationGasLimit & maxFeePerGas in userOp
  //    that will not revert on validation simulation.
  let resultOp = userOp;

  resultOp.maxFeePerGas = ethers.utils.parseUnits(
    Math.ceil(feeData.approximateFastFee).toString(),
    "9"
  );
  resultOp.maxPriorityFeePerGas = ethers.utils.parseUnits(
    Math.ceil(feeData.approximateFastFee).toString(),
    "9"
  );
  resultOp.preVerificationGas = BigNumber.from(DEFAULT_PRE_VERIFICATION_GAS);

  const gasPrice = ethers.utils.parseUnits(
    Math.ceil(feeData.approximateFastFee).toString(),
    "9"
  );

  let sumCallLimits = callLimits!.callGasLimit
    .add(callLimits!.verificationGasLimit)
    .add(resultOp.preVerificationGas);
  console.log("sum of limits", Number(sumCallLimits));

  if (resultOp.initCode.length > 2) {
    // If the deployment is included add 20% more gas to be on safe side.
    resultOp.callGasLimit = callLimits!.callGasLimit.add(DEPLOYMENT_GAS);
    resultOp.verificationGasLimit =
      callLimits!.verificationGasLimit.add(DEPLOYMENT_GAS);
    sumCallLimits = sumCallLimits.add(BigNumber.from(DEPLOYMENT_GAS).mul(2));
    console.log("sum of limits (after deployment) ", Number(sumCallLimits));
  } else {
    resultOp.callGasLimit = callLimits!.callGasLimit;
    resultOp.verificationGasLimit = callLimits!.verificationGasLimit;
  }

  if (transferData.tokenAddress != NATIVE) {
    const noOfTokenToDeductAsFees = await getTokensAgainstGas(
      gasPrice,
      BigNumber.from(sumCallLimits),
      transferData.tokenAddress,
      transferTokenDecimal
    );
    transferData.tokenAmount = noOfTokenToDeductAsFees!;
  } else {
    transferData.tokenAmount = gasPrice.mul(sumCallLimits).toString();
    console.log("transfer eth fee: ", transferData.tokenAmount);
  }

  console.log("transferData", transferData);
  let callDataUserOp: BytesLike = getBatchCalldataForBundlerWithToken(
    CallDataType.Batch,
    userCallDataArray,
    transferData
  );

  resultOp.callData = callDataUserOp;

  console.log("feeData", feeData);

  // 3. Give this data to paymaster to sign
  // let signedPaymasterOp = await getVerifierPaymasterSignatureFromServer(
  //   resultOp,
  //   PaymasterType.OffChainVerifier,
  //   paymaster_signer,
  //   bundlerRPC,
  //   rpcEndpoint
  // );

  let signedPaymasterOp = await getSignatureObj({
    userOp: resultOp,
    payMasterType: PaymasterType.OffChainVerifier,
  });
  console.log("file: prepaidGas.ts:437  signedPaymasterOp:", signedPaymasterOp);

  // 4. Sign the tx form the user
  let signature = signUserOp(
    signedPaymasterOp,
    wallet,
    EntryPoint_Address,
    chainId
  );

  const finalOp = (signedPaymasterOp = {
    ...signedPaymasterOp,
    signature: signature,
  });

  console.log("Modified resultOp (POST) : ", finalOp);

  return finalOp;
}

async function getGasLimitsWithOp(
  userOp: IUserOperation,
  provider: providers.JsonRpcProvider
): Promise<CallLimits> {
  let verificationGasLimit;
  let callGasLimit;
  let preVerificationGas;
  const IEntryPoint = EntryPoint__factory.connect(EntryPoint_Address, provider);
  try {
    await IEntryPoint.callStatic.simulateHandleOp(
      userOp,
      userOp.sender,
      userOp.callData
    );
  } catch (error: any) {
    //@ts-ignore
    if (error.errorName === "ExecutionResult") {
      const _verificationGasLimitPreOp = error.errorArgs.preOpGas.sub(
        userOp.preVerificationGas
      );
      // console.log("_verificationGasLimit: ", Number(_verificationGasLimitPreOp));
      verificationGasLimit = _verificationGasLimitPreOp;
      console.log("_verificationGasLimit : ", Number(verificationGasLimit));

      const _callGasLimitWithMultiplier = error.errorArgs.paid;
      // console.log("_callGasLimitWithMultiplier: ", Number(_callGasLimitWithMultiplier));
      const _callGasLimit = _callGasLimitWithMultiplier.div(
        userOp.maxFeePerGas
      );
      // console.log("_callGasLimit: ", Number(_callGasLimit));
      callGasLimit = _callGasLimit;
      console.log("_callGasLimit : ", Number(callGasLimit));

      preVerificationGas = userOp.preVerificationGas;
      console.log("_preVerificationGas : ", Number(preVerificationGas));
    } else {
      throw error;
    }
  }

  return { verificationGasLimit, callGasLimit };
}

// async function getTokensAgainstGas(gasPrice: BigNumber, gasCost: BigNumber) {
//   // 1. Get the USD value of ether against the provided token.
//   let res;
//   try {
//     res = await axios.get(
//       "https://gpoly.blockscan.com/gasapi.ashx?apikey=key&method=gasoracle"
//     );
//   } catch (error: any) {
//     console.error("There was an error. Reason: ", error.message);
//   }
//   if (res?.status == 200 && res?.data.message) {
//     const price = ethers.utils.parseUnits(res.data.result.UsdPrice, "18");

//     /// @notice: price = GAS_PRICE (In Wei) * GAS_COST * NATIVE_PRIVE (In USD) * TOKEN_DECIMALS / 1e36
//     const result = gasPrice
//       .mul(gasCost)
//       .mul(price)
//       .mul(ethers.utils.parseUnits("1", 6))
//       .div(ethers.utils.parseUnits("1", 36));
//     // 2. Convert the gas to USDC

//     // 3. Return the no. of tokens to dedeuct as fees.
//     // console.log(result)
//     return result.toString();
//   }
// }

async function getTokensAgainstGas(
  gasPrice: BigNumber,
  gasCost: BigNumber,
  tokenAddress: string,
  tokenDecimal: number
) {
  console.log("file: prepaidGasasdasdasd.ts:531  tokenDecimal:", tokenDecimal);
  // 1. Get the USD value of ether against the provided token.
  try {
    // let res = await axios.get(
    //   "https://gpoly.blockscan.com/gasapi.ashx?apikey=key&method=gasoracle"
    // );

    const assetTokenAddress =
      tokenAddress == "0x"
        ? "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270"
        : tokenAddress;
    const tokenPrice = await getTokenDollarValue(assetTokenAddress);
    // const usdPrice = 83;
    console.log(
      "file: prepaidGasasdasdasd.ts:539  usdPrice:",
      tokenPrice,
      tokenAddress
    );
    const usdPrice = tokenPrice;
    // if (res?.status == 200 && res?.data.message) {
    const price = ethers.utils.parseUnits(String(usdPrice), "18");

    /// @notice: price = GAS_PRICE (In Wei) * GAS_COST * NATIVE_PRIVE (In USD) * TOKEN_DECIMALS / 1e36
    const result = gasPrice
      .mul(gasCost)
      .mul(price)
      .mul(ethers.utils.parseUnits("1", String(tokenDecimal)))
      .div(ethers.utils.parseUnits("1", 36));
    console.log("file: prepaidGasasdasdasd.ts:549  result:", result);
    // 2. Convert the gas to USDC

    // 3. Return the no. of tokens to dedeuct as fees.
    // console.log(result)
    return result.toString();
    // }
  } catch (error: any) {
    console.error("There was an error. Reason: ", error.message);
    throw error;
  }
}
