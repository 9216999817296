import React, { useState, FC } from "react";
import QRCode from "react-qr-code";
import { Snackbar, Typography, Box } from "@mui/material";
import WalletIcon from "assets/wallet.svg";
import InfoIcon from "assets/info.svg";
import EmberLogo from "assets/ember-receive.svg";

import "./index.css";
import BasicButton from "components/Button";
import { useAppSelector } from "store/store";
import QRLogo from "assets/logo-qr.svg";
import { SUPPORTED_NETWORKS } from "constants/chains";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import NavigatorHeading from "../../../../components/NavigatorHeading";
import CloseButton from "../../../../components/CloseButton";
import CustomizedSteppers from "components/Stepper";
import NetworksList from "components/NetworksList";
import CardLayout from "components/CardLayout";
import { ItemWrapper } from "components/Styled";
import CenterItem from "components/CenterItem";
import CopyIcon from "assets/whiteCopyIcon.svg";

const Receive: FC<{
  isNftPage?: boolean;
}> = ({ isNftPage }) => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [step, setStep] = useState(0);
  const { activeAccount, activeNetwork } = useAppSelector((state) => state.app);
  const navigate = useNavigate();

  const { icon } =
    SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS];

  const chainName =
    SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS]
      .chainName;
  const chainSymbol =
    SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS].symbol;

  const onCopy = () => {
    setShowSnackbar(true);
    navigator.clipboard.writeText(activeAccount.smartAccountAddress);
  };

  return (
    <>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        message="Address copied!"
      />
      <Box mt={6}>
        <NavigatorHeading
          title={isNftPage ? "Receive NFT" : "Receive Crypto"}
          RightComponent={
            <CloseButton
              handleOnClick={() => {
                navigate(isNftPage ? "/nfts" : "/crypto");
              }}
            />
          }
        />
      </Box>
      <Box mt={6}>
        <CustomizedSteppers
          step={step}
          steps={["Network", "Details"]}
          changeStep={(selectedStep: number) => {
            //eg. if user is on step 3 he should be able to move at step 1 or 2 on clicking step icon
            if (selectedStep < step) {
              setStep(selectedStep);
            }
          }}
        />
      </Box>
      <Box mt={5}>
        {step == 0 && (
          <Grid container display="flex" justifyContent="center">
            <Grid item lg={6} sm={12}>
              <NetworksList
                nextStep={() => setStep(1)}
                // title={`Select which network you want to receive ${
                //   isNftPage ? 'NFT' : 'crypto'
                // } on`}
                title={``}
              />
            </Grid>
          </Grid>
        )}
        {step === 1 && (
          <Box
            width={"450px"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            margin={"auto"}
          >
            <Box width="85%" marginLeft={"auto"}>
              <Typography
                variant={"h5"}
                fontWeight={700}
                color="rgba(26, 28, 32, 1)"
                fontSize={34}
              >
                @{activeAccount.accountName}
              </Typography>
            </Box>
            <Box
              mt={5}
              sx={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CardLayout backgroundColor="#fff">
                <Box position="relative">
                  <Box marginX={"18px"} marginY={"8px"}>
                    <QRCode
                      value={activeAccount.smartAccountAddress}
                      size={282}
                    />
                  </Box>
                  <img src={QRLogo} className="qr-img" />
                </Box>
              </CardLayout>
            </Box>
            <Box marginTop={5}>
              <Typography
                sx={{
                  color: "rgba(26, 28, 32, 0.50)",
                  textAlign: "start",
                  fontFamily: "Helvetica Neue",
                  fontsize: 17,
                  fontWeight: 500,
                }}
              >
                Address
              </Typography>

              <Box display={"flex"} alignItems={"center"}>
                <ItemWrapper
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  sx={{ cursor: "pointer" }}
                >
                  {activeAccount.smartAccountAddress}
                </ItemWrapper>
                <Box marginLeft={1}>
                  <CenterItem>
                    <div
                      className="tab-chip-box"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "5px",
                        alignItems: "center",
                      }}
                      onClick={onCopy}
                    >
                      <Typography
                        variant={"h5"}
                        fontWeight={500}
                        color="white"
                        fontSize={12}
                        fontFamily={"Helvetica Neue"}
                        whiteSpace={"nowrap"}
                        letterSpacing={2}
                      >
                        Copy{" "}
                      </Typography>

                      <img
                        src={CopyIcon}
                        alt="copy icon"
                        style={{
                          cursor: "pointer",

                          height: "15px",
                        }}
                      />
                    </div>
                  </CenterItem>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>

      {/* <Grid paddingY={5}>
        <NavigatorHeading
          title="Receive Crypto"
          RightComponent={
            <CloseButton
              handleOnClick={() => {
                navigate("/crypto");
              }}
            />
          }
        />
      </Grid>
      <div className="receive-container">
        <div className="receive-box">
          <Typography>{chainName}</Typography>
          <Typography variant="body2">{chainSymbol} Wallet</Typography>
        </div>
        <div className="receive-address-box">
          <Typography>{chainSymbol} Address</Typography>
          <div className="account-info">
            <img src={WalletIcon} />
            <Typography
              variant="body2"
              style={{ marginLeft: 10, marginTop: 5 }}
            >
              {activeAccount.smartAccountAddress}
            </Typography>
            <BasicButton title="Copy" onClick={onCopy} className="copy" />
          </div>
        </div>
        <Typography style={{ margin: "20px 0" }}></Typography>

        <div className="qr-box">
          <Typography>Scan address to deposit payment</Typography>
          <div className="qr-container">
            <div className="qr">
              <QRCode value={activeAccount.smartAccountAddress} />
              <img src={icon} className="qr-img" />
            </div>
          </div>
        </div>
        <div className="receive-box info">
          <div className="info-icon">
            <img src={InfoIcon} />
          </div>
          <Typography variant="body2">
            Please only send {chainName} Network tokens to this address
          </Typography>
        </div>
      </div> */}
    </>
  );
};

export default Receive;
