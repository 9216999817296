import React, { useEffect, useState } from "react";
import Web3 from "web3";

import "./index.css";
import { Box, Grid, Snackbar, Typography } from "@mui/material";
import BasicModal from "components/BasicModal";
import ModalHeader from "components/ModalHeader";
import AssetsView from "components/AssetsView";
import { Token } from "interfaces";
import { useAppDispatch, useAppSelector } from "store/store";

import { SUPPORTED_NETWORKS } from "constants/chains";
import { extractTokenData, numFormatter, showAlert } from "utils/utils";
import abi from "abis/erc20abi.json";

import { setSelectedToken } from "@slices/walletSlice";
import { ethers } from "ethers";
import { Paymaster_Owner_Address } from "../../../../contract-integration/constants";
import { useNavigate } from "react-router-dom";
import CustomizedSteppers from "components/Stepper";
import NavigatorHeading from "components/NavigatorHeading";
import CloseButton from "components/CloseButton";
import NetworksList from "components/NetworksList";
import TokensListTable from "../TokensListTable";
import TokenInputForm from "components/TokenInputForm";
import { getEthDollarValue } from "../../../../utils/portfolio";
import CryptoFiatTabs from "components/CryptoFiatTabs";
import ReceiveTokenList from "components/ReceiveTokenList";
import SwapSummary from "components/SwapSummary";
import BuyFiat from "components/Buyfiat";
import GBPIcon from "assets/GBPIcon.svg";
import BuyFiatPreview from "components/BuyFiatPreview";
import { setSwapDetails } from "@slices/appSlice";
import { fetchUniswapSupportedTokens } from "utils/swap";
import axios from "axios";

const CryptoBuy = () => {
  const [value, setValue] = useState("");
  const [to, setTo] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [openModal, setOpenModal] = useState(false);
  const [tokens, setTokens] = useState<Token[]>([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [step, setStep] = useState(0);

  const [sameTokens, setSameTokens] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState("crypto");

  const [isCashSelected, setIsCashSelected] = useState(false);

  const [paymentMethod, setPaymentMethod] = useState(false);
  const [activeCurrency, setActiveCurrency] = useState("GBP");
  const [activeCurrencyIcon, setActiveCurrencyIcon] = useState(GBPIcon);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({});
  const [activeCryptoOnRamperId, setActiveCryptoOnRamperId] = useState("");
  const [selectedTokenB, setSelectedTokenB] = useState({});
  const [swapTokens, setSwapTokens] = useState<any>([]);
  const [fiatList, setFiatList] = useState([{}]);
  const [cryptoList, setCryptoList] = useState([{}]);

  const navigate = useNavigate();
  const {
    activeAccount,
    holdings,
    accounts,
    activeNetwork,
    portfolio,
    rootAccountInfo,
    balances,
    swapDetails: { tokenB, tokenA },
  } = useAppSelector((state) => state.app);

  const { selectedToken, isAccountDeployed } = useAppSelector(
    (state) => state.wallet
  );
  console.log("file: index.tsx:89 Send selectedToken:", selectedToken);
  const { hashedPassword, txStatus } = useAppSelector((state) => state.wallet);

  const { nativeTokenName, symbol, icon, rpc, block_explorer_url } =
    SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS];

  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      setLoading(true);

      const tokens = await fetchUniswapSupportedTokens(activeNetwork);
      setSwapTokens(tokens);

      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await axios.get("https://api.onramper.com/supported", {
          headers: {
            Authorization: process.env.ONRAMP_API_KEY,
          },
        });
        const { message } = data;

        const { crypto, fiat } = message;
        setFiatList(fiat);
        setCryptoList(crypto);
      } catch (error) {
        console.log("E>>>>>>>>>>>", error);
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    let userTokens: Token[] =
      holdings[activeAccount.smartAccountAddress]?.tokens || [];

    setTokens(userTokens);
  }, [activeAccount, holdings]);

  useEffect(() => {
    const data = extractTokenData(activeNetwork, portfolio.assets);
    // SetTokenForPayment(data.address);
    // setTokenForPaymentDecimal(data.decimal);
    setSameTokens(data.sameTokens);
  }, [activeNetwork, portfolio.assets]);

  const isSelectedToken = selectedToken.length > 0;

  const tokenPriceInUsd = isSelectedToken ? selectedToken[0].tokenPrice : 0;

  useEffect(() => {
    console.log("selectedtoken", tokenB);
    if (
      tokenB.tokenSymbol &&
      tokenB.tokenAddress &&
      cryptoList.length > 0 &&
      step === 3 &&
      activeTab === "fiat"
    ) {
      const ISNATIVE =
        tokenB.tokenAddress == "0x0000000000000000000000000000000000000000" ||
        tokenB.tokenAddress == "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270";

      const availableCrypto = ISNATIVE
        ? cryptoList.find(
            (cr) =>
              cr?.address?.toLowerCase() ===
                "0x0000000000000000000000000000000000001010" &&
              cr.network === "polygon" &&
              cr.symbol === "matic"
          )
        : cryptoList.find(
            (cr) =>
              (cr?.address?.toLowerCase() ===
                tokenB.tokenAddress.toLowerCase() ||
                cr?.symbol?.toLowerCase() ===
                  tokenB.tokenSymbol.toLowerCase()) &&
              cr.network === "polygon"
          );

      console.log("availableCrypto", availableCrypto);

      if (!availableCrypto) {
        showAlert(`${tokenB.tokenSymbol} currently not supported for onRamp`);
        setStep(1);
      } else {
        console.log("availableCrypto", availableCrypto);
        setActiveCryptoOnRamperId(availableCrypto);
      }
    }
  }, [tokenB, cryptoList, step, activeTab]);

  // const { dummyToken } = SUPPORTED_NETWORKS[activeNetwork];

  useEffect(() => {
    setPaymentMethod(false);
  }, [activeTab]);

  const selectTokenHandler = (tk: Token) => {
    dispatch(setSelectedToken([tk]));
    setOpenModal(false);
  };

  const handleAmountChange = (value) => {
    const inputValue = value;

    if (/[^0-9.]/.test(inputValue)) {
      setError("Special characters are not allowed");
    } else if ((inputValue.match(/\./g) || []).length > 1) {
      setError("Only one decimal point is allowed");
    } else if (!/^\d{0,10}(\.\d{0,15})?$/.test(inputValue)) {
      setError(
        "Maximum of 10 digits before decimals and 15 digits after decimals are allowed"
      );
    } else {
      setError("");

      setValue(inputValue);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "ArrowUp") {
      event.preventDefault();

      // Parse the current value to a float
      const numericValue = parseFloat(value || "0");

      // Increment the value by 1
      const newValue = (numericValue + 1).toFixed(2);
      if (/[^0-9.]/.test(newValue)) {
        setError("Special characters are not allowed");
      } else if (parseFloat(newValue) * tokenPriceInUsd < 2.01) {
        setError("Value should not be less than $2.01");

        setValue(newValue);
      } else {
        setValue(newValue);
        setError("");
      }
    }

    if (event.key === "ArrowDown") {
      event.preventDefault();

      // Parse the current value to a float
      const numericValue = parseFloat(value || "0");

      // Ensure the value doesn't go below 0.01
      const newValue = (numericValue - 1).toFixed(2);
      if (/[^0-9.]/.test(newValue)) {
        setError("Special characters are not allowed");
      } else if (parseFloat(newValue) * tokenPriceInUsd < 2.01) {
        setError("Value should not be less than $2.01");

        setValue(newValue);
      } else {
        if (Number(newValue) > 0) {
          setValue(newValue);
        }
        setError("");
      }
    }
  };

  const onAmountContinue = () => {
    const selectedTokenDetails = {
      id: 1,
      image: tokenB.image,
      amountInUSD: value * (selectedTokenB?.priceInUSD || 1),
      tokenAddress: tokenB.tokenAddress,
      tokenBalance: 0,
      tokenDecimal: tokenB.tokenDecimal,
      tokenName: tokenB.tokenName,
      tokenPrice: selectedTokenB?.priceInUSD || 1,
      tokenSymbol: tokenB.tokenSymbol,
      amount: value,
    };

    dispatch(setSwapDetails({ tokenB: selectedTokenDetails }));
    setStep(3);
  };

  const verifyBalances = () => {
    // dispatch(setSwapDetails({ tokenA: TokenDetails }));
    if (tokenB.amountInUSD > tokenA.tokenBalance * tokenA.tokenPrice) {
      showAlert(`Insufficient ${tokenA.tokenName} balance`);
      setStep(1);
    } else {
      const TokenDetails = {
        id: tokenA.id,
        image: tokenA.image,
        amountInUSD:
          (tokenB.amountInUSD / tokenA.tokenPrice) * tokenA.tokenPrice,
        tokenAddress: tokenA.tokenAddress,
        tokenBalance: tokenA.tokenBalance,
        tokenDecimal: tokenA.tokenDecimal,
        tokenName: tokenA.tokenName,
        tokenPrice: tokenA.tokenPrice,
        tokenSymbol: tokenA.tokenSymbol,
        amount: tokenB.amountInUSD / tokenA.tokenPrice,
      };
      dispatch(setSwapDetails({ tokenA: TokenDetails }));
      setStep(4);
    }
  };

  console.log("tokenB", tokenB, selectedTokenB);

  return (
    <>
      <BasicModal open={openModal} onClose={() => setOpenModal(false)}>
        <>
          <ModalHeader
            title="Select asset"
            onClose={() => setOpenModal(false)}
            showBackIcon
          />
          <AssetsView tokens={tokens} selectTokenHandler={selectTokenHandler} />
        </>
      </BasicModal>
      <Box mt={6}>
        <NavigatorHeading
          title="Buy"
          RightComponent={
            <CloseButton
              handleOnClick={() => {
                navigate("/crypto");
              }}
            />
          }
        />
      </Box>
      <Box mt={6}>
        <CustomizedSteppers
          step={step}
          steps={["Network", "Asset", "Amount", "Method", "Buy"]}
          changeStep={(selectedStep: number) => {
            //eg. if user is on step 3 he should be able to move at step 1 or 2 on clicking step icon
            if (selectedStep < step) {
              setStep(selectedStep);
            }
          }}
        />

        <Box mt={5}>
          {step == 0 && (
            <Grid container display="flex" justifyContent="center">
              <Grid
                item
                lg={6}
                sm={12}
                style={{
                  flexBasis: "65%",
                  maxWidth: "100%",
                }}
              >
                <NetworksList
                  nextStep={() => setStep(1)}
                  title="Select which network you want to receive crypto on"
                />
              </Grid>
            </Grid>
          )}
          {step == 1 && (
            <Grid container display="flex" justifyContent="center">
              <Grid
                item
                lg={6}
                sm={12}
                style={{
                  flexBasis: "65%",
                  maxWidth: "100%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "17px",
                    textAlign: "left",
                    color: "rgba(26, 28, 32, 0.5)",
                    fontFamily: "Helvetica Neue",
                    fontWeight: 500,
                    width: "100%",
                    paddingBottom: "20px",
                  }}
                >
                  Select the token you want to Buy
                </Typography>
                <ReceiveTokenList
                  nextStep={() => setStep(2)}
                  setIsCashSelected={() => setIsCashSelected(!isCashSelected)}
                  isCashSupported={false}
                  setSelectedTokenB={setSelectedTokenB}
                  swapTokens={swapTokens}
                  loading={loading}
                  type="buy"
                />
              </Grid>
            </Grid>
          )}
          {step == 2 && (
            <Grid container display="flex" justifyContent="center" py={4}>
              <Grid
                item
                lg={6}
                sm={12}
                style={{
                  flexBasis: "65%",
                  maxWidth: "100%",
                }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Typography
                    sx={{
                      fontSize: "17px",
                      textAlign: "left",
                      color: "rgba(26, 28, 32, 0.5)",
                      fontFamily: "Helvetica Neue",
                      fontWeight: 500,
                      width: "100%",
                    }}
                  >
                    Enter amount to buy
                  </Typography>
                  <TokenInputForm
                    placeHolder={tokenB.tokenBalance.toString()}
                    title="Crypto Tag"
                    addBorder
                    type="number"
                    onChange={handleAmountChange}
                    onKeydown={handleKeyDown}
                    value={value}
                    receiverENS={to}
                    isDepositValid={true}
                    nextStep={onAmountContinue}
                    tokenName={tokenB.tokenSymbol}
                    tokenIcon={tokenB.image}
                    buttonTitle="Continue"
                    errorMessage={error}
                  />
                </Box>
              </Grid>
            </Grid>
          )}

          {step == 3 && (
            <Grid container display="flex" justifyContent="center">
              <Grid
                item
                lg={6}
                sm={12}
                style={{
                  flexBasis: "65%",
                  maxWidth: "100%",
                }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Typography
                    sx={{
                      fontSize: "17px",
                      textAlign: "left",
                      color: "rgba(26, 28, 32, 0.5)",
                      fontFamily: "Helvetica Neue",
                      fontWeight: 500,
                      width: "100%",
                      margin: "5px 0px 5px 0px",
                    }}
                  >
                    Select how you want to pay
                  </Typography>

                  <CryptoFiatTabs
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />

                  {activeTab === "crypto" ? (
                    <>
                      <TokensListTable
                        transactionForm={true}
                        nextStep={verifyBalances}
                        chainId={activeNetwork}
                        isApplyFilter={false}
                        isShowTokenAmountUnderName
                      />
                    </>
                  ) : (
                    <BuyFiat
                      setPaymentMethod={setPaymentMethod}
                      setActiveCurrency={setActiveCurrency}
                      setActiveCurrencyIcon={setActiveCurrencyIcon}
                      setSelectedPaymentMethod={setSelectedPaymentMethod}
                      activeCurrency={activeCurrency}
                      paymentMethod={paymentMethod}
                      nextStep={setStep}
                      tokenAddress={tokenB.tokenAddress}
                      setActiveCryptoOnRamperId={setActiveCryptoOnRamperId}
                      tokenSymbol={tokenB.tokenSymbol}
                      activeCryptoOnRamperId={activeCryptoOnRamperId}
                      type="buy"
                      fiatList={fiatList}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          )}

          {step == 4 &&
            (activeTab === "crypto" ? (
              <SwapSummary />
            ) : (
              <BuyFiatPreview
                selectedPaymentMethod={selectedPaymentMethod}
                currencyIcon={activeCurrencyIcon}
                currencySymbol={activeCurrency}
                tokenIcon={tokenB.image}
                tokenPriceInUsd={tokenB.tokenPrice}
                tokenSymbol={tokenB.tokenSymbol}
                value={value}
                activeCryptoOnRamperId={activeCryptoOnRamperId}
                type="buy"
                nextStep={() => setStep(2)}
              />
            ))}
        </Box>
      </Box>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        message="Funds are not enough to use as gas fees"
      />
    </>
  );
};

export default CryptoBuy;
