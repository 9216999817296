import axios from "axios";
import ens from "@ensdomains/eth-ens-namehash";

import { BASE_URL } from "constants/";
import { showAlert } from "./utils";
import Web3 from "web3";

export const getEnsAddress = async (ens: string) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/ens/getSubAddressFromENS?name=${ens}`
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const getToAddress = async (toAddress: string) => {
  try {
    const isEVMAddress = toAddress.startsWith("0x");
    let to;

    if (!isEVMAddress) {
      const normzalized = ens.normalize(toAddress);
      const data = await getEnsAddress(normzalized);
      if (data) {
        to = data.address;
      } else {
        showAlert("The stashed tag does not belongs to any wallet address");

        return;
      }
    } else {
      // console.log("res", to);
      const res = Web3.utils.isAddress(toAddress);
      // console.log("res", res);
      if (res) {
        to = toAddress;
      } else {
        showAlert(
          "Invalid recipient wallet address. Please enter a valid Ethereum wallet address."
        );

        return;
      }
    }

    return to;
  } catch (e) {
    throw e;
  }
};

export const getCashToAddress = async (toAddress: string) => {
  try {
    const isEVMAddress = toAddress.startsWith("0x");
    let to;

    if (!isEVMAddress) {
      const normzalized = ens.normalize(toAddress);
      const data = await getEnsAddress(normzalized);
      if (data) {
        to = data.address;
      } else {
        showAlert("The cash tag does not belong to any account");

        return;
      }
    } else {
      // console.log("res", to);
      const res = Web3.utils.isAddress(toAddress);
      // console.log("res", res);
      if (res) {
        to = toAddress;
      } else {
        showAlert(
          "Invalid recipient wallet address. Please enter a valid Ethereum wallet address."
        );

        return;
      }
    }

    return to;
  } catch (e) {
    throw e;
  }
};
