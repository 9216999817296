import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, IconButton } from "@mui/material";
import NavigatorHeading from "../../../../components/NavigatorHeading";
import CloseButton from "../../../../components/CloseButton";
import SearchInput from "../../../../components/SearchInput";
import NFTCollectionList from "../NFTCollectionList";

const Collections = () => {
  let { NftColName } = useParams();

  const [value, setValue] = useState("");
  const navigate = useNavigate();
  return (
    <Grid paddingY={5}>
      <NavigatorHeading
        title={NftColName ?? ""}
        RightComponent={
          <CloseButton
            handleOnClick={() => {
              navigate("/nfts");
            }}
          />
        }
      />
      <Grid>
        <Grid container py={4}>
          <Grid sm={11}>
            <SearchInput
              placeholder="Search NFT"
              value={value}
              handleOnChange={(e) => {
                setValue(e.target.value);
              }}
            />
          </Grid>
          <Grid sm={1}></Grid>
        </Grid>
        <NFTCollectionList searchText={value} />
      </Grid>
    </Grid>
  );
};

export default Collections;
