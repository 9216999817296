import { Snackbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import StashedTagIcon from "assets/stashedTagIcon.png";
import SettingsCopyIcon from "assets/settings-copy-icon.svg";
import { useAppSelector } from "../../../../../../store/store";
import CenterItem from "../../../../../../components/CenterItem";

const StashedTag = () => {
  const { activeAccount } = useAppSelector((state) => state.app);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const onCopy = () => {
    setShowSnackbar(true);
    navigator.clipboard.writeText(activeAccount.accountName);
  };
  return (
    <>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        message="Stashed tag copied!"
      />
      <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <Box>
          <Typography variant="body2">Stashed Tag</Typography>
        </Box>
        <Box
          fontWeight={200}
          fontSize={14}
          p={3}
          borderRadius={"10px"}
          lineHeight={1.4}
          display={"flex"}
          gap={1}
          alignItems={"center"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          sx={{ background: "#FFFFFF" }}
        >
          <Box
            display={"flex"}
            gap={1}
            alignItems={"center"}
            flexDirection={"row"}
          >
            <img src={StashedTagIcon} height={20} width={20} />
            <Typography
              fontWeight={500}
              variant="body1"
              fontSize={"16px"}
            >{`@${activeAccount.accountName}`}</Typography>
          </Box>
          <Box>
            <CenterItem>
              <div
                style={{
                  cursor: "pointer",
                }}
                onClick={onCopy}
              >
                <img src={SettingsCopyIcon} alt="" />
                {/* <Typography
                  variant={"h5"}
                  fontWeight={500}
                  color="white"
                  fontSize={12}
                  fontFamily={"Helvetica Neue"}
                  whiteSpace={"nowrap"}
                  letterSpacing={2}
                >
                  Copy{" "}
                </Typography>
                <ContentCopyIcon
                  style={{
                    color: "#ffffff",
                    fontSize: "14",
                    cursor: "pointer",
                  }}
                /> */}
              </div>
            </CenterItem>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default StashedTag;
