import { CircularProgress, Typography } from "@mui/material";
import { BtnProps } from "interfaces";
import React, { FC } from "react";

const BasicButton: FC<BtnProps> = ({
  title,
  onClick,
  mode = "active",
  style = {},
  loading,
  showIcon = false,
  icon,
  className = "",
  id = "",
}) => {
  return loading ? (
    <div style={{ width: "100%", textAlign: "center" }}>
      <CircularProgress color="inherit" />
    </div>
  ) : (
    <div
      className={`btn-spacing ${className} ${
        mode === "not-active" && "disable"
      }`}
      onClick={mode === "not-active" ? () => {} : onClick}
      style={style}
      id={id}
    >
      {showIcon && <img src={icon} />}
      <span
        style={{ marginLeft: showIcon ? 10 : 0, marginTop: showIcon ? 3 : 0 }}
      >
        <Typography
          color="#fff"
          fontSize={17}
          fontWeight={500}
          fontFamily={"Space Grotesk"}
        >
          {title}
        </Typography>
      </span>
    </div>
  );
};

export default BasicButton;
