import { FC } from "react";

import { Box, Modal, ModalProps, Typography } from "@mui/material";

import "./index.css";
import CardLayout from "components/CardLayout";

const BasicModal: FC<ModalProps> = ({ children, onClose, open }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <CardLayout
        backgroundColor="#fff"
        style={{
          width: 390,
          padding: "40px 20px",
        }}
      >
        {children}
      </CardLayout>
    </Modal>
  );
};

export default BasicModal;
