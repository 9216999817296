import { IconButton } from '@mui/material'
import { Box } from '@mui/system'
import CloseIcon from '@mui/icons-material/Close'
import React, { ReactNode } from 'react'
import TokenImage from 'components/TokenImage'

const NavigatorHeading = ({
  title,
  RightComponent,
  img,
}: {
  title: string
  RightComponent?: ReactNode
  img?: string
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: {
          xs: 'row',
          sm: 'row',
          md: 'row',
        },
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        gap: '20px',
        flexFlow: 'wrap',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {img && (
          <Box mr={1.25}>
            <TokenImage logo={img} size={42} />{' '}
          </Box>
        )}
        <Box
          sx={{
            color: '#1A1C20',
            fontFamily: 'Space Grotesk',
            fontSize: '25px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: 'normal',
          }}
        >
          {title}
        </Box>
      </Box>
      <Box>{RightComponent}</Box>
    </Box>
  )
}

export default NavigatorHeading
