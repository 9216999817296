import React from "react";
import SettingsViewSeedPhrase from "../../../../components/SettingsViewSeedPhrase";

const SeedphraseSettings = () => {
  return (
    <>
      <SettingsViewSeedPhrase />
    </>
  );
};

export default SeedphraseSettings;
