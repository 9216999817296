import React from "react";
import StashedLogo from "assets/stashed-logo.svg";

import "./styles.css";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store/store";

const AuthHeader = () => {
  const navigate = useNavigate();

  const { popupLoading } = useAppSelector((state) => state.wallet);

  return (
    <div className="auth-header">
      <img
        src={StashedLogo}
        className="stashed-logo"
        onClick={() => (popupLoading ? () => {} : navigate("/"))}
      />
    </div>
  );
};

export default AuthHeader;
