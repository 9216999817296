import React, { FC } from "react";
import ContactMenu from "../../pages/app/Settings/Collapse/SubMenu/ContactMenu";

const TransactionContactList: FC<{
  nextStep?: (address: string, tag: string) => void;
  shouldAddContact?: boolean;
  isTxForm?: boolean;
  isChooseRecipient?: boolean;
  isCashMode?: boolean;
}> = ({
  nextStep,
  shouldAddContact = false,
  isTxForm = false,
  isChooseRecipient = false,
  isCashMode = false,
}) => {
  return (
    <>
      <ContactMenu
        shouldAddContact={shouldAddContact}
        isTxForm
        nextStep={nextStep}
        isChooseRecipient={isChooseRecipient}
        isCashMode={isCashMode}
      />
    </>
  );
};

export default TransactionContactList;
