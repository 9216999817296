import axios from "axios";
import React, { useEffect, useState } from "react";
import { message } from "./constants";

const OnRampView = () => {
  const [fiatList, setFiatList] = useState([{}]);
  const [selectedFiat, setSelectedFiat] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [fiatAmount, setFiatAmount] = useState(0);

  const [selectedCrypto, setSelectedCrypto] = useState("");
  const [cryptoAmount, setCryptoAmount] = useState(0);
  const [cryptosList, setCryptosList] = useState([{}]);
  const [rate, setRate] = useState(0);
  const [ramp, setRamp] = useState("");
  const [quoteId, setQuoteId] = useState("");
  const [transactionUrl, setTransactionUrl] = useState("");

  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.get("https://api.onramper.com/supported", {
          headers: {
            Authorization: process.env.ONRAMP_API_KEY,
          },
        });
        const { message } = data;

        const { crypto, fiat } = message;

        setCryptosList(crypto);
        setFiatList(fiat);
      } catch (error) {
        console.log("E>>>>>>>>>>>", error);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (selectedCrypto && selectedFiat && fiatAmount) {
        try {
          const { data } = await axios.get(
            `https://api.onramper.com/quotes/${selectedFiat}/${selectedCrypto}?amount=${fiatAmount}`,
            {
              headers: {
                Authorization: process.env.ONRAMP_API_KEY,
              },
            }
          );

          setCryptoAmount(data[0].payout);
          setRate(data[0].rate);
          setRamp(data[0].ramp);
          setQuoteId(data[0].quoteId);
        } catch (error) {
          console.log("E>>>>>>>>>>>", error);
        }
      }
    })();
  }, [selectedCrypto, selectedFiat, fiatAmount]);

  const fiatHandler = (e) => {
    console.log(e.target.value);
    setSelectedFiat(e.target.value);
  };

  const cryptoHandler = (e) => {
    console.log(e.target.value);
    setSelectedCrypto(e.target.value);
  };

  const createIntent = async () => {
    try {
      const { data } = await axios.post(
        "https://api.onramper.com/checkout/intent",
        {
          onramp: ramp,
          source: selectedFiat,
          destination: selectedCrypto,
          amount: fiatAmount,
          type: "buy",
          paymentMethod: "creditcard",
          network: "ethereum",

          originatingHost: "buy.onramper.com",
          metaData: {
            quoteId: quoteId,
          },
          wallet: {
            address: walletAddress,
          },
        },
        {
          headers: {
            Authorization: process.env.ONRAMP_API_KEY,
          },
        }
      );
      console.log("DATAAAAAAAA", data);
      setTransactionUrl(data.message.transactionInformation.url);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <h2>Select Fiat</h2>
      <input
        value={fiatAmount}
        onChange={(e) => setFiatAmount(e.target.value)}
      />
      <select onChange={fiatHandler} value={selectedFiat}>
        {fiatList.map((fiat, index) => (
          <option value={fiat.id} selected={fiat.id === selectedFiat}>
            {fiat.name} {fiat.symbol}
          </option>
        ))}
      </select>

      <h2>Select Crypto</h2>
      <input
        value={cryptoAmount}
        onChange={(e) => setCryptoAmount(e.target.value)}
        disabled
      />

      <select onChange={cryptoHandler} value={selectedCrypto}>
        {cryptosList.map((crypto, index) => (
          <option selected={crypto.id === selectedCrypto} value={crypto.id}>
            {crypto.name} {crypto.symbol}
          </option>
        ))}
      </select>

      {selectedFiat && selectedCrypto && fiatAmount && rate && (
        <div>
          1 {selectedCrypto.toUpperCase()} ≈ {rate} {selectedFiat.toUpperCase()}
        </div>
      )}

      <div>
        <label htmlFor="">Enter Wallet Address</label>
        <input
          type="text"
          value={walletAddress}
          onChange={(e) => setWalletAddress(e.target.value)}
        />
      </div>

      <div>
        <button onClick={createIntent}>Proceed to checkout</button>
      </div>

      {transactionUrl && (
        <iframe
          title="MyIFrame"
          width="600"
          height="400"
          src={transactionUrl}
          style={{ display: "block" }}
        ></iframe>
      )}
    </div>
  );
};

export default OnRampView;
