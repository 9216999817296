import { useAppSelector } from "store/store";
import CashView from "./view";

const Cash = () => {
  const { activeAccount } = useAppSelector((state) => state.app);

  const viewProps = {
    accountName: activeAccount.accountName,
  };

  return <CashView {...viewProps} />;
};

export default Cash;
