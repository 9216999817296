import { Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import pbkdf2 from "pbkdf2";

import BasicButton from "components/Button";
import Input from "components/Input";
import { LockedSecretSeedPhrase } from "components/LockedSecretPhrase";
import { ethers } from "ethers";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "store/store";
import { decryptMessage, showAlert } from "utils/utils";
import { Box, useTheme } from "@mui/system";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const SettingsViewSeedPhrase = () => {
  const [error, setError] = useState(false);
  const [showPhrase, setShowPhrase] = useState(false);
  const [locked, setLocked] = useState(true);
  const [seedphrase, setSeedPhrase] = useState([]);
  const [password, setPassword] = useState("");
  const [btnText, setBtnText] = useState("Copy Seed Phrase");
  const belowMedium = useMediaQuery("(max-width:900px)");
  const { encryptedSeedPhraseWithPassword } = useAppSelector(
    (state) => state.app
  );
  const { hashedPassword } = useAppSelector((state) => state.wallet);

  useEffect(() => {
    try {
      const decryptSeed = decryptMessage(
        encryptedSeedPhraseWithPassword,
        hashedPassword
      );
      console.log(decryptSeed);
      setSeedPhrase(decryptSeed.split(" "));
    } catch (err) {
      console.log(err);
    }
  }, []);

  const onCopy = () => {
    navigator.clipboard.writeText(seedphrase.join(" "));
    setBtnText("Copied");

    setTimeout(() => {
      setBtnText("Copy Seed Phrase");
    }, 2000);
  };

  const checkPassword = () => {
    const hashedEnteredPassword = pbkdf2
      .pbkdf2Sync(password, "salt", 1, 32, "sha512")
      .toString("hex");
    if (hashedEnteredPassword === hashedPassword) {
      setShowPhrase(true);
      setError(false);
    } else {
      setError(true);
    }
  };
  const theme = useTheme();

  return (
    <div className="center-container box-main" style={{ minHeight: "60vh" }}>
      {showPhrase ? (
        <>
          <Typography variant="h4" sx={{ marginBottom: "50px" }}>
            Your seed phrase
          </Typography>

          {locked ? (
            <LockedSecretSeedPhrase onClick={() => setLocked(false)} />
          ) : (
            // <div
            //   style={{
            //     // border: "1px solid #E8ECF5",
            //     borderRadius: "6px",
            //     // background: "#F9F8FD",
            //     maxWidth: "780px",
            //     flexWrap: "wrap",
            //     gap: "20px",
            //     display: "flex",
            //   }}
            //   onCopy={onCopy}
            // >
            <Grid
              container
              spacing={1}
              sx={{
                [theme.breakpoints.down("md")]: {
                  maxWidth: "100%", // Change to green on small screens (sm and up)
                },
                maxWidth: "70%",
              }}
            >
              {seedphrase.map((phrase, index) => {
                let number = (index + 1).toString();
                if (number.length === 1) {
                  number = "0" + number;
                }

                return (
                  <Grid item xs={12} sm={3} md={3} key={index}>
                    <Box
                      style={{
                        padding: "15px",
                        borderRadius: "10px",
                        border: "0.5px solid rgba(26, 28, 32, 0.50)",
                      }}
                    >
                      <Box>
                        <Typography
                          variant="body2"
                          fontSize={"12px"}
                          style={{
                            color: "#1A1C2080",
                            // marginLeft: "25%",
                            fontWeight: "500",
                          }}
                        >
                          #{number}
                        </Typography>
                      </Box>

                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          // alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="h5"
                          style={{
                            fontWeight: "600",
                            fontSize: "18px",
                          }}
                        >
                          {phrase}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          )}

          {/* <BasicButton
            title={btnText}
            onClick={onCopy}
            style={{
              height: "50px",
              flex: 0,
              maxWidth: "670px",
              minWidth: "320px",
            }}
            id="secret_recovery_phrase_continue"
          /> */}
          <div
            className={`btn-spacing`}
            onClick={onCopy}
            style={{
              marginTop: "50px",
              position: "relative",
              height: "50px",
              flex: 0,
              maxWidth: "670px",
              minWidth: "320px",
            }}
          >
            <Typography color="#fff" fontSize={17} fontWeight={700}>
              {btnText}
            </Typography>
            <ContentCopyIcon
              style={{
                color: "#ffffff",
                position: "absolute",
                right: "15px",
              }}
            />
          </div>
        </>
      ) : (
        <div className="box bottom-box">
          <Typography style={{ marginBottom: 25, textAlign: "center" }}>
            Enter Your Password
          </Typography>
          <Input
            style={{
              maxWidth: "700px",
              minWidth: belowMedium ? "300px" : "350px",
              marginBottom: 2,
            }}
            title="Password"
            value={password}
            onChange={setPassword}
            type="password"
          />
          {error && (
            <Typography
              style={{
                margin: "-20px 0px 20px 0px",
                color: "red",
                fontSize: 14,
                textAlign: "left",
              }}
            >
              Password is not correct
            </Typography>
          )}
          <BasicButton
            title="Continue"
            style={{
              flex: 0,
              maxWidth: "670px",
              minWidth: belowMedium ? "270px" : "320px",
            }}
            onClick={checkPassword}
            mode={!password ? "not-active" : "active"}
          />
        </div>
      )}
    </div>
  );
};

export default SettingsViewSeedPhrase;
