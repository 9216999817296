import React, { useState, FC } from "react";
import QRCode from "react-qr-code";
import { Snackbar, Typography, Box } from "@mui/material";
import WalletIcon from "assets/wallet.svg";
import InfoIcon from "assets/info.svg";
import EmberLogo from "assets/ember-receive.svg";

import "./index.css";
import BasicButton from "components/Button";
import { useAppSelector } from "store/store";
import QRLogo from "assets/logo-qr.svg";
import { SUPPORTED_NETWORKS } from "constants/chains";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import NavigatorHeading from "../../../../components/NavigatorHeading";
import CloseButton from "../../../../components/CloseButton";
import CustomizedSteppers from "components/Stepper";
import NetworksList from "components/NetworksList";
import CardLayout from "components/CardLayout";
import { ItemWrapper } from "components/Styled";
import CenterItem from "components/CenterItem";
import CopyIcon from "assets/whiteCopyIcon.svg";

const CashReceive: FC<{
  isNftPage?: boolean;
}> = ({ isNftPage }) => {
  const [showSnackbar, setShowSnackbar] = useState({
    state: false,
    message: "",
  });
  const [step, setStep] = useState(0);
  const { activeAccount, activeNetwork } = useAppSelector((state) => state.app);
  const navigate = useNavigate();
  const location = useLocation();

  const { title, isCashTab, isShowWithOutTitle } = location?.state || {};

  const { icon } =
    SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS];

  const chainName =
    SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS]
      .chainName;
  const chainSymbol =
    SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS].symbol;

  const onCopy = (text: string, message: string) => {
    setShowSnackbar({ state: true, message });
    navigator.clipboard.writeText(text);
  };

  return (
    <>
      <Snackbar
        open={showSnackbar.state}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar({ state: false, message: "" })}
        message={showSnackbar.message}
      />
      <Box mt={6}>
        <NavigatorHeading
          title={"Receive Cash"}
          RightComponent={
            <CloseButton
              handleOnClick={() => {
                navigate("/cash");
              }}
            />
          }
        />
      </Box>

      <Box mt={5}>
        <Box
          width={"450px"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          margin={"auto"}
        >
          <Box width="85%" marginLeft={"auto"}>
            <Typography
              variant={"h5"}
              fontWeight={700}
              color="rgba(26, 28, 32, 1)"
              fontSize={34}
            >
              {title}
            </Typography>
          </Box>
          <Box
            mt={5}
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CardLayout backgroundColor="#fff">
              <Box position="relative">
                <Box marginX={"18px"} marginY={"8px"}>
                  <QRCode
                    value={activeAccount.smartAccountAddress}
                    size={282}
                  />
                </Box>
                <img src={QRLogo} className="qr-img" />
              </Box>
            </CardLayout>
          </Box>
          <Box
            marginTop={5}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
          >
            <Box display={"flex"} alignItems={"center"}>
              <ItemWrapper
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{ cursor: "pointer" }}
              >
                {`joinstashed.me/${title?.substring(1)}`}
              </ItemWrapper>
              <Box marginLeft={1}>
                <CenterItem>
                  <div
                    className="tab-chip-box"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "5px",
                      alignItems: "center",
                    }}
                    onClick={() =>
                      onCopy(
                        `joinstashed.me/${title?.substring(1)}`,
                        "URL Copied"
                      )
                    }
                  >
                    <Typography
                      variant={"h5"}
                      fontWeight={500}
                      color="white"
                      fontSize={12}
                      fontFamily={"Helvetica Neue"}
                      whiteSpace={"nowrap"}
                      letterSpacing={2}
                    >
                      Copy{" "}
                    </Typography>

                    <img
                      src={CopyIcon}
                      alt="copy icon"
                      style={{
                        cursor: "pointer",

                        height: "15px",
                      }}
                    />
                  </div>
                </CenterItem>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CashReceive;
