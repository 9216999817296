import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import TransactionMetaDataItem from "./TransactionMetaDataItem";
import { getEnsFromAddress } from "utils/utils";
import { useAppSelector } from "store/store";

interface ChildComponentProps {
  props: {
    date: string;
    status: string;
    toEns: string;
    network: string;
    chain: string;
    networkFee?: string;
    scanUrl?: string;
    type: string;
    from: string;
    hash: string;
  };
}

const TransactionMetaData: React.FC<ChildComponentProps> = ({ props }) => {
  const {
    date,
    status,
    toEns,
    network,
    networkFee,
    chain,
    scanUrl,
    type,
    from,
    hash,
  } = props;

  const [ens, setEns] = useState("");
  const [fromEns, setFromEns] = useState("");
  const { cashExchangeTxs, activeAccount } = useAppSelector(
    (state) => state.app
  );

  useEffect(() => {
    (async () => {
      try {
        console.log(
          "HASSSSSSSSSS",
          cashExchangeTxs.includes(hash),
          cashExchangeTxs,
          hash
        );
        const ens = await getEnsFromAddress(
          cashExchangeTxs.includes(hash)
            ? activeAccount.smartAccountAddress.toLowerCase()
            : toEns
        );
        if (ens) {
          setEns("@" + ens);
        } else {
          setEns(toEns);
        }
        const fromEns = await getEnsFromAddress(from);
        if (fromEns) {
          setFromEns("@" + fromEns);
        } else {
          setFromEns(from);
        }
      } catch (error) {
        throw error;
      }
    })();
  }, []);

  const newDate = new Date(date);

  const options: any = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
    newDate
  );

  const formatTo = async () => {};

  return (
    <Box display={"flex"} flexDirection={"column"} gap={2}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          padding: "20px",
          borderRadius: "10px",
          background: "#FFFFFF",
        }}
      >
        <TransactionMetaDataItem
          title="Date"
          RightComponent={
            //   <a
            //     target="_blank"
            //     // href={`${url}/address/${contractAddress}`}
            //     style={{ textDecoration: "none" }}
            //   >
            <Box
              sx={{
                fontFamily: "Space Grotesk",
                fontWeight: 500,
              }}
            >
              {formattedDate}
              {/* {formatWalletAddress(contractAddress, 4)} */}
            </Box>
            //   </a>
          }
        />
        <TransactionMetaDataItem
          title="Status"
          RightComponent={
            <Box
              sx={{
                fontFamily: "Space Grotesk",
                fontWeight: 500,
              }}
            >
              {status}
            </Box>
          }
        />

        <TransactionMetaDataItem
          title={type.toLowerCase() === "buy" ? "From" : "To"}
          RightComponent={
            <Box
              sx={{
                fontFamily: "Space Grotesk",
                fontWeight: 500,
              }}
            >
              {type.toLowerCase() === "buy" ? fromEns : ens}
            </Box>
          }
        />

        {/* <TransactionMetaDataItem
          title="Network Fee"
          RightComponent={
            <Box
              sx={{
                color: "#1A1C20",
                fontFamily: "Space Grotesk",
                fontWeight: 500,
              }}
            >
              {networkFee}
            </Box>
          }
        /> */}
        {/* {lastUpdated && (
        <TransactionMetaDataItem
          title="Last Updated"
          RightComponent={
            <Box
              sx={{
                color: "#1A1C20",
                fontFamily: "Space Grotesk",
                fontWeight: 500,
              }}
            >
              {lastUpdated}
            </Box>
          }
        />
      )}
      {lastUpdated && (
        <TransactionMetaDataItem
          title="Last Updated"
          RightComponent={
            <Box
              sx={{
                color: "#1A1C20",
                fontFamily: "Space Grotesk",
                fontWeight: 500,
              }}
            >
              {lastUpdated}
            </Box>
          }
        />
      )} */}
      </Box>
    </Box>
  );
};

export default TransactionMetaData;
