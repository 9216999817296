import { Box, Typography } from "@mui/material";

import { FC } from "react";
import TokenInput from "components/TokenInput";
import BasicButton from "components/Button";

interface TokenInputFormProps {
  title: string;
  mode?: string;
  tokenName?: string;
  tokenIcon?: string;
  disabled?: boolean;
  value?: string;
  onChange?: any;
  onKeydown?: any;
  type?: string;
  style?: {};
  addBorder?: boolean;
  showStartIcon?: boolean;
  length?: number;
  showEndIcon?: boolean;
  secure?: boolean;
  depositedAmount?: number;
  loading?: boolean;
  isValid?: boolean;
  receiverENS?: string;
  isDepositValid?: boolean;
  isSwap?: boolean;
  depositTransaction?: () => void;
  sendTransaction?: () => void;
  endIconAction?: () => void;
  showSecure?: () => void;
  nextStep: () => void;
  buttonTitle?: string;
  placeHolder?: string;
  balance?: number;
  errorMessage?: string;
}

const TokenInputForm: FC<TokenInputFormProps> = ({
  title,
  mode,
  disabled,
  type,
  onChange,
  onKeydown,
  style,
  value,
  addBorder,
  showStartIcon,
  showEndIcon,
  length,
  endIconAction,
  nextStep,
  depositedAmount,
  loading,
  isValid = true,
  isDepositValid = true,
  depositTransaction,
  sendTransaction,
  tokenName,
  tokenIcon,
  receiverENS,
  isSwap = false,
  buttonTitle = "Continue",
  placeHolder,
  balance,
  errorMessage,
}) => {
  const inputProps = {
    title,
    mode,
    disabled,
    type,
    onChange,
    onKeydown,
    style,
    value,
    addBorder,
    showStartIcon,
    showEndIcon,
    length,
    endIconAction,
    tokenName,
    tokenIcon,
    receiverENS,
    buttonTitle,
    placeHolder,
  };
  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          flexDirection: "column",
        }}
      >
        <Box>
          <Typography
            sx={{
              color: "rgba(26, 28, 32, 0.50)",
              fontFamily: "Space Grotesk",
              fontsize: 17,
              fontWeight: 700,
            }}
          >
            {isSwap ? "Enter Amount" : receiverENS && `Send to ${receiverENS}`}
          </Typography>
          <Box mt={2.5}>
            <TokenInput {...inputProps} balance={balance} />
          </Box>
          {errorMessage && (
            <div style={{ color: "red" }}>{errorMessage}</div> // Style as needed
          )}
          {balance && Number(value) > balance && (
            <div style={{ color: "red" }}>Insufficient balance!</div> // Style as needed
          )}
          <Box mt={5}>
            <BasicButton
              title={buttonTitle}
              onClick={nextStep}
              loading={loading}
              disabled={disabled}
              mode={isDepositValid ? "active" : "not-active"}
              id="send"
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TokenInputForm;
