import { Grid, Input } from "@mui/material";
import SearchIcon from "assets/SearchInputIcon.svg";

const SearchInput = ({
  handleOnChange,
  padding = "5px 20px",
  width = "100%",
  placeholder = "Search",
  value,
}: {
  handleOnChange: (e: any) => void;
  padding?: string;
  width?: string;
  placeholder?: string;
  value?: string;
}) => {
  return (
    <Input
      placeholder={placeholder}
      onChange={handleOnChange}
      startAdornment={
        <Grid
          sx={{
            minHeight: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={SearchIcon}
            style={{
              // background: "red",
              height: "20px",
              width: "25px",
            }}
          />
        </Grid>
      }
      sx={{
        padding,
        gap: "8.384px",
        flexShrink: " 0",
        width,
        borderRadius: "60px",
        opacity: " 0.8",
        background: " #EFF3F4",
        //placeholder
        input: {
          color: "#536371",
          fontFamily: "Helvetica Neue",
          fontSize: "14px",
          fontWeight: 400,
          paddingTop: "8px",
          "&::placeholder": {
            opacity: 0.6,
          },
        },
      }}
      value={value}
    />
  );
};

export default SearchInput;
