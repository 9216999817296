import { IconButton, InputBase, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import TokenItem from "components/TokenItem";
import { SwapToToken, Token } from "interfaces";
import React, { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import SearchIcon from "assets/search.svg";
import { setSelectedToken } from "@slices/walletSlice";

const AssetsView: FC<{
  tokens: Token[] | SwapToToken[];
  selectTokenHandler: (tk: Token) => void;
}> = ({ tokens, selectTokenHandler }) => {
  const [searchToken, setSearchToken] = useState("");
  const [allTokens, setAllTokens] = useState<Token[] | SwapToToken[]>(tokens);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!searchToken) {
      setAllTokens(tokens);
    } else {
      let filteredTokens = tokens.filter(
        tk =>
          tk.tokenName.toLowerCase().includes(searchToken.toLowerCase()) ||
          tk.tokenSymbol.toLowerCase().includes(searchToken.toLowerCase())
      );
      setAllTokens(filteredTokens);
    }
  }, [searchToken]);

  const tokenHandler = (tk: Token) => {
    selectTokenHandler(tk);
  };

  return (
    <div>
      <Stack
        direction={"row"}
        justifyContent='flex-start'
        gap='20px'
        sx={{
          marginBottom: "25px",
          width: "93%",
          paddingTop: "16px",
          paddingLeft: "16px",
        }}
      >
        <Box
          display='flex'
          borderRadius='6px'
          flex={8}
          sx={{
            border: "1px solid #F3F5F9",
            backgroundColor: "white",
            padding: 1,
          }}
        >
          <IconButton type='button' sx={{ p: 1 }}>
            <img src={SearchIcon} alt='search' />
          </IconButton>
          <InputBase
            sx={{ ml: 2, flex: 1, fontSize: "14px" }}
            placeholder='Search'
            value={searchToken}
            onChange={e => setSearchToken(e.target.value)}
            id='search'
          />
        </Box>
      </Stack>

      <div className='assets-box'>
        {allTokens.length > 0 ? (
          allTokens.map((tk, i) => (
            <div onClick={() => tokenHandler(tk)}>
              <TokenItem
                symbol={tk.tokenSymbol}
                name={tk.tokenName}
                image={tk.image}
                usdPrice={tk?.priceInUSD}
                amount={tk?.tokenBalance}
                tokenAddress={tk.tokenAddress}
              />
            </div>
          ))
        ) : (
          <Typography variant='h4' style={{ textAlign: "center" }}>
            No Tokens
          </Typography>
        )}
      </div>
    </div>
  );
};

export default AssetsView;
