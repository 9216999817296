export const EVM_DERIVATION_PATH = "m/44'/60'/0'/0";
export const DEVELOPMENT_MODE = false;
export const LOGIN_EXPIRY = 900000;
export const BASE_URL = "https://backendv2.joinstashed.com";
export const BLOCK_AID_URL = "https://api.blockaid.io";
export enum GRAPH_PERIODS {
  monthly = 30,
  weekly = 7,
  daily = 1,
}
export const nftTempImage =
  "https://testnets.opensea.io/static/images/placeholder.png";
export const SANDBOX_CASE = "0xa342f5d851e866e18ff98f351f2c6637f4478db5";
export const IN_INVENTORY = "Available";
export const ON_LEND1 = "On Lend";
export const ON_SELL = "On Sell";
export const ON_RENT = "On Rent";
export const ON_SUBLEND = "ON_SUBLEND";
export const ON_SUBRENT = "ON_SUBRENT";
export const CLAIMABLE = "CLAIMABLE";
export const BORROWED = "BORROWED";
export const NATIVE_ADDRESS = "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee";
export const APPROVE_AMOUNT =
  "115792089237316195423570985008687907853269984665640564039457584007913129639935"; //(2^256 - 1 )

export const SHARES = 5;
export const THRESHOLD = 3;

export const LIGHT_GREEN_COLOR = "rgba(0, 229, 153, 0.1)";
export const DARK_GREEN_COLOR = "rgba(0, 229, 153, 1)";

export const LIGHT_BLUE_COLOR = "rgba(34, 232, 208, 1)";
export const DARK_BLUE_COLOR = "rgba(49, 112, 209, 1)";

export const areaChartCryptoColors = {
  fill: "rgba(69, 106, 253, 0.6)",
  stroke: "rgba(0, 229, 153, 1)",
  stops: [0, 58.66, 100],
  firstColor: "rgba(69, 106, 253, 0.4)",
  secondColor: "rgba(34, 232, 209, 0.36)",
  thirdColor: "rgba(255, 255, 255, 0)",
};

export const areaChartCashColors = {
  fill: "rgba(0, 229, 153, 0.1)",
  stroke: "rgba(0, 229, 153, 1)",
  stops: [0, 58.66, 100],
  firstColor: "rgba(83, 238, 185, 0.6)",
  secondColor: "rgba(203, 245, 193, 0.6)",
  thirdColor: "rgba(255, 255, 255, 0)",
};

export const MOBULA_API = "https://api.app-mobula.com/api/1";

export const contractAddresses = {
  polygon: {
    qouter: "0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6",
    across: "0x9295ee1d8C5b022Be115A2AD3c30C72E34e7F096",
    stargate: "0x45A01E4e04F14f7A4a6702c74187c5F6222033cd",
    stargateBridge: "0x9d1B1669c73b033DFe47ae5a0164Ab96df25B944",
    stargateLayerZeroEndpoint: "0x3c2269811836af69497E5F486A85D7316753cf62",
    uniswapV3Factory: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
    swapRouter02: "0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45",
    universalRouter: "0x3fC91A3afd70395Cd496C647d5a6CC9D4B2b7FAD",
    positionManager: "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
  },
};

export const SPECIAL_CHARACTERS_REGEX =
  /[\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g;

export const stepsCount = {
  "/signin": 3,
  "/signin-with-password": 3,
  "/signin-with-pin": 3,
  "/signin-download-recovery-key": 5,
  "/account-recovered": 5,
  "/create-account": 7,
  "/recover-stashed-tag": 6,
  "/forgot-password/recover-choose-method": 5,
  "/forgot-pin/recover-choose-method": 5,
};
