import axios from "axios";
import { BASE_URL } from "../constants";

export const getSignatureObj = async ({
  userOp,
  payMasterType,
}: {
  userOp: any;
  payMasterType: any;
}) => {
  try {
    const { data } = await axios.post(`${BASE_URL}/transaction/send`, {
      userOp: userOp,
      payMasterType,
    });

    return data;
  } catch (error) {
    throw error;
  }
};
