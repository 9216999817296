export const bridgeAbi = [
    {
        name: 'deposit',
        inputs: [
            { type: 'address', name: 'recipient' },
            { type: 'address', name: 'originToken' },
            { type: 'uint256', name: 'amount' },
            { type: 'uint256', name: 'destinationChainId' },
            { type: 'int64', name: 'relayerFeePct' },
            { type: 'uint32', name: 'quoteTimestamp' },
            { type: 'bytes', name: 'message' },
            { type: 'uint256', name: 'maxCount' },
        ],
        outputs: [],
        stateMutability: 'payable',
        type: 'function',
    },
];