import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { FC } from "react";

const FeeUrgencyComponent: FC<{
  feeGasUrgency: string;
  setFeeGasUrgency: (isUrgent: string) => void;
}> = ({ feeGasUrgency, setFeeGasUrgency }) => {
  return (
    <Box>
      <Box
        display={"flex"}
        flexDirection={"row"}
        gap={2}
        sx={{
          border: "0px",
          background: "#F7F7F7",
          borderRadius: "10px",
          padding: "5px",
        }}
      >
        <Box
          sx={{
            // Active styling
            padding: "10px",
            borderRadius: "10px",
            cursor: "pointer",
            backgroundColor: feeGasUrgency === "Normal" ? "#1A1C20" : "",
            color: feeGasUrgency === "Normal" ? "white" : "",
          }}
          onClick={() => {
            setFeeGasUrgency("Normal");
          }}
        >
          Normal
        </Box>
        <Box
          sx={{
            // Active styling
            padding: "10px",
            borderRadius: "10px",
            cursor: "pointer",
            backgroundColor: feeGasUrgency == "Lightning" ? "#1A1C20" : "",
            color: feeGasUrgency == "Lightning" ? "white" : "",
          }}
          onClick={() => {
            setFeeGasUrgency("Lightning");
          }}
        >
          Lightning
        </Box>
      </Box>
      <Typography
        sx={{
          fontSize: "10px",
          textAlign: "center",
          color: "rgba(26, 28, 32, 0.5)",
          fontFamily: "Space Grotesk",
          fontWeight: 400,
          width: "100%",
        }}
      >
        (1-2 minutes)
      </Typography>
    </Box>
  );
};

export default FeeUrgencyComponent;
