import React, { useEffect } from "react";

import { showAlert } from "utils/utils";

const Marketplace = () => {
  useEffect(() => {
    showAlert("Coming Soon");
  }, []);

  return <div className="market-place-container"></div>;
};

export default Marketplace;
