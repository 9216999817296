import { BytesLike } from "ethers";

/**
 * The Ethereum address for the factory contract.
 * @type {string}
 */

export const Factory_Address = "0x5f16eA3B9647437283fbCd02c4D9b9f534ea008c";

/**
 * The Ethereum address for the paymaster contract.
 * @type {string}
 */
export const Paymaster_Token_Address = "0xB536ad7C360f443C010703263374F79998FFBFB5";

/**
 * The Ethereum address for the entry point contract.
 * @type {string}
 */
export const EntryPoint_Address = "0x5FF137D4b0FDCD49DcA30c7CF57E578a026d2789";

/**
 * The Ethereum address for the paymaster verifier contract.
 * @type {string}
 */
export const Paymaster_Verifier_Address =
  "0xeB34c1C1581fb66C8d91847ae329C8f342882B8f";

export const Paymaster_Owner_Address = "0xF9f8ed79bc47bB5A2F3248B26BF94cd2827584B9"; 

export const EMPTY_CALLDATA: BytesLike = "0x";

/**
 * Enum to specify the type of call data.
 * - `Empty`: Represents an empty call data.
 * - `Execute`: Represents a call data to execute some function.
 */
export enum CallDataType {
  Empty,
  Execute,
  Batch
}

/**
 * Enum to specify actions related to a factory.
 * - `FactoryCreateSender`: Represents a factory action to create a sender.
 * - `FactoryGetSender`: Represents a factory action to retrieve a sender.
 */
export enum FactoryData {
  FactoryCreateSender,
  FactoryGetSender,
  Empty,
}

/**
 * Enum to specify the type of paymaster.
 * - `TokenReceiver`: Represents a token receiving paymaster.
 * - `OffChainVerifier`: Represents an off-chain verifying paymaster.
 * - `None`: Represents no specific type of paymaster.
 */
export enum PaymasterType {
  TokenReceiver,
  OffChainVerifier,
  None,
}

export interface ExecuteCall {
  to: string;
  value: string;
  calldata: BytesLike;
}

export interface TransferData {
  tokenAddress: string;
  tokenAmount: string;
}

/**
 * The salt used for generating deterministic contract addresses or other cryptographic operations.
 * @type {number}
 */
export const SALT = 0;