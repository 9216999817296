import React, { FC } from 'react'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { Box, Typography } from '@mui/material'

const ModalHeader: FC<{
  title: string
  onClose?: () => void
  showBackIcon?: boolean
  textAlign?: string
}> = ({ title, onClose, showBackIcon, textAlign = 'left' }) => {
  return (
    <Box>
      {showBackIcon && (
        <div className='modal-header-icon' onClick={onClose}>
          <ChevronLeftIcon />
        </div>
      )}
      <Typography
        variant='h3'
        color={'black'}
        fontWeight={700}
        fontSize={20}
        sx={{
          textAlign,
        }}
      >
        {title}
      </Typography>
    </Box>
  )
}

export default ModalHeader
