import { useSnapshot } from "valtio";
import { useCallback, useEffect, useState } from "react";
import { Box, Modal as CustomModal } from "@mui/material";
import SessionSignTypedDataModal from "../views/SessionSignTypedDataModal";
import SessionUnsuportedMethodModal from "../views/SessionUnsupportedMethodModal";
import SessionRequestModal from "../views/SessionSignModal";
import SessionSendTransactionModal from "../views/SessionSendTransactionModal";
import SessionProposalModal from "../views/SessionProposalModal";
import ModalStore from "../walletConnectStore/ModalStore";
import WalletConnectPage from "./Walletconnect";
import { signClient } from "../utils/WalletConnectUtil";
import { styledToast } from "../utils/HelperUtil";
import { useAppSelector } from "store/store";

const DappConnectionModal = ({ open, onClose }) => {
  //   const { open, view } = useSnapshot(ModalStore.state);
  // handle the modal being closed by click outside
  const [uri, setUri] = useState("");
  const [loading, setLoading] = useState(false);

  const { connectedDapps } = useAppSelector((state) => state.app);

  async function onConnect(uri: string) {
    try {
      setLoading(true);
      const data = await signClient.core.pairing.pair({ uri });
      console.log(
        "🚀 ~ file: DappConnectionModal.tsx:24 ~ onConnect ~ data:",
        data
      );
      console.log("connectedDapps", connectedDapps);
      onClose();
    } catch (error) {
      styledToast((error as Error).message, "error");
    } finally {
      setUri("");
      setLoading(false);
    }
  }

  return (
    <CustomModal
      onClose={onClose}
      open={open}
      style={{
        border: "1px solid rgba(139, 139, 139, 0.4)",
        // backgroundColor: "red",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          background: "white",
          width: "390px",
          height: "250px",
          // width: "auto",
          // height: "auto",
          // transform: "translateX(150%) translateY(70%)",
          // paddingTop: "50px",
          borderRadius: "4px",
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          paddingTop: "50px",
        }}
      >
        <WalletConnectPage
          {...{ uri, setUri, loading, setLoading, onConnect }}
        />
      </Box>
    </CustomModal>
  );
};

export default DappConnectionModal;
