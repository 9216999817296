import React, { ReactNode, useEffect, useState } from "react";

import Web3 from "web3";

import "./index.css";
import {
  Box,
  Fade,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  Typography,
} from "@mui/material";
import BasicButton from "components/Button";
import BasicModal from "components/BasicModal";
import ModalHeader from "components/ModalHeader";
import AssetsView from "components/AssetsView";
import { Token } from "interfaces";
import { useAppDispatch, useAppSelector } from "store/store";
import { SUPPORTED_NETWORKS } from "constants/chains";
import { decryptMessage, extractTokenData, showAlert } from "utils/utils";
import abi from "abis/erc20abi.json";
import paymasterAbi from "abis/paymasterabi.json";

import { setSelectedToken } from "@slices/walletSlice";
import { fetchGasPrice } from "utils/gas";
import { ethers, BigNumber } from "ethers";
import { setPendingTx, setPendingTxDetails } from "@slices/appSlice";
import { useNavigate } from "react-router-dom";
import { getToAddress } from "utils/ens";
import CustomizedSteppers from "components/Stepper";
import NavigatorHeading from "components/NavigatorHeading";
import CloseButton from "components/CloseButton";
import NetworksList from "components/NetworksList";
import TokensListTable from "../TokensListTable";
import TokenInputForm from "components/TokenInputForm";
import TransactionContactList from "../../../../components/TransactionContactList";
import SendAsset from "./SendAsset";

import { tokensForGasObject } from "../../../../constants/topTokensConf";
import TokenImage from "../../../../components/TokenImage";
import TopTokenSelectComponent from "../../../../components/TopTokenSelect";

import {
  EMPTY_CALLDATA,
  ExecuteCall,
  Paymaster_Token_Address,
  TokenPaymaster__factory,
  Token__factory,
  TransferData,
  getCounterFactualAddress,
  sendUserOp,
} from "../../../../contract-integration";
import { txSubmissionOrderPrepaid } from "../../../../contract-integration/prepaidGas";
import { txSubmissionOrderPostpaid } from "../../../../contract-integration/postpaidGas";
import ConfirmPatternModal from "components/ConfirmPatternModal";
import GasTokenSelect from "../../../../components/GasTokenSelect";
import SendTxComponent from "../../../../components/SendTxComponent";
import FeeUrgencyComponent from "../../../../components/SendTxComponent/FeeUrgencyComponent";
import TxTypeSwitch from "../../../../components/SendTxComponent/TxTypeSwitch";
import FeeUIComponent from "../../../../components/SendTxComponent/FeeUIComponent";
import axios from "axios";
import { BASE_URL } from "constants/";
import Simulation from "components/Simulation";

const Send = () => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [to, setTo] = useState("");
  const [risk, setRisk] = useState(false);

  const [gasFeeInUSD, setGasFeeInUSD] = useState("0");
  const [gasFee, setGasFee] = useState<any>(null);
  const [tag, setTag] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [tokens, setTokens] = useState<Token[]>([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [gasPrice, setGasPrice] = useState(0);
  const [max, setMax] = useState(false);
  const [filter, setFilter] = useState(0);
  const [depositedAmount, setDepositedAmount] = useState(0);
  const [step, setStep] = useState(0);
  const [feeGasUrgency, setFeeGasUrgency] = React.useState("Normal");
  const [tokenForPayment, SetTokenForPayment] = React.useState("");
  const [depositableTokens, setDepositableTokens] = useState<Array<any>>([]);
  const [tokenForPaymentDecimal, setTokenForPaymentDecimal] = useState<
    number | null
  >(null);
  console.log(
    "file: index.tsx:84  Send  tokenForPaymentDecimal:",
    tokenForPaymentDecimal
  );

  const [tokenForPaymentBalance, setTokenForPaymentBalance] =
    useState<number>(0);

  const [error, setError] = useState("");
  const [openPatternModal, setOpenPatternModal] = useState(false);
  const [isPatternCorrect, setIsPatternCorrect] = useState(false);

  const [finalOpState, setFinalOpState] = useState<any>(null);
  const [sameTokens, setSameTokens] = useState<any[]>([]);

  const [selectedDepositTokenAddress, setSelectedDepositTokenAddress] =
    useState("");
  console.log(
    "file: index.tsx:96  Send  selectedDepositTokenAddress:",
    selectedDepositTokenAddress
  );
  const [txByDeposited, setTxByDeposited] = useState(false);

  const ISNATIVE =
    tokenForPayment == "0x0000000000000000000000000000000000000000" ||
    tokenForPayment == "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270";

  const handleGasButtonChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setFeeGasUrgency(newAlignment);
  };

  const navigate = useNavigate();
  const {
    activeAccount,
    holdings,
    activeNetwork,
    accounts,
    // portfolio,
    portfolio: { assets },
    rootAccountInfo,
    userSpendingDetails,
  } = useAppSelector((state) => state.app);
  console.log(
    "file: index.tsx:104  Send  activeAasdasccount:",
    activeAccount,
    rootAccountInfo,
    accounts
  );

  const {
    selectedToken,
    isAccountDeployed,
  }: { selectedToken: any; isAccountDeployed: boolean } = useAppSelector(
    (state) => state.wallet
  );
  console.log("file: index.tsx:89  Send  selectedToken:", selectedToken);
  const { hashedPassword, txStatus } = useAppSelector((state) => state.wallet);

  const { nativeTokenName, symbol, icon, rpc, block_explorer_url } =
    SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS];

  const { nativeBalance, nativeBalanceUsd, nativeTokenPrice } =
    holdings[activeAccount.smartAccountAddress];

  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      const { average } = await fetchGasPrice(activeNetwork);
      console.log(
        "GASSSSSSSSSSs",
        average,
        Number(Web3.utils.fromWei(Number(average).toString(), "ether"))
      );
      setGasPrice(average);
    })();
  }, []);

  useEffect(() => {
    let userTokens: Token[] =
      holdings[activeAccount.smartAccountAddress]?.tokens || [];

    setTokens(userTokens);
  }, [activeAccount, holdings]);

  useEffect(() => {
    if (isPatternCorrect) {
      setOpenPatternModal(false);
      // executeSendCrypto();
    }
  }, [isPatternCorrect]);

  const isSelectedToken = selectedToken.length > 0;
  const tokenSymbol = isSelectedToken ? selectedToken[0].tokenSymbol : symbol;
  const tokenIcon = isSelectedToken ? selectedToken[0].image : icon;
  const tokenName = isSelectedToken
    ? selectedToken[0].tokenName
    : nativeTokenName;
  const tokenBalance = isSelectedToken
    ? selectedToken[0].tokenBalance
    : nativeBalance;
  const tokenBalanceInUsd = isSelectedToken
    ? selectedToken[0].priceInUSD
    : nativeBalanceUsd;
  const tokenPriceInUsd = isSelectedToken
    ? selectedToken[0].tokenPrice
    : nativeTokenPrice;
  const tokenAddress = isSelectedToken ? selectedToken[0].tokenAddress : "";

  // const { dummyToken } = SUPPORTED_NETWORKS[activeNetwork];

  useEffect(() => {
    setValue("");
  }, [selectedToken]);

  useEffect(() => {
    if (max && Number(value) < tokenBalance) {
      setFilter(0);
    } else if (Number(value) < tokenBalance * filter) {
      setFilter(0);
    }
  }, [value]);

  // useEffect(() => {
  //   if (selectedDepositTokenAddress) {
  //     calculateGasInEth(selectedDepositTokenAddress);
  //   } else if (tokenForPayment) {
  //     calculateGasInEth(tokenForPayment);
  //   }
  // }, [selectedToken, tokenForPayment, step, feeGasUrgency]);

  const readAccountHoldings = async (
    tokenAddress: string,
    address: string,
    rpc: string
  ) => {
    const web3 = new Web3(rpc);
    const paymasterContract = new web3.eth.Contract(
      paymasterAbi as any,
      Paymaster_Token_Address
    );
    console.log(
      "file: index.tsx:236  Send depositableTokens paymasterContract:"
    );
    // Create an object to keep track of cumulative balances for each token

    try {
      const accountHolding = await paymasterContract.methods
        .balances(tokenAddress, address)
        .call();
      console.log(
        "file: index.tsx:241 depositableTokens  Send  accountHolding:",
        accountHolding,
        tokenAddress
      );
      return Number(accountHolding);
    } catch (error) {
      console.error("Error reading depositableTokens account holdings:", error);
      return 0; // Handle the error gracefully
    }
  };

  async function fetchBalances() {
    const allAccountsAddress = Object.keys(accounts);

    const firstAccountAddress =
      accounts[allAccountsAddress[0]].smartAccountAddress;
    console.log(
      "file: index.tsx:256  fetchBalances depositableTokens  firstAccountAddress:",
      firstAccountAddress
    );

    let depositedTokenObject: Array<any> = [];
    for (const tokenInfo of tokensForGasObject[activeNetwork]) {
      const { tokenAddress, rpc } = tokenInfo;
      console.log(
        "file: index.tsx:265  fetchBalances depositableTokensasas  tokenAddress:",
        tokenAddress
      );
      const balance = await readAccountHoldings(
        tokenAddress,
        firstAccountAddress,
        rpc
      );
      console.log(
        "file: index.tsx:271 depositableTokens fetchBalances  balance:",
        balance
      );

      if (balance > 0) {
        depositedTokenObject.push({
          ...tokenInfo,
          balance: balance / 10 ** tokenInfo.decimal,
        });
      }
    }
    setDepositableTokens(depositedTokenObject);
  }
  useEffect(() => {
    fetchBalances();
  }, [accounts, activeAccount, activeNetwork]);

  useEffect(() => {
    console.log(
      "file: index.tsx:923  useEffect  txByDeposited:",
      selectedToken.length > 0,
      tokenForPayment,
      to,
      value,
      selectedDepositTokenAddress,
      !txByDeposited
    );
    if (
      step === 4 &&
      selectedToken.length > 0 &&
      tokenForPayment &&
      to &&
      value &&
      !txByDeposited
    ) {
      sendCryptoTransaction();
    }
    console.log(
      "file: index.tsx:301  useEffect  txByDeposited && selectedDepositTokenAddress && to && value:",
      txByDeposited && selectedDepositTokenAddress && to && value,
      txByDeposited,
      selectedDepositTokenAddress,
      to,
      value
    );
    if (
      step === 4 &&
      txByDeposited &&
      selectedDepositTokenAddress &&
      to &&
      value
    ) {
      newDepositSendTransaction();
    }
  }, [selectedToken, tokenForPayment, selectedDepositTokenAddress, step]);

  async function sendCryptoTransaction() {
    try {
      setLoading(true);
      setError(false);
      setFinalOpState(null);
      setGasFee({
        isLoading: true,
        usdcFee: "",
        transferGasFee: "",
        alternativeMessage: "Calculating...",
      });

      if (
        !tokenForPayment.trim() ||
        !to.trim() ||
        !activeNetwork ||
        !tokenForPaymentDecimal
      ) {
        window.alert("Please provide all details");
        setLoading(false);
        setGasFee({
          isLoading: false,
          usdcFee: "0",
          transferGasFee: "",
          alternativeMessage: "",
        });
        return;
      }

      const ISTRANSFER_TOKEN_NATIVE =
        selectedToken[0]?.tokenAddress ==
          "0x0000000000000000000000000000000000000000" ||
        selectedToken[0]?.tokenAddress ==
          "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270";
      const rpcEndpoint =
        SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS]
          .alchemy_url;

      const pkey = decryptMessage(activeAccount.secret, hashedPassword);

      const provider = new ethers.providers.JsonRpcProvider(rpcEndpoint);
      const wallet = new ethers.Wallet(pkey, provider);

      let userCallDataArray: ExecuteCall[] = [];
      const tokenAddress = selectedToken[0].tokenAddress;
      let tokenAmountToTransfer = ISNATIVE ? "1000000000" : "1000";

      let transferData: TransferData = {
        tokenAddress: tokenForPayment,
        tokenAmount: tokenAmountToTransfer,
      };

      if (ISTRANSFER_TOKEN_NATIVE) {
        const call0 = "0x";
        userCallDataArray.push({
          to: to,
          value: ethers.utils
            .parseUnits(String(value), selectedToken[0].tokenDecimal)
            .toString(),
          calldata: call0,
        });
      } else {
        const call0 = Token__factory.createInterface().encodeFunctionData(
          "transfer",
          [
            to,
            ethers.utils
              .parseUnits(String(value), selectedToken[0].tokenDecimal)
              .toString(),
          ]
        );
        console.log(
          "file: index.tsx:366  newSendTransaction  ethers:",
          ethers.utils
            .parseUnits(String(value), selectedToken[0].tokenDecimal)
            .toString()
        );
        userCallDataArray.push({
          to: tokenAddress,
          value: "0",
          calldata: call0,
        });
        console.log("file: index.tsx:460  newSendTransaction  call0:", call0);
      }
      if (ISNATIVE) {
        transferData = {
          tokenAddress: "0x",
          tokenAmount: tokenAmountToTransfer,
        };
        //for ether or matic
      } else {
        transferData = {
          tokenAddress: tokenForPayment,
          tokenAmount: tokenAmountToTransfer,
        };
      }
      console.log(
        "🚀 ~ file: index.tsx:367 ~ sendCryptoTransaction ~ userCallDataArray:",
        userCallDataArray
      );

      const isHighFees = feeGasUrgency == "Lightning";

      const { blockaid } =
        SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS];

      const { data } = await axios.post(`${BASE_URL}/api/blockaid`, {
        options: ["validation"],

        data: {
          from: activeAccount.smartAccountAddress,
          data: userCallDataArray[0].calldata,
          value: userCallDataArray[0].value,
          to: userCallDataArray[0].to,
        },
        metadata: { domain: "https://app.1inch.io" },
        network: blockaid,
      });

      console.log("DATAAAAAAAAAAa", data);

      if (data.validation.result_type !== "Benign") {
        setRisk(true);
      }

      const [counterfactual, { finalOp, usdcFee, transferGasFee }] =
        await Promise.all([
          getCounterFactualAddress(EMPTY_CALLDATA, wallet.address, 0, provider),
          txSubmissionOrderPrepaid({
            transferData,
            userCallDataArray,
            counterfactual: activeAccount.smartAccountAddress,
            wallet,
            transferTokenDecimal: tokenForPaymentDecimal,
            isHighFees,
            isAccountDeployed,
          }),
        ]);
      console.log(
        "file: index.tsx:483  newSendTransaction  counterfactual:",
        counterfactual,
        userCallDataArray
      );

      console.log("Clicked!");

      console.log(
        "file: index.tsx:1058  sendCryptoTransactionfinal  finalOp:",
        finalOp
      );
      const IsSameToken =
        tokenForPayment?.toLowerCase() ==
        selectedToken[0]?.tokenAddress?.toLowerCase();

      if (IsSameToken) {
        const HAS_ENOUGH_BALANCE =
          selectedToken[0]?.tokenBalance -
            Number(value) -
            Number(transferGasFee) >
          0;
        if (!HAS_ENOUGH_BALANCE) {
          showAlert(
            "You don't have enough token balance to pay fee, Please select different payment token.",
            "Not enough balance"
          );
          setError(true);
        }
      } else {
        if (+usdcFee >= tokenForPaymentBalance) {
          showAlert(
            "You don't have enough token balance to pay fee, Please select different payment token.",
            "Not enough balance"
          );
          setError(true);
        }
      }
      setFinalOpState(finalOp);
      setGasFeeInUSD(usdcFee);
      console.log(
        "file: index.tsx:1128  sendCryptoTransactionusdcFee:",
        usdcFee
      );
      setGasFee({ usdcFee, transferGasFee, isLoading: false });

      setLoading(false);
    } catch (e: any) {
      console.log("file: index.tsx:674  newSendTransaction  e:", e);

      showAlert(e?.message ? e?.message : "Error in Submitting Transaction");
      setLoading(false);
      setGasFee({
        isLoading: false,
        usdcFee: "",
        transferGasFee: "",
        alternativeMessage: "",
      });
      SetTokenForPayment("");
    }
  }

  const handleSend = async () => {
    // if (
    //   userSpendingDetails.isDailyLimitExceed &&
    //   userSpendingDetails.isPatternSet &&
    //   userSpendingDetails.isSpendingLimitSaved
    // ) {
    //   setOpenModal(true);
    // } else if (
    //   !userSpendingDetails.isFirstTx &&
    //   (!userSpendingDetails.isPatternSet ||
    //     !userSpendingDetails.isSpendingLimitSaved)
    // ) {
    //   navigate("/transaction-success");
    // } else {
    if (
      userSpendingDetails.isDailyLimitExceed &&
      userSpendingDetails.isPatternSet
    ) {
      setOpenModal(true);
    } else executeSendCrypto();
  };

  const executeSendCrypto = async () => {
    setLoading(true);
    if (finalOpState) {
      const response = await sendUserOp(
        finalOpState,
        "https://api.stackup.sh/v1/node/221b5cfa6d4f5cff2e221d693b2e953d49d9797d0f18f2e6d119482223a92a37",
        "https://polygon-mainnet.g.alchemy.com/v2/HBxGEElD4fSo3gWukvZFV9YTKO4OvCnw"
      );
      const userOPResponse: any = await response.wait();
      console.log("userOp Hash :", response.userOpHash);
      console.log("Tx Hash :", userOPResponse?.transactionHash);
      console.log("success status :", userOPResponse?.args.success);
      console.log(
        "actualGasCost  :",
        Number(userOPResponse?.args.actualGasCost)
      );

      console.log(
        "actualGasCost  :",
        Number(userOPResponse?.args.actualGasCost)
      );
      console.log(
        "actualGasUsed  :",
        Number(userOPResponse?.args.actualGasUsed)
      );
      dispatch(
        setPendingTxDetails({
          value: value,
          valueIn$: String(
            Number(value) * Number(selectedToken[0].tokenPrice ?? 0)
          ),
          transferAmount: value,
          transactionMethod: "Crypto",
          scanLink: block_explorer_url,
          eoaEns: rootAccountInfo.name,
          addressEns: activeAccount.accountName,
          toAddressEns: to,
          toAddress: to,
          assetName: selectedToken[0].tokenSymbol,
          networkFeesIn$: gasFeeInUSD,
          iconURL: selectedToken[0].image,
          txByDesposited: txByDeposited,
          action: "Sent",
        })
      );
      dispatch(setPendingTx(response.userOpHash));

      showAlert(
        "Soon you can see your transaction in the transactions tab",
        "Transaction Submitted",
        `<a href="https://polygonscan.com/tx/${userOPResponse?.transactionHash}" target="_blank">View on Polygonscan</a>`
      );
    }
    setFinalOpState(null);
    setLoading(false);
    if (!userSpendingDetails.isFirstTx && !userSpendingDetails.isFirstTxInApp) {
      navigate("/transaction-success");
    } else navigate("/crypto");
  };

  async function newDepositSendTransaction() {
    console.log(
      "file: index.tsx:544  newDepositSendTransaction  newDepositSendTransaction:"
    );
    try {
      setLoading(true);
      setError(false);

      if (!selectedDepositTokenAddress.trim() || !to.trim() || !activeNetwork) {
        window.alert("Please provide all details");
        setGasFee({
          isLoading: false,
          usdcFee: "0",
          transferGasFee: "",
          alternativeMessage: "",
        });
        setLoading(false);

        return;
      }

      const allAccountsAddress = Object.keys(accounts);
      if (allAccountsAddress.length == 0) {
        return;
      }
      const firstAccountAddress =
        accounts[allAccountsAddress[0]].smartAccountAddress;

      // await switchAccount(switchToAccount);
      const pkey = decryptMessage(activeAccount.secret, hashedPassword);
      const rpcEndpoint =
        SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS]
          .alchemy_url;

      const provider = new ethers.providers.JsonRpcProvider(rpcEndpoint);
      const wallet = new ethers.Wallet(pkey, provider);

      // First Wallet Account Address
      const firstAccount = accounts[allAccountsAddress[0]];
      const pkeyForFirstAccount = decryptMessage(
        firstAccount.secret,
        hashedPassword
      );
      const firstAccountWallet = new ethers.Wallet(
        pkeyForFirstAccount,
        provider
      );
      // const firstWallet = new ethers.Wallet()
      // 1. Get the user calldata array from the frontend.
      let userCallDataArray: ExecuteCall[] = [];
      const txType = "postpaid";
      // Assuming the user wants to call transfer on some token using postpaid transaction.
      const tokenAddress = selectedToken[0].tokenAddress;
      const enteredAmountInWei: BigNumber = ethers.utils.parseUnits(
        String(value),
        "ether"
      );

      const ISTRANSFER_TOKEN_NATIVE =
        selectedToken[0]?.tokenAddress ==
          "0x0000000000000000000000000000000000000000" ||
        selectedToken[0]?.tokenAddress ==
          "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270";

      if (ISTRANSFER_TOKEN_NATIVE) {
        const call0 = "0x";
        userCallDataArray.push({
          to: to,
          value: ethers.utils
            .parseUnits(String(value), selectedToken[0].tokenDecimal)
            .toString(),
          calldata: call0,
        });
      } else {
        const call0 = Token__factory.createInterface().encodeFunctionData(
          "transfer",
          [
            to,
            ethers.utils
              .parseUnits(String(value), selectedToken[0].tokenDecimal)
              .toString(),
          ]
        );
        console.log(
          "file: index.tsx:366  newSendTransaction  ethers:",
          ethers.utils
            .parseUnits(String(value), selectedToken[0].tokenDecimal)
            .toString()
        );
        userCallDataArray.push({
          to: tokenAddress,
          value: "0",
          calldata: call0,
        });
        console.log("file: index.tsx:460  newSendTransaction  call0:", call0);
      }

      const isHighFees = feeGasUrgency == "Lightning";

      const { finalOp, transferGasFee, usdcFee } =
        await txSubmissionOrderPostpaid({
          sponsorWallet: firstAccountWallet,
          currentWallet: wallet,
          sponsorAccountAddress: firstAccountAddress,
          counterfactual: activeAccount.smartAccountAddress,
          userCallDataArray: userCallDataArray,
          selectedDepositTokenAddress: selectedDepositTokenAddress,
          transferTokenDecimal: tokenForPaymentDecimal ?? 18,
          isHighFees,
          isAccountDeployed,
        });
      // const response = await sendUserOp(finalOp, bundlerRPC, rpcEndpoint);
      console.log("Clicked!");
      const depositedTokenDetails = depositableTokens.filter(
        (token) =>
          token.tokenAddress.toLowerCase() ==
          selectedDepositTokenAddress.toLowerCase()
      );
      setGasFeeInUSD(usdcFee);
      setGasFee({ usdcFee, transferGasFee, isLoading: false });
      if (+transferGasFee >= depositedTokenDetails[0].balance) {
        showAlert(
          "You don't have enough token balance to pay fee, Please select different payment token.",
          "Not enough balance"
        );
        setError(true);
      }
      console.log(
        "file: index.tsx:1058  sendCryptoTransactionfinal  finalOp:",
        finalOp
      );

      setFinalOpState(finalOp);

      setLoading(false);

      // navigate("/");
      // console.log("response :", response);
    } catch (e: any) {
      console.log("file: index.tsx:674  newSendTransaction  e:", e);

      showAlert("Not Enough Deposits to Continue");
      setError(true);
      setGasFee({
        isLoading: false,
        usdcFee: "",
        transferGasFee: "",
        alternativeMessage: "",
      });
      setLoading(false);
    }
  }

  const selectTokenHandler = (tk: Token) => {
    dispatch(setSelectedToken([tk]));
    setOpenModal(false);
  };

  const isValid = Number(value) && to && Number(value) <= Number(tokenBalance);
  const isDepositValid = Number(value) && Number(value) <= Number(tokenBalance);

  const handleDepositTokenChange = (
    event: SelectChangeEvent<string>,
    child: ReactNode
  ) => {
    console.log(
      "file: index.tsx:1812  Send  depositableTokens:",
      depositableTokens
    );
    const decimal = depositableTokens.filter(
      (token) =>
        token.tokenAddress.toLowerCase() == event.target.value.toLowerCase()
    );
    console.log("file: index.tsx:40  handleChangeasdasd  decimal:", decimal);
    setTokenForPaymentDecimal(decimal[0].decimal);
    setSelectedDepositTokenAddress(event.target.value as string);
  };
  const handleSelectTokenForPayment = (address: string) => {
    SetTokenForPayment(address);
  };
  const handleSelectTokenForPaymentWithDecimal = ({
    address,
    decimal,
    tokenBalance,
  }: {
    address: string;
    decimal: number;
    tokenBalance?: number;
  }) => {
    setError(false);
    SetTokenForPayment(address);
    setTokenForPaymentDecimal(decimal);
    setTokenForPaymentBalance(tokenBalance || 0);
  };
  useEffect(() => {
    const data = extractTokenData(activeNetwork, assets);
    // SetTokenForPayment(data.address);
    // setTokenForPaymentDecimal(data.decimal);
    // const dsdsdsssssssssssss = [...data.sameTokens, ...data.sameTokens];

    setSameTokens(data.sameTokens);
  }, [activeNetwork, assets]);

  const onTop20Select = () => {
    if (loading) {
      return;
    }
    setTxByDeposited(false);
    setFinalOpState(null);
    SetTokenForPayment("");
    setSelectedDepositTokenAddress("");
  };

  const onDepositSelect = () => {
    if (loading) {
      return;
    }
    setTxByDeposited(true);
    setFinalOpState(null);
    SetTokenForPayment("");
    setSelectedDepositTokenAddress("");
  };

  const handleAmountChange = (value) => {
    const inputValue = value;
    console.log(inputValue);

    if (/[^0-9.]/.test(inputValue)) {
      setError("Special characters are not allowed");
    } else if ((inputValue.match(/\./g) || []).length > 1) {
      setError("Only one decimal point is allowed");
    } else if (!/^\d{0,10}(\.\d{0,15})?$/.test(inputValue)) {
      setError(
        "Maximum of 10 digits before decimals and 15 digits after decimals are allowed"
      );
    } else {
      setError("");

      setValue(inputValue);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "ArrowUp") {
      event.preventDefault();

      // Parse the current value to a float
      const numericValue = parseFloat(value || "0");

      // Increment the value by 1
      const newValue = (numericValue + 1).toFixed(2);
      setValue(newValue);
      setError("");
    }

    if (event.key === "ArrowDown") {
      event.preventDefault();

      // Parse the current value to a float
      const numericValue = parseFloat(value || "0");

      // Ensure the value doesn't go below 0.01
      const newValue = (numericValue - 1).toFixed(2);
      if (Number(newValue) > 0) {
        setValue(newValue);
      }
      setError("");
    }
  };

  // test

  return (
    <>
      <BasicModal open={openModal} onClose={() => setOpenModal(false)}>
        <>
          <ModalHeader
            title="Select asset"
            onClose={() => setOpenModal(false)}
            showBackIcon
          />
          <AssetsView tokens={tokens} selectTokenHandler={selectTokenHandler} />
        </>
      </BasicModal>
      <Box mt={6}>
        <NavigatorHeading
          title="Send Crypto"
          RightComponent={
            <CloseButton
              handleOnClick={() => {
                navigate("/crypto");
              }}
            />
          }
        />
      </Box>
      <Box mt={2}>
        <CustomizedSteppers
          step={step}
          steps={["Network", "Recipient", "Asset", "Amount", "Send"]}
          changeStep={(selectedStep: number) => {
            //eg. if user is on step 3 he should be able to move at step 1 or 2 on clicking step icon
            if (selectedStep < step) {
              setStep(selectedStep);
            }
          }}
        />
      </Box>
      <Box mt={2}>
        {step == 0 && (
          <Grid container display="flex" justifyContent="center">
            <Grid
              item
              lg={6}
              sm={12}
              style={{
                flexBasis: "100%",
                maxWidth: "68%",
              }}
            >
              <NetworksList
                nextStep={() => setStep(1)}
                title="Select which network you want to send crypto on"
              />
            </Grid>
          </Grid>
        )}
        {/* required contact smart wallet address from step 1*/}
        {step == 1 && (
          <Box display={"flex"} flexDirection={"column"}>
            <Grid container display="flex" justifyContent="center">
              <Grid
                item
                lg={12}
                sm={12}
                style={{
                  flexBasis: "100%",
                  maxWidth: "68%",
                }}
              >
                <TransactionContactList
                  shouldAddContact={false}
                  isTxForm
                  isChooseRecipient={true}
                  nextStep={(address: string, tag: string) => {
                    setStep(2);
                    setTo(address);
                    setTag(tag);
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        )}
        {/* required token object in redux name selectedToken from step 2 */}
        {step == 2 && (
          <Grid container display="flex" justifyContent="center">
            <Grid
              item
              lg={12}
              sm={12}
              style={{
                flexBasis: "100%",
                maxWidth: "68%",
              }}
            >
              <TokensListTable
                transactionForm={true}
                nextStep={() => setStep(3)}
                chainId={activeNetwork}
                isApplyFilter={false}
                isShowTokenAmountUnderName
              />
            </Grid>
          </Grid>
        )}
        {/* required token value to transfer from step 3 */}
        {step == 3 && (
          <Grid container display="flex" justifyContent="center">
            <Grid
              item
              lg={6}
              sm={12}
              display="flex"
              justifyContent="center"
              style={{
                flexBasis: "100%",
                maxWidth: "65%",
              }}
            >
              <TokenInputForm
                placeHolder={selectedToken[0]?.tokenBalance}
                title="Crypto Tag"
                addBorder
                balance={selectedToken[0]?.tokenBalance}
                type="number"
                onChange={handleAmountChange}
                onKeydown={handleKeyDown}
                value={String(value)}
                receiverENS={tag ? tag : to}
                isDepositValid={!!isValid || !!isDepositValid}
                nextStep={() => setStep(4)}
                tokenName={tokenSymbol}
                tokenIcon={tokenIcon}
                errorMessage={error}
              />
            </Grid>
          </Grid>
        )}
        {/* review tx and get gas fees option from step 4 */}
        {step == 4 && (
          <Grid container display="flex" justifyContent="center" py={0}>
            <Grid
              item
              lg={6}
              sm={12}
              style={{
                flexBasis: "100%",
                maxWidth: "87%",
              }}
            >
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={1.5}
                alignItems={"center"}
              >
                <Box width={"100%"}>
                  <SendAsset
                    nextStep={() => console.log("end")}
                    value={value}
                    constantValue={`${value} ${selectedToken[0]?.tokenSymbol}`}
                  />
                </Box>

                {risk && <Simulation />}

                <FeeUrgencyComponent
                  feeGasUrgency={feeGasUrgency}
                  setFeeGasUrgency={setFeeGasUrgency}
                />
                <TxTypeSwitch
                  loading={loading}
                  txByDeposited={txByDeposited}
                  onTop20Select={onTop20Select}
                  onDepositSelect={onDepositSelect}
                />
                <SendTxComponent
                  loading={loading}
                  // SetTokenForPayment={SetTokenForPayment}
                  gasTokenSelect={
                    <GasTokenSelect
                      selectedDepositTokenAddress={selectedDepositTokenAddress}
                      handleDepositTokenChange={handleDepositTokenChange}
                    />
                  }
                  top20TokenSelect={
                    <TopTokenSelectComponent
                      handleSelectToken={handleSelectTokenForPayment}
                      handleSelectTokenWithDecimal={
                        handleSelectTokenForPaymentWithDecimal
                      }
                      paymentTokenAddress={tokenForPayment}
                      sameTokens={sameTokens}
                    />
                  }
                  txByDeposited={txByDeposited}
                  handleSend={handleSend}
                  finalOpState={finalOpState}
                  isSwap={false}
                  error={error}
                >
                  <FeeUIComponent gasFeeInUSD={gasFeeInUSD} />
                </SendTxComponent>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        message="Funds are not enough to use as gas fees"
      />
      <ConfirmPatternModal
        open={openPatternModal}
        onClose={() => {
          setOpenPatternModal(false);
        }}
        // error={error}
        // setError={setError}
        setIsPatternCorrect={setIsPatternCorrect}
      />
    </>
  );
};

export default Send;
