import useApp from "hooks/useApp";
import AuthFlowRoutes from "routes/auth";
import AppFlowRoutes from "./app";
import useOpHashConfirmation from "hooks/useOpHashConfirmation";

const Routes = () => {
  const { isLoggedIn } = useApp();
  useOpHashConfirmation();
  return isLoggedIn ? <AppFlowRoutes /> : <AuthFlowRoutes />;
};

export default Routes;
