import { SupportedChainId, SUPPORTED_NETWORKS } from "constants/chains";

export const showNetworksList = (
  mainnetNetworks: SupportedChainId[],
  testnetNetworks: SupportedChainId[]
) => {
  const mainNetworks = mainnetNetworks.map(
    id => SUPPORTED_NETWORKS[id as keyof typeof SUPPORTED_NETWORKS]
  );

  const testnetworks = testnetNetworks.map(
    id => SUPPORTED_NETWORKS[id as keyof typeof SUPPORTED_NETWORKS]
  );

  return { mainNetworks, testnetworks };
};
