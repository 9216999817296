import { IconButton } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

const CloseButton = ({ handleOnClick }: { handleOnClick: () => void }) => {
  return (
    <IconButton
      onClick={handleOnClick}
      aria-label="settings"
      disableRipple={true}
      size="small"
      sx={{
        backgroundColor: "rgba(235, 236, 238, 1)",
        "&:hover": {
          backgroundColor: "lightgray",
        },
      }}
    >
      <CloseIcon />
    </IconButton>
  );
};

export default CloseButton;
