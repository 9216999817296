import { useMemo } from "react";
import { useSnapshot } from "valtio";

// import SettingsStore from '@/store/SettingsStore'
// import { getVerifyStatus } from '@/utils/HelperUtil'

// import { Avatar, Col, Link, Row, Text } from '@nextui-org/react'
import { SignClientTypes } from "@walletconnect/types";
import { getVerifyStatus } from "../utils/HelperUtil";
import SettingsStore from "../walletConnectStore/SettingsStore";

/**
 * Types
 */
interface IProps {
  metadata: SignClientTypes.Metadata;
}

/**
 * Components
 */
export default function ProjectInfoCard({ metadata }: IProps) {
  const { currentRequestVerifyContext } = useSnapshot(SettingsStore.state);
  const { icons, name, url } = metadata;
  const validation = useMemo(
    () => getVerifyStatus(currentRequestVerifyContext),
    [currentRequestVerifyContext]
  );
  return (
    <>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {/* <Avatar src={icons[0]} /> */}
          <img src={icons[0]} />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h5 data-testid="session-info-card-text">{name}</h5>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <a href={url} data-testid="session-info-card-url">
              {url}
            </a>
            <p
              style={{ marginLeft: "5px" }}
              data-testid="session-info-card-verify"
            >
              {validation}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
