import React, { useEffect, useState } from "react";
import pbkdf2 from "pbkdf2";

import WelcomeBox from "components/WelcomeBox";

import Google from "assets/google.svg";
import Discord from "assets/discord.svg";
import UpdateSignInImg from "assets/update-signin.png";

import { CircularProgress, Grid, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

import AuthHeader from "components/AuthHeader";
import { useAppDispatch } from "store/store";
import useGoogleWeb3Auth from "hooks/useGoogleWeb3Auth";
import { init, loginWithGoogle } from "utils/google";
import { fetchEncryptedKeys, isUserExists, showAlert } from "utils/utils";
import {
  removeAccounts,
  setAccesToken,
  setActiveAccount,
  setFetchedKeys,
  setIsAccountCreated,
  setMode,
  setOwnReferralCode,
  setReferredTo,
  setUserInfo,
} from "@slices/appSlice";
import { setPopupLoading, setTorusKey } from "@slices/walletSlice";
import { Box } from "@mui/system";
import CustomizedSteppers from "components/Stepper";
import { stepsCount } from "../../../../../../src/constants";
import StepperForPages from "components/Stepper/StepperForPages";
import { Web3AuthNoModal } from "@web3auth/no-modal";
import RPC from "constants/webRPC";

const RecoverChooseMethod = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [path, setPath] = useState("/forgot-password/recover-choose-method");
  const [web3auth, setWeb3auth] = useState<Web3AuthNoModal | null>(null);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [step, setStep] = useState(0);

  useEffect(() => {
    (async () => {
      const web3auth = await init(path);
      if (web3auth) {
        setWeb3auth(web3auth);
        if (web3auth.connected) {
          setLoading(true);
          const res = await web3auth.getUserInfo();
          const rpc = new RPC(web3auth.provider!);
          console.log("res0000000000000000", res);
          const address = await rpc.getAccounts();
          console.log(address);

          const { name, email, profileImage, idToken } = res;

          dispatch(setIsAccountCreated(false));
          dispatch(removeAccounts({}));
          dispatch(
            setActiveAccount({
              address: "",
              secret: "",
              smartAccountAddress: "",
            })
          );

          if (email) {
            const data = await fetchEncryptedKeys(idToken);
            dispatch(setAccesToken(idToken));
            if (!data) {
              showAlert("Email does not exist");

              setLoading(false);
              dispatch(setPopupLoading(false));
              await web3auth.logout();
            } else {
              dispatch(setReferredTo(data.referedTo));
              dispatch(setOwnReferralCode(data.referenceCode));

              dispatch(
                setUserInfo({
                  email: data.email,
                  name: data.username,
                })
              );

              const hashedTorus = pbkdf2
                .pbkdf2Sync(address[0].toString("hex"), "salt", 1, 32, "sha512")
                .toString("hex");
              dispatch(setTorusKey(hashedTorus));

              dispatch(
                setFetchedKeys({
                  rootAddress: data.address,
                  key1: data.keys[0]?.key1,
                  key2: data.keys[0]?.key2,
                  key3: data.keys[0]?.key3,
                  key4: data.keys[0]?.key4,
                })
              );
              dispatch(setPopupLoading(false));

              setPath("/forgot-password/recover-pin");
              navigate("/forgot-password/recover-pin", {
                state: {
                  currentStep: step + 1,
                  pathName: "/forgot-password/recover-choose-method",
                },
              });
            }
          }
        }
      }
    })();
  }, [path]);

  const signinWithGoogle = async () => {
    try {
      if (web3auth) {
        setLoading(true);

        await loginWithGoogle(web3auth);
        setLoading(false);
      }
    } catch (error) {
      dispatch(setPopupLoading(false));
      setLoading(false);
      // setError("Recovery Failed");
    }
  };

  const signupWithDiscord = () => {
    showAlert("Coming Soon");
  };

  const totalSteps = stepsCount["/forgot-password/recover-choose-method"] || 3;

  return (
    <div className="create-account-container">
      <Grid container spacing={2}>
        <Grid item xs={8} className="auth-header-with-steps">
          <AuthHeader />
        </Grid>
        <Grid
          item
          xs={16}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box
            className="auth-header-steps"
            width={"50%"}
            style={{
              position: "absolute",
              top: "54px",
            }}
          >
            <StepperForPages
              step={step}
              steps={new Array(totalSteps).fill("")}
              changeStep={(selectedStep: number) => {
                //eg. if user is on step 3 he should be able to move at step 1 or 2 on clicking step icon
                if (selectedStep < step) {
                  setStep(selectedStep);
                }
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <div className="create-account-box">
        <Typography
          variant="h2"
          sx={{
            margin: "0px 0px 30px 0px",

            fontWeight: "700",
          }}
        >
          Choose method
        </Typography>

        <div className="welcome-box-container">
          {loading ? (
            <div
              style={{ width: "100%", textAlign: "center", margin: "20px 0px" }}
            >
              <CircularProgress color="inherit" />
            </div>
          ) : (
            <>
              <WelcomeBox
                title="Continue with Google"
                description=""
                onClick={signinWithGoogle}
                icon={<img src={Google} style={{ marginRight: 10 }} />}
              />
              {/* <WelcomeBox
                title="Continue with Discord"
                description=""
                onClick={signupWithDiscord}
                icon={<img src={Discord} style={{ marginRight: 10 }} />}
              /> */}
            </>
          )}
          {error && (
            <Typography style={{ color: "red", marginTop: "5%" }}>
              {error}
            </Typography>
          )}
        </div>
        {/* <div className="stashed-update-signin">
          <img src={UpdateSignInImg} />
        </div> */}
      </div>
    </div>
  );
};

export default RecoverChooseMethod;
