import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Typography } from '@mui/material'

import './styles.css'
import { useTheme } from '@mui/material'
import WelcomeBox from 'components/WelcomeBox'
import AuthHeader from 'components/AuthHeader'
import gradient from 'assets/leftShade.svg'

const Welcome = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  console.log('testing jenkins')
  return (
    <Box
      sx={{
        backgroundImage: `url(${gradient})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'auto',
        position: 'static',
        width: '100%',
        height: 'auto',
        top: 0,
        backgroundPositionY: 'bottom',
        // paddingTop: '10%',
      }}
    >
      <div className='welcome-container'>
        <AuthHeader />
        {/* <div className='welcome-gradient'>
        <img src={gradient} />
      </div> */}

        <Typography
          variant='body1'
          // className='lightGray-text'
          sx={{
            margin: '30px 0px 30px 0px',
            color: theme.palette.text.lightGray,
            fontWeight: '400',
            zIndex: 1,
          }}
        >
          The Super App for your digital finances
        </Typography>

        <div className='welcome-box-container'>
          <WelcomeBox
            title='Sign In'
            description='Sign in to your Stashed account'
            onClick={() => navigate('/signin')}
          />
          <WelcomeBox
            title='Create Account'
            description='Start your Stash today'
            onClick={() => navigate('/create-account')}
          />
          <WelcomeBox
            title='Forgot Your Details'
            description='Recover your account'
            onClick={() => navigate('/recover')}
          />
        </div>
      </div>
    </Box>
  )
}

export default Welcome
