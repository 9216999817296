import axios, { AxiosResponse } from "axios";
import { GRAPH_PERIODS, MOBULA_API } from "constants/";
import { SupportedChainId, SUPPORTED_NETWORKS } from "constants/chains";
import { Interval } from "enums";
import {
  GraphQueryData,
  Holdings,
  FetchChainBalancesChartDataParams,
} from "interfaces";

const HOUR = 3600000; // 1 hour in milliseconds
const DAY = 24 * HOUR;
const WEEK = 7 * DAY;
const MONTH = 30 * DAY;

const intervals = [HOUR, DAY, WEEK, MONTH];

export const fetchWalletPortfolio = async (address: string) => {
  try {
    const tokenWorthInUSD =
      address.toLowerCase() ===
      "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE".toLowerCase()
        ? "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270"
        : address;

    const baseURL = `${MOBULA_API}/wallet/portfolio`;
    const fetchURL = `${baseURL}?wallet=${tokenWorthInUSD}& blockchains=Ethereum%2CPolygon`;
    const { data } = await axios.get(fetchURL, {
      headers: {
        Authorization: process.env.REACT_APP_MOBULA_KEY,
      },
    });
    const res = data.data;

    console.log("wallet history--------------------------:", res, address);

    return res;
  } catch (error) {
    console.log("err in fetching wallet portfolio from mobula", error);
    return [];
  }
};

export const getEthDollarValue = async ({
  asset = "Ethereum",
}: {
  asset?: string;
}) => {
  try {
    const baseURL = `${MOBULA_API}/metadata?asset=${asset}`;
    const { data } = await axios.get(baseURL, {
      headers: {
        Authorization: process.env.REACT_APP_MOBULA_KEY,
      },
    });
    const res = data.data;

    console.log("wallet history--------------------------:", res);

    return res.price;
  } catch (error) {
    console.log("err in fetching wallet portfolio from mobula", error);
    return 0;
  }
};

export const getTokenDollarValue = async (tokenContractAddress: string) => {
  try {
    const tokenWorthInUSD =
      tokenContractAddress.toLowerCase() ===
      "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE".toLowerCase()
        ? "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270"
        : tokenContractAddress;
    const baseURL = `${MOBULA_API}/metadata?asset=${tokenWorthInUSD}`;
    const { data } = await axios.get(baseURL, {
      headers: {
        Authorization: process.env.REACT_APP_MOBULA_KEY,
      },
    });
    const res = data.data;

    console.log("wallet history--------------------------:", res);

    return res.price;
  } catch (error) {
    console.log("err in fetching wallet portfolio from mobula", error);
    return 0;
  }
};

const getNextBoundary = (timestamp: number, interval: Interval) => {
  return timestamp + (interval - (timestamp % interval));
};

export const filterDataByInterval = (
  data: Array<number[]>,
  interval: Interval
) => {
  const result = [];
  let nextBoundary = 0;

  for (let i = 0; i < data.length; i++) {
    const [timestamp, value] = data[i];

    if (timestamp >= nextBoundary) {
      result.push([timestamp, value]);
      nextBoundary = getNextBoundary(timestamp, intervals[interval]);
    }
  }

  return result;
};

export const fetchTokenTransactions = async (
  wallet: string,
  from: number,
  to: number,
  asset: string
) => {
  try {
    const baseURL = await `${MOBULA_API}/wallet/transactions`;
    const fetchURL = `${baseURL}?wallet=${wallet}&from=${from}&to=${to}&asset=${asset}&blockchains=Ethereum%2CPolygon`;
    const { data } = await axios.get(fetchURL, {
      headers: {
        Authorization: process.env.REACT_APP_MOBULA_KEY,
      },
    });
    const res = data?.data?.transactions;

    return res;
  } catch (error) {
    console.log("err in fetching wallet history from mobula", error);
    return [];
  }
};

export const fetchTokenChartData = async (
  wallet: string,
  from: number,
  to: number,
  asset: string
) => {
  try {
    const baseURL = await `${MOBULA_API}/wallet/history`;
    const fetchURL = `${baseURL}?wallet=${wallet}&from=${from * 1000}&to=${
      to * 1000
    }&asset=${asset}&blockchains=Ethereum%2CPolygon`;
    const { data } = await axios.get(fetchURL, {
      headers: {
        Authorization: process.env.REACT_APP_MOBULA_KEY,
      },
    });
    const res = data?.data?.balance_history;

    return res;
  } catch (error) {
    console.log("err in fetching wallet history from mobula", error);
    return [];
  }
};

export const fetchChainBalancesChartData = async ({
  wallet = "0xa918F45E81358473003fF7f33172E126EcDA1b8b",
  blockchains = "137",
  cache = false,
  stale = 5,
}: FetchChainBalancesChartDataParams = {}): Promise<any> => {
  try {
    const baseURL = `${MOBULA_API}/wallet/portfolio`;
    const fetchURL = `${baseURL}?wallet=${wallet}&blockchains=${blockchains}&cache=${cache}&stale=${stale}`;
    const { data } = await axios.get(fetchURL, {
      headers: {
        Authorization: process.env.REACT_APP_MOBULA_KEY,
      },
    });
    const res = data?.data;
    return res;
  } catch (error) {
    console.log("err in fetching chain balances", error);
    return [];
  }
};

export const fetchCashAccountBalance = async (
  wallet: string,
  asset: string
) => {
  try {
    const baseURL = await `${MOBULA_API}/wallet/portfolio`;
    const fetchURL = `${baseURL}?wallet=${wallet}&asset=${asset}&blockchains=Base`;
    const { data } = await axios.get(fetchURL, {
      headers: {
        Authorization: process.env.REACT_APP_MOBULA_KEY,
      },
    });
    const res = data?.data;

    return res;
  } catch (error) {
    console.log("err in fetching wallet history from mobula", error);
    return [];
  }
};

export const fetchCashAccountChartData = async (
  from: number,
  to: number,
  wallet: string,
  asset: string
) => {
  try {
    const baseURL = await `${MOBULA_API}/wallet/history`;
    const fetchURL = `${baseURL}?wallet=${wallet}&from=${from * 1000}&to=${
      to * 1000
    }&asset=${asset}&blockchains=Base`;
    const { data } = await axios.get(fetchURL, {
      headers: {
        Authorization: process.env.REACT_APP_MOBULA_KEY,
      },
    });
    console.log("data===============", data);
    const res = data?.data?.balance_history;

    return res;
  } catch (error) {
    console.log("err in fetching wallet history from mobula", error);
    return [];
  }
};

export const fetchCashAccountHistory = async (wallet: string) => {
  try {
    const baseURL = `${MOBULA_API}/wallet/transactions`;
    const fetchURL = `${baseURL}?wallet=${wallet}&blockchains=Base&asset=USD%20Coin&order=desc`;
    const { data } = await axios.get(fetchURL, {
      headers: {
        Authorization: process.env.REACT_APP_MOBULA_KEY,
      },
    });
    console.log("data===============", data);
    const res = data?.data?.transactions;

    return res;
  } catch (error) {
    console.log("err in fetching wallet history from mobula", error);
    return [];
  }
};

export const getAssetsDollarWorth = async (assets: string[]) => {
  try {
    const baseURL = `${MOBULA_API}/market/multi-data?assets=${assets}`;
    const { data } = await axios.get(baseURL, {
      headers: {
        Authorization: process.env.REACT_APP_MOBULA_KEY,
      },
    });
    const res = data.data;

    console.log("wallet history--------------------------:", res);

    return res;
  } catch (error) {
    console.log("err in fetching wallet portfolio from mobula", error);
    return 0;
  }
};

export const fetchCryptoAccHistory = async (
  address: string,
  selectedTime: number
) => {
  const timeInSeconds = [
    //1H, 1D, 1W, 1M,
    3600, 86400, 604800, 2629743,
  ];
  const from = Math.floor(Date.now() / 1000) - timeInSeconds[selectedTime];
  try {
    const baseURL = `${MOBULA_API}/wallet/history`;
    const fetchURL = `${baseURL}?wallet=${address}&from=${from}&blockchains=Ethereum%2CPolygon&limit=50`;
    const { data } = await axios.get(fetchURL, {
      headers: {
        Authorization: process.env.REACT_APP_MOBULA_KEY,
      },
    });
    const res = data?.data?.balance_history;

    console.log("wallet history:", res);

    return res;
  } catch (error) {
    console.log("err in fetching wallet history from mobula", error);
    return [];
  }
};
