import styled from "@emotion/styled";
import { Box, Button, colors, Typography, useTheme } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "store/store";

import { nftTempImage } from "constants/";
import BlueTick from "assets/BlueTick.svg";
import BasicSelect from "components/Select";
import WalletIcon from "assets/walletIcon.svg";
import BackIcon from "assets/back.svg";

import "./index.css";
import BasicButton from "components/Button";

const NftDetails = ({ setStep }) => {
  const { nfts, activeAccount } = useAppSelector(state => state.app);

  const profileNfts = nfts[activeAccount.smartAccountAddress].nfts;

  const [nft, setNft] = useState({});

  const { address, tokenId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const allUserNfts = profileNfts.map(coll => coll.nfts.flat()).flat();
    console.log("allUserNfts", allUserNfts);
    setNft(
      allUserNfts.find(
        nft => nft.tokenId === tokenId && nft.nftAddress === address
      )
    );
  }, [profileNfts]);

  console.log("nft from param", nft);
  return (
    <>
      <div
        onClick={() => navigate("/collectibles")}
        style={{ cursor: "pointer" }}
      >
        <img src={BackIcon} />
      </div>
      <Stack
        direction='row'
        alignItems={"flex-start"}
        justifyContent='space-between'
        width='100%'
        sx={{ marginTop: "20px", marginBottom: "70px" }}
      >
        {/* <Box> */}
        <div className='nft-image'>
          <img
            src={nft?.image}
            alt='nft'
            onError={e => (e.currentTarget.src = nftTempImage)}
            style={{
              width: "424px",
              height: "418px",
              borderRadius: "7px",
            }}
          />
        </div>
        {/* </Box> */}
        <Box
          sx={{
            height: "350px",
            width: "70%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            marginTop: "40px",
            marginLeft: "20px",
          }}
        >
          <Box>
            <Typography variant='h2' fontWeight={500} color={"black"}>{`${
              nft?.name || ""
            } #${tokenId}`}</Typography>
            <Stack direction='row' mt='10px' mb='10px'>
              <img src={BlueTick} alt='ble tick' width={35} />
              <Typography
                variant='body1'
                fontWeight={400}
                color='#1DA1F2'
                style={{ padding: 5 }}
              >
                {nft?.contractName}
              </Typography>
            </Stack>
            <Stack direction='row' mt='10px' mb='10px'>
              <img src={WalletIcon} alt='ble tick' width={25} />
              <Typography
                variant='body2'
                fontWeight={400}
                color={"black"}
                style={{ padding: 5 }}
              >
                {address}
              </Typography>
            </Stack>
            {nft?.properties?.length > 0 && (
              <BasicSelect properties={nft?.properties || []} />
            )}
          </Box>
        </Box>
      </Stack>

      {/* <Box width="100%" marginBottom="100px">
        <SplineChart />
      </Box> */}

      <Box
        display='flex'
        flexDirection='row'
        width='95%'
        marginTop='20px'
        marginBottom='5px'
        alignItems={"center"}
        justifyContent={"center"}
        pl={1}
        sx={{ position: "absolute", bottom: 0 }}
      >
        <BasicButton
          onClick={() => setStep(1)}
          title='Send'
          style={{ width: "100%" }}
          // disabled={nft?.status === ON_LEND1 || nft?.status === ON_RENT}
        />
      </Box>
    </>
  );
};

export default NftDetails;
