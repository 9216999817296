import React, { FC } from 'react'
import { Box, Typography } from '@mui/material'

import './styles.css'

const ChipButton: FC<{
  title?: string
  onClick: () => void
  icon?: string
  fontWeight?: number
  disabled?: boolean
  fontSize?: number
  iconHeight?: number
  iconWidth?: number
  fontFamily?: string
  chipHeight?: number
  paddingInline?: string
  width?: string
}> = ({
  title,
  onClick,
  icon,
  fontWeight = 500,
  disabled = false,
  fontSize = 12,
  iconHeight = 16,
  iconWidth = 16,
  fontFamily = 'Space Grotesk',
  chipHeight,
  paddingInline = '20px',
  width = 'fit-content',
}) => {
  return (
    <Box
      className='btn-chip-box'
      sx={{
        backgroundColor: disabled
          ? 'rgba(235, 236, 238, 0)'
          : 'rgba(235, 236, 238, 1)',
        height: chipHeight ? chipHeight : 40,
        justifyContent: 'center',
        borderRadius: 20,
        width,
        paddingInline,
      }}
      onClick={onClick}
    >
      {icon && (
        <img style={{ width: iconWidth, height: iconHeight }} src={icon} />
      )}
      {title && (
        <Typography
          variant={'h5'}
          fontWeight={fontWeight}
          color={disabled ? 'rgba(26, 28, 32, 0.50)' : 'rgba(26, 28, 32, 1)'}
          fontSize={fontSize}
          marginLeft={!!icon ? 1 : 0}
          fontFamily={fontFamily}
        >
          {title}
        </Typography>
      )}
    </Box>
  )
}

export default ChipButton
