import { Box } from "@mui/material";
import BasicModal from "components/BasicModal";
import React, { FC, useState } from "react";
import PatternLock from "react-pattern-lock/lib/components/PatternLock";
import { useAppSelector } from "store/store";

const ConfirmPatternModal: FC<{
  open: boolean;
  onClose: () => void;

  setIsPatternCorrect: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ onClose, open, setIsPatternCorrect }) => {
  const { userSpendingDetails } = useAppSelector((state) => state.app);

  const [path, setPath] = useState<number[]>([]);
  const [error, setError] = useState(false);

  return (
    <BasicModal
      open={open} //if is spending limit exceed
      onClose={onClose}
    >
      <Box
        width="90%"
        margin={"auto"}
        display={"flex"}
        flexDirection={"column"}
      >
        <PatternLock
          width={300}
          pointSize={25}
          size={3}
          path={path}
          disabled={false}
          error={false}
          success={true}
          onChange={(pattern) => {
            console.log(
              "🚀 ~ file: DrawPattern.tsx:58 ~ DrawPattern ~ pattern:",
              pattern
            );
            setPath(pattern);

            // this.setState({ path : pattern });
          }}
          onFinish={() => {
            let areArraysEqual =
              path.length === userSpendingDetails.path.length &&
              path.every(
                (value, index) => value === userSpendingDetails.path[index]
              );

            if (!areArraysEqual) {
              setPath([]);
              setError(true);
            } else {
              setError(false);
              setIsPatternCorrect(true);
            }
          }}
        />
        {error && (
          <div style={{ color: "red" }}>Pattern Incorrect</div> // Style as needed
        )}
      </Box>
    </BasicModal>
  );
};

export default ConfirmPatternModal;
