import {
  SupportedChainId,
  SUPPORTED_NETWORKS,
  TESTNET_NETWORKS,
  CASH_SUPPORTED_NETWORK,
} from "constants/chains";
import { ethers } from "ethers";

export const fetchBaseChainGasPrice = async (network: SupportedChainId) => {
  try {
    const { rpc } = CASH_SUPPORTED_NETWORK[network];

    const ethersProvider = new ethers.providers.JsonRpcProvider(rpc);

    let [safeLow, average, fast] = [0, 0, 0];

    const priority = await ethersProvider.getFeeData();
    safeLow = +priority?.lastBaseFeePerGas || +priority?.maxFeePerGas / 2;
    fast = +priority?.maxFeePerGas || 0;

    if (TESTNET_NETWORKS.includes(network)) {
      average =
        network == SupportedChainId.MANTLE_TESTNET
          ? priority.gasPrice?.toString()
          : Math.ceil((fast + safeLow) / 1.25);
    } else {
      average = (safeLow + fast) / 2;
      console.log("average", { safeLow, average, fast });
    }

    console.log("SLOW", safeLow);
    console.log("FAST", fast);
    console.log("average", average);

    return { safeLow, average: Math.ceil(average), fast };
    // return {
    //   safeLow: Math.ceil(safeLow),
    //   average: Math.ceil(average),
    //   fast: Math.ceil(fast),
    //   avgWait: 1,
    //   fastWait: 0.5,
    //   safeLowWait: 1.5,
    // };
  } catch (error) {
    throw error;
  }
};

export const fetchGasPrice = async (network: SupportedChainId) => {
  try {
    const { rpc } = SUPPORTED_NETWORKS[network];

    const ethersProvider = new ethers.providers.JsonRpcProvider(rpc);

    let [safeLow, average, fast] = [0, 0, 0];
    if (
      +network === SupportedChainId.BSCT ||
      +network === SupportedChainId.BSC
    ) {
      const gasPrice = await ethersProvider.getGasPrice();
      console.log("gasPrice", gasPrice);

      const gasPriceInGwei = gasPrice;
      safeLow = gasPriceInGwei;
      average = gasPriceInGwei;
      fast = gasPriceInGwei;
    } else {
      const priority = await ethersProvider.getFeeData();
      safeLow = +priority?.lastBaseFeePerGas || +priority?.maxFeePerGas / 2;
      fast = +priority?.maxFeePerGas || 0;

      if (TESTNET_NETWORKS.includes(network)) {
        average =
          network == SupportedChainId.MANTLE_TESTNET
            ? priority.gasPrice?.toString()
            : Math.ceil((fast + safeLow) / 1.25);
      } else {
        average = (safeLow + fast) / 2;
        console.log("average", { safeLow, average, fast });
      }

      console.log("SLOW", safeLow);
      console.log("FAST", fast);
      console.log("average", average);
    }

    return network == SupportedChainId.MANTLE_TESTNET
      ? { safeLow, average, fast }
      : { safeLow, average: Math.ceil(average), fast };
    // return {
    //   safeLow: Math.ceil(safeLow),
    //   average: Math.ceil(average),
    //   fast: Math.ceil(fast),
    //   avgWait: 1,
    //   fastWait: 0.5,
    //   safeLowWait: 1.5,
    // };
  } catch (error) {
    throw error;
  }
};

export const fetchGasPriceForNativeMaxSwap = async (
  network: SupportedChainId
) => {
  try {
    const { rpc } = SUPPORTED_NETWORKS[network];

    const ethersProvider = new ethers.providers.JsonRpcProvider(rpc);

    let [safeLow, average, fast] = [0, 0, 0];
    if (
      +network === SupportedChainId.BSCT ||
      +network === SupportedChainId.BSC
    ) {
      const gasPrice = await ethersProvider.getGasPrice();
      console.log("gasPrice", gasPrice);

      const gasPriceInGwei = gasPrice;
      safeLow = gasPriceInGwei;
      average = gasPriceInGwei;
      fast = gasPriceInGwei;
    } else {
      const priority = await ethersProvider.getFeeData();
      safeLow = +priority?.lastBaseFeePerGas || +priority?.maxFeePerGas / 2;
      fast = +priority?.maxFeePerGas || 0;

      if (TESTNET_NETWORKS.includes(network)) {
        average =
          network == SupportedChainId.MANTLE_TESTNET
            ? priority.gasPrice?.toString()
            : Math.ceil((fast + safeLow) / 1.25);
      } else {
        average = fast;
        console.log("average in max swap", { safeLow, average, fast });
      }

      console.log("SLOW", safeLow);
      console.log("FAST", fast);
      console.log("average", average);
    }

    return network == SupportedChainId.MANTLE_TESTNET
      ? average
      : Math.ceil(average);
    // return {
    //   safeLow: Math.ceil(safeLow),
    //   average: Math.ceil(average),
    //   fast: Math.ceil(fast),
    //   avgWait: 1,
    //   fastWait: 0.5,
    //   safeLowWait: 1.5,
    // };
  } catch (error) {
    throw error;
  }
};

export const gasPriceData = async (chainId: number) => {
  try {
    const { rpc } =
      SUPPORTED_NETWORKS[chainId as keyof typeof SUPPORTED_NETWORKS];

    const ethersProvider = new ethers.providers.JsonRpcProvider(rpc);

    const priority = await ethersProvider.getFeeData();

    const safeLow = Number(priority.gasPrice);
    const fast = Number(priority.maxFeePerGas);
    const average = Math.ceil((fast + safeLow) / 2);

    return {
      status: "Ok",
      message: "",
      data: {
        safeLow: Math.ceil(safeLow),
        average: Math.ceil(average),
        fast: Math.ceil(fast),
        avgWait: 1,
        fastWait: 0.5,
        safeLowWait: 1.5,
      },
    };
  } catch (error) {
    return {
      status: "Not Ok",
      message: `${error}`,
      data: {
        safeLow: 0,
        average: 0,
        fast: 0,
        avgWait: 1,
        fastWait: 0.5,
        safeLowWait: 1.5,
      },
    };
  }
};

export const fetchTransactionCostInNativeToken = async (
  estimateGas: number,
  gwei: number
) => {
  const transactionCostInGwei = estimateGas * gwei;

  const transactionCostInNativeToken = transactionCostInGwei / Math.pow(10, 9);

  return transactionCostInNativeToken;
};
