import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import RecoveryIcon from "assets/recoveryKeyIcon.svg";
import SeedPhraseIcon from "assets/seedPhraseIcon.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { useAppDispatch, useAppSelector } from "../../../../../../store/store";
import { setSettingsOpenOption } from "../../../../../../store/slices/appSlice";
import { SETTINGS_OPENED_OPTIONS } from "../../../../../../interfaces";

const ViewDetails = () => {
  const { settingsOpenOption } = useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();

  return (
    <>
      {/* <Snackbar
         open={showSnackbar}
         autoHideDuration={6000}
         onClose={() => setShowSnackbar(false)}
         message="Stashed tag copied!"
       /> */}
      <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <Box>
          <Typography variant="body2">View details</Typography>
        </Box>
        <Box
          fontWeight={200}
          fontSize={14}
          p={3}
          borderRadius={"10px"}
          lineHeight={1.4}
          display={"flex"}
          gap={1}
          alignItems={"center"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          sx={{ background: "#FFFFFF", cursor: "pointer" }}
          onClick={() => {
            dispatch(setSettingsOpenOption(SETTINGS_OPENED_OPTIONS.RECOVERY));
          }}
        >
          <Box
            display={"flex"}
            gap={1}
            alignItems={"center"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            width={"100%"}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <img src={RecoveryIcon} height={20} width={20} />
              <Typography
                marginLeft={1}
                fontWeight={500}
                variant="body1"
                fontSize={"16px"}
              >{`Recovery Key`}</Typography>
            </div>
            <div>
              <ExpandMoreIcon
                style={{
                  transform:
                    settingsOpenOption == SETTINGS_OPENED_OPTIONS.RECOVERY
                      ? "rotate(180deg)"
                      : "rotate(270deg)",
                  color: "#8C8D8F",
                }}
              />
            </div>
          </Box>
          <Box></Box>
        </Box>
        <Box
          fontWeight={200}
          fontSize={14}
          p={3}
          borderRadius={"10px"}
          lineHeight={1.4}
          display={"flex"}
          gap={1}
          alignItems={"center"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          sx={{ background: "#FFFFFF", cursor: "pointer" }}
          onClick={() => {
            dispatch(setSettingsOpenOption(SETTINGS_OPENED_OPTIONS.SEEDPHRASE));
          }}
        >
          <Box
            display={"flex"}
            gap={1}
            alignItems={"center"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            width={"100%"}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <img src={SeedPhraseIcon} height={20} width={20} />
              <Typography
                marginLeft={1}
                fontWeight={500}
                variant="body1"
                fontSize={"16px"}
              >{`Seed Phrase`}</Typography>
            </div>
            <ExpandMoreIcon
              style={{
                transform:
                  settingsOpenOption == SETTINGS_OPENED_OPTIONS.SEEDPHRASE
                    ? "rotate(180deg)"
                    : "rotate(270deg)",
                color: "#8C8D8F",
              }}
            />
          </Box>
          <Box></Box>
        </Box>
      </Box>
    </>
  );
};

export default ViewDetails;
