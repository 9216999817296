import React from "react";
import SettingsView from "./View";

const NewSettings = () => {
  return (
    <>
      <SettingsView />
    </>
  );
};

export default NewSettings;
