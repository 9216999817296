// import ProjectInfoCard from '@/components/ProjectInfoCard'

import { Box, Button, Divider } from "@mui/material";
import ProjectInfoCard from "../components/ProjectInfoCard";
import RequestDetailsCard from "../components/RequestDetailsCard";
// import RequesDetailsCard from '@/../components/RequestDetalilsCard'

import RequestMethodCard from "../components/RequestMethodCard";
// import RequestMethodCard from '@/../components/RequestMethodCard'

import RequestModalContainer from "../components/RequestModalContainer";
// import RequestModalContainer from '@/components/RequestModalContainer'

// import ModalStore from '@/store/ModalStore'

// import { approveEIP155Request, rejectEIP155Request } from '@/utils/EIP155RequestHandlerUtil'

// import { getSignParamsMessage, styledToast } from '@/utils/HelperUtil'

// import { signClient } from '@/utils/WalletConnectUtil'

// import { Button, Col, Divider, Modal, Row, Text } from '@nextui-org/react'
import { Fragment } from "react";
import {
  approveEIP155Request,
  rejectEIP155Request,
} from "../utils/EIP155RequestHandlerUtil";
import { getSignParamsMessage, styledToast } from "../utils/HelperUtil";
import { signClient } from "../utils/WalletConnectUtil";
import ModalStore from "../walletConnectStore/ModalStore";

export default function SessionSignModal() {
  // Get request and wallet data from store
  const requestEvent = ModalStore.state.data?.requestEvent;
  const requestSession = ModalStore.state.data?.requestSession;

  // Ensure request and wallet are defined
  if (!requestEvent || !requestSession) {
    return <p>Missing request data</p>;
  }

  // Get required request data
  const { topic, params } = requestEvent;
  const { request, chainId } = params;

  // Get message, convert it to UTF8 string if it is valid hex
  const message = getSignParamsMessage(request.params);

  // Handle approve action (logic varies based on request method)
  async function onApprove() {
    if (requestEvent) {
      const response = await approveEIP155Request(requestEvent);
      try {
        await signClient.respond({
          topic,
          response,
        });
      } catch (e) {
        styledToast((e as Error).message, "error");
        return;
      }
      ModalStore.close();
    }
  }

  // Handle reject action
  async function onReject() {
    if (requestEvent) {
      const response = rejectEIP155Request(requestEvent);
      try {
        await signClient.respond({
          topic,
          response,
        });
      } catch (e) {
        styledToast((e as Error).message, "error");
        return;
      }
      ModalStore.close();
    }
  }

  return (
    <Box
      sx={{
        background: "white",
        overflowY: "scroll",
        width: "50%",
        height: "70%",
        transform: "translateX(50%) translateY(10%)",
        padding: "10px",
      }}
    >
      <RequestModalContainer title="Sign Message">
        <ProjectInfoCard metadata={requestSession.peer.metadata} />

        <Divider />

        <RequestDetailsCard
          chains={[chainId ?? ""]}
          protocol={requestSession.relay.protocol}
        />

        <Divider />

        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h5>Message</h5>
            <p data-testid="request-message-text">{message}</p>
          </div>
        </div>

        <Divider />

        <RequestMethodCard methods={[request.method]} />
      </RequestModalContainer>

      <div>
        <Button
          color="error"
          onClick={onReject}
          data-testid="request-button-reject"
        >
          Reject
        </Button>
        <Button
          color="success"
          onClick={onApprove}
          data-testid="request-button-approve"
        >
          Approve
        </Button>
      </div>
    </Box>
  );
}
