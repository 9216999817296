import React, { ReactNode, useState } from "react";
import { Button, Collapse, Paper, Typography, makeStyles } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/system";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { setSettingsOpenOption } from "../../../../store/slices/appSlice";

function CollapeWrapper({
  // LeftComponent,
  title,
  BodyComponent,
  icon,
  darkIcon,
  defaultOpenState = false,
  background = "#F7F7F7",
}: {
  // LeftComponent: ReactNode;
  title: string;
  BodyComponent: ReactNode;
  icon: any;
  darkIcon: any;
  defaultOpenState?: boolean;
  background?: string;
}) {
  const [open, setOpen] = useState(defaultOpenState);
  const dispatch = useAppDispatch();
  const { settingsOpenOption, user } = useAppSelector((state) => state.app);
  const toggleCollapse = () => {
    if (!settingsOpenOption) setOpen(!open);
    if (settingsOpenOption) {
      setOpen(!open);
      dispatch(setSettingsOpenOption(null));
    }
    if (open) {
      dispatch(setSettingsOpenOption(null));
    }
  };

  return (
    <Paper
      elevation={3}
      sx={{
        borderRadius: "10px",
        boxShadow: "0px 0px 0px rgba(0, 0, 0, 0)",
        background,
      }}
      onClick={() => {
        console.log(
          "file: CollapeWrapper.tsx:114  settingsOpenOption:",
          settingsOpenOption
        );
      }}
    >
      <Box
        onClick={toggleCollapse}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "25px",
          cursor: "pointer",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
          }}
        >
          {open ? (
            <img src={darkIcon} height={20} width={20} />
          ) : (
            <img src={icon} height={20} width={20} />
          )}
          <Typography variant="body2" fontWeight={500} fontSize={16}>
            {title}
          </Typography>
        </Box>
        {/* <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          <SortOutlinedIcon />
          <Typography variant="h6">{title}</Typography>
        </Box> */}
        <ExpandMoreIcon
          style={{
            transform: open ? "rotate(180deg)" : "rotate(270deg)",
            color: "#8C8D8F",
          }}
        />
      </Box>
      <Collapse in={open}>
        <Box sx={{ padding: "0px 30px 30px 30px" }}>{BodyComponent}</Box>
      </Collapse>
    </Paper>
  );
}

export default CollapeWrapper;
