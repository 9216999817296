import React, { ReactNode, useState } from "react";
import { Button, Collapse, Paper, Typography, makeStyles } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/system";

function TransactionCollapeWrapper({
  TitleComponent,
  BodyComponent,
  defaultOpenState = false,
}: {
  TitleComponent: ReactNode;
  BodyComponent: ReactNode;
  defaultOpenState?: boolean;
}) {
  const [open, setOpen] = useState(defaultOpenState);

  const toggleCollapse = () => {
    setOpen(!open);
  };

  return (
    <Paper
      elevation={3}
      sx={{
        borderRadius: "10px",
        background: "#F7F7F7",
        // "&:hover": {
        //   backgroundColor: "lightgray",
        // },
        boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.0)",
      }}
    >
      <Box
        onClick={toggleCollapse}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          //   padding: "25px",
          cursor: "pointer",
        }}
      >
        {TitleComponent}
        {/* <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          <SortOutlinedIcon />
          <Typography variant="h6">{title}</Typography>
        </Box> */}

        {/* <ExpandMoreIcon
          style={{ transform: open ? "rotate(180deg)" : "rotate(0deg)" }}
        /> */}
      </Box>
      <Collapse in={open}>
        <Box sx={{ padding: "0px 20px 20px 20px" }}>{BodyComponent}</Box>
      </Collapse>
    </Paper>
  );
}

export default TransactionCollapeWrapper;
