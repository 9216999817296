import React from "react";
import { Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";

interface ImageViewerProps {
  imageUrl: string;
  title: string;
  customStyles?: React.CSSProperties;
}

const ImageViewer: React.FC<ImageViewerProps> = ({
  imageUrl,
  title,
  customStyles,
}) => {
  const styles = {
    image: {
      maxWidth: "100%",
      maxHeight: "80vh",
      borderRadius: "20px",
    },
  };

  return (
    <img
      src={imageUrl}
      alt={title}
      style={customStyles ? customStyles : styles.image}
    />
  );
};

export default ImageViewer;
