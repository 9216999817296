import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import BlueTick from "assets/BlueTick.svg";

const CollapseBody = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <Box>
        <Typography variant="h6">
          {title}
          <img src={BlueTick} style={{ marginLeft: "5px" }} alt={"blue tick"} />
        </Typography>
      </Box>
      <Box fontWeight={200} fontSize={14} lineHeight={1.4}>
        {description}
      </Box>
    </Box>
  );
};

export default CollapseBody;
