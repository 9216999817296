import React, { useEffect } from "react";

import { showAlert } from "utils/utils";

const Earn = () => {
  useEffect(() => {
    showAlert("Coming Soon");
  }, []);

  return <div className="earn-container"></div>;
};

export default Earn;
