import { Grid, Slide, Typography } from '@mui/material'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/store'
import { Asset } from 'interfaces'
import TokenImage from 'components/TokenImage'
import { numFormatter } from 'utils/utils'
import CardLayout from '../../../../components/CardLayout'
import { Box } from '@mui/system'
import Button from '../../../../components/NewButton'
import Globe from 'assets/emptyCryptoList.svg'
import { setSelectedToken } from '../../../../store/slices/walletSlice'
import {
  WRAPPED_TOKEN_ADDRESS,
  tokensForGas,
} from '../../../../constants/topTokensConf'
import { SUPPORTED_NETWORKS } from '../../../../constants/chains'

const ListComponent: FC<{
  asset: Asset
  nextStep?: () => void
  transactionForm?: boolean
}> = ({ asset, nextStep = () => {}, transactionForm = false }) => {
  console.log('file: TokensList.tsx:19  asset: selectedToken', asset)
  const navigate = useNavigate()
  const { nfts, activeAccount, activeNetwork } = useAppSelector(
    state => state.app
  )
  console.log('file: index.tsx:17  activeNetwork:', activeNetwork)
  const { amount: tokenBalance, symbol: tokenSymbol } = numFormatter(
    asset.token_balance,
    4
  )
  const dispatch = useAppDispatch()
  const { amount: usdBalance, symbol: usdSymbol } = numFormatter(
    asset.estimated_balance,
    2
  )
  return (
    <Grid
      sx={{
        padding: '10px',
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        cursor: 'pointer',
        borderRadius: '10px',
        '&:hover': {
          backgroundColor: 'lightgray',
        },
      }}
      onClick={() => {
        if (transactionForm) {
          console.log(
            'file: TokensList.tsx:44 selectedToken  transactionForm:',
            transactionForm,
            asset
          )
          const matchingObject = Object.values(asset.cross_chain_balances).find(
            balanceObject => balanceObject.chainId === activeNetwork
          )
          console.log(
            'file: TokensList.tsx:57  matchingObject: transactionForm',
            matchingObject
          )
          if (matchingObject) {
            dispatch(
              setSelectedToken([
                // { asset },
                {
                  id: asset.asset.id,
                  image: asset.asset.logo,
                  priceInUSD: asset.price,
                  tokenAddress: matchingObject.address,
                  tokenBalance: matchingObject.balance,
                  tokenDecimal:
                    (
                      SUPPORTED_NETWORKS[
                        activeNetwork as keyof typeof SUPPORTED_NETWORKS
                      ]?.topTokens as any
                    )[asset.asset.symbol]?.decimals ?? 18,
                  tokenName: asset.asset.name,
                  tokenPrice: asset.price,
                  tokenSymbol: asset.asset.symbol,
                  isNative:
                    matchingObject.address ==
                    WRAPPED_TOKEN_ADDRESS[activeNetwork],
                },
              ])
            )
          }
          nextStep()
        } else {
          navigate(asset.asset.name)
        }
      }}
    >
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
          alignItems: 'center',
        }}
      >
        <TokenImage logo={asset.asset.logo} />
        <Grid
          sx={{
            color: '#1A1C20',
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
            fontFamily: 'Space Grotesk',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: 'normal',
          }}
        >
          <div>{asset.asset.name}</div>
          <div
            style={{
              // color: "rgba(26, 28, 32, 0.50)",
              fontFamily: 'Helvetica Neue',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: 'normal',
            }}
          >
            <span
              style={{
                color: 'rgba(26, 28, 32, 0.50)',
              }}
            >
              {tokenBalance + tokenSymbol + ' '} {asset.asset.symbol}
            </span>
          </div>
        </Grid>
      </Grid>
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
          alignItems: 'center',
        }}
      >
        <Grid
          sx={{
            color: '#1A1C20',
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
            fontFamily: 'Space Grotesk',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: 'normal',
          }}
        >
          <div>${usdBalance + usdSymbol}</div>
          {/* <div
              style={{
                // color: "rgba(26, 28, 32, 0.50)",
                fontFamily: "Helvetica Neue",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }}
            >
              Min Price
            </div> */}
        </Grid>
      </Grid>
    </Grid>
  )
}

const TokensList: FC<{
  transactionForm?: boolean
  nextStep?: () => void
  filterText?: string
}> = ({ transactionForm = false, nextStep = () => {}, filterText = '' }) => {
  const navigate = useNavigate()
  const {
    portfolio: { assets },
    activeNetwork,
  } = useAppSelector(state => state.app)

  const updatedAssets = assets.filter(item => {
    const matchingObject = Object.values(item.cross_chain_balances).find(
      (balanceObject: any) => balanceObject.chainId === activeNetwork
    )

    if (matchingObject) {
      return true
    }
    return false
  })

  const chainAssets = updatedAssets.filter(asset =>
    tokensForGas.some(gasToken => gasToken.token === asset?.asset?.symbol)
  )
  return (
    <Grid
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        width: '100%',
        paddingTop: '20px',
      }}
    >
      {chainAssets && chainAssets.length > 0 ? (
        (() => {
          const filteredAssets = chainAssets.filter(
            item =>
              item?.asset?.name
                ?.toLowerCase()
                ?.includes(filterText.toLowerCase()) ||
              item?.asset?.symbol
                ?.toLowerCase()
                ?.includes(filterText.toLowerCase())
          )

          if (filteredAssets.length === 0) {
            return (
              <Box textAlign={'center'} py={5}>
                <Typography variant='h3'>No results found</Typography>
              </Box>
            )
          }

          return filteredAssets.map(asset => (
            <ListComponent
              asset={asset}
              nextStep={nextStep}
              transactionForm={transactionForm}
            />
          ))
        })()
      ) : (
        <>
          {transactionForm ? (
            <>
              {' '}
              <Box textAlign={'center'} py={5}>
                <Typography variant='h3'>No Asset on Selected Chain</Typography>
              </Box>
            </>
          ) : (
            <>
              {' '}
              <CardLayout
                backgroundColor='white'
                style={{
                  flex: 1,
                  width: '100%',
                  marginTop: '40px',
                  marginBottom: '40px',
                  overflow: 'hidden',
                }}
              >
                <Slide in={true} timeout={500} direction={'down'}>
                  <Box
                    className='flex-col-center'
                    style={{
                      padding: '20px 0px',
                      height: '275px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Typography
                      variant={'h5'}
                      fontWeight={700}
                      color='rgba(26, 28, 32, 1)'
                      fontSize={25}
                      style={{ cursor: 'pointer' }}
                    >
                      Start your Stash!{' '}
                    </Typography>
                    <Typography
                      variant={'h5'}
                      fontWeight={400}
                      color='rgba(26, 28, 32, 0.5)'
                      fontSize={12}
                      style={{ cursor: 'pointer' }}
                    >
                      Get started!
                    </Typography>
                    <img
                      src={Globe}
                      width={'100%'}
                      height={'100%'}
                      style={{
                        overflow: 'hidden',
                      }}
                    />

                    <div
                      style={{
                        width: '70%',
                        maxWidth: '305px',
                        marginTop: '-10%',
                      }}
                    >
                      <Button
                        title='Receive'
                        style={{
                          backgroundColor: 'rgba(235, 236, 238, 1)',
                          margin: 0,
                        }}
                        textStyle={{ color: 'rgba(26, 28, 32, 1)' }}
                        onClick={() => {
                          navigate('/crypto/receive')
                        }}
                      />
                    </div>
                  </Box>
                </Slide>
              </CardLayout>
            </>
          )}
        </>
      )}
    </Grid>
  )
}

export default TokensList
